import styled from 'styled-components';
import dropdown from '../../images/dropdown.svg';

export const Lock = styled.span`
  border-radius: 2px;
  background-color: #fdaf5c;
  padding: 3px 5px !important;
  color: #ffffff;
  font-size: 12px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 23px;
  width: 93px;
  cursor: pointer;
  z-index: 3;
  span {
    width: 70px;
    height: 15px;
  }
  img {
    width: 7px;
    height: 8.6px;
  }
`;

export const AlertTimeWidget = styled.ul`
  list-style-type: none;
  padding-left: 11px;
  line-height: 5px;
  display: flex;
  width: 250px;
  height: 35px;

  &.disabled-ul {
    opacity: 0.56;
    pointer-events: none;
  }

  &.large li {
    margin: 0 24px;
  }
  &.small li {
    margin: 0 20px;
  }

  li {
    display: block;
    margin: 0 12.2%;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    input[type='radio'] {
      border-radius: 30px;
      box-shadow: none;
      display: none;
      height: 14px;
      width: 14px;
      position: absolute;
    }

    input[type='radio']:checked:after {
      content: '';
      display: none;
      position: absolute;
      height: 8px;
      width: 8px;
      top: 1px;
      left: 1px;
      border: 1px solid var(--text-link);
      background: var(--text-link);
      border-radius: 30px;
    }

    .mins {
      position: absolute;
      width: 38px;
      height: 14px;
      font-size: 10px;
      color: #7d7d7d;
      top: 25px;
      display: inline-block;
      left: -9px;
      letter-spacing: 0.2px;
    }
  }
  .alertspan {
    font-size: 12px !important;
    color: #383838 !important;
  }
`;

export const Contain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inputStyle {
    margin-bottom: 0 !important;
    width: 268px;
  }

  .Select {
    width: 146px;
    height: 30px;

    .Select-control {
      border-radius: 5px;
      height: 30px;
      border: 0;

      .Select-value {
        background: #f7f5f2;
        border: 0;
        line-height: 30px;
      }
    }
  }

  input {
    margin-bottom: 0 !important;
  }

  .Select-input {
    height: 30px;
  }
`;

export const CustomHeaders = styled.div`
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    width: 100%;

    .remove-header {
      padding: 2px 5px;
      border-radius: 3px;
      background-color: #ffffff;
      border: solid 1px #d3d3d3;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      cursor: pointer;

      &.disabled {
        background-color: #d3d3d3;
        pointer-events: none;
      }

      svg {
        width: 12px;
        height: 12px;
        opacity: 0.5;
        path {
          stroke: #a3a3a3;
        }
      }
    }

    input [type='text'] {
      display: inline-block;
    }
  }

  .add-custom-header {
    text-align: right;
    color: #ed7c02;
    cursor: pointer;
    margin-top: 15px;
  }
`;

export const AdvancedSettingsRender = styled.div`
  margin-top: 15px;
  border: 1px solid #e4e2e2;
  border-radius: 3px;
  width: 100%;

  .noaccess {
    position: relative;
    margin-top: 15px;
  }

  .apdex-allow {
    display: inline-block !important;
  }

  .as_body {
    border: 0;
    max-height: 0;
    opacity: 0;
    box-sizing: border-box;
    padding: 0 15px;
    width: inherit;
    overflow: auto;
    margin-bottom: 0;

    .features {
      display: none;
    }

    .as_logourl {
      margin-top: 20px;
    }
    .as_form-help {
      font-size: 12px;
      line-height: 1.55;
      color: #767676;
      b {
        font-weight: 500;
      }
    }
    .as_logo-preview {
      border-radius: 4px;
      background-color: #f9f9f9;
      border: solid 1px #dedede;
      text-align: center;
      display: block;
      padding: 5px 0;
      margin-top: 10px;
      img {
        max-height: 90px;
        max-width: 100%;
        padding: 5px 20px;
        box-sizing: border-box;
      }
    }
  }

  .as_body.block {
    max-height: 961px;
    opacity: 1;
    padding: 0 15px 15px;
    border-top: 1px solid #e4e2e2;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .features {
      display: block;
    }

    ul.as_list {
      margin: 0;
      list-style-type: none;
      padding: 0;

      li.as_item {
        &:last-child {
          border-bottom: 0;
        }

        &.dashed {
          border-bottom-style: dashed;
        }

        .Select.user-type-dropdown {
          .Select-control {
            border-width: 0;
            border-bottom-width: 1px;
            border-color: #dadfe3 !important;

            .Select-value {
              padding-left: 0;
            }

            .Select-input {
              height: 30px;
            }
          }
        }

        .Select.response-check-dropdown {
          margin-bottom: 10px;
          border-radius: 5px;
          width: 155px;
          float: left;
          .Select-control {
            border-width: 0;
            border-bottom-width: 1px;
            border-radius: 5px;
            background-color: rgb(245, 245, 245);
            .Select-value {
              padding-right: 1;
            }
          }
        }
        .Select.is-open {
          box-shadow: none;
        }

        .Select-menu-outer {
          border: 1px solid #e4e2e2;
          .Select-menu {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
        }

        input[type='text']:focus::-webkit-calendar-picker-indicator,
        input[type='text']:hover::-webkit-calendar-picker-indicator {
          display: none;
        }

        &.alert-notes {
          .edfSeh {
            height: 14px;

            .cosmos-tooltip {
              left: 22px;
              top: 15px;
              width: 210px;
              z-index: 4;
            }
          }
        }

        .sslswitch,
        .performanceswitch {
          float: right;
          margin-top: 24px;
        }

        .sslswitchdisabled,
        .performanceswitchdisabled {
          pointer-events: none;
        }

        .label {
          font-weight: 500;
          font-size: 12px;
          color: #222222;
          opacity: 0.5;
        }
      }
    }
    .edfSeh {
      display: inline !important;
    }
  }

  .as_header {
    background-color: #fff9f3;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    padding: 10px;
    color: black;
    transition: 0.15s ease;

    .as_title {
      font-weight: 500;
    }
    .as_helptext {
      font-size: 12px;
      color: #363636;
      margin-top: 4px;
      opacity: 0.5;
      line-height: 1.43;
      width: 95%;
    }
    .as_close {
      position: absolute;
      right: 10px;
      top: calc(50% - 4px);
      background: url(${dropdown}) no-repeat center center;
      height: 14px;
      width: 14px;
    }

    .as_open {
      position: absolute;
      right: 10px;
      top: calc(50% - 4px);
      background: url(${dropdown}) no-repeat center center;
      height: 14px;
      width: 14px;
      transform: rotate(270deg);
    }

    .updown-arrow {
      transform: rotate(0);
      bottom: 50%;
      left: 95%;
      &:before,
      &:after {
        background-color: transparent;
        width: 2px;
        height: 5px;
        display: inline-block;
        position: absolute;
        border-bottom: 9px solid #979797;
        left: 0;
        transform: rotate(0);
      }
      &:before {
        transform: rotate(-135deg);
      }
      &:after {
        transform: rotate(135deg);
      }
    }

    &.active {
      .updown-arrow {
        transform: rotate(0);
        transform: rotate(270deg) !important;

        &:before {
          transform: rotate(-225deg);
        }
        &:after {
          transform: rotate(225deg);
        }
      }
    }
  }

  .updown-arrow {
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: -5px;
    left: -10px;
    transition: 0.4s ease;
    margin-top: 2px;
    text-align: left;
    transform: rotate(45deg);

    &:before,
    &:after {
      position: absolute;
      content: '';
      display: inline-block;
      width: 6px;
      height: 3px;
      background-color: #fff;
      transition: 0.4s ease;
    }
    &:after {
      position: absolute;
      transform: rotate(90deg);
      top: -5px;
      left: 5px;
    }
  }
  &.active {
    .updown-arrow {
      transform: rotate(270deg) !important;

      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }
  .advanced-settings__apdex .cosmos-tooltip {
    max-width: 352px;
    left: 22px;
    padding: 8px;
    line-height: 1.4;
    z-index: 1;
  }
  .__react_component_tooltip {
    border: 0 !important;
    height: 20px !important;
  }
  .disabledfeatures {
    background-color: #ececec !important;
    opacity: 0.2;
    z-index: 1 !important;
    pointer-events: none !important;
    border-bottom: 1px dashed #333;
    border-top: 1px dashed #333;
    .features {
      display: inline-flex;
    }
  }
  .disabledtextarea {
    pointer-events: none !important;
    height: 15px;
    overflow: hidden;
  }
  li.disabled-li {
    pointer-events: none;
    display: none !important;
  }
`;

export const InputRangeStyleContainer = styled.div`
  position: relative;
  .input-range__track {
    background: #d8d8d8;
    :hover {
      background: #d8d8d8;
    }
  }
  .input-range--disabled .input-range__track {
    :hover {
      background: #eeeeee;
    }
  }
  .input-range__track--active {
    background: #ffd09e !important;
  }
  .input-range__slider {
    background: #ed7c02 !important;
    border: solid 1px #ed7c02;
  }
  .input-range--disabled .input-range__slider {
    background: #eeeeee !important;
    border: solid 1px #eeeeee !important;
    :hover {
      background: #eeeeee !important;
      border: solid 1px #eeeeee !important;
    }
  }
`;

export const Garden = styled.div`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

  label {
    display: inline-flex;
  }

  .sslcheckbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    padding-top: 15px;
  }

  .sslcheckboxenabled {
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
      opacity: 1;
    }
  }

  .performancealert-options {
    height: 70px;

    &.inactive {
      opacity: 0.3;
      pointer-events: none;
    }

    label {
      padding-top: 10px;
      float: left;
    }

    .performancealert-option {
      display: inline-block;
      float: right;
      width: 265px;
      padding-top: 6px;

      .input-range {
        width: 185px;
      }
    }
  }
`;
export const FeatureLock = styled.div`
  opacity: 1 !important;
  background: #fbfbfb;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(251, 251, 251, 0.6);

  a {
    text-decoration: none;
    color: #fff;
    width: 100%;
  }

  .feature-text {
    top: calc(50% + 30px);
    color: #6e6e6e;
    margin: 0 25%;
    position: absolute;
    text-align: center;
    font-weight: 500;

    >div {
      width: 100%
      padding: 5px;
      background: rgba(251, 251, 251, 1);
    }
  }

  .feature-lock {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 45px);
    border-radius: 2px;
    background-color: #fdaf5c;
    padding: 3px 5px;
    color: #ffffff;
    font-size: 12px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 90px;
    height: 24px;
    z-index: 3;
    text-align: center;

    span {
      vertical-align: middle;
      padding: 0 2px;
    }

    img {
      width: 7px;
      height: 8px;
    }
  }
`;

export const Blossom = styled.div`
  width: 150px !important;
  border-radius: 4px;
  background-color: #ed7c02;
  padding: 15px 5px !important;
  color: #ffffff;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  height: 25px;
  cursor: pointer;
  span,
  img {
    display: inline-block !important;
    padding: 0 2px;
  }

  &.lock-only {
    width: 14px !important;
    height: 20px;
    background-color: #ffffff;
  }
`;

export const OptionalSettingsRender = styled.div`
  margin-top: 15px;
  border: 1px solid #e4e2e2;
  border-radius: 3px;
  width: 100%;

  .as_body {
    border: 0;
    max-height: 0;
    opacity: 0;
    box-sizing: border-box;
    padding: 0 15px;
    width: inherit;
    overflow:auto;
    margin-bottom:0;

    .as_logourl {
      margin-top: 20px;
    }
    .as_form-help {
      font-size: 12px;
      line-height: 1.55;
      color: #767676;
      b {
        font-weight: 500;
      }
    }
    .as_logo-preview {
      border-radius: 4px;
      background-color: #f9f9f9;
      border: solid 1px #dedede;
      text-align: center;
      display: block;
      padding: 5px 0;
      margin-top: 10px;
      img {
        max-height: 90px;
        max-width: 100%;
        padding: 5px 20px;
        box-sizing: border-box;
      }
    }
  }

  h4.title-text {
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .as_body.block {
    max-height: 545px;
    opacity: 1;
    padding: 0 15px 15px;
    border-top: 1px solid #e4e2e2;
    overflow: auto;
    position: relative;

    ul.as_list {
      margin: 0;
      list-style-type: none;
      padding: 0;

      li.as_item {
        border-bottom: 1px solid #e4e4e4;

        &:last-child {
          border-bottom:0;
        }

        &.dashed {
          border-bottom-style:dashed;
        }

        .Select.user-type-dropdown {

          .Select-control {
            border-width: 0;
            border-bottom-width: 1px;
            border-color:#dadfe3 !important;
          }
        }

        input[type="text"]:focus::-webkit-calendar-picker-indicator,
        input[type="text"]:hover::-webkit-calendar-picker-indicator {
          display: none;
        }

        &.alert-notes {
          .edfSeh{
            height: 14px;

            .cosmos-tooltip{
              left: 20px;
              top: 15px;
              width: 210px
              z-index:4;
            }
          }
        }

        .label {
          font-weight: 500;
          font-size: 12px;
          color: #222222;
          opacity:0.5;
        }
      }
    }
  }

  .as_header {
    background-color: #fff9f3;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    padding: 10px;
    color: black;
    transition: 0.15s ease;

    .as_title {
      font-weight: 500;
    }
    .as_helptext {
      font-size: 12px;
      color: #363636;
      margin-top: 4px;
      opacity: 0.5;
      line-height: 1.43;
    }
    .as_close {
      position: absolute;
      right: 10px;
      top: calc(50% - 4px);
      background: url(${dropdown}) no-repeat center center;
      height: 14px;
      width: 14px;
    }

    .as_open{
      position: absolute;
      right: 10px;
      top: calc(50% - 4px);
      background: url(${dropdown}) no-repeat center center;
      height: 14px;
      width: 14px;
      transform:rotate(270deg);
    }

    .updown-arrow {
      transform: rotate(0);
      bottom: 50%;
      left: 95%;
      &:before, &:after {
        background-color: transparent;
        width: 2px;
        height: 5px;
        display: inline-block;
        position: absolute;
        border-bottom: 9px solid #979797;
        left: 0;
        transform: rotate(0);
      }
      &:before {
        transform: rotate(-135deg);
      }
      &:after {
        transform: rotate(135deg);
      }
    }

    &.active {
      .updown-arrow {
        transform: rotate(270deg) !important;

        &:before {
          transform: rotate(-45deg);
        }
        &:after {
          transform: rotate(45deg);
        }
      }
    }
  }

  .updown-arrow {
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: -5px;
    left: -10px;
    transition: 0.4s ease;
    margin-top: 2px;
    text-align: left;
    transform: rotate(45deg);

    &:before, &:after {
      position: absolute;
      content: '';
      display: inline-block;
      width: 6px;
      height: 3px;
      background-color: #fff;
      transition: 0.4s ease;
    }
    &:after {
      position: absolute;
      transform: rotate(90deg);
      top: -5px;
      left: 5px;
    }
  }

  &.active {
    .updown-arrow {
      transform: rotate(270deg) !important;

      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }
`;

export const PlanTag = styled.div`
  height: 32px;
  width: 80px !important;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 3px 5px !important;
  color: #ed7c02;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #e3e3e3;
  user-select: none;
  span,
  img {
    display: inline-block !important;
    padding: 0 2px;
  }

  &.lock-only {
    width: 14px !important;
    height: 20px;
    background-color: #ffffff;
  }
`;
