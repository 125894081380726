import { fork, take, call, put, all } from "redux-saga/effects";
import { request } from "../utils/Api";
import {
  CREATE_BADGE,
  UPDATE_BADGE,
  DELETE_BADGE,
  SET_ACTIVE_BADGE,
  GET_BADGE_REQUEST,
  GET_BADGE_CHECKS_REQUEST,
  // REQUEST_BADGE,
  BADGE_FORM_FIELDS,
  getBadgeSuccess,
  getBadgeFailure,
  getBadgeChecksSuccess,
  getBadgeChecksFailure,
  setActiveBadgeChecksSuccess,
  setActiveBadgeChecksFailure,
  updateBadgeSuccess,
  updateBadgeFailure,
  createBadgeSuccess,
  createBadgeFailure,
  deleteBadgeSuccess,
  deleteBadgeFailure,
  // requestBadgeSuccess,
  // requestBadgeFailure,
  addToast
} from "../actions";

function* addToastError(error) {
  yield put(addToast({ toastr: { type: "warning", message: error } }));
}

export function* requestAndPut(
  requestParameters,
  successAction,
  failureAction,
  meta,
  toastrObj
) {
  const response = yield call(...requestParameters);
  if (
    (response && Object.keys(response).length && response.status !== 404) ||
    response === ""
  ) {
    const filterExternalErrors = [];
    if (response.status === 400) {
      Object.keys(response.response).forEach(key => {
        if (BADGE_FORM_FIELDS.indexOf(key) === -1) {
          filterExternalErrors.push(response.response[key]);
        }
      });
      yield all(filterExternalErrors.map(error => call(addToastError, error)));
    }
    yield put(
      successAction({
        response,
        meta: toastrObj
          ? {
              meta,
              toastr:
                toastrObj && response.status !== 400 ? { ...toastrObj } : null
            }
          : meta
      })
    );
  } else {
    yield put(failureAction({ response, id: meta }));
  }
}

export default function* getBadgeSaga() {
  while (true) {
    const action = yield take(GET_BADGE_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/badges/`],
      getBadgeSuccess,
      getBadgeFailure,
      action.payload
    );
  }
}

export function* getBadgeChecksSaga() {
  while (true) {
    const action = yield take(GET_BADGE_CHECKS_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/badges/${action.payload}`],
      getBadgeChecksSuccess,
      getBadgeChecksFailure,
      action.payload
    );
  }
}

export function* setBadgeAndChecks() {
  while (true) {
    const action = yield take(SET_ACTIVE_BADGE);
    yield fork(
      requestAndPut,
      [request, `/badges/${action.payload.id}`],
      setActiveBadgeChecksSuccess,
      setActiveBadgeChecksFailure,
      action.payload
    );
  }
}

export function* updateBadge() {
  while (true) {
    const action = yield take(UPDATE_BADGE);
    yield fork(
      requestAndPut,
      [
        request,
        `/badges/${action.payload.id}/`,
        { method: "PATCH", payload: action.payload }
      ],
      updateBadgeSuccess,
      updateBadgeFailure,
      action.payload,
      {
        type: "success",
        message: `${action.payload.name} successfully updated`
      }
    );
  }
}

export function* deleteBadge() {
  while (true) {
    const action = yield take(DELETE_BADGE);
    yield fork(
      requestAndPut,
      [request, `/badges/${action.payload.id}/`, { method: "DELETE" }],
      deleteBadgeSuccess,
      deleteBadgeFailure,
      action.payload,
      {
        type: "success",
        message: `${action.payload.name} successfully deleted`
      }
    );
  }
}

export function* createBadge() {
  while (true) {
    const action = yield take(CREATE_BADGE);
    delete action.payload.id;
    yield fork(
      requestAndPut,
      [request, `/badges`, { method: "POST", payload: action.payload }],
      createBadgeSuccess,
      createBadgeFailure,
      action.payload
      //{ type: "success", message: `Badge created` }
    );
  }
}

// export function* requestBadge() {
//   while(true) {
//     const action = yield take(REQUEST_BADGE);
//     console.log(action.payload)
//     yield fork(
//       requestAndPut,
//       [
//         request,
//         '/create-support-ticket',
//         {method: "POST", payload: action.payload}
//       ],
//       requestBadgeSuccess,
//       requestBadgeFailure
//     )
//   }
// }
