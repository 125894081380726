// import { delay, eventChannel } from "redux-saga";
import { fork, take } from "redux-saga/effects";
import { request } from "../utils/Api";
import { requestAndPut } from "./";
import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_ORGANIZATIONS_REQUEST,
  GET_SUBSCRIPTION_REQUEST,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  getCurrentUserOrgSuccess,
  getCurrentUserOrgFailure,
  getSubscriptionSuccess,
  getSubscriptionFailure,
  UPDATE_USER_UI_STATE
} from "../actions";
import { getOrgId } from "../utils/utility";

export function* getCurrentUser(id) {
  while (true) {
    yield take(GET_CURRENT_USER_REQUEST);
    yield fork(
      requestAndPut,
      [request, "/logged-in-user/"],
      getCurrentUserSuccess,
      getCurrentUserFailure
    );
  }
}

export function* updateUserUIState() {
  while (true) {
    const action = yield take(UPDATE_USER_UI_STATE);

    yield fork(
      requestAndPut,
      [
        request,
        `/logged-in-user/${action.payload.id}`,
        { method: "PATCH", payload: action.payload.body }
      ],
      getCurrentUserSuccess,
      getCurrentUserFailure,
      action.payload
    );
  }
}

export function* getCurrentUserOrganisation() {
  while (true) {
    yield take(GET_CURRENT_USER_ORGANIZATIONS_REQUEST);
    yield fork(
      requestAndPut,
      [request, "/organizations/"],
      getCurrentUserOrgSuccess,
      getCurrentUserOrgFailure
    );
  }
}

export function* getSyncSubscription() {
  while (true) {
    yield take(GET_SUBSCRIPTION_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/subscriptions/?org_id=${getOrgId()}&sync_subscription=true`],
      getSubscriptionSuccess,
      getSubscriptionFailure
    );
  }
}
