import React, { Component, Fragment } from 'react';
import { Button } from 'freemium-ui';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { BADGE_PUBLIC_URL } from '../config';
import {
  StyledFeatureInterest,
  StyledHeader,
  StyledFooter
} from '../components/CaptureInterst';
class BadgeCreateModal extends Component {
  render() {
    const {
      badgeform,
      gotoList,
      isCreateModalOpen,
      isCancelModalOpen,
      flipCancelModal
    } = this.props;

    const path = `${BADGE_PUBLIC_URL}/badge/${badgeform.id}?${Math.random()}`;
    const copyCode = `<a href="${badgeform.data.target_url}"><img src="${path}"/> </a>`;
    return (
      <ModalTransition>
        {isCreateModalOpen && (
          <Modal
            heading={undefined}
            autoFocus={false}
            shouldCloseOnOverlayClick={true}
            components={{
              Header: () => <Header type={''} />,
              Footer: () => (
                <CopyToClipboard text={copyCode}>
                  <Footer
                    close={gotoList}
                    isCreateModal={true}
                    copyCode={copyCode}
                  />
                </CopyToClipboard>
              )
            }}
          >
            <StyledFeatureInterest>
              {
                <div className="description">
                  <Div>
                    <Text>
                      Your Badge <strong>{badgeform.name}</strong> is created
                    </Text>
                    <img src={path} alt="Your Badge " />
                    <Code>
                      <code>{copyCode}</code>
                    </Code>
                  </Div>
                </div>
              }
            </StyledFeatureInterest>
          </Modal>
        )}
        {isCancelModalOpen && (
          <Modal
            heading={undefined}
            autoFocus={false}
            shouldCloseOnOverlayClick={true}
            width="500px"
            components={{
              Header: () => <Header type={'Cancel Confirmation'} />,
              Footer: () => (
                <Footer
                  close={gotoList}
                  flipCancelModal={flipCancelModal}
                  isCreateModal={false}
                />
              )
            }}
          >
            <StyledFeatureInterest>
              {
                <div className="description">
                  Are you sure? All your changes will be lost.
                </div>
              }
            </StyledFeatureInterest>
          </Modal>
        )}
        )}
      </ModalTransition>
    );
  }
}

export default BadgeCreateModal;

const Div = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  padding: 0px 20px;
`;
const Text = styled.div`
  font-size: 20px;
  color: #4f4f4f;
  padding-bottom: 10px;
`;
const Code = styled.div`
  border: 1px solid #d2d2d2;
  margin-top: 10px;
  padding: 10px;
  color: #707070;
`;

const Header = props => (
  <StyledHeader>
    <h2> {props.type} </h2>
  </StyledHeader>
);

const Footer = props => (
  <StyledFooter>
    {props.isCreateModal ? (
      <Fragment>
        <CopyToClipboard text={props.copyCode}>
          <Button inline type="primary" onClick={() => props.close()}>
            Copy Code and Close
          </Button>
        </CopyToClipboard>
      </Fragment>
    ) : (
      <Fragment>
        <Button inline type="secondary" onClick={() => props.flipCancelModal()}>
          Stay
        </Button>
        <Button inline onClick={() => props.close()} type="primary">
          Proceed
        </Button>
      </Fragment>
    )}
  </StyledFooter>
);
