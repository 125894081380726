import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { toggleInterestModal, setBadgeUrl, createBadgeLocal } from '../actions';
import { getOrgId } from '../utils/utility';
import { request } from '../utils/Api';
import { INTEREST_CONFIG } from '../constants/constant';

class CaptureInterest extends React.Component {
  state = {
    open: false,
    showConfirmation: false,
    submitStatus: false
  };

  close = () =>
    this.props.toggleInterestModal({ state: false, submitStatus: false });

  createInterest = async (reason, PlanName) => {
    this.setState({
      submitStatus: true
    });
    var response;
    if (reason !== 'Free Badge request') {
      const payload = {
        reason:
          reason.indexOf('Request for') > -1 ? reason : `Request for ${reason}`,
        organization: Number(getOrgId())
      };
      if (reason.toLowerCase().includes('downgrade')) {
        payload.DowngradeTo = PlanName;
      }
      response = await request(`/create-support-ticket/`, {
        method: 'POST',
        payload
      });
    }

    if (reason === 'Free Badge request') {
      // this.props.setBadgeUrl({url : response.url})
      // var urlArray = []
      // if(window.localStorage.getItem("badge-ticket-url")) {
      //   urlArray = JSON.parse(window.localStorage.getItem("badge-ticket-url"))
      // }
      // var ticketDetails = {"orgId": Number(getOrgId()),"ticketUrl": response.url}
      // urlArray.push(ticketDetails)
      // window.localStorage.setItem("badge-ticket-url", JSON.stringify(urlArray))
      // this.props.createBadgeLocal()
      const typeformUrl = 'https://support.freshping.io/en/support/tickets/new';
      window.open(typeformUrl);
    }

    if (this.props.interest.type === 'noaccess' && response.url) {
      this.setState({ showConfirmation: true });
    } else if (this.props.interest.type !== 'access') {
      this.close();
    }
  };

  componentDidMount() {
    this.setState({
      submitStatus: false
    });
    if (this.props.interest.type === 'access')
      this.createInterest(INTEREST_CONFIG['access']['heading']);
  }

  render() {
    const { interest } = this.props;
    return (
      <ModalTransition>
        {interest.state && (
          <Modal
            heading={undefined}
            autoFocus={false}
            width="500px"
            onClose={this.close}
            components={{
              Header: () => <Header type={interest.type} />,
              Footer: () => (
                <Footer
                  close={this.close}
                  createInterest={this.createInterest}
                  type={interest.type}
                  PlanName={interest.PlanName}
                  submitStatus={this.state.submitStatus}
                />
              )
            }}
          >
            <StyledFeatureInterest className="text-grey">
              <div className="description">
                {INTEREST_CONFIG[interest.type]['msg']}
              </div>
            </StyledFeatureInterest>
          </Modal>
        )}
      </ModalTransition>
    );
  }
}

const mapStateToProps = state => ({
  interest: state.app.interest
});

const mapDispatchProps = dispatch => ({
  toggleInterestModal: state => dispatch(toggleInterestModal(state)),
  setBadgeUrl: url => dispatch(setBadgeUrl(url)),
  createBadgeLocal: () => dispatch(createBadgeLocal())
});

export default connect(mapStateToProps, mapDispatchProps)(CaptureInterest);

const Header = props => (
  <StyledHeader>
    <h2> {INTEREST_CONFIG[props.type]['heading']} </h2>
  </StyledHeader>
);

const Footer = props => (
  <StyledFooter>
    {props.type === 'noaccess' || props.type === 'access' ? (
      <Fragment>
        <button
          onClick={() => props.close()}
          style={{ width: '100px' }}
          className="primary-btn"
        >
          Okay
        </button>
      </Fragment>
    ) : (
      <Fragment>
        <button onClick={() => props.close()} className="secondary-btn">
          {INTEREST_CONFIG[props.type]['cancel']}
        </button>
        <button
          onClick={() =>
            props.createInterest(
              INTEREST_CONFIG[props.type]['heading'],
              props.PlanName
            )
          }
          disabled={props.submitStatus}
          className="primary-btn"
        >
          {props.submitStatus
            ? 'Please wait'
            : INTEREST_CONFIG[props.type]['submit']}
        </button>
      </Fragment>
    )}
  </StyledFooter>
);

export const StyledFooter = styled.footer`
  border-radius: 3px;
  background-color: #f9f9f9;
  min-height: 55px;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 0 10px;
`;

export const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  transition: box-shadow 200ms;
  z-index: 1;
  padding: 14px 20px 4px;
  box-shadow: none;
  h2 {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #4f4f4f;
  }
`;

export const StyledFeatureInterest = styled.div`
  .description {
    padding-top: 10px;
    padding-bottom: 20px;
    line-height: 1.5;
    font-size: 14px;
  }
`;
