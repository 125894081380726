import React, { Component } from 'react';
import styled from 'styled-components';
import { FRESHWORKS_PRODUCTS, productCategories } from '../constants/constant';
import ExploreModal from './ExploreModal';

export function fireClick(node) {
  if (document.createEvent) {
    var evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, false);
    node.dispatchEvent(evt);
  } else if (document.createEventObject) {
    node.fireEvent('onclick');
  } else if (typeof node.onclick == 'function') {
    node.onclick();
  }
}

class ExploreCards extends Component {
  state = {
    showModal: false,
    selectedProduct: null,
    category: 'All'
  };
  close = () => {
    this.setState({ showModal: false });
  };
  onOpenSignup = (productName, productURL) => {
    if (window._na && window._na_module) {
      window._na.sendFeatureEvent(
        productName + ' - ' + 'Start Trial',
        window._na_module
      );
    }

    var openOmnibarEvent = new CustomEvent('showOmnibar');
    var eventTarget = document.querySelector('[data-omnibar-event-target]');
    if (eventTarget) {
      eventTarget.dispatchEvent(openOmnibarEvent);

      setTimeout(() => {
        const productNameUpper =
          productName.substring(0, 1).toUpperCase() +
          productName.substring(1, productName.length);
        const existingAccount = document.querySelector(
          '[data-freshworks-product="' +
            productName +
            '"] .add-new-account, [data-freshworks-product="' +
            productNameUpper +
            '"] .add-new-account'
        );

        if (existingAccount) {
          fireClick(existingAccount);
        } else {
          const newAccount = document.querySelector(
            '[data-freshworks-product="' +
              productName +
              '"], [data-freshworks-product="' +
              productNameUpper +
              '"]'
          );
          if (newAccount) {
            fireClick(newAccount);
          } else {
            window.open(productURL);
          }
        }
      }, 1000);
    } else {
      window.open(productURL);
    }
  };
  render() {
    let profile =
      this.props.userDetails != null ? this.props.userDetails.profile : null;
    const category = this.state.category;
    return (
      <React.Fragment>
        {this.state.showModal && this.props.userDetails && (
          <ExploreModal
            onClose={this.close}
            userProfile={profile}
            userDetails={this.props.userDetails}
            getCurrentUser={this.props.getCurrentUser}
            product={this.state.selectedProduct}
          />
        )}
        <StyledCardsHeader>
          More Products
          <div className="categories">
            {productCategories.map(eachCategory => (
              <div
                onClick={() => this.setState({ category: eachCategory })}
                className="category"
                style={
                  eachCategory === category
                    ? {
                        border: '1px solid #149999',
                        backgroundColor: '#149999',
                        color: '#fff'
                      }
                    : {}
                }
              >
                {eachCategory}
              </div>
            ))}
          </div>
        </StyledCardsHeader>
        <Cards>
          {FRESHWORKS_PRODUCTS.filter(
            product => product.category === category || category === 'All'
          ).map(product => (
            <Card key={product.name}>
              <img src={product.logo} alt={product.name} />
              <div className="description">{product.description}</div>
              <div className="button-container">
                {product.url && (
                  <button
                    className="trial-button"
                    type="button"
                    onClick={() => this.onOpenSignup(product.name, product.url)}
                  >
                    START TRIAL
                  </button>
                )}
                <button
                  className="demo-button"
                  type="button"
                  onClick={() => {
                    this.setState({ selectedProduct: product });
                    this.setState({ showModal: true });
                  }}
                >
                  Request Demo
                </button>
              </div>
            </Card>
          ))}
        </Cards>
      </React.Fragment>
    );
  }
}
const StyledCardsHeader = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;

  .categories {
    display: inline-flex;
    gap: 16px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
  }
  .category {
    background-color: transparent;
    border: 1px solid #d6d4d4;
    white-space: nowrap;
    color: #282828;
    padding: 6px 20px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 14px;
    cursor: pointer;
  }
`;

const Cards = styled.div`
  width: 100%;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  margin: 0 auto;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  box-sizing: border-box;
  height: 190px;
  align-items: flex-start;
  padding: 24px;
  background-color: white;
  border-radius: 3px;
  transition: box-shadow 0.3s ease;
  :hover {
    box-shadow: 0px 8px 8px rgba(101, 101, 101, 0.1);
  }
  .description {
    font-size: 16px;
    line-height: 1.45;
    color: #848484;
  }
  .button-container {
    display: flex;
    width: 100%;
    justify-content: left;
  }
  .trial-button {
    border: solid 1px #9d9d9d;
    font-weight: 600;
    font-size: 13px;
    height: 32px;
    width: 140px;
    border-radius: 3px;
    background-color: #fff;
    color: #656565;
    margin-right: 20px;
    cursor: pointer;
    :hover {
      background-color: var(--bg-dark);
      border-color: transparent;
      color: #fff;
    }
  }
  .demo-button {
    border: none;
    color: #544f4f;
    font-weight: 500;
    font-size: 13px;
    background: #fff;
    height: 32px;
    width: 140px;
    cursor: pointer;
  }
  .demo-button:hover {
    border: 1px solid #9d9d9d;
    border-radius: 3px;
  }
  img {
    height: 32px;
  }
`;
export default ExploreCards;
