import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";

import Layout from "../components/Layout";
import BadgeList from "../containers/BadgeList";
import BadgeView from "../containers/BadgeView";
import { getCurrentUserOrg } from "../actions";
import { getOrgId } from "../utils/utility";
import styled from "styled-components";

const isBadgeAllowed = () => {
  return true;
};
const Badge = props => {
  const {
    match: { url }
  } = props;

  const currentOrg =
    props.org &&
    props.org.find(org => org.id === Number(getOrgId()));

  return (
    <Layout page="Badge">
      {props.org && currentOrg.logged_in_user_role === "AD" ? (
        <Switch>
          <Route
            exact
            path={`${url}/new`}
            render={() =>
            isBadgeAllowed() ? (
              <BadgeView url={url} create />
            ) : (
              <Redirect to={url} />
            )
          }
          />
          <Route
            exact
            path={`${url}/:id`}
            component={() => <BadgeView url={url} />}
          />
          <Route
            exact
            path={`${url}/`}
            component={() => <BadgeList url={url} />}
          />
        </Switch>) : (
          <AccessDenied>
              Sorry, looks like you do not have access to view this page.
          </AccessDenied>
        )}
    </Layout>
  );
};

const mapStateToProps = state => ({
    org: state.user.org
});
export default connect(mapStateToProps, { getCurrentUserOrg })(Badge);

export const AccessDenied = styled.div`
  text-align: center;
  width: 100%;
  margin: 30px 0;
  font-size: 15px;
`;
