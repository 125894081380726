import {
  GET_STATUSPAGES_REQUEST,
  GET_STATUSPAGES_SUCCESS,
  GET_STATUSPAGES_FAILURE,
  GET_STATUS_CHECKS_REQUEST,
  GET_STATUS_CHECKS_SUCCESS,
  DELETE_STATUSPAGE_SUCCESS,
  SET_STATUSPAGE_FORM,
  SET_ACTIVE_STATUSPAGE,
  SET_ACTIVE_STATUSPAGE_SUCCESS,
  GET_STATUS_CHECKS_FAILURE,
  UPDATE_STATUSPAGE,
  UPDATE_STATUSPAGE_SUCCESS,
  UPDATE_STATUSPAGE_FAILURE,
  CREATE_STATUSPAGE,
  RESET_STATUSPAGE_ERRORS,
  SET_STATUSPAGE_ERRORS,
  CREATE_STATUSPAGE_SUCCESS,
  CREATE_STATUSPAGE_FAILURE,
  CREATE_STATUSPAGE_CNAME_SUCCESS,
  VERIFY_STATUSPAGE_CNAME_SUCCESS,
  CREATE_STATUSPAGE_CNAME_FAILURE,
  CREATE_STATUSPAGE_CNAME
} from '../actions';

export const initialStatusPageState = {
  appState: 'idle',
  statuspages: {
    count: 0,
    results: []
  },
  formState: 'idle',
  statuspageformErrors: {
    logo_url: '',
    custom_domain: '',
    name: ''
  },
  statuspageform: {
    organization: null,
    logo_url: '',
    custom_domain: undefined,
    custom_domain_cname: undefined,
    ga_tracking_id: undefined,
    name: '',
    associated_checks: []
  }
};

export default function statuspagesReducer(
  state = initialStatusPageState,
  action
) {
  switch (action.type) {
    case DELETE_STATUSPAGE_SUCCESS:
      return {
        ...state,
        statuspages: {
          ...state.statuspages,
          results: state.statuspages.results.filter(
            statuspage => statuspage.id !== action.payload.meta.meta.id
          ),
          count: state.statuspages.results.length - 1
        }
      };
    case SET_STATUSPAGE_ERRORS:
      return {
        ...state,
        statuspageformErrors: {
          ...initialStatusPageState.statuspageformErrors,
          ...state.statuspageformErrors,
          ...action.payload
        }
      };
    case RESET_STATUSPAGE_ERRORS:
      return {
        ...state,
        statuspageformErrors: initialStatusPageState.statuspageformErrors
      };
    case CREATE_STATUSPAGE:
    case UPDATE_STATUSPAGE:
      return {
        ...state,
        formState: 'loading'
      };
    case UPDATE_STATUSPAGE_SUCCESS:
    case CREATE_STATUSPAGE_SUCCESS:
      if (action.payload.response.status === 400) {
        const { statuspageformErrors } = initialStatusPageState;
        Object.keys(action.payload.response.response).forEach(key => {
          statuspageformErrors[key] = action.payload.response.response[key];
        });
        return {
          ...state,
          formState: 'error',
          statuspageformErrors
        };
      }
      return {
        ...state,
        formState: 'success',
        statuspageform: {
          ...state.statuspageform,
          ...action.payload.response
        }
      };
    case UPDATE_STATUSPAGE_FAILURE:
    case CREATE_STATUSPAGE_FAILURE:
      return {
        ...state,
        formState: 'failed'
      };
    case CREATE_STATUSPAGE_CNAME:
      return {
        ...state,
        formState: 'success',
        statuspageform: {
          ...(state.statuspageform || {}),
          custom_domain_cname: {
            ...(state.statuspageform.custom_domain_cname || {}),
            isFetching: true
          }
        }
      };
    case CREATE_STATUSPAGE_CNAME_SUCCESS:
      if (
        action.payload.response &&
        action.payload.response.custom_domain_cname
      ) {
        return {
          ...state,
          formState: 'success',
          statuspageform: {
            ...(state.statuspageform || {}),
            ...action.payload.response,
            custom_domain_cname: {
              ...(state.statuspageform.custom_domain_cname || {}),
              ...(action.payload.response.custom_domain_cname || {}),
              isFetching: false
            }
          }
        };
      }
      break;
    case CREATE_STATUSPAGE_CNAME_FAILURE:
      return {
        ...state,
        formState: 'failed',
        statuspageform: {
          ...(state.statuspageform || {}),
          custom_domain_cname: {
            ...(state.statuspageform.custom_domain_cname || {}),
            isFetching: false
          }
        }
      };
    case VERIFY_STATUSPAGE_CNAME_SUCCESS:
      if (
        action.payload.response &&
        action.payload.response.custom_domain_cname &&
        action.payload.response.custom_domain_cname.is_verified
      ) {
        return {
          ...state,
          formState: 'success',
          statuspageform: {
            ...state.statuspageform,
            ...action.payload.response
          }
        };
      }
      return {
        ...state,
        formState: 'failed'
      };

    case SET_ACTIVE_STATUSPAGE:
      return {
        ...state,
        formState: 'idle',
        statuspageform: {
          ...state.statuspageform,
          ...action.payload
        }
      };
    case SET_STATUSPAGE_FORM:
      return {
        ...state,
        statuspageform: {
          ...state.statuspageform,
          ...action.payload
        }
      };
    case SET_ACTIVE_STATUSPAGE_SUCCESS:
      return {
        ...state,
        statuspages: {
          ...state.statuspages,
          results: state.statuspages.results.map(status => {
            if (status.id === action.payload.meta.id) {
              return { ...status, checks: action.payload.response.results };
            }
            return status;
          })
        },
        formState: 'ready',
        statuspageform: {
          ...state.statuspageform,
          associated_checks: action.payload.response.results.map(check => ({
            ...check,
            current: true
          }))
        }
      };
    case GET_STATUSPAGES_REQUEST:
      return {
        ...state,
        appState: 'loading'
      };
    case GET_STATUSPAGES_SUCCESS:
      return {
        ...state,
        appState: 'success',
        statuspages: action.payload.response
      };
    case GET_STATUSPAGES_FAILURE:
      return {
        ...state,
        appState: 'failure'
      };
    case GET_STATUS_CHECKS_SUCCESS:
      return {
        ...state,
        statuspages: {
          ...state.statuspages,
          results: state.statuspages.results.map(status => {
            if (status.id === action.payload.meta) {
              return {
                ...status,
                associated_checks: action.payload.response.results
              };
            }
            return status;
          })
        }
      };
    case GET_STATUS_CHECKS_REQUEST:
    case GET_STATUS_CHECKS_FAILURE:
    default:
      return state;
  }
  return state;
}
