import styled from 'styled-components';

export const OutageAnalysisContainer = styled.div`
  background: #f6f6f6;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
`;

export const Header = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  div.heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.0923077px;
    color: #1b1b1b;
    margin-bottom: 8px;
  }
  div.summary {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0923077px;
    color: #595959;
  }
  div.duration {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0923077px;
    color: #595959;
    opacity: 0.6;
    margin-bottom: 8px;
    text-align: right;
  }
  div.interval {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.0923077px;
    color: #595959;
    text-align: right;
  }
`;

export const OutageList = styled.div`
  overflow: auto;
  flex: 1
  padding: 16px;
`;
