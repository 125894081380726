import {
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ORGANIZATIONS_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  GET_SUBSCRIPTION_SUCCESS,
} from "../actions";
import {getOrgId} from '../utils/utility';

const userToken = localStorage.getItem("auth-token");
const initialUser = {
  isAuthenticated: !!userToken,
  appState: "idle",
  user: null
};

export default function userReducer(state = initialUser, action) {
  switch (action.type) {
    case GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        appState: "loading"
      };
    case GET_CURRENT_USER_SUCCESS:
      const user = action.payload.response.results[0];
      if (window.fcWidget) {
        window.fcWidget.setExternalId(user.id);
        window.fcWidget.user.setEmail(user.email);
      }
      if (window.beamer_config) {
        window.beamer_config.user_firstname = user.full_name;
        window.beamer_config.user_lastname = "";
        window.beamer_config.user_email = user.email;
        window.beamer_config.user_id = user.id;
        if (window.Beamer) window.Beamer.init();
      }
      return {
        ...state,
        appState: "success",
        user
      };
    case GET_CURRENT_USER_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        org: action.payload.response.results
      };
    case GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        appState: "failure"
      };
    case GET_SUBSCRIPTION_SUCCESS:
      const updated = state.org.map(org => {
        if(org.id === Number(getOrgId())){
          org.subscription = action.payload.response[0];
          return org;
        }
        return org;
      });
      return {
        ...state,
        org: updated
      };
    default:
      return state;
  }
}
