/* eslint-disable arrow-parens */
import React from 'react';

// class AddNewIntegrationModal extends React.Component {
const AddNewIntegrationModal = props => {
  const title = props.new ? 'Create' : 'Edit';
  const buttonTxt = props.new ? 'Add Integration' : 'Save Changes';

  return (
    <div className="add-new-user modal">
      <div className="modal-content">
        <div className="modal-header">
          <h4>
            {title} {props.name} SMS Alert
          </h4>
        </div>
        <div className="modal-body">
          <form action="">
            <label htmlFor="account-id">{props.name} Account SID</label>
            <input id="account-id" />
            <label htmlFor="auth-token">{props.name} Auth Token</label>
            <input id="auth-token" />
            <label htmlFor="phone-num">{props.name} Phone Number</label>
            <input id="phone-num" />
            <label htmlFor="source-alerts">Source Alerts</label>
            <select id="source-alerts">
              <option defaultValue="Administrator">All application</option>
              <option value="Freshdesk">Freshdesk</option>
            </select>
            <label htmlFor="states-to-check">States to Check</label>
            <select id="states-to-check">
              <option defaultValue="any-state">Any state</option>
              <option value="AV">AV</option>
              <option value="NR">NR</option>
            </select>
            <label htmlFor="alert-recipients">Alert Recipients</label>
            <select id="alert-recipients">
              <option defaultValue="all-members">
                All Members of Application
              </option>
            </select>
            <label htmlFor="additional-phone-num">
              Additional Phone Numbers (comma separated)
            </label>
            <input id="additional-phone-num" />

            <div className="modal-footer">
              <button
                className="cancel-btn secondary-btn"
                onClick={() => props.onCloseModal()}
              >
                Cancel
              </button>
              <button className="primary-btn">{buttonTxt}</button>
            </div>
          </form>
        </div>
      </div>
      <div className="close-icon">
        <span className="close" onClick={() => props.onCloseModal()}>
          &times;
        </span>
      </div>
    </div>
  );
};

export default AddNewIntegrationModal;
