import palette from './palette';
import colors from './colors';
import layout from './shared';
import typography from './typography';

export default {
  palette,
  colors,
  layout,
  typography
};
