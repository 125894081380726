import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > :not(:last-child) {
    margin-right: 16px;
  }
  .label {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #475867;
  }
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .placeholder {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #3966c9;
    :not(.disabled):hover {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
`;

export const Form = styled.form`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* height: 100%; */
  input[type='radio'],
  input[type='checkbox'] {
    width: 1px;
    height: 1px;
  }
  .checkbox {
    width: unset;
    padding: unset;
  }
  .wehbook-radio-group label {
    padding: 16px !important;
    div {
      text-shadow: none;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.0923077px;
      color: #595959;
    }
  }
  label {
    /* font-weight: unset; */
    /* font-size: unset; */
    /* color: unset; */
    display: unset;
    padding-top: unset;
    padding-bottom: 12px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #383838 !important;
    line-height: 14px !important;
    letter-spacing: -0.0923077px !important;
  }
`;

export const FormElements = styled.div`
  padding: 16px;
  flex: 1;
  overflow: auto;
`;

export const FormSection = styled.div`
  padding: 16px;
  :not(:last-child) {
    margin-bottom: ${({ mb }) => mb || 16}px;
    padding-bottom: 32px;
    border-bottom: 1px solid #e3e3e3;
  }
  div.form-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.0923077px;
    color: #595959;
    margin-bottom: 12px;
  }
  div.section-name {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #696969;
    margin-bottom: 22px;
  }
`;

export const FormField = styled.div`
  position: relative;
  :not(:last-child) {
    margin-bottom: ${({ mb }) => mb || '32'}px;
  }
  div.field-desc {
    font-size: 11px;
    line-height: 22px;
    margin: 16px 0;
    letter-spacing: -0.0923077px;
    color: #595959;
  }
  div.field-hint {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
  }
`;

export const FormActions = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid rgb(232, 232, 232);
  align-items: center;
`;

export const RadioBox = styled.div`
  display: flex;
  align-items: center;
  label {
    padding-top: unset;
    padding-bottom: unset !important;
  }
  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const ShowButton = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #3966c9;
  padding: 0 16px;
  cursor: pointer;
`;
