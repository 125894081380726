import styled from 'styled-components';

export const OutageCardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Toggle = styled.div`
  display: grid;
  place-content: center;
  transform-origin: center;
  cursor: pointer;
  padding: 8px;
  transform: rotate(${({ rotate }) => rotate || 0}deg);
  transition: transform 0.3s ease-in;
`;

export const Header = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  padding: 16px;
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  div.title {
    flex: 1;
    padding: 0 8px;
  }
  div.desc {
    width: 340px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div.status-box {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  div.heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1b1b1b;
    margin-bottom: 8px;
  }
  div.timestamp {
    font-size: 14px;
    line-height: 14px;
    color: #222222;
    mix-blend-mode: normal;
    opacity: 0.7;
  }
  span.status-txt {
    font-size: 14px;
    color: #646464;
  }
  span.error {
    font-weight: 500;
    font-size: 14px;
    color: #f6202e;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div.location {
    line-height: 16px;
    color: #595959;
    display: flex;
    align-items: center;
    & > span {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > svg {
      margin-right: 4px;
    }
  }
`;

export const Content = styled.div`
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ContentHeader = styled.div`
  background: #f6f6f6;
  border-radius: 3px;
  margin: 24px 24px 24px 48px;
  padding: 16px;
  div.chip-container {
    display: flex;
    div.chip {
      :not(:last-child) {
        margin-right: 8px;
      }
      background: #b7b7b7;
      border-radius: 3px;
      padding: 4px;
      font-weight: 600;
      font-size: 11px;
      color: #ffffff;
      mix-blend-mode: normal;
    }
  }
  div.link-time {
    display: flex;
    margin-bottom: 16px;
    div.time {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      width: 300px;
      color: #1b1b1b;
    }
  }
`;

export const LinkURL = styled.div`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 24px;
  font-size: 14px;
  line-height: 17px;
  color: #3e80df;
`;

export const ContentFooter = styled.div`
  background: #f6f6f6;
  border-radius: 3px;
  margin: 24px 24px 24px 48px;
  padding: 16px;
  div.footer-title {
    margin-bottom: 20px;
    font-weight: 600;
    color: #595959;
  }
  div.row-data {
    display: flex;
    & > div:first-child {
      margin-right: 6px;
    }
    :not(:last-child) {
      margin-bottom: 12px;
    }
    div.bold {
      font-size: 14px;
      line-height: 17px;
      color: #1b1b1b;
    }
  }
`;

export const FoldableRowContainer = styled.div`
  :not(:last-child) {
    margin-bottom: 24px;
  }
  margin: 0 24px;
  div.foldable-header {
    display: flex;
    align-items: baseline;
    padding-right: 16px;
    div.foldable-toggle {
      min-width: 26px;
    }
    div.label {
      padding-right: 16px;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    div.value {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  div.foldable-body {
    margin: 12px 0px 12px 24px;
    div.foldable-row {
      display: flex;
      div.foldable-label {
        flex: 1;
      }
      div.foldable-value {
        width: 300px;
        font-weight: 500;
        font-size: 14px;
        color: #f6202e;
      }
      padding: 0 16px;
    }
  }
`;
