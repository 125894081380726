import { useQuery, useMutation } from 'react-query';
import { request } from 'utils/Api';
import queryClient from 'utils/client';
import { toastr } from 'react-redux-toastr';
import {
  nameOfFieldsNotToShow,
  nameOfFieldsToChange
} from './IntegrationForm/constants';
import { isMandatory } from './IntegrationForm/utils';

export const useFsIntegration = () =>
  useQuery('fsIntegration', async () => {
    const response = await request('/integrations/freshservice-alerters/');
    return response && response.results && response.results.length > 0
      ? response.results
      : [];
  });

export const useFsFields = ({
  freshservice_api_key,
  freshservice_domain,
  is_valid,
  id,
  data_json
}) =>
  useQuery(['fsFields', `${id}`], async () => {
    if (!is_valid || !freshservice_api_key || !freshservice_domain) return [];
    const response = await request('/integrations/freshservice-form-fields/', {
      method: 'POST',
      payload: {
        data_json,
        api_key: freshservice_api_key,
        domain: freshservice_domain
      }
    });
    const options = {
      agent: (
        (response &&
          response.freshservice_agents_json &&
          response.freshservice_agents_json.agents) ||
        []
      ).map(agent => ({
        id: agent.id,
        value: `${agent.first_name} ${agent.last_name}`,
        stringId: agent.email
      })),
      group: (
        (response &&
          response.freshservice_groups_json &&
          response.freshservice_groups_json.groups) ||
        []
      ).map(group => ({
        id: group.id,
        value: group.name,
        stringId: group.name
      })),
      department: (
        (response &&
          response.freshservice_departments_json &&
          response.freshservice_departments_json.departments) ||
        []
      ).map(department => ({
        id: department.id,
        value: department.name,
        stringId: department.name
      }))
    };
    return (
      (response &&
        response.freshservice_form_fields_json &&
        response.freshservice_form_fields_json.ticket_fields) ||
      []
    )
      .filter(field => !nameOfFieldsNotToShow.includes(field.name))
      .map(field => ({
        ...field,
        name: nameOfFieldsToChange[field.name] || field.name,
        options:
          options[field.name] &&
          field &&
          field.choices &&
          options[field.name].length > field.choices.length
            ? options[field.name]
            : field.choices && field.choices.length > 0
            ? field.choices.map(choice => ({
                stringId: choice.value,
                id: choice.id,
                value: choice.value
              }))
            : [],
        isMandatory: Boolean(isMandatory(field)),
        type:
          field.field_type &&
          field.field_type.slice(field.field_type.indexOf('_') + 1)
      }))
      .sort((a, b) => b.isMandatory - a.isMandatory);
  });

export const useAddFsIntegration = ({ updateIntegrationCount } = {}) =>
  useMutation(
    async ({ payload }) => {
      const response = await request('/integrations/freshservice-alerters/', {
        method: 'POST',
        payload
      });
      return response;
    },
    {
      onSuccess: (response, { actions, onCreate, fetchFields }) => {
        if (updateIntegrationCount) updateIntegrationCount(false);
        if (actions) actions.setSubmitting(false);
        if (response.freshservice_api_key && response.freshservice_domain) {
          queryClient.invalidateQueries('fsIntegration');
          toastr.success('Freshservice authenticated successfully');
          if (onCreate) onCreate(response);
          if (fetchFields)
            queryClient.invalidateQueries(['fsFields', `${response.id}`]);

          if (window._na && window._na_module) {
            window._na.sendFeatureEvent(
              'Freshservice Create Integration',
              window._na_module
            );
          }
        } else {
          toastr.error('Freshservice authentication failed');
        }
      },
      onError: () => {
        if (updateIntegrationCount) updateIntegrationCount(false);
        toastr.error('Freshservice authentication failed');
      }
    }
  );

export const useSaveFsIntegration = () =>
  useMutation(
    async ({ id, payload }) => {
      const response = await request(
        `/integrations/freshservice-alerters/${id}/`,
        {
          method: 'PATCH',
          payload
        }
      );
      if ((response && response.status > 399) || response === undefined)
        throw new Error(JSON.stringify((response && response.response) || {}));
      return response;
    },
    {
      onSuccess: (response, { id, fetchFields, statusChange, actions }) => {
        queryClient.setQueryData('fsIntegration', integrations =>
          integrations.map(integration =>
            integration.id === response.id ? response : integration
          )
        );
        let text = 'Freshservice integration modified';
        if (fetchFields) {
          queryClient.invalidateQueries(['fsFields', `${id}`]);
        }
        if (statusChange) {
          text = `Freshservice integration ${response.freshservice_domain} ${
            response.state === 'AC' ? 'enabled' : 'disabled'
          }`;
        }
        if (actions) actions.setSubmitting(false);
        toastr.success(text);

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent(
            'Freshservice Edit Integration',
            window._na_module
          );
        }
      },
      onError: (error, { actions, mutate = false }, snapshotValue) => {
        const errorMessage = JSON.parse((error && error.message) || '{}');
        if (actions) actions.setSubmitting(false);
        if (mutate) queryClient.setQueryData('fsIntegration', snapshotValue);
        toastr.error(
          (errorMessage &&
            errorMessage.non_field_errors &&
            errorMessage.non_field_errors[0]) ||
            'Save freshservice integration failed'
        );
      },
      onMutate: ({ payload, id, mutate = false }) => {
        if (!mutate) return {};
        const previousValue = queryClient.getQueryData('fsIntegration');
        queryClient.setQueryData('fsIntegration', integrations =>
          integrations.map(integration =>
            integration.id === id ? { ...integration, ...payload } : integration
          )
        );
        return previousValue;
      }
    }
  );

export const useDeleteFsIntegration = ({ updateIntegrationCount } = {}) =>
  useMutation(
    async id => {
      await request(`/integrations/freshservice-alerters/${id}/`, {
        method: 'DELETE'
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fsIntegration');
        toastr.success('Freshservice integration deleted');
        if (updateIntegrationCount) updateIntegrationCount(false);

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent(
            'Freshservice Delete Integration',
            window._na_module
          );
        }
      }
    }
  );
