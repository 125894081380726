export default {
  jade: '#02B875',
  celery: '#A0D76A',
  candlelight: '#FFD012',
  sunrise: '#ffad1e',
  scotchMist: '#fffade',
  pearlLusta: '#fffdf6',
  dodger: '#448ee1',
  amethyst: '#af64ce',
  permission: '#E43538',
  ferrari: '#C82124',
  mintCream: '#f6feff',
  athens: '#f9f9f9',
  smoke: '#f3f5f7',
  porcelain: '#ebeef0',
  zircon: '#dadfe3',
  chateau: '#acb6be',
  raven: '#6f7c87',
  elephant: '#183247',
  rhino: '#284255',
  jungle: '#00A886',
  azure: '#2C5CC5',
  skylight: '#e5f2fd',
  clara: `#283DA5`,
  casablanca: '#E86F25',
  transparent: 'rgba(255, 255, 255, 0)',
  passion: '#D72D30',
  peach: '#FFD0D6',
  babylon: '#F89FA1',
  coral: '#F2797B',
  black: '#000000',
  white: '#ffffff',
  E900: '#12344D',
  E800: '#264966',
  E700: '#425A70',
  E600: '#E5F2FD',
  E500: '#83A6C8',
  E400: '#D72D30',
  E300: '#DFF0FF',
  S900: '#27313A',
  S800: '#384551',
  S700: '#475867',
  S600: '#576C7D',
  S500: '#647A8E',
  S400: '#7B8E9F',
  S300: '#92A2B1',
  S200: '#B1BDC8',
  S100: '#CFD7DF',
  S75: '#E6E8EB',
  S50: '#EBEFF3',
  S25: '#F5F7F9',
  S10: '#F7F9FA'
};
