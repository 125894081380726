/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import styled, { css } from "styled-components";

import { color, font } from "shared/utils/styles";

export const StyledTextarea = styled.div`
  display: inline-block;
  width: 100%;
  textarea {
    overflow-y: hidden;
    width: 100%;
    padding: 8px 5px 9px;
    border: 0;
    border-bottom: 1px solid ${color.borderLightest};
    color: ${color.textDarkest};
    ${font.regular}
    ${font.size(14)}

    &:focus {
      outline: none;
      border-bottom: 1px solid ${color.primary};
    }
    ${props =>
      props.invalid &&
      css`
        &,
        &:focus {
          border: 1px solid ${color.danger};
        }
      `}
  }
`;
