import React from 'react';
import { toastr } from 'react-redux-toastr';

import 'styles/Settings.css';
import { request } from 'utils/Api';
import { getOrgId } from 'utils/utility';
import Switch from 'components/ui/Switch';
import StatusActions from 'components/StatusActions';

class SlackCreatedIntegrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  authorizeSlackIntegration(integration) {
    localStorage.setItem('slack-oauth-orgid', integration.organization);
    window.location = integration.oauth_authorization_url;
  }

  toggleSlackIntegration(integration) {
    const self = this;
    const data = {
      state: integration.state === 'AC' ? 'DA' : 'AC',
      organization: getOrgId()
    };
    request(`/integrations/slack-alerters/${integration.id}`, {
      method: 'PATCH',
      payload: data
    })
      .catch(error => console.error('Error-->:', error))
      .then((response) => {
        if (response && response.id) self.props.changeSlackState(response);
      });
  }

  toggleSwitch(integration) {
    this.setState(
      prevState => ({
        slackIntegrations: this.props.slackIntegrations.map(integ =>
          (integ.id === integration.id
            ? { ...integ, state: integ.state === 'AC' ? 'DA' : 'AC' }
            : integ))
      }),
      () => {
        this.toggleSlackIntegration(integration);
      }
    );
  }

  statusAction(selected, integration) {
    if (selected.label === 'Edit') {
      this.props.openCreateSlackPopup(integration, true);
    }
    if (selected.label === 'Delete') {
      const toastrConfirmOptions = {
        component: () => (
          <div className="delete-confirm__message statuspage_delete">
            <p className="name">
                Slack Team<span>{integration.slack_team_name}</span>
            </p>
            <p className="name">
                Channel<span>{integration.slack_incoming_webhook_channel}</span>
            </p>
          </div>
        ),
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: () => this.props.deleteSlackIntegration(integration.id)
      };

      toastr.confirm('Delete Slack Integration', toastrConfirmOptions);
    }
  }
  render() {
    const slackIntegrations = this.state.slackIntegrations
      ? this.state.slackIntegrations
      : this.props.slackIntegrations;
    return (
      <div className="created-integrations-container slack-created-integrations">
        {slackIntegrations && slackIntegrations.length > 0 && (
          <div className="row integrations-heading">
            <div className="col">Slack Team</div>
            <div className="col">Channel</div>
            <div className="col align-center slack-status">Status</div>
            <div className="col align-center slack-authorize">
              Authorize Status
            </div>
            <div className="align-center" style={{ width: '8%' }}>
              Action
            </div>
          </div>
        )}
        {slackIntegrations &&
          slackIntegrations.length > 0 &&
          slackIntegrations.map((integration, index) => (
            <div className="row" key={integration.id}>
              <div className="col">{integration.slack_team_name}</div>
              <div className="col">
                {integration.slack_incoming_webhook_channel}
              </div>
              <div className="col align-center slack-status">
                <Switch
                  onToggle={() => this.toggleSwitch(integration)}
                  on={integration.state === 'AC'}
                />
              </div>
              {integration.is_authorized ? (
                <div
                  className="col align-center slack-authorize"
                  onClick={() => this.authorizeSlackIntegration(integration)}
                >
                  Authorized -{' '}
                  <span className="authorize-text">Reauthorize</span>
                </div>
              ) : (
                <div
                  className="col align-center slack-authorize"
                  onClick={() => this.authorizeSlackIntegration(integration)}
                >
                  <span className="authorize-text">Authorize</span>
                </div>
              )}
              <div className="statuspage-action">
                <StatusActions
                  onChange={e => this.statusAction(e, integration)}
                />
              </div>
            </div>
          ))}
      </div>
    );
  }
}
export default SlackCreatedIntegrations;
