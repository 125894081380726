import React, { Component } from 'react';
import { Button } from 'freemium-ui';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input/react-responsive-ui';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { request } from '../utils/Api';
import { getOrgId } from '../utils/utility';
import { env } from '../config';

class OnboardingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onboarding: false,
      progress: 0,
      picture: null,
      picture_preview: null,
      name_initial: '',
      full_name: '',
      company_role: '',
      company_role_text: '',
      company_size: '',
      phoneNumber: '',
      isPhoneNumberValid: false,
      availability: true,
      domainNameValid: true,
      accountName: null
    };
    this.nameInput = null;
  }
  roleOptions = [
    'CEO or Owner',
    'Devops',
    'Customer support',
    'Operations',
    'Product',
    'Engineering'
  ];
  sizeOptions = [
    '0 - 10',
    '11 - 50',
    '51 - 200',
    '200 - 500',
    '500 - 1000',
    '1000+'
  ];

  componentDidUpdate(prevProps) {
    const { org } = this.props;
    if (prevProps.org !== org) {
      if (org) {
        const currentOrg = org.find(o => o.id === Number(getOrgId()));
        this.setState({
          accountName: currentOrg.freshid_accounts_name,
          name: currentOrg.freshid_accounts_name
        });
      }
    }
    if (prevProps.user === null && prevProps.user !== this.props.user) {
      const currentUser = this.props.user;
      const profile = currentUser.profile;
      const full_name = currentUser.full_name ? currentUser.full_name : '';
      const name_initial =
        full_name.length > 0
          ? full_name.substring(0, 1)
          : currentUser.email.substring(0, 1);
      const phoneNumber = profile.phone_number;

      if (
        currentUser.verification_required_before != null &&
        localStorage.getItem('onboarding_skip') === null
      ) {
        if (
          full_name.length === 0 ||
          profile.company_role.length === 0 ||
          profile.company_size.length === 0
        ) {
          this.setState({
            onboarding: true,
            name_initial: name_initial.toUpperCase(),
            full_name: full_name,
            company_role: this.isRoleAnOption(profile.company_role)
              ? profile.company_role
              : '',
            company_role_text: this.isRoleAnOption(profile.company_role)
              ? ''
              : profile.company_role,
            company_size: profile.company_size,
            phoneNumber: phoneNumber,
            isPhoneNumberValid: isValidPhoneNumber(phoneNumber)
          });
          this.handleProgress();
        }
      }
    }
  }
  close = () => {
    localStorage.setItem('onboarding_skip', true);
    this.setState({ onboarding: false });
  };

  isRoleAnOption = currentRole => {
    let a = this.roleOptions.find(role => role === currentRole);
    return a ? true : false;
  };

  getDomainUrl = () => {
    const domainUrl = `https://${this.state.accountName}${
      env !== 'production' ? '.freshping-sta91ng.io' : '.freshping.io'
    }`;
    return domainUrl;
  };

  closeOnboarding = async () => {
    const {
      name,
      phoneNumber,
      company_size,
      full_name,
      company_role,
      company_role_text,
      accountName
    } = this.state;
    let splitIndex = full_name.lastIndexOf(' ');
    var first_name = full_name;
    var last_name = '';
    if (splitIndex >= 0) {
      last_name = full_name.substr(splitIndex).trim();
      first_name = full_name.substr(0, splitIndex).trim();
    }
    const payload = {
      phone_number: isValidPhoneNumber(phoneNumber) ? phoneNumber : null,
      company_size: company_size,
      company_role: company_role.length ? company_role : company_role_text,
      first_name: first_name,
      last_name: last_name ? last_name : '',
      freshid_accounts_name: accountName.toLowerCase()
    };
    const response = await request(`/user-profile/`, {
      method: 'POST',
      payload
    });
    if (response) {
      if (name !== accountName) {
        const authToken = localStorage.getItem('auth-token');
        const orgId = getOrgId();
        localStorage.clear();
        window.location.href = `${this.getDomainUrl()}/token-login/${authToken}/${orgId}`;
      }
      this.setState({ onboarding: false });
    }
  };

  handleName = value => {
    if (!this.state.picture_preview)
      this.setState({
        name_initial: value
          .trim()
          .substring(0, 1)
          .toUpperCase()
      });
    this.setState({ full_name: value });
    this.handleProgress();
  };

  handleOptions = (field, value) => {
    this.setState({ [field]: value || '' });
    if (field === 'phoneNumber') {
      this.setState({
        isPhoneNumberValid: isValidPhoneNumber(value) ? true : false
      });
    }
    this.handleProgress();
  };

  handleRole = (field, value) => {
    if (field === 'company_role') {
      this.setState({ company_role_text: '', company_role: value });
    } else {
      this.setState({ company_role_text: value, company_role: '' });
    }
    this.handleProgress();
  };

  handleProgress = () => {
    this.setState(state => {
      return {
        enableButton:
          state.full_name.length > 0 &&
          (state.company_role.length > 1 ||
            state.company_role_text.length > 1) &&
          state.company_size.length,
        progress:
          (state.full_name.length > 0 && 25) +
          (state.company_role.length && 25) +
          (state.company_role_text.length > 1 && 25) +
          (state.company_size.length && 25) +
          (state.isPhoneNumberValid === true && 25)
      };
    });
  };

  getErrorState = () => {
    const { availability, domainNameValid, accountName } = this.state;
    let errorTxt = '';
    if (availability === false) errorTxt = 'Domain not available';
    else if (accountName && accountName.length < 3)
      errorTxt = 'Freshping domain name length atleast 3 characters';
    else if (!domainNameValid)
      errorTxt = 'Enter valid name to set Freshping URL';
    return errorTxt;
  };

  handleAccountName = async (orgname, blur) => {
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,49}[a-z0-9])?)$/g;
    const isValid =
      domainRegex.test(orgname.trim()) && orgname.trim().length > 2;
    this.setState({
      accountName: orgname.trim(),
      domainNameValid: isValid
    });
    if (orgname.trim().length > 3 && isValid && orgname !== this.state.name) {
      const response = await request(`/domain_available?domain=${orgname}`);
      this.setState({
        availability: response.available
      });
    }
  };

  render() {
    const availabilityError = this.getErrorState();
    return (
      <ModalTransition>
        {this.state.onboarding && (
          <Modal
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
            onClick={this.closeOnboarding}
            autoFocus={true}
            heading={undefined}
            isChromeless={true}
            width="600px"
          >
            <ModalContent>
              <div key="content" className="content">
                <div key="header" className="onboarding-header">
                  <span onClick={this.close} className="modal-close" style={{}}>
                    &times;
                  </span>
                  <h2>
                    Hello{' '}
                    <span role="img" aria-label="hello" aria-labelledby="img">
                      👋
                    </span>
                  </h2>
                  <h2>
                    <span role="img" aria-label="Hi">
                      Let's setup your profile
                    </span>
                  </h2>
                </div>
                <div className="onboarding-content">
                  <div className="onboarding-content-left"></div>
                  <div className="onboarding-content-right">
                    <Questions>
                      <div className="questions">
                        <div className="question">
                          First up, set your Freshping URL
                        </div>
                        <div className="options">
                          {this.state.accountName !== null ? (
                            <input
                              minLength="4"
                              maxLength="32"
                              type="text"
                              pattern="[a-z-]"
                              value={this.state.accountName}
                              autoComplete="off"
                              onChange={e =>
                                this.handleAccountName(e.target.value)
                              }
                              onBlur={e =>
                                this.handleAccountName(e.target.value, true)
                              }
                              className={
                                (this.state.availability === false ||
                                  !this.state.domainNameValid) &&
                                'error-input'
                              }
                            />
                          ) : null}
                          {availabilityError !== '' ? (
                            <div className="error-text">
                              {availabilityError}
                            </div>
                          ) : (
                            this.state.accountName && (
                              <div className="orgUrl">
                                {this.getDomainUrl()}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="questions">
                        <div className="question">
                          What should we call you?&nbsp;
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </div>
                        <div className="options">
                          <input
                            type="text"
                            value={this.state.full_name}
                            onChange={e => this.handleName(e.target.value)}
                            ref={input => {
                              this.nameInput = input;
                            }}
                          />
                          {/* {!(
                            this.state.full_name.length > 4 ||
                            this.state.full_name.length == 0
                          ) && (
                            <p className="error-text">
                              Minimum length required is 5
                            </p>
                          )} */}
                        </div>
                      </div>

                      <div className="questions">
                        <div className="question">
                          Which function do you belong to?&nbsp;
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </div>
                        <div className="options">
                          {this.roleOptions.map((option, i) => (
                            <div
                              className={
                                this.state['company_role'] === option
                                  ? 'radio-container'
                                  : 'radio-container'
                              }
                              key={i}
                            >
                              <input
                                key={'company_role' + i}
                                type="radio"
                                id={'company_role' + i}
                                checked={this.state['company_role'] === option}
                                value={option}
                                onChange={e =>
                                  this.handleRole(
                                    'company_role',
                                    e.target.value
                                  )
                                }
                              />
                              <label
                                htmlFor={'company_role' + i}
                                className="label"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                          <input
                            key={'company_role_text'}
                            className="text-container"
                            type="text"
                            placeholder="Other function"
                            value={this.state.company_role_text}
                            onChange={e =>
                              this.handleRole(
                                'company_role_text',
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="questions">
                        <div className="question">
                          Great and How big is your company?&nbsp;
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </div>
                        <div className="options">
                          {this.sizeOptions.map((option, i) => (
                            <div className={'radio-container'} key={i}>
                              <input
                                key={'company_size' + i}
                                type="radio"
                                id={'company_size' + i}
                                checked={this.state['company_size'] === option}
                                value={option}
                                onChange={e =>
                                  this.handleOptions(
                                    'company_size',
                                    e.target.value
                                  )
                                }
                              />
                              <label
                                htmlFor={'company_size' + i}
                                className="label"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="questions">
                        <div className="question">
                          Lastly, What’s your contact number?&nbsp;
                          <span style={{ color: '#aaa' }}>(optional)</span>
                        </div>
                        <div className="options phone-input">
                          <PhoneInput
                            key="phone-number"
                            placeholder="Enter phone number"
                            value={this.state.phoneNumber}
                            style={{ marginBottom: '0px' }}
                            country="US"
                            onChange={value =>
                              this.handleOptions('phoneNumber', value)
                            }
                          />
                          {!this.state.isPhoneNumberValid &&
                            this.state.phoneNumber !== null &&
                            this.state.phoneNumber.length > 0 && (
                              <p className="error-text">
                                Invalid mobile number
                              </p>
                            )}
                        </div>
                      </div>
                    </Questions>
                  </div>
                </div>
                <div className="onboarding-footer">
                  <Progress>
                    <div className="progressbar">
                      <div
                        className="filled"
                        style={{ width: this.state.progress * 2 + 'px' }}
                      />
                    </div>
                    <div className="progress-status">
                      {this.state.progress}% Completed
                    </div>
                  </Progress>
                  <div>
                    <Button inline type="secondary" onClick={this.close}>
                      Skip
                    </Button>
                    <Button
                      inline
                      type="primary"
                      disabled={
                        !this.state.availability || !this.state.domainNameValid
                      }
                      onClick={this.closeOnboarding}
                    >
                      Finish setup
                    </Button>
                  </div>
                </div>
              </div>
            </ModalContent>
          </Modal>
        )}
      </ModalTransition>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  org: state.user.org
});

export default connect(mapStateToProps)(OnboardingModal);

const ModalContent = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  max-height: 100%;
  border-radius: 3px;

  div .uploadPictureContainer {
    display: none;
  }

  .orgUrl {
    font-size: 12px;
    color: #7d7d7d;
    padding-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 372px;
  }
  .react-phone-number-input__icon-image {
    width: 20px;
  }
  .error-text {
    color: #f00003;
    font-size: 12px;
    padding: 5px 0;
  }
  .content {
    position: relative;
    color: #000;

    .onboarding-header {
      border-bottom: solid 1px #f7f7f7;
      text-align: center;
      padding-top: 10px;
      .modal-close {
        font-size: 35px;
        line-height: 35px;
        position: absolute;
        right: 15px;
        top: 0px;
        cursor: pointer;
        color: #aaa;
      }

      h4 {
        color: #636363;
        font-size: 20px;
      }

      h2 {
        color: #323232;
        font-size: 26px;
        font-weight: 500;
      }
    }

    .onboarding-content {
      position: relative;
      height: calc(100% - 170px);
      display: flex;
      z-index: 1;
      overflow: auto;
      padding-bottom: 80px;

      .onboarding-content-left {
        width: 20%;
        padding: 22px 0 0 0;
      }
      .onboarding-content-right {
        width: 80%;
        padding-right: 75px;
      }
    }

    .onboarding-footer {
      height: 67px;
      width: 600px;
      box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.09);
      border: solid 0.5px #ffffff;
      border-radius: 0 0 3px 3px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      padding: 20px 15px;
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
  }
`;

const Questions = styled.div`
  .questions {
    width: 100%;
    display: inline-block;

    .question {
      width: 100%;
      display: inline-block;
      font-weight: 600;
      margin-top: 22px;
    }

    .options {
      width: 100%;
      display: inline-block;
    }
    .phone-input {
      max-width: 91%;
    }
    .error-border {
      border-bottom: 1px solid red;
    }

    .radio-container {
      width: 180px;
      height: 26px;
      position: relative;
      display: inline-block;
      margin-right: 12px;
      margin-top: 10px;
      border-radius: 2px;
      border: solid 0.5px #dcdcdc;

      input[type='radio'] {
        visibility: hidden;
        position: absolute;
      }

      input[type='radio'] + label {
        position: relative;
        padding: 5px 5px 5px 30px;
        font-size: 12px;
        color: #3b3b3b;
        cursor: pointer;
        width: 100%;
        display: block;
        line-height: normal;
      }

      input[type='radio'] + label:before {
        content: '';
        position: absolute;
        left: 9px;
        top: 6px;
        width: 12px;
        height: 12px;
        border: 1px solid var(--text-link);
        border-radius: 100%;
        background: #fff;
      }

      input[type='radio'] + label:after {
        content: '';
        width: 6px;
        height: 6px;
        background: var(--text-link);
        position: absolute;
        top: 9px;
        left: 12px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      input[type='radio']:not(:checked) + label:after {
        transform: scale(0);
      }

      input[type='radio']:checked + label:after {
        transform: scale(1);
      }
    }
    .text-container {
      font-size: 12px;
      padding: 10px 0 0 10px;
    }

    .radio-container.active {
      border: solid 0.5px #000000;

      input[type='radio'] + label {
        font-weight: 500;
      }
    }

    input {
      display: inline-block;
      box-sizing: border-box;
      height: 30px;
      box-shadow: 0 1px 0 0 #dadfe3;
      border: none;
      border-radius: 0;
      width: 92%;
      color: #222;
      padding: 0;

      &.error-input {
        box-shadow: 0 1px 0 0 #f00003;
      }
    }
  }
`;

const Progress = styled.div`
  .progressbar {
    display: inline-block;
    width: 200px;
    height: 5px;
    border-radius: 2.5px;
    background-color: #d8d8d8;
    .filled {
      height: 100%;
      background-color: var(--text-link);
    }
  }
  .progress-status {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: #5f5f5f;
    padding-left: 15px;
  }
`;
