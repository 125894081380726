/* eslint-disable function-paren-newline */
/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'shared/components/Select';
import { TickIcon } from 'components/Icons';
import { StyledRadioGroup, RadioOption, Options } from './style';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invalid: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

const defaultProps = {
  className: undefined,
  value: undefined,
  invalid: false,
  onChange: () => {}
};

const RadioGroupWithMenu = ({
  className,
  name,
  options,
  value,
  onChange,
  ...OptionProps
}) => {
  const selectedOption = options.findIndex(opt =>
    opt.options.find(menu => menu.value === value)
  );
  const selectedMenu =
    selectedOption > -1
      ? options[selectedOption].options.findIndex(menu => menu.value === value)
      : 0;
  const [activeOption, setActiveOption] = useState(
    selectedOption > -1 ? selectedOption : 0
  );
  const [activeMenu, setActiveMenu] = useState(selectedMenu);
  const handleChange = optionIndex => {
    setActiveOption(optionIndex);
    if (options[activeOption].options[activeMenu]) {
      onChange(options[activeOption].options[activeMenu].value);
    } else {
      setActiveMenu(0);
      onChange(options[activeOption].options[0].value);
    }
  };
  const handleSelectChange = (selectOptions, change) => {
    let activeMenuIndex = 0;
    selectOptions.forEach((opt, index) => {
      if (opt.value === change) {
        activeMenuIndex = index;
      }
    });
    setActiveMenu(activeMenuIndex);
    onChange(options[activeOption].options[activeMenuIndex].value);
  };
  return (
    <StyledRadioGroup className={className}>
      <Options {...OptionProps}>
        {options.map((option, index) => (
          <RadioOption
            key={`rg-${option.id}-index`}
            onClick={() => {
              handleChange(index);
            }}
            isActive={isGroupActive(option, value)}
          >
            <input
              type="radio"
              name={name}
              key={option.value}
              value={option.value}
              checked={value === option.value}
              readOnly
            />
            <div className="radio-select">
              <TickIcon width="11" height="11" />
            </div>
            <div className="section-option">
              {option.prepend}
              {option.options.length > 1 ? (
                <Select
                  isInline
                  value={
                    option.options[activeMenu] &&
                    option.options[activeMenu].value
                  }
                  options={option.options}
                  onChange={change => {
                    handleSelectChange(option.options, change);
                  }}
                />
              ) : (
                <span>&nbsp;{option.options[0].label}&nbsp;</span>
              )}

              {option.append}
            </div>
          </RadioOption>
        ))}
      </Options>
    </StyledRadioGroup>
  );
};

RadioGroupWithMenu.propTypes = propTypes;
RadioGroupWithMenu.defaultProps = defaultProps;

function isGroupActive(options, value) {
  return options.options.reduce((accumulator, current) => {
    if (value === current.value) {
      return true;
    }
    return accumulator;
  }, false);
}
export default RadioGroupWithMenu;
