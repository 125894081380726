import React, { Component } from 'react';
import { Button } from 'freemium-ui';
import { Toggle } from 'freemium-ui';
import BadgePreview from '../components/BadgePreview';
import BadgeOptions from '../components/BadgeOptions';
import { StyledLoading } from '../components/styled/Loading';
import { connect } from 'react-redux';

class BadgeViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckSet: false
    };
  }

  componentDidMount() {
    this.props.resetBadgeErrors();
    this.validateChecks(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      typeof nextProps.checks === Array &&
      typeof this.props.checks === Array &&
      nextProps.checks.length != this.props.checks.length
    )
      this.validateChecks(this.props);
    if (
      this.props.badgeform.id === -1 &&
      !this.props.badgeform.data.checks.length &&
      nextProps.checks.length &&
      !this.state.isCheckSet
    ) {
      this.setState({ isCheckSet: true });
      this.props.setBadgeForm({
        data: {
          ...nextProps.badgeform.data,
          checks: nextProps.checks.length ? [nextProps.checks[0].id] : []
        }
      });
    }
  }

  validateChecks = props => {
    if (props.badgeform.id !== -1 || !props.checks.length) {
      const badgeChecks = props.badgeform.data.checks;
      let resultChecks = props.checks.filter(check => {
        return badgeChecks.includes(check.id);
      });
      if (!resultChecks.length && props.checks.length) {
        props.setBadgeErrors({ isCheckError: true });
      } else if (
        resultChecks.length !== badgeChecks.length &&
        props.checks.length
      ) {
        resultChecks = resultChecks.map(check => {
          return check.id;
        });
        props.setBadgeForm({
          data: {
            ...this.props.badgeform.data,
            checks: resultChecks
          }
        });
      }
    }
  };

  submitForm = () => {
    const { badgeform, resetBadgeErrors } = this.props;
    resetBadgeErrors();
    if (badgeform.id === -1) {
      this.props.createBadge(badgeform);

      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Create Badge', window._na_module);
      }
    } else {
      this.props.updateBadge(badgeform);

      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Edit Badge', window._na_module);
      }
    }
  };

  toggleChecks = (selected, selectedChecks) => {
    const activeChecks = selectedChecks.filter(check => {
      if (
        (check.active && check.id !== selected.id) ||
        (check.id === selected.id && !check.active)
      ) {
        return check.id;
      }
    });
    const data = {
      ...this.props.badgeform.data,
      checks: activeChecks.map(check => {
        return check.id;
      })
    };
    if (!data.checks.length) {
      this.props.setBadgeErrors({ isCheckError: true });
    } else {
      this.props.setBadgeErrors({ isCheckError: false });
    }
    this.props.setBadgeForm({ data: data });
  };

  handleChange = e => {
    this.props.setBadgeForm({ [e.target.name]: e.target.value });
  };

  handleDatafieldChanges = e => {
    const data = {
      ...this.props.badgeform.data,
      target_url: e.target.value
    };
    this.props.setBadgeForm({ data: data });
  };

  handleConfiguration = e => {
    let data = { ...this.props.badgeform.data };
    if (e.name === 'current_status') {
      if (e.value === true) {
        data.metric_types.push('CS');
      } else {
        data.metric_types.pop();
      }
    }
    if (
      e.name === 'no_of_days_since' &&
      Number(e.value) !== data.no_of_days_since
    ) {
      data.no_of_days_since = Number(e.value);
      if (e.value > 0) {
        if (!(data.metric_types && data.metric_types[0] === 'AVL')) {
          data.metric_types.unshift('AVL');
        }
      } else {
        data.metric_types = data.metric_types.slice(1);
      }
    }
    this.props.setBadgeForm({ data: data });

    if (data.metric_types.length === 0) {
      this.props.setBadgeErrors({ isTypeError: true });
    } else {
      this.props.setBadgeErrors({ isTypeError: false });
    }
  };

  handleBadgeTypeChanges = e => {
    const badge_meta = { ...this.props.badgeform.data.badge_meta };
    if (e.name === 'shape') {
      badge_meta[e.name] = e.value ? 'round' : 'square';
    }
    if (e.name === 'theme') {
      badge_meta[e.name] = e.value ? 'light' : 'dark';
    }
    const data = {
      ...this.props.badgeform.data,
      badge_meta: badge_meta
    };
    this.props.setBadgeForm({ data: data });
  };

  isBadgeValid = (badgeform, badgeformErrors) => {
    return (
      badgeform.name.length &&
      !badgeformErrors.isCheckError &&
      !badgeformErrors.isTypeError
    );
  };

  selectAllChecks = checks => {
    const data = {
      ...this.props.badgeform.data,
      checks: checks.map(check => {
        return check.id;
      })
    };
    this.props.setBadgeForm({ data: data });
    this.props.setBadgeErrors({ isCheckError: false });
  };

  render() {
    const { badgeform, checks, formState, badgeformErrors } = this.props;
    const checksStyle = {
      marginBottom: 0
    };
    const selectedChecks =
      checks &&
      checks.length &&
      badgeform.data.checks &&
      checks.map(check => {
        const currentCheck = badgeform.data.checks.find(
          selected => check.id === selected
        );
        if (currentCheck) {
          return {
            ...check,
            active: true
          };
        } else {
          return check;
        }
      });

    return (
      <React.Fragment>
        <section className="status-page-viewer">
          <div className="statuspage-controls badgepage-controls">
            <div className="badge-scrollable">
              <div className="badge-title">
                {badgeform.id === -1 ? 'Create ' : 'Edit '} badge
              </div>
              <div className="badge-controls badge-fields">
                <label className="badgeField" htmlFor="name">
                  Badge name
                </label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  className="badge-text-field"
                  name="name"
                  placeholder="Freshworks Badge"
                  autoComplete="off"
                  value={badgeform.name}
                />
                <label className="badgeField" htmlFor="target_url">
                  Target URL on badge click
                </label>
                <input
                  type="text"
                  onChange={this.handleDatafieldChanges}
                  className="badge-text-field"
                  name="target_url"
                  placeholder="https://status.freshworks.com"
                  autoComplete="off"
                  value={badgeform.data.target_url}
                />
              </div>
              <BadgeOptions
                badgeform={badgeform}
                isTypeError={this.props.badgeformErrors.isTypeError}
                handleConfiguration={this.handleConfiguration}
                handleAvailability={this.handleAvailability}
                handleBadgeTypeChanges={this.handleBadgeTypeChanges}
              />
              <div className="badge-controls" style={checksStyle}>
                <div className="viewer-checks badge-viewer-checks">
                  <div className="badge-checks-header">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>
                        Checks to include{' '}
                        <span className="badge-count">
                          {' '}
                          {' (' +
                            badgeform.data.checks.length +
                            '/' +
                            checks.length +
                            ')'}
                        </span>
                      </span>
                      <button
                        className="select-checks"
                        onClick={() => {
                          this.selectAllChecks(checks);
                        }}
                      >
                        {' '}
                        Select All
                      </button>
                    </div>
                    {this.props.badgeformErrors.isCheckError && (
                      <p className="badge-checks-error">
                        {' '}
                        Select atleast one Check
                      </p>
                    )}
                  </div>
                  <div className="badge-content">
                    <ul>
                      {checks &&
                      selectedChecks &&
                      (badgeform.id === -1 ||
                        formState !== 'idle' ||
                        formState !== 'error') ? (
                        selectedChecks.map((check, index) => (
                          <React.Fragment>
                            <div>
                              <li
                                className="checks-content"
                                key={check.id}
                                style={{ padding: '7px 0 10px 15px' }}
                              >
                                <div className="grip-span">
                                  {check.name ? check.name : `id-${check.id}`}{' '}
                                </div>
                                <Toggle
                                  hasIcon={false}
                                  on={check.active}
                                  handleChange={() =>
                                    this.toggleChecks(check, selectedChecks)
                                  }
                                />
                              </li>
                            </div>
                          </React.Fragment>
                        ))
                      ) : (
                        <StyledLoading />
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="statuspage-form-action badge-form-action">
              <Button
                inline
                type="secondary"
                onClick={this.props.flipCancelModal}
              >
                Cancel
              </Button>
              <Button
                inline
                type="primary"
                disabled={!this.isBadgeValid(badgeform, badgeformErrors)}
                onClick={this.submitForm}
              >
                {formState === 'loading'
                  ? 'Please wait'
                  : badgeform.id !== -1
                  ? 'Update'
                  : 'Create'}
              </Button>
            </div>
          </div>

          <BadgePreview
            badgeform={badgeform}
            badgeformErrors={badgeformErrors}
          />
        </section>
      </React.Fragment>
    );
  }
}

export default connect(() => {})(BadgeViewer);
