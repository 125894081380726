import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Link } from "react-router-dom";
// import { request } from "../utils/Api";
import {
  getAllChecks,
  getStatusPages,
  resetStatusPageErrors,
  setStatusPageErrors,
  getStatusPageChecks,
  createStatusPage,
  createStatusPageCname,
  verifyStatusPageCname,
  setActiveStatusChecks,
  setStatusPageForm,
  updateStatusPage
} from '../actions';
import { initialStatusPageState } from '../reducers/statuspages';
import { getOrgId } from '../utils/utility';
import { StyledLoading } from '../components/styled/Loading';
import StatusViewer from './StatusViewer';
import '../styles/status.css';

const mapDispatchToProps = dispatch => ({
  getStatusPages: id => dispatch(getStatusPages(id)),
  getAllChecks: () => dispatch(getAllChecks()),
  setStatusPageErrors: errors => dispatch(setStatusPageErrors(errors)),
  resetStatusPageErrors: () => dispatch(resetStatusPageErrors()),
  setStatusPageForm: status => dispatch(setStatusPageForm(status)),
  updateStatusPage: status => dispatch(updateStatusPage(status)),
  createStatusPage: status => dispatch(createStatusPage(status)),
  createStatusPageCname: status => dispatch(createStatusPageCname(status)),
  verifyStatusPageCname: status => dispatch(verifyStatusPageCname(status)),
  getStatusChecks: id => dispatch(getStatusPageChecks(id)),
  setActiveStatusChecks: status => dispatch(setActiveStatusChecks(status))
});

const mapStateToProps = state => ({
  statuspages: state.statuspages,
  checks: state.checks
});

class Status extends Component {
  state = {
    statusId: null,
    isReady: false
  };
  componentDidMount() {
    this.props.getAllChecks();
    this.props.getStatusPages();
    if (this.props.create) {
      this.props.setStatusPageForm({
        ...initialStatusPageState.statuspageform,
        id: -1,
        organization: getOrgId()
      });
    }
  }

  componentWillUnmount() {
    // resetting statuspage
    this.props.setStatusPageForm({
      ...initialStatusPageState.statuspageform,
      id: null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.create) {
      if (!this.props.checks.checks.length && nextProps.checks.checks.length) {
        this.props.setStatusPageForm({
          associated_checks: [nextProps.checks.checks[0]]
        });
      }
      if (
        this.props.statuspages.formState === 'loading' &&
        nextProps.statuspages.formState === 'success' &&
        nextProps.statuspages.statuspageform.id
      ) {
        if (nextProps.statuspages.statuspageform.custom_domain) {
          this.props.createStatusPageCname({
            id: nextProps.statuspages.statuspageform.id,
            custom_domain: nextProps.statuspages.statuspageform.custom_domain
          });
        }
        this.props.history.push(
          `/status/${nextProps.statuspages.statuspageform.id}`
        );
      }
      return;
    }
    if (
      this.props.statuspages.formState === 'loading' &&
      nextProps.statuspages.formState === 'success' &&
      nextProps.statuspages.statuspageform.id
    ) {
      if (
        nextProps.statuspages.statuspageform.custom_domain &&
        !nextProps.statuspages.statuspageform.custom_domain_cname
      ) {
        this.props.createStatusPageCname({
          id: nextProps.statuspages.statuspageform.id,
          custom_domain: nextProps.statuspages.statuspageform.custom_domain
        });
        this.props.history.push(
          `/status/${nextProps.statuspages.statuspageform.id}`
        );
      } else if (
        nextProps.statuspages.statuspageform.custom_domain_cname &&
        !nextProps.statuspages.statuspageform.custom_domain_cname.is_verified
      )
        this.props.history.push(
          `/status/${nextProps.statuspages.statuspageform.id}`
        );
      else this.gotoList();
    }
    const {
      checks: { isLoading, checks },
      statuspages: { appState, statuspages, statuspageform }
    } = nextProps;
    // const statuspageschecks = statuspages.results.length
    // ?
    // : 0;
    const url = window.location.href;
    const statusId = Number(url.substring(url.lastIndexOf('/') + 1));

    if (
      (statuspageform.id && statuspageform.id !== statusId) ||
      (!isLoading &&
        checks.length &&
        appState === 'success' &&
        statuspages.results.length &&
        !statuspageform.id)
    ) {
      this.setFormEdit(statuspages, statusId);
      // this.props.getStatusChecks(currentStatus.public_url);
    }
  }
  setFormEdit = (statuspages, statusId) => {
    const selectedItem = statuspages.results.find(
      status => status.id === statusId
    );
    if (selectedItem) {
      this.props.setActiveStatusChecks(selectedItem);
    }
  };
  gotoList = () => {
    this.props.history.push(`/status`);
  };
  render() {
    if (
      'checks' in this.props.checks &&
      this.props.checks.checks.length &&
      'associated_checks' in this.props.statuspages.statuspageform &&
      this.props.statuspages.statuspageform.associated_checks.length
    ) {
      this.props.checks.checks.forEach((check, parentindex) => {
        if (!check.order) {
          var matchedObject = this.props.statuspages.statuspageform.associated_checks.find(
            selected => {
              if ('application_check' in selected) {
                return selected.application_check === check.id;
              } else {
                return selected.id === check.id;
              }
            }
          );
        }
        if (matchedObject) {
          this.props.checks.checks[parentindex].order = matchedObject.order;
        }
      });
    }

    if (this.props.checks.checks) {
      this.props.checks.checks.sort(function(a, b) {
        var orderA = a.order,
          orderB = b.order;
        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });
      var zeroOrderIndex = [];
      this.props.checks.checks.forEach((el, index) => {
        //if(el.order == 0 && (!("active" in el) || !el.active)) {
        if (!el.order) {
          zeroOrderIndex.push(index);
        }
      });
      zeroOrderIndex.forEach((el, index) => {
        const start = el - index;
        const end = this.props.checks.checks.length;
        const [removed] = this.props.checks.checks.splice(start, 1);
        this.props.checks.checks.splice(end, 0, removed);
      });
    }

    const {
      statuspages: {
        appState,
        // statuspages,
        formState,
        statuspageform,
        statuspageformErrors
      },
      checks: { checks },
      setStatusPageForm,
      updateStatusPage,
      setStatusPageErrors,
      resetStatusPageErrors,
      createStatusPage,
      createStatusPageCname,
      verifyStatusPageCname
    } = this.props;
    // const { statusId } = this.state;

    // const currentStatus = statuspages.results
    //   ? statuspages.results.find(status => status.id === statuspageform.id)
    //   : null;
    // let selectedChecks = null;
    // selectedChecks = currentStatus && currentStatus.checks && checks.map(check => {
    //   return currentStatus.checks.find(
    //     selected => check.id === selected.check_id
    //   )
    //     ? { ...check, active: true }
    //     : check;
    // });
    return (
      <div className="status-container">
        {appState !== 'success' && statuspageform && statuspageform.id ? (
          <StyledLoading />
        ) : (
          <React.Fragment>
            {statuspageform.id && (
              <StatusViewer
                statuspageform={statuspageform}
                statuspageformErrors={statuspageformErrors}
                updateStatusPage={updateStatusPage}
                setStatusPageErrors={setStatusPageErrors}
                resetStatusPageErrors={resetStatusPageErrors}
                createStatusPage={createStatusPage}
                createStatusPageCname={createStatusPageCname}
                verifyStatusPageCname={verifyStatusPageCname}
                checks={checks}
                gotoList={this.gotoList}
                formState={formState}
                setStatusPageForm={setStatusPageForm}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Status));
