import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Sidebar from "./sidebar";
import Header from "./Header";
import AccountSuspensionCard from "../containers/AccountSuspensionCard";
import AccountPausedCard from "../containers/AccountPausedCard";
import AccountInactiveCard from "../containers/AccountInactiveCard";
import { getCurrentUserOrg } from "../actions";
import { getOrgId } from "../utils/utility";
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrg: null,
      deviceSize: null,
    };

    window.addEventListener("resize", this.handleResize.bind(this));
  }

  componentDidMount() {
    // if (!this.props.user.isAuthenticated)
    const token = localStorage.getItem("auth-token");
    if (!token) {
      window.location.href = `${window.location.origin}/login`;
    }
    this.props.getCurrentUserOrg();
    this.setState({
      currentOrg: getOrgId(),
    });
  }

  componentWillMount() {
    this.handleResize();
    window.freshworksOmnibar = {
      onSessionStateChanged: function callback() {
        // clear product's session and do
        // /authorize call to FreshID
        window.location.href = `${window.location.origin}/freshid/logout/`;
      },
    };
  }

  handleResize = () => {
    let deviceSize = "device-large";

    if (typeof window !== "undefined" && window.innerWidth < 1000) {
      deviceSize = "device-small";
    }

    this.setState({ deviceSize: deviceSize });
  };

  render() {
    const { org, children, page } = this.props;
    const { currentOrg, deviceSize } = this.state;

    let isAdmin = false;
    org &&
      org.forEach((el) => {
        if (el.id === Number(currentOrg) && el.logged_in_user_role === "AD") {
          isAdmin = true;
        }
      });
    const userOrg = org && org.find((obj) => obj.id === Number(currentOrg));

    if (userOrg && userOrg.state === "CA") {
      return (
        <Fragment>
          <AccountSuspensionCard userOrg={userOrg} />
        </Fragment>
      );
    }

    if (userOrg && userOrg.state === "PS") {
      return (
        <Fragment>
          <AccountPausedCard userOrg={userOrg} />
        </Fragment>
      );
    }

    if (userOrg && userOrg.state === "NA") {
      return (
        <Fragment>
          <AccountInactiveCard userOrg={userOrg} />
        </Fragment>
      );
    }

    return (
      <React.Fragment>
        <Sidebar isAdmin={isAdmin} deviceSize={deviceSize} />
        <div className="App-content">
          <Header isAdmin={isAdmin} page={page} deviceSize={deviceSize} />
          {deviceSize && deviceSize === "device-small" ? (
            <div className="device-small-warning">
              Freshping is not fully accessible on mobile.
            </div>
          ) : null}
          <div className={deviceSize + " App-main"}>{children}</div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  org: state.user.org,
});

export default connect(mapStateToProps, { getCurrentUserOrg })(Layout);
