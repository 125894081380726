import React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Popover } from 'freemium-ui';
import { toastr } from 'react-redux-toastr';
import { getOrgId } from 'utils/utility';
import { request } from 'utils/Api';
import slackLogo from 'images/slack-logo.svg';
import twilioLogo from 'images/twilio-logo.svg';
import zapierlogo from 'images/zapier.svg';
import dropdownimg from 'images/dropdown.svg';
import FreshDeskIntegration from 'containers/Integrations/FreshDeskIntegration';
import FreshServiceIntegration from 'containers/Integrations/FreshServiceIntegration';
import WebhookIntegration from 'containers/Integrations/WebhookIntegration';

import AddNewIntegrationModal from './AddNewIntegrationModal';
import TwilioCreatedIntegrations from './TwilioCreatedIntegrations';
import TwilioIntegrationModal from './TwilioIntegrationModal';
import SlackCreatedIntegrations from './SlackCreatedIntegrations';

class IntegrationItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creatingSlackIntegration: false,
      slackAnchorEl: null,
      twilioIntegrations: [],
      slackIntegrations: [],
      newIntegration: true,
      alert_source_check_state: 'AN'
    };
    this.showAddIntModal = this.showAddIntModal.bind(this);
    this.onclose = this.onclose.bind(this);
    this.openTwilioAddIntModal = this.openTwilioAddIntModal.bind(this);
    this.openCreateSlackPopup = this.openCreateSlackPopup.bind(this);
    this.popupRef = React.createRef();
  }
  onclose() {
    this.setState({ showAddIntModal: false });
  }
  setTwilioIntegration(integration) {
    this.setState({
      twilioIntegrations: [...this.state.twilioIntegrations, integration]
    });
  }
  setSlackIntegration(integration) {
    this.setState({
      slackIntegrations: [...this.state.slackIntegrations, integration]
    });
  }
  changeState(changedIntegration) {
    const twilioIntegrations = [...this.state.twilioIntegrations];
    const index = twilioIntegrations.findIndex(
      integration => integration.id === changedIntegration.id
    );
    twilioIntegrations[index] = changedIntegration;
    this.setState({ twilioIntegrations });
  }
  changeSlackState(changedIntegration) {
    const slackIntegrations = [...this.state.slackIntegrations];
    const index = slackIntegrations.findIndex(
      integration => integration.id === changedIntegration.id
    );
    slackIntegrations[index] = changedIntegration;
    this.setState({ slackIntegrations });
  }
  showAddIntModal(e, name) {
    this.setState({ showAddIntModal: !this.state.showAddIntModal, name });
  }
  openCreateSlackPopup(integration, doEdit, e) {
    this.setRequiredState();
    this.setState({
      slackAnchorEl: e.currentTarget,
      slackIntegrationToEdit: integration,
      doEdit
    });
  }
  closeCreateSlackPopup() {
    this.setState({ slackAnchorEl: null });
  }
  openTwilioAddIntModal(e, integration, newInteg) {
    if (window.fcWidget) {
      window.fcWidget.hide();
    }
    this.setState({
      toggleTwilioAddIntModal: true,
      integrationToEdit: integration,
      newIntegration: !newInteg
    });
    document.body.classList.add('remove-overflow');
  }

  closeTwilioAddIntModal() {
    if (window.fcWidget) {
      window.fcWidget.show();
    }
    document.body.classList.remove('remove-overflow');
    this.setState({ toggleTwilioAddIntModal: false });
  }
  handleChange(evt, value, name) {
    this.setState({
      [evt.target ? evt.target.name : name]: evt.target
        ? evt.target.value
        : evt.value
    });
  }
  async getTwilioIntegrations() {
    const response = await request(
      `/integrations/sms-alerters/?organization=${getOrgId()}`
    );
    this.setState({ twilioIntegrations: response.results });
  }
  async getSlackIntegrations() {
    const response = await request(
      `/integrations/slack-alerters/?organization=${getOrgId()}`
    );
    this.setState({ slackIntegrations: response.results });
  }
  async createSlackIntegration(e) {
    if (this.state.doEdit) {
      this.editSlackIntegration(e);
      return;
    }
    e.preventDefault();
    const data = {
      organization: getOrgId(),
      state: 'AC',
      alert_source_mode: 'AA',
      alert_source_check_state: this.state.alert_source_check_state
        ? this.state.alert_source_check_state
        : 'AN'
    };
    this.setState({ creatingSlackIntegration: true });
    localStorage.setItem('slack-oauth-orgid', getOrgId());
    const response = await request('/integrations/slack-alerters/', {
      method: 'POST',
      payload: data
    });

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Slack Create Integration', window._na_module);
    }

    this.setState({ creatingSlackIntegration: false });
    if (response.oauth_authorization_url) {
      this.setState({ creatingSlackIntegration: true });
      window.location = response.oauth_authorization_url;
    } else if (response && response.response.non_field_errors) {
      console.log(response.response.non_field_errors[0]);
    } else if (response) {
      if (response.data) console.log(response.data[0]);
      else if (response.response.data) console.log(response.response.data[0]);
    }
  }
  setRequiredState() {
    const slackIntegrationToEdit = this.state.slackIntegrationToEdit;
    if (slackIntegrationToEdit) {
      this.setState({
        alert_source_check_state:
          slackIntegrationToEdit.alert_source_check_state
      });
    } else {
      this.setState({ alert_source_check_state: '' });
    }
  }
  async editSlackIntegration(e) {
    e.preventDefault();
    const self = this;
    const slackIntegrationToEdit = this.state.slackIntegrationToEdit;
    const data = {
      alert_source_check_state: this.state.alert_source_check_state,
      organization: getOrgId()
    };
    self.setState({ creatingSlackIntegration: true });
    request(`/integrations/slack-alerters/${slackIntegrationToEdit.id}`, {
      method: 'PATCH',
      payload: data
    })
      .catch(error => console.error('Error-->:', error))
      .then(response => {
        self.setState({ creatingSlackIntegration: false });
        if (response && response.id) {
          if (response.oauth_authorization_url) {
            if (window._na && window._na_module) {
              window._na.sendFeatureEvent('Slack Edit Integration', window._na_module);
            }
            // window.open(response.oauth_authorization_url, '_blank');
            localStorage.setItem('slack-oauth-orgid', response.organization);
            window.location = response.oauth_authorization_url;
          }
        }
      });
  }
  async deleteTwilioIntegration(id) {
    await request(`/integrations/sms-alerters/${id}`, {
      method: 'DELETE'
    });
    this.setState({
      twilioIntegrations: this.state.twilioIntegrations.filter(
        integration => integration.id !== id
      )
    });
    this.props.updateIntegrationCount();
    toastr.success('Twilio integration has been deleted successfully!');

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Twillio Delete Integration', window._na_module);
    }
  }
  async deleteSlackIntegration(id) {
    await request(`/integrations/slack-alerters/${id}`, {
      method: 'DELETE'
    });
    this.setState({
      slackIntegrations: this.state.slackIntegrations.filter(
        integration => integration.id !== id
      )
    });
    this.props.updateIntegrationCount();
    toastr.success('Slack integration has been deleted successfully!');

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Slack Delete Integration', window._na_module);
    }
  }
  componentDidMount() {
    this.getTwilioIntegrations();
    this.getSlackIntegrations();
    setTimeout(() => {
      const el = document.getElementById('zapier');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src =
        'https://zapier.com/apps/embed/widget.js?guided_zaps=29910,29912,30003,30007&limit=4&html_id=zapier';
      el.appendChild(script);
    }, 300);
  }
  render() {
    const {
      creatingSlackIntegration,
      twilioIntegrations,
      slackIntegrations
    } = this.state;
    return (
      <div className="integrations-list topAdj content">
        <section className="topbar m_integration-type content">
          <span className="tab active-tab">Native integrations</span>
        </section>
        <FreshDeskIntegration
          isIntegrationAllowed={this.props.isIntegrationAllowed}
          updateIntegrationCount={this.props.updateIntegrationCount}
        />
        <FreshServiceIntegration
          isIntegrationAllowed={this.props.isIntegrationAllowed}
          updateIntegrationCount={this.props.updateIntegrationCount}
        />
        <WebhookIntegration
          isIntegrationAllowed={this.props.isIntegrationAllowed}
          updateIntegrationCount={this.props.updateIntegrationCount}
        />
        <div>
          <section className="slack-integration integration-item content">
            <div className="name">
              <div className="slack-title">
                <img src={slackLogo} alt="slack Logo" />
                <span>Slack</span>
              </div>
              <Button
                size="normal"
                onClick={e => this.openCreateSlackPopup({}, false, e)}
                inline
                className={
                  !this.props.isIntegrationAllowed ? 'slack-disabled' : ''
                }
                disabled={!this.props.isIntegrationAllowed}
                aria-describedby="slack-popup"
                type="link"
              >
                &#43; Create Integration
              </Button>
              <Popover
                id="slack-popup"
                anchorEl={this.state.slackAnchorEl}
                onClose={() =>
                  this.setState({
                    slackAnchorEl: null
                  })
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <div
                  role="none"
                  className="modal-body popuptext create-group-popup create-integration-popup"
                  onClick={e => e.stopPropagation()}
                >
                  <form className="invite-user-form slack-popup-form">
                    <label htmlFor="alert_source_check_state">
                      Send alerts for
                    </label>
                    <Select
                      style={{ boxShadow: 'none' }}
                      className=""
                      value={
                        this.state.alert_source_check_state
                          ? this.state.alert_source_check_state
                          : 'AN'
                      }
                      clearable={false}
                      required
                      searchable={false}
                      onChange={(e, value) =>
                        this.handleChange(e, value, 'alert_source_check_state')
                      }
                      options={[
                        { label: 'Any State', value: 'AN' },
                        { label: 'Any Error', value: 'AE' },
                        { label: 'Reporting Error only', value: 'RE' },
                        { label: 'Not Responding only', value: 'NR' }
                      ]}
                    />
                    <div className="modal-footer">
                      <Button
                        inline
                        type="primary"
                        disabled={creatingSlackIntegration}
                        onClick={e => this.createSlackIntegration(e)}
                      >
                        {creatingSlackIntegration
                          ? 'Please wait'
                          : this.state.slackIntegrationToEdit
                          ? 'Save and Authorize'
                          : 'Create and Authorize'}
                      </Button>
                    </div>
                  </form>
                </div>
              </Popover>
            </div>
            {slackIntegrations.length > 0 && (
              <SlackCreatedIntegrations
                slackIntegrations={slackIntegrations}
                deleteSlackIntegration={e => this.deleteSlackIntegration(e)}
                changeSlackState={e => this.changeSlackState(e)}
                openCreateSlackPopup={this.openCreateSlackPopup}
              />
            )}
          </section>
        </div>
        <div>
          <section className="slack-integration integration-item content">
            <div className="name">
              <div className="slack-title">
                <img src={twilioLogo} alt="twilio Logo" />
                <span>Twilio</span>
              </div>
              <div className="create-integration">
                <Button
                  size="normal"
                  onClick={e => this.openTwilioAddIntModal(e)}
                  inline
                  className={
                    !this.props.isIntegrationAllowed ? 'slack-disabled' : ''
                  }
                  disabled={!this.props.isIntegrationAllowed}
                  type="link"
                >
                  &#43; Create Integration
                </Button>
              </div>
            </div>
            {twilioIntegrations.length > 0 && (
              <TwilioCreatedIntegrations
                twilioIntegrations={twilioIntegrations}
                deleteTwilioIntegration={e => this.deleteTwilioIntegration(e)}
                changeState={e => this.changeState(e)}
                openTwilioAddIntModal={this.openTwilioAddIntModal}
              />
            )}
          </section>
        </div>
        {this.state.showAddIntModal ? (
          <AddNewIntegrationModal
            onCloseModal={this.onclose}
            name={this.state.name}
            new
          />
        ) : null}
        {this.state.toggleTwilioAddIntModal ? (
          <TwilioIntegrationModal
            onCloseModal={e => this.closeTwilioAddIntModal(e)}
            new={this.state.newIntegration}
            integrationToEdit={this.state.integrationToEdit}
            getTwilioIntegrations={e => this.getTwilioIntegrations(e)}
            setTwilioIntegration={e => this.setTwilioIntegration(e)}
            changeState={e => this.changeState(e)}
            updateIntegrationCount={this.props.updateIntegrationCount}
          />
        ) : null}
        <section className="topbar m_integration-type content">
          <span className="tab active-tab">Zapier Integrations</span>
        </section>
        <div>
          <section className="slack-integration integration-item content">
            <div className="name">
              <div className="slack-title">
                <img src={zapierlogo} alt="zapier Logo" />
                <span> Zapier </span>
              </div>
            </div>
            <StyledZapierContent id="zapier" />
            <a
              href="https://zapier.com/apps/Freshping/integrations?rebuild=true&utm_source=Freshping&utm_medium=app"
              className="moreIntegration"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              More Integrations{' '}
            </a>
          </section>
        </div>
      </div>
    );
  }
}
IntegrationItems.contextTypes = {
  router: PropTypes.object.isRequired
};
export default IntegrationItems;

const StyledZapierContent = styled.div`
  .zap-widget-bd1kqYPZ {
    margin: 0 !important;
  }

  .zap-widget {
    display: flex;
    flex-direction: row;

    .zap-row {
      margin: 0px 5px 5px 0 !important;
      text-align: center;
      border: 0px !important;
      position: relative;
      flex: 1;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 25%;
        height: 50%;
        left: 100%;
        border-right: 1px solid #979797;
        opacity: 0.15;
      }

      &:nth-child(4n) {
        &:after {
          display: none !important;
        }
      }

      .zap-services,
      .zap-cta {
        display: table-row !important;
        text-align: center !important;
      }

      .zap-description {
        display: block !important;
        padding: 10px 0px !important;
        text-align: center !important;
        font-size: 13px !important;
        min-height: 5rem;
        color: #6f7c87 !important;
      }

      .zap-cta {
        a.zap-button {
          background: #fff !important;
          color: var(--text-link) !important;
          padding: 8px 15px !important;
          border-radius: 5px !important;
          font-weight: 500 !important;
          width: 120px;
          &:hover {
            background: var(--color-smoke-50) !important;
          }
          &:first-letter {
            text-transform: uppercase;
          }
        }
      }

      .zap-services {
        .zap-arrow {
          background-image: url(${dropdownimg}) !important;
          width: 12px !important;
          height: 12px;
          background-size: 13px !important;
          transform: rotate(270deg);
          background-repeat: no-repeat !important;
        }
        .zap-service {
          border: 0 !important;
        }
      }
    }

    .zap-power-bar {
      display: none;
    }
  }
`;
