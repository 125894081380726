/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { StyledRadioGroup, RadioOption, Options } from './style';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invalid: PropTypes.bool,
  justify: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  inline: PropTypes.bool,
  onChange: PropTypes.func
};

const defaultProps = {
  className: undefined,
  value: undefined,
  invalid: false,
  justify: 'flex-start',
  inline: false,
  onChange: () => {}
};

const Input = ({
  className,
  name,
  options,
  value,
  inline,
  onChange,
  justify,
  ...OptionProps
}) => (
  <StyledRadioGroup className={className} inline={inline}>
    <Options {...OptionProps} justify={justify}>
      {options.map(option => (
        <RadioOption
          onClick={() => option.value !== value && onChange(option.value)}
          key={`${name}-${option.value}`}
          isActive={option.value === value}
          className={option.value === value ? 'active' : ''}
        >
          <input
            type="radio"
            name={name}
            key={option.value}
            value={option.value}
            checked={value === option.value}
            readOnly
          />
          {option.label}
        </RadioOption>
      ))}
    </Options>
  </StyledRadioGroup>
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
