/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button } from 'freemium-ui';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FP_USER_LOCALSTATE } from '../constants/constant';
import welcomeImg from '../images/welcome_popup.png';
import { updateUserUIState } from '../actions';

class WelcomeModal extends Component {
  state = {
    welcome: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.user === null && prevProps.user !== this.props.user) {
      const customUIState = JSON.parse(this.props.user.custom_ui_state);
      if (customUIState !== null) {
        if (
          customUIState[FP_USER_LOCALSTATE] !== 'visited' &&
          customUIState.plan_name !== 'SPROUT'
        ) {
          this.setState({
            welcome: true,
            plan: customUIState.plan_name
          });
        }
      }
    }
  }

  closeAndNavigateToPricing = () => {
    this.closeWelcome();
    this.props.history.push(`/settings/pricing`);
  };

  closeWelcome = () => {
    this.props.updateUserUIState({
      body: {
        custom_ui_state: JSON.stringify({
          ...JSON.parse(this.props.user.custom_ui_state),
          [FP_USER_LOCALSTATE]: 'visited'
        })
      },
      id: this.props.user.id
    });
    this.setState({ welcome: false });
  };

  render() {
    const { welcome, plan } = this.state;
    return (
      <ModalTransition>
        {welcome && (
          <Modal
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
            onClick={this.closeWelcome}
            className="hi"
            heading={undefined}
            width="700px"
            components={{
              Body: () => (
                <ModalContent>
                  <img
                    alt="welcome"
                    src={welcomeImg}
                    width="260x"
                    height="220px"
                  />
                  <div className="content">
                    <h4>
                      <span role="img" aria-label="Hi">
                        👋
                      </span>
                      Hello, Welcome to freshping!
                    </h4>
                    <div className="planname">
                      You are in <b>SPROUT</b> plan!
                    </div>
                    <p>
                      You can take <b>{plan}</b> trial plan, for 21 days trial
                      period.
                      <br />
                      You can activate the plan at anytime within the trial
                      period.
                    </p>
                    <div className="action">
                      <Button
                        inline
                        type="secondary"
                        onClick={this.closeWelcome}
                      >
                        I'll do it later
                      </Button>
                      <Button
                        inline
                        type="primary"
                        onClick={this.closeAndNavigateToPricing}
                      >
                        Start {plan} trial
                      </Button>
                    </div>
                  </div>
                </ModalContent>
              ),
              Container: 'div'
            }}
          />
        )}
      </ModalTransition>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchProps = dispatch => ({
  updateUserUIState: payload => dispatch(updateUserUIState(payload))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchProps)(WelcomeModal)
);

const ModalContent = styled.div`
  display: flex;
  justify-content: flex-start;

  img {
    border-right: solid 1px #ebf3eb;
    /* margin-left: -20px; */
    display: block;
  }

  .content {
    padding: 20px 0 0 20px;
    color: #333;
    h4 {
      color: #444;
      font-weight: 500;
      margin-top: 0;
      font-size: 18px;
      padding-left: 5px;
    }
    .planname {
      border-radius: 22px;
      background-image: linear-gradient(to top, #ffffff, #f9f6f1);
      width: 200px;
      padding: 10px 15px;
    }

    p {
      line-height: 25px;
      padding-left: 5px;
      b {
        font-weight: 500;
        font-size: 16px;
        color: var(--base-color);
        text-transform: capitalize;
      }
    }

    .action {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
      button {
        margin: 0 10px;
      }
    }
  }
`;
