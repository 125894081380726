import React from 'react';

export const BadgeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="22"
    viewBox="0 0 22 19"
  >
    <g fill="none" fillRule="evenodd" stroke="#abafba">
      <path
        fill="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M21.57 16.758V6.5c0-.828-.713-1.5-1.593-1.5H2.254c-.88 0-1.593.672-1.593 1.5v10.262c0 .828.713 1.5 1.593 1.5h17.723c.88 0 1.593-.676 1.593-1.504zm-20.136 0V6.76a1 1 0 0 1 1-1h17.363a1 1 0 0 1 1 1v9.998c0 .426-.367.772-.82.772H2.254c-.452 0-.82-.346-.82-.772z"
      />
      <path
        fill="#abafba"
        d="M12.41 9.594l2.542-2.541 2.562 2.562.078-6.69v-.017a1.5 1.5 0 0 0-1.5-1.5h-2.147a1.5 1.5 0 0 0-1.5 1.492l-.035 6.694z"
      />
      <path
        d="M4.318 12.627l2.288-2.088 2.288 2.088M17.591 11.962l-5-.023"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export const StatusIcon = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" fill="none">
      <g id="8" transform="translate(-17 -178)" stroke="#abafba">
        <g id="side_menu">
          <g id="public_status" transform="translate(17 179)">
            <g id="Group-4" fill="#abafba">
              <path
                d="M21.5697745,14.7577242 L21.5697745,1.50030829 C21.5697745,0.67166952 20.8565992,0 19.976874,0 L2.25425429,0 C1.37447568,0 0.661353765,0.67166952 0.661353765,1.50030829 L0.661353765,14.761847 C0.661353765,15.5902344 1.37447568,16.262105 2.25425429,16.262105 L19.976874,16.262105 C20.8565992,16.262105 21.5697745,15.5863127 21.5697745,14.7577242 Z M1.43431622,14.7577242 L1.43431622,1.75978067 C1.43431622,1.20749592 1.88203147,0.75978067 2.43431622,0.75978067 L19.7967587,0.75978067 C20.3490434,0.75978067 20.7967587,1.20749592 20.7967587,1.75978067 L20.7967587,14.7577242 C20.7967587,15.1840853 20.4295482,15.5299506 19.976874,15.5299506 L2.25425429,15.5299506 C1.80152676,15.5299506 1.43431622,15.1840853 1.43431622,14.7577242 Z"
                id="Fill-3"
              />
            </g>
            <path
              d="M5.73917236,3.15572046 C5.85284115,3.15572046 5.96188734,3.11014367 6.04248669,3.03219994 C6.12326384,2.95442776 6.17049743,2.84920658 6.17049743,2.73952494 C6.17049743,2.62967174 6.12326384,2.52445056 6.04248669,2.44667838 C5.96188734,2.36896339 5.85284115,2.32315786 5.73917236,2.32315786 C5.62532577,2.32315786 5.51627958,2.36896339 5.43568023,2.44667838 C5.35514014,2.52445056 5.30766949,2.62967174 5.30766949,2.73952494 C5.30766949,2.84920658 5.35514014,2.95442776 5.43568023,3.03219994 C5.51627958,3.11460414 5.62532577,3.15572046 5.73917236,3.15572046 Z"
              id="Fill-7"
              fill="#abafba"
            />
            <g id="Group-18" transform="rotate(-180 8.712 5.808)">
              <polyline
                id="Path-7"
                points="0.3646871 2.55187968 2.65268076 0.464461148 4.94067441 2.55187968"
              />
              <path
                d="M2.63807027,5.16637213 L2.63807027,1.14298871"
                id="Path-7"
              />
            </g>
            <path
              d="M3.4160145,3.15572046 C3.52992035,3.15572046 3.63896653,3.11014367 3.71950662,3.03219994 C3.80004671,2.95442776 3.84751736,2.84920658 3.84751736,2.73952494 C3.84751736,2.62967174 3.80004671,2.52445056 3.71950662,2.44667838 C3.63896653,2.36896339 3.58379681,2.32315786 3.4160145,2.32315786 C3.24823218,2.32315786 3.19306246,2.36896339 3.11252237,2.44667838 C3.03192301,2.52445056 2.98451163,2.62967174 2.98451163,2.73952494 C2.98451163,2.84920658 3.03192301,2.95442776 3.11252237,3.03219994 C3.19306246,3.11460414 3.3023457,3.15572046 3.4160145,3.15572046 Z"
              id="Fill-9"
              fill="#abafba"
            />
            <g id="Group-20" transform="translate(4.646 5.808)">
              <polyline
                id="Path-7"
                points="0.729046665 2.7557976 3.01704032 0.668379069 5.30503398 2.7557976"
              />
              <path
                d="M3.00242983,5.37029005 L3.00242983,1.34690663"
                id="Path-7"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const Logo = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.9632 63.9265C14.3195 63.9265 0 49.6069 0 31.9632C0 14.3195 14.3195 0 31.9632 0H55.9357C60.3466 0 63.9265 3.57988 63.9265 7.99081V31.9632C63.9265 49.6069 49.6069 63.9265 31.9632 63.9265Z"
      fill="#1F9999"
    />
    <path
      d="M31.9631 48.6202C41.1625 48.6202 48.62 41.1626 48.62 31.9633C48.62 22.7639 41.1625 15.3064 31.9631 15.3064C22.7638 15.3064 15.3062 22.7639 15.3062 31.9633C15.3062 41.1626 22.7638 48.6202 31.9631 48.6202Z"
      fill="white"
    />
    <path
      d="M10.2534 32.9473H24.3648L29.4875 39.771L34.2544 26.0508L39.5972 32.9473H41.3672H54.7919"
      stroke="#1F9999"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Caution = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 22 19"
  >
    <path
      fill="#fff"
      fillRule="nonzero"
      d="M21.806 16.659L12.154.652a1.348 1.348 0 0 0-2.308 0L.194 16.659a1.348 1.348 0 0 0 1.154 2.044h19.304a1.348 1.348 0 0 0 1.154-2.044zM11.007 5.537c.555 0 1.024.313 1.024.867 0 1.691-.2 4.122-.2 5.813 0 .441-.483.626-.824.626-.455 0-.838-.185-.838-.626 0-1.691-.2-4.122-.2-5.813 0-.554.455-.867 1.038-.867zm.014 10.475a1.095 1.095 0 0 1-1.094-1.094c0-.597.469-1.094 1.094-1.094.583 0 1.08.497 1.08 1.094 0 .583-.497 1.094-1.08 1.094z"
    />
  </svg>
);

export const Refresh = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
  </svg>
);

export const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className="app-icon app-icon--verysmall plus-icon"
  >
    <path d="M17.6 17.6h4.8a1.6 1.6 0 1 0 0-3.2h-4.8V9.6a1.6 1.6 0 1 0-3.2 0v4.8H9.6a1.6 1.6 0 1 0 0 3.2h4.8v4.8a1.6 1.6 0 1 0 3.2 0v-4.8z" />
  </svg>
);

export const Success = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M3.506 20.494c-4.675-4.675-4.675-12.313 0-16.988 4.675-4.675 12.313-4.675 16.988 0 4.675 4.675 4.675 12.313 0 16.988-4.675 4.675-12.313 4.675-16.988 0zM4.83 19.17c3.943 3.944 10.397 3.944 14.34 0 3.944-3.943 3.944-10.397 0-14.34C15.227.886 8.773.886 4.83 4.83.886 8.773.886 15.227 4.83 19.17zm2.343-7.293l2.977 2.977 6.995-7.028a.973.973 0 0 1 1.38 1.372l-7.684 7.72a.973.973 0 0 1-1.377.002l-3.667-3.667a.973.973 0 0 1 1.376-1.376z"
    />
  </svg>
);

export const Close = ({ width = 32, height = 32 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32 32"
    className="modal-close"
    aria-hidden="true"
  >
    <title>close</title>
    <path d="M17.992 16l8.796-8.796a1.409 1.409 0 0 0-1.992-1.992L16 14.008 7.204 5.212a1.409 1.409 0 0 0-1.992 1.992L14.008 16l-8.796 8.796a1.409 1.409 0 0 0 1.992 1.992L16 17.992l8.796 8.796a1.409 1.409 0 0 0 1.992-1.992L17.992 16z" />
  </svg>
);

export const Pause = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="8"
    fill="none"
    viewBox="0 0 6 8"
  >
    <path
      fill="#183247"
      fillRule="evenodd"
      d="M.3 7.3c0 .387.313.7.7.7.387 0 .7-.313.7-.7V.7C1.7.313 1.387 0 1 0 .613 0 .3.313.3.7v6.6zm4 0c0 .387.313.7.7.7.186 0 .364-.074.495-.205s.205-.31.205-.495V.7C5.7.313 5.387 0 5 0c-.387 0-.7.313-.7.7v6.6z"
      clipRule="evenodd"
    />
  </svg>
);

export const Delete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="19"
    fill="none"
    viewBox="0 0 8 9"
  >
    <path
      fill="#183247"
      fillRule="evenodd"
      d="M5.526 1.143H7.6c.22 0 .4.179.4.4 0 .22-.18.4-.4.4h-.171v5.674c0 .84-.68 1.523-1.52 1.526H2.09c-.84-.003-1.52-.685-1.52-1.526V1.943H.4c-.22 0-.4-.18-.4-.4 0-.221.18-.4.4-.4h2.057V.97c0-.536.435-.971.972-.971H4.57c.53.01.955.442.955.971v.172zM3.429.8c-.09.006-.158.082-.155.171v.172h1.469V.97c.001-.046-.016-.09-.049-.123C4.662.816 4.617.798 4.571.8H3.43zm2.48 7.543c.398-.003.72-.327.72-.726V1.943H1.37v5.674c0 .399.322.723.72.726H5.91zM2.954 2.857c-.22 0-.4.18-.4.4V7.03c.025.204.198.357.403.357.206 0 .379-.153.403-.357V3.257c0-.107-.043-.21-.12-.285-.075-.075-.179-.116-.286-.115zm1.686.4V7.03c.024.204.197.357.403.357.205 0 .378-.153.403-.357V3.257c-.025-.204-.198-.358-.403-.358-.206 0-.379.154-.403.358z"
      clipRule="evenodd"
    />
  </svg>
);

export const Restart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="9"
    fill="none"
    viewBox="0 0 9 9"
  >
    <path
      fill="#183247"
      fillRule="evenodd"
      d="M4.5 0C2.015 0 0 2.015 0 4.5c-.004 1.046.36 2.06 1.03 2.863H.561c-.217 0-.393.176-.393.394 0 .217.176.394.393.394h1.463c.026.005.053.005.079 0h.067c.02-.008.04-.02.056-.034.025-.018.048-.039.068-.062v-.04c.042-.035.068-.085.073-.14.003-.022.003-.045 0-.067V6.288c0-.218-.176-.394-.394-.394-.217 0-.393.176-.393.394v.562C.484 5.511.46 3.59 1.523 2.222 2.585.854 4.453.404 6.023 1.136c1.57.732 2.425 2.452 2.06 4.145C7.72 6.975 6.232 8.191 4.5 8.213c-.217 0-.394.176-.394.393 0 .218.177.394.394.394C6.985 9 9 6.985 9 4.5S6.985 0 4.5 0z"
      clipRule="evenodd"
    />
  </svg>
);

export const AddCustomHeader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    className="modal-close"
    aria-hidden="true"
  >
    <title>Add</title>
    <path d="M17.992 16l8.796-8.796a1.409 1.409 0 0 0-1.992-1.992L16 14.008 7.204 5.212a1.409 1.409 0 0 0-1.992 1.992L14.008 16l-8.796 8.796a1.409 1.409 0 0 0 1.992 1.992L16 17.992l8.796 8.796a1.409 1.409 0 0 0 1.992-1.992L17.992 16z" />
  </svg>
);

export const Notification = () => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    xmlns="http://www.w3.org/2000/svg"
    data-test-icon="notification"
    className="app-icon--link app-icon--topalign app-icon--large"
    data-identifyelement="105"
  >
    <path
      d="M4.559 15.903H.727A.74.74 0 0 1 0 15.15a.74.74 0 0 1 .727-.754h.647v-6.36c0-3.393 2.376-6.21 5.499-6.765a1.23 1.23 0 0 1-.004-.1C6.869.526 7.375 0 8 0s1.131.525 1.131 1.172c0 .033-.001.066-.004.099 3.123.554 5.5 3.372 5.5 6.764v6.361h.646a.74.74 0 0 1 .727.754.74.74 0 0 1-.727.753H11.44C11.205 17.653 9.755 19 8 19s-3.205-1.347-3.441-3.097zm1.48 0c.217.913 1.012 1.59 1.961 1.59.949 0 1.744-.677 1.962-1.59H6.038zm7.133-1.507v-6.36c0-2.96-2.316-5.358-5.172-5.358S2.828 5.077 2.828 8.035v6.361h10.344z"
      fill="#777"
      data-identifyelement="106"
    />
  </svg>
);
export const Copy = () => (
  <svg
    width="16px"
    height="17px"
    viewBox="0 0 16 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Copy</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="011_badge-copy"
        transform="translate(-1218.000000, -197.000000)"
        strokeWidth="1.5"
      >
        <g id="Group-16" transform="translate(119.000000, 121.500000)">
          <g id="Group" transform="translate(0.000000, 27.000000)">
            <g id="Group-14" transform="translate(613.000000, 38.000000)">
              <g id="copy" transform="translate(487.544259, 12.089944)">
                <path
                  d="M2,2.91005555 L8,2.91005555 C9.1045695,2.91005555 10,3.80548605 10,4.91005555 L10,11.9100555 C10,13.014625 9.1045695,13.9100555 8,13.9100555 L2,13.9100555 C0.8954305,13.9100555 1.3527075e-16,13.014625 0,11.9100555 L0,4.91005555 C-1.3527075e-16,3.80548605 0.8954305,2.91005555 2,2.91005555 Z"
                  id="Rectangle"
                  stroke="#12344d"
                />
                <path
                  d="M12.8435184,10.4100555 L12.8435184,4 C12.8435184,1.790861 11.0526574,-4.05812251e-16 8.84351839,0 L4.60799991,0"
                  id="Path-6"
                  stroke="#12344d"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const Lock = ({ width = 8, height = 9 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 8 9"
  >
    <path
      fill="#149999"
      fillRule="evenodd"
      d="M1.369 2.859V2.54a2.223 2.223 0 0 1 4.446 0v.318h.299a1 1 0 0 1 1 1v4.224a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3.86a1 1 0 0 1 1-1h.369zm1.111 0h2.223V2.54a1.112 1.112 0 1 0-2.223 0v.318zm1.077 3.557a.89.89 0 1 0 0-1.779.89.89 0 0 0 0 1.779z"
    />
  </svg>
);

export const Exclamation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 29 29"
  >
    <path
      fill="#D72D30"
      fillRule="nonzero"
      d="M14.5 0C6.492 0 0 6.492 0 14.5 0 22.509 6.492 29 14.5 29 22.509 29 29 22.51 29 14.5 29 6.492 22.508 0 14.5 0zm0 26.281c-6.507 0-11.781-5.274-11.781-11.781 0-6.507 5.274-11.781 11.781-11.781 6.507 0 11.781 5.274 11.781 11.781 0 6.507-5.274 11.781-11.781 11.781zm0-19.922c-1.038 0-1.823.542-1.823 1.418v8.029c0 .876.785 1.416 1.824 1.416 1.013 0 1.824-.562 1.824-1.416v-8.03c0-.854-.81-1.417-1.824-1.417zm0 12.672c-.997 0-1.809.812-1.809 1.81s.812 1.81 1.81 1.81 1.809-.812 1.809-1.81-.811-1.81-1.81-1.81z"
    />
  </svg>
);

export const Upload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 13 9"
  >
    <path
      fill="#bbb"
      fillRule="nonzero"
      d="M11.868 4.062a3.33 3.33 0 0 0 .069-.676c0-1.87-1.55-3.386-3.46-3.386a3.464 3.464 0 0 0-3.17 2.028 2.648 2.648 0 0 0-1.429-.416c-1.386 0-2.518 1.053-2.612 2.386A2.118 2.118 0 0 0 0 5.926c0 1.172.97 2.122 2.168 2.122H5.3c.154 0 .162-.167.162-.167V6.52a.214.214 0 0 0-.217-.212h-.592c-.119 0-.158-.076-.088-.17l1.806-2.385c.071-.094.187-.094.258 0l1.806 2.385c.07.094.03.17-.088.17h-.592a.214.214 0 0 0-.216.212v1.364s.009.164.168.164h3.125c1.197 0 2.168-.95 2.168-2.122 0-.805-.458-1.505-1.132-1.864z"
    />
  </svg>
);
export const Email = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="10"
    viewBox="0 0 12 10"
  >
    <g fill="#8C8C8C" fillRule="nonzero" opacity=".56">
      <path d="M1.15 2.923l1.373.954c.763.529 1.347.936 1.753 1.221l.284.204c.145.105.266.19.362.254.095.065.211.138.348.218.136.08.264.14.384.18.12.04.232.06.335.06H6.002c.103 0 .215-.02.335-.06.12-.04.249-.1.385-.18a7.066 7.066 0 0 0 .709-.472l.284-.204 3.132-2.175c.326-.227.598-.502.816-.823.219-.32.328-.658.328-1.01 0-.295-.106-.547-.318-.756a1.034 1.034 0 0 0-.752-.315H1.07C.727.02.463.135.278.367.093.6 0 .89 0 1.237c0 .281.123.586.368.913.245.328.506.586.783.773z" />
      <path d="M11.322 3.64A139.07 139.07 0 0 0 7.99 5.947c-.255.187-.46.333-.62.438a3.82 3.82 0 0 1-.632.321c-.263.11-.508.164-.736.164H5.99c-.228 0-.473-.054-.736-.164a3.822 3.822 0 0 1-.632-.32 15.847 15.847 0 0 1-.62-.44C3.4 5.508 2.292 4.738.677 3.64A3.544 3.544 0 0 1 0 3.057V8.37c0 .295.105.547.314.756.21.21.462.315.757.315h9.85c.294 0 .546-.105.756-.315.21-.21.314-.461.314-.756V3.057a3.69 3.69 0 0 1-.669.582z" />
    </g>
  </svg>
);
export const PhoneNumber = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill="#8C8C8C"
      fillRule="nonzero"
      d="M11.976 9.47a.58.58 0 0 1-.178.508l-1.687 1.677a.95.95 0 0 1-.298.216 1.23 1.23 0 0 1-.361.114l-.076.007a1.724 1.724 0 0 1-.165.006c-.161 0-.421-.027-.78-.082-.36-.055-.8-.191-1.32-.407-.52-.216-1.11-.54-1.769-.972-.66-.432-1.361-1.025-2.105-1.779a13.916 13.916 0 0 1-1.472-1.677 11.953 11.953 0 0 1-.938-1.48 7.98 7.98 0 0 1-.533-1.233 6.548 6.548 0 0 1-.241-.953 3.152 3.152 0 0 1-.05-.629l.012-.254c.017-.118.055-.239.114-.362a.952.952 0 0 1 .216-.298L2.032.182a.558.558 0 0 1 .405-.178c.11 0 .208.032.292.095a.883.883 0 0 1 .216.235l1.357 2.58a.62.62 0 0 1 .063.444.792.792 0 0 1-.215.407l-.622.622a.212.212 0 0 0-.044.083.314.314 0 0 0-.02.095c.035.178.11.381.23.61a5.6 5.6 0 0 0 .468.743c.212.293.512.63.901 1.01.38.39.719.693 1.015.91.296.215.543.374.742.476.198.101.35.163.456.184l.159.032a.302.302 0 0 0 .082-.02.212.212 0 0 0 .083-.044l.723-.737a.776.776 0 0 1 .533-.203c.143 0 .257.025.342.076h.013l2.448 1.448c.177.11.283.25.317.42z"
      opacity=".56"
    />
  </svg>
);
export const Organisation = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="#8C8C8C" fillRule="nonzero" opacity=".56">
      <path d="M9.89 1.406V12H12V1.406H9.89zm.704 6.328v-.703h.703v.703h-.703zm.703.704v.703h-.703v-.704h.703zm-.703-2.11v-.703h.703v.703h-.703zm0-1.406v-.703h.703v.703h-.703zm0-1.406v-.704h.703v.704h-.703zM2.11 1.406H0V12h2.11V1.406zM1.405 4.22v.703H.703v-.703h.703zm-.703-.703v-.704h.703v.704H.703zm.703 2.109v.703H.703v-.703h.703zm0 1.406v.703H.703v-.703h.703zm0 1.407v.703H.703v-.704h.703zM4.922 2.109h2.156v1H4.922zM4.922 7.711h2.156v1H4.922zM4.922 4.922h2.156v1H4.922zM6.352 9.117h1V12h-1zM4.922 9.117h1V12h-1z" />
      <path d="M9.188 0H2.811v12H4.22V7.008H7.78V12h1.407V0zm-4.97 1.406h3.563v2.086H4.22V1.406zm0 4.899V4.219h3.563v2.086H4.22z" />
    </g>
  </svg>
);
export const OrgSize = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="11"
    viewBox="0 0 14 11"
  >
    <path
      fill="#8C8C8C"
      fillRule="nonzero"
      d="M6.985 5.284c1.225 0 2.177-1.27 2.177-2.585v-.412C9.162.972 8.21.039 6.985.039S4.808.972 4.808 2.287v.412c0 1.314.952 2.585 2.177 2.585zm-4.362.425c.35 0 1.309-.455 1.309-1.397v-.699c0-.942-.692-1.397-1.309-1.397-.596 0-1.308.455-1.308 1.397v.699c0 .942.977 1.397 1.308 1.397zm7.84 1.746l-.004-.02a1.683 1.683 0 0 0-.048-.241v-.002a1.494 1.494 0 0 0-.239-.488c-.188-.262-.474-.529-.937-.79-.245-.137-.352-.2-.504-.2-.24 0-.45.188-.805.513l-.322.296c-.012.01-.359.5-.619.5s-.607-.49-.619-.5l-.322-.296c-.354-.325-.564-.513-.805-.513-.151 0-.287.063-.504.2-.45.284-.748.527-.937.789l-.002.002a1.494 1.494 0 0 0-.285.728c0 .008-.003.015-.003.023a2.42 2.42 0 0 0-.015.266v1.921c0 .62.234.873.873.873h5.238c.645 0 .873-.239.873-.873v-1.92c0-.092-.005-.18-.015-.268zm.891-1.746c.35 0 1.309-.455 1.309-1.397v-.699c0-.942-.691-1.397-1.309-1.397-.596 0-1.308.455-1.308 1.397v.699c0 .942.977 1.397 1.308 1.397zm-10.36.945A1.889 1.889 0 0 0 0 8.334v.873c0 .633.213.873.873.873H2.62V7.46l-.437-.436c-.462-.407-.851-.562-1.19-.37zm1.652.824c.022.01 0-.247 0 0zm11.324.856c0-.48-.181-1.217-.993-1.68-.339-.192-.728-.037-1.19.37l-.436.436v2.62h1.746c.66 0 .873-.24.873-.873v-.873zm-2.587-.856c0-.247-.022.01 0 0z"
      opacity=".56"
    />
  </svg>
);
export const EmployeeRole = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
  >
    <g fill="#8C8C8C" fillRule="nonzero" opacity=".56">
      <path d="M11.143 9.286C9.685 8.73 8.609 8.264 8.609 8.264L7.482 11.82l-.154.48-.502-1.425c1.158-1.612-.086-1.692-.305-1.692h-.01-.006c-.219 0-1.468.08-.31 1.692l-.501 1.426-.155-.48-1.126-3.557s-.876.572-2.334 1.127a2.32 2.32 0 0 0-.667.358c-.322.31-.616 1.652-.88 4.025h11.932c-.703-2.925-1.143-4.42-1.321-4.488z" />
      <path d="M3.489 5.093c.197.705.384.897.523.913.128.838.774 1.9 1.831 2.27.433.154.913.154 1.346-.006C8.229 7.896 8.897 6.834 9.025 6c.14-.01.326-.207.523-.912.273-.961-.016-1.105-.261-1.079.048-.133.08-.266.107-.395.416-2.488-.812-2.573-.812-2.573S8.38.651 7.845.352C7.488.14 6.985-.027 6.33.032a2.2 2.2 0 0 0-.603.117 2.59 2.59 0 0 0-.662.342 3.44 3.44 0 0 0-.684.566 2.705 2.705 0 0 0-.736 1.297c-.107.4-.08.817.005 1.266.021.133.059.261.107.395-.251-.022-.54.117-.267 1.078z" />
    </g>
  </svg>
);
export const DropdownIcon = () => (
  <svg
    width="11px"
    height="7px"
    viewBox="0 0 11 7"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="003_Add_new_check_1"
        transform="translate(-1380.000000, -321.000000)"
        stroke="#979797"
        strokeWidth="2"
      >
        <polyline
          id="dropdown"
          transform="translate(1385.574844, 322.569070) rotate(-135.000000) translate(-1385.574844, -322.569070) "
          points="1388.14969 320 1383 320 1383.01155 325.138141"
        />
      </g>
    </g>
  </svg>
);

// TODO create a Icon component
export const TickIcon = ({
  width = '24',
  height = '24',
  viewBox = '0 0 24 24'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <defs>
      <path
        id="a"
        d="M9.59 15.58l-3.465-3.465a1 1 0 0 0-1.412-.003l-.003.003a.997.997 0 0 0-.002 1.413L9.59 18.41l8.925-9.024a1 1 0 0 0-.004-1.41l-.001-.002a.991.991 0 0 0-1.406.004L9.59 15.58z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="currentColor" fillRule="nonzero" xlinkHref="#a" />
      <g fill="currentColor" mask="url(#b)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export const ChevronDown = ({ size = '1em' }) => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    height={size}
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline points="6 9 12 15 18 9" />
  </svg>
);

export const ChevronDownBold = ({ size = '1em' }) => (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9998 1.1875C9.99984 1.42264 9.90523 1.6479 9.7373 1.8125L5.6248 5.9375C5.27609 6.27405 4.72351 6.27405 4.3748 5.9375L0.262301 1.8125C-0.0828767 1.46733 -0.0828767 0.907681 0.262301 0.562503C0.607479 0.217326 1.16712 0.217326 1.5123 0.562503L4.9998 4.075L8.4998 0.562503C8.75452 0.31946 9.12883 0.249965 9.45381 0.385377C9.7788 0.520788 9.99302 0.835499 9.9998 1.1875Z"
      fill="#12344D"
    />
  </svg>
);

export const AgentMessagePointer = () => (
  <svg
    width="12px"
    height="17px"
    viewBox="0 0 14 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Path</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="2"
        transform="translate(-233.000000, -168.000000)"
        fill="#FFFFFF"
        stroke="#EDEDED"
      >
        <path
          d="M246,180 L235.718324,185.140838 C235.224345,185.387827 234.623672,185.187603 234.376683,184.693624 C234.208655,184.357569 234.243177,183.955688 234.466054,183.653212 L246,168 L246,168"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
);
