/* eslint-disable indent */
import React from "react";
import Downshift from "downshift";
import { getOrgId, getFreshIdUrl, getLogoutUrl } from "../utils/utility";

const UserBadgeDropdown = ({ user, currentOrg }) => {
  const USER_MENUS = [
    {
      label: "Profile",
      href: `${getFreshIdUrl(currentOrg)}/profile?redirect_uri=${window.location.origin}`,
      rel: "noreferrer",
      target: "_blank",
    },
    {
      label: "Logout",
      href: getLogoutUrl(currentOrg),
    },
  ];

  return (
    <div className="badge-container">
      <Downshift>
        {({ getItemProps, isOpen, toggleMenu }) => (
          <div>
            <div className="btn-group">
              <div className="badge badge-icon" onClick={toggleMenu}>
                {user && user.email[0].toUpperCase()}
              </div>
              <div
                className={`profile-dropdown droplist-menu ${isOpen &&
                  "dropdown-menu--open"}`}
              >
                <div className="profile-dropdown__item profile-dropdown__textitem">
                  <div className="text__content text--semibold">
                    {user.full_name
                      ? user.full_name
                      : user.email
                          .split("@")[0]
                          .split(".")
                          .join(" ")}
                  </div>
                  <div className="text__infotext">{user.email}</div>
                </div>
                {USER_MENUS.map((item) => (
                  <a
                    {...getItemProps({ item })}
                    key={item.label}
                    className="profile-dropdown__item"
                    style={{ cursor: "pointer" }}
                    href={item.href}
                    rel={item.rel}
                    target={item.target}
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      </Downshift>
    </div>
  );
};

export default UserBadgeDropdown;
