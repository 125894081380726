import React from 'react';
import styled from 'styled-components';

const EndList = () => {
  return (
    <StyledEndList>
      <span className="line" />
      <span className="circle" />
      <span className="line" />
    </StyledEndList>
  );
};

export default EndList;

const StyledEndList = styled.div`
  margin: 50px 30px 30px;
  text-align: center;
  .line,
  .circle {
    background: var(--secondary-color);
    box-shadow: 0 1px 0 #fff;
    display: inline-block;
    vertical-align: middle;
  }
  .line {
    width: 50px;
    height: 2px;
    margin: 0 auto;
    border-radius: 2px;
  }
  .circle {
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin: 0 2px;
  }
`;
