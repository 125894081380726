// Fdesk apis expects to be in the value format instead of name key
export const nameOfFieldsToChange = {
  ticket_type: 'type',
  group: 'group_id',
  agent: 'responder_id'
};

// These fields will output stringId as value
export const stringDropdownFieldTypes = ['field'];

// These fields will be treated as dropdown fields
export const dropdownFieldTypes = [
  'ticket_type',
  'source',
  'status',
  'priority',
  'group',
  'dropdown',
  'field',
  'agent'
];

// https://developers.freshdesk.com/api/#create_ticket
// These will be populated from the backend
export const nameOfFieldsNotToShow = [
  'description',
  'subject',
  'priority',
  'company',
  'requester',
  'email',
  'status',
  'source'
];
