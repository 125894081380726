/* eslint-disable react/forbid-prop-types */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { StyledInput, InputElement } from "./style";

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  invalid: PropTypes.bool,
  filter: PropTypes.instanceOf(RegExp),
  onBlurSideEffect: PropTypes.any,
  onChange: PropTypes.func
};

const defaultProps = {
  className: undefined,
  value: undefined,
  icon: undefined,
  invalid: false,
  onBlurSideEffect: undefined,
  filter: undefined,
  onChange: () => {}
};

const Input = forwardRef(
  (
    {
      className,
      filter,
      onChange,
      onBlurSideEffect,
      setFieldValue,
      formError,
      formTouched,
      formValues,
      ...inputProps
    },
    ref
  ) => {
    const handleChange = event => {
      if (!filter || filter.test(event.target.value)) {
        onChange(event.target.value, event);
      }
    };

    const onBlurEffect = value => {
      if (onBlurSideEffect) {
        onBlurSideEffect(value, formValues);
      }
      return null;
    };

    return (
      <StyledInput className={className}>
        <InputElement
          {...inputProps}
          onBlur={event => onBlurEffect(event.target.value)}
          onChange={handleChange}
          ref={ref}
        />
      </StyledInput>
    );
  }
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
