// import { delay, eventChannel } from "redux-saga";
import { call, put, fork, take } from 'redux-saga/effects';
import { request } from '../utils/Api';
import { getOrgId } from '../utils/utility';
import {
  GET_APPLICATION_REQUEST,
  getCheckSuccess,
  getCheckFailure,
  GET_ALL_CHECKS_REQUEST,
  getAllChecks,
  getChecksSuccess,
  getChecksFailure,
  GET_CHECK_STATUSES_REQUEST,
  getCheckStatusesFailure,
  getCheckStatusesSuccess,
  ADD_CHECK,
  ADD_CHECK_SUCCESS,
  addCheckSuccess,
  addCheckFailure
} from '../actions';

export function* requestAndPut(
  requestParameters,
  successAction,
  failureAction,
  meta,
  toastr
) {
  const response = yield call(...requestParameters);
  if (response && Object.keys(response).length && response.status !== 404) {
    yield put(
      successAction({
        response,
        meta: { ...meta, toastr: toastr ? { ...toastr } : {} }
      })
    );
  } else {
    yield put(failureAction({ response, id: meta }));
  }
}

export function* getChecks() {
  while (true) {
    yield take(GET_ALL_CHECKS_REQUEST);
    let pagePointer = 1;
    let checks = [];
    while (true) {
      const response = yield call(
        request,
        `/checks/?application__organization=${getOrgId()}&page=${pagePointer}&page_size=10`
      );
      if (response) {
        checks = checks.concat(response.results);
        if (response.next === null) {
          break;
        }
        pagePointer = new URL(response.next).searchParams.get('page');
      }
    }

    if (checks && checks.length) {
      yield put(getChecksSuccess({ response: { results: checks } }));
    } else {
      yield put(getChecksFailure());
    }
  }
}

export function* getCheck() {
  while (true) {
    const action = yield take(GET_APPLICATION_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/checks/?application=${action.payload}`],
      getCheckSuccess,
      getCheckFailure,
      action.payload
    );
  }
}

export function* getCheckStatuses() {
  while (true) {
    const action = yield take(GET_CHECK_STATUSES_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/check-statuses/${action.payload}/`],
      getCheckStatusesSuccess,
      getCheckStatusesFailure,
      action.payload
    );
  }
}

export function* addCheck() {
  while (true) {
    const action = yield take(ADD_CHECK);
    yield fork(
      requestAndPut,
      [request, `/checks/`, { method: 'POST', payload: action.payload }],
      addCheckSuccess,
      addCheckFailure,
      action.payload,
      { type: 'success', message: `Check has been added successfully` }
    );
  }
}

export function* addChecksToList() {
  while (true) {
    const action = yield take(ADD_CHECK_SUCCESS);
    const { meta } = action.payload.meta;
    if (meta) {
      yield put(getAllChecks());
    }
  }
}
