import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router';
import StatusActions from '../components/StatusActions';
import { StatusIcon } from '../components/Icons';

class StatusListItem extends Component {
  componentDidMount() {
    this.props.getStatusChecks(this.props.statuspage.id);
  }
  statusAction = selected => {
    if (selected.label === 'Edit') {
      this.props.history.push(`${this.props.url}/${this.props.statuspage.id}`);
    }
    if (selected.label === 'Delete') {
      const toastrConfirmOptions = {
        component: () => {
          const { statuspage } = this.props;
          return (
            <div className="delete-confirm__message statuspage_delete">
              <p className="name">
                Name<span>{statuspage.name}</span>
              </p>
              <p>
                Public URL
                <span className="delete-check-url">
                  {statuspage.public_url}
                </span>
              </p>
            </div>
          );
        },
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: () => {
          this.props.deleteStatusPage(this.props.statuspage);

          if (window._na && window._na_module) {
            window._na.sendFeatureEvent('Delete Status Page', window._na_module);
          }
        }
      };

      toastr.confirm('Delete Status Page Confirmation', toastrConfirmOptions);
    }
  };
  render() {
    const { statuspage } = this.props; //url
    return (
      <section className="status-page-content">
        <div className="row-title">
          <div className="statuspage-icon">
            <StatusIcon />
          </div>
          <div className="statuspage-default">
            <div className="app-name">{statuspage.name}</div>
            <div className="app-url">
              Default URL :{' '}
              <a
                href={statuspage.public_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {statuspage.public_url}
              </a>
            </div>
          </div>
          <div className="statuspage-custom">
            {statuspage.custom_domain && (
              <p className="label">
                Custom URL :<br />
                <a
                  href={
                    statuspage.custom_domain.indexOf('http') > -1
                      ? statuspage.custom_domain
                      : `http://${statuspage.custom_domain}`
                  }
                  target="_blank"
                  className="domain"
                  rel="noopener noreferrer"
                >
                  {statuspage.custom_domain}
                </a>
              </p>
            )}
          </div>
          <div className="statuspage-action">
            {/* <Link to={`${url}/${statuspage.id}`} href={`${url}/${statuspage.id}`} className="link"> */}
            {/* <img src={settings} alt="Settings icon" className="settings-icon" /> */}
            {/* </Link> */}
            <StatusActions onChange={this.statusAction} />
          </div>
        </div>
        {/* {statuspage && statuspage.associated_checks && statuspage.associated_checks.map(check => (
          <div className="row-content" key={check.check_name}>
            <span className="check-name">
              {check.check_name}
            </span>
          </div>
        ))} */}
      </section>
    );
  }
}

export default withRouter(StatusListItem);
