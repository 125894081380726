import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Layout from "../components/Layout";
import SettingsRoute from "../containers/Settings";
import { getSubscription, getCurrentUserOrg } from "../actions";
import { getOrgId } from "../utils/utility";

const mapStateToProps = state => ({
  org: state.user.org
});

class Settings extends React.Component {
  componentDidMount() {
    this.props.getSubscription();
    this.props.getCurrentUserOrg();
  }
  render() {
    const currentOrg =
      this.props.org &&
      this.props.org.find(org => org.id === Number(getOrgId()));
    return (
      <Layout page="Settings">
        {this.props.org && currentOrg.logged_in_user_role === "AD" ? (
          <SettingsRoute
            orgId={this.props.match.params}
            match={this.props.match}
          />
        ) : (
          <NoAccess>
            Sorry, looks like you do not have access to view this page.
          </NoAccess>
        )}
      </Layout>
    );
  }
}

export default connect(
  mapStateToProps,
  { getSubscription, getCurrentUserOrg }
)(Settings);

export const NoAccess = styled.div`
  text-align: center;
  width: 100%;
  margin: 30px 0;
  font-size: 15px;
`;
