import React, { Fragment } from 'react';
import TimezonePicker from 'react-timezone';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { toastr } from 'react-redux-toastr';
import Skeleton from 'react-loading-skeleton';
import { request } from '../utils/Api';
import { getOrgId, getLogoutUrl } from '../utils/utility';
import Select from 'react-select';
import { Button } from 'freemium-ui';
import 'react-select/dist/react-select.css';
import '../styles/Settings.css';
import {
  StyledFooter,
  StyledHeader,
  StyledFeatureInterest
} from '../components/CaptureInterst';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Copy } from '../components/Icons';
import { env } from '../config';

class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: 'UTC',
      savingAccountInfo: false,
      tranferAdmin: '',
      transferAdminEmail: null,
      showModal: false,
      type: '',
      disable_delete: false,
      disable_transfer: false,
      deleteTicketUrl: null,
      transferTicketUrl: null,
      currentLoggedInUser: null,
      LoggedInUserRole: null,
      TransferContentText: null,
      DeleteContentText: null,
      ShouldHideButtonSection: false,
      IsAccountDelete: false,
      isCopyButtonClicked: false,
      domainNameValid: true,
      accountName: null,
      availability: true,
      accountState: ''
    };
  }

  setDefaultStates = () => {
    const deleteTicket = window.localStorage.getItem('deleteTicketUrl');
    const transferTicket = window.localStorage.getItem('transferTicketUrl');
    this.setState({
      deleteTicketUrl:
        deleteTicket && deleteTicket !== 'null' ? deleteTicket : null,
      transferTicketUrl:
        transferTicket && transferTicket !== 'null' ? transferTicket : null
    });
  };

  componentDidMount() {
    this.getAccountInfo();
    this.getCurrentLoggedInUserInfo();
    this.getOrganizationMembers();
    this.setDefaultStates();
  }

  getAccountInfo = async () => {
    const Org = await request(`/organizations/${getOrgId()}`);
    this.setState({
      orgname: Org.name,
      name: Org.freshid_accounts_name,
      timezone: Org.timezone,
      email_alerts_sensitivity_mode: Org.computed_email_alerts_sensitivity_mode,
      currentOrg: Org,
      public_api_key: Org.api_key,
      accountName: Org.freshid_accounts_name,
      accountState: Org.owner_mini.state
    });
  };

  getDomainUrl = () => {
    const domainUrl = `https://${this.state.accountName}${
      env !== 'production' ? '.freshping-sta91ng.io' : '.freshping.io'
    }`;
    return domainUrl;
  };

  editAccountInfo = async () => {
    const {
      orgname,
      name,
      accountName,
      timezone,
      email_alerts_sensitivity_mode,
      availability,
      accountState
    } = this.state;
    this.setState({ savingAccountInfo: true });
    const data = {
      name: orgname,
      freshid_accounts_name: accountName.toLowerCase(),
      timezone,
      email_alerts_sensitivity_mode
    };
    const response = await request(`/organizations/${getOrgId()}`, {
      method: 'PATCH',
      payload: data
    });

    if (response) {
      toastr.success('Changes updated successfully');
      if (accountName !== name && availability) {
        const authToken = localStorage.getItem('auth-token');
        const orgId = getOrgId();
        const currentOrg = this.state.currentOrg;
        const domainUrl = this.getDomainUrl();
        const logoutUrl = getLogoutUrl(currentOrg);
        localStorage.clear();
        window.location =
          accountState === 'NV'
            ? `${domainUrl}/token-login/${authToken}/${orgId}`
            : logoutUrl;
      }
      this.setState({
        orgname: response.name,
        savingAccountInfo: false,
        name: response.freshid_accounts_name,
        timezone: response.timezone,
        email_alerts_sensitivity_mode:
          response.computed_email_alerts_sensitivity_mode,
        accountName: response.freshid_accounts_name
      });
    }
  };

  editValueChange = async (evt, name) => {
    this.setState(
      {
        [evt.target ? evt.target.name : name]: evt.target
          ? evt.target.value
          : evt,
        savingAccountInfo: true
      },
      function() {
        this.editAccountInfo();
      }
    );
  };

  transferOwnership = async () => {
    if (this.state.tranferAdmin) {
      this.setState({
        showModal: true,
        type: 'transferring ownership to ' + this.state.transferAdminEmail
      });
    }
  };

  TransferOwnershipToAdmin = async () => {
    var data = {
      currentowner: this.state.currentOrg.owner_mini.email,
      currentadmin: this.state.transferAdminEmail
    };
    const transfer = await request('/transfer-ownerShip/', {
      method: 'POST',
      payload: data
    });
    if (transfer['currentowner'] && transfer['currentadmin']) {
      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Transfer Ownership', window._na_module);
      }

      this.close();
      window.location.reload();
    } else if (transfer.status === 400) {
      this.setState({
        transferError: 'Already an owner of another Freshping account',
        disable_transfer: true
      });
    }
  };

  getOrganizationMembers = async () => {
    const orgUsers = await request(
      `/organization-members/?organization=${getOrgId()}`
    );
    if (
      orgUsers.results &&
      this.state.currentLoggedInUser &&
      this.state.currentLoggedInUser[0].role
    ) {
      this.setTranferAdmin(orgUsers.results, this.state.currentLoggedInUser[0]);
    }
    this.setState({
      orgMembers: orgUsers.results
    });
  };

  setTranferAdmin = (orgMembers, currenUser) => {
    const { email, role, id } = currenUser;
    const orgMemberId = orgMembers.find(r => r.user === id && r.role === 'AD');
    this.setState({
      transferAdminEmail: role === 'AD' ? email : null,
      tranferAdmin: role === 'AD' ? orgMemberId.id : null
    });
  };

  getCurrentLoggedInUserInfo = async () => {
    const LoggedInUser = await request(
      `/logged-in-user/?organization=${getOrgId()}`
    );
    const { role } = LoggedInUser.results[0];
    if (LoggedInUser.results && this.state.orgMembers && role === 'AD')
      this.setTranferAdmin(this.state.orgMembers, LoggedInUser.results[0]);
    this.setState({
      currentLoggedInUser: LoggedInUser.results,
      LoggedInUserRole: role,
      TransferContentText:
        role === 'OW'
          ? 'Account ownership can be transferred only to verified Admins added to this account'
          : 'You can now request to transfer ownership of this account to yourself if the owner has left the company or for any other reasons',
      DeleteContentText:
        role === 'OW'
          ? 'Deleting the account will delete all the associated data permanently.'
          : 'You can now request to delete this account if the owner has left the company or for any other reasons'
    });
  };

  handleChange = (evt, value, name) => {
    this.setState({
      [evt.target ? evt.target.name : name]: evt.target
        ? evt.target.value
        : evt.value || value,
      transferAdminEmail: evt.target ? evt.target.label : evt.label
    });
  };

  onDelete = async () => {
    this.setState({
      showModal: true,
      type: 'Confirm deletion?',
      IsAccountDelete: true
    });
  };

  deleteAccount = async () => {
    const orgId = getOrgId();
    await request(`/organizations/${orgId}`, {
      method: 'DELETE'
    });
    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Delete Account', window._na_module);
    }

    const currentOrg = this.state.currentOrg;
    const logOutUrl = getLogoutUrl(currentOrg);
    localStorage.clear();
    window.location.href = logOutUrl;
  };

  raiseL2Ticket = type => {
    if (this.state.tranferAdmin && this.state.LoggedInUserRole === 'AD') {
      this.setState({ showModal: true, type: 'Transfer ownership request' });
    } else this.setState({ showModal: true, type });
  };

  getFunction = () => {
    const {
      transferAdminEmail,
      LoggedInUserRole,
      IsAccountDelete
    } = this.state;
    if (transferAdminEmail && LoggedInUserRole === 'OW') {
      return this.TransferOwnershipToAdmin;
    } else if (IsAccountDelete && LoggedInUserRole === 'OW') {
      return this.deleteAccount;
    } else {
      return this.createInterest;
    }
  };

  close = () =>
    this.setState({
      transferError: null,
      showModal: false
      // tranferAdmin: "",
      // transferAdminEmail: null
    });

  handlesupportTicket = (reason, ticketUrl) => {
    if (reason.indexOf('ownership') > -1) {
      this.setState({
        disable_transfer: true,
        transferTicketUrl: ticketUrl
      });
      window.localStorage.setItem('disable_transfer', true);
      window.localStorage.setItem('transferTicketUrl', ticketUrl);
    } else if (reason.indexOf('Delete') > -1) {
      this.setState({
        disable_delete: true,
        deleteTicketUrl: ticketUrl
      });
      window.localStorage.setItem('disable_delete', true);
      window.localStorage.setItem('deleteTicketUrl', ticketUrl);
    }
  };

  createInterest = async reason => {
    if (reason) {
      const payload = {
        reason: `Request for ${reason}`,
        organization: Number(getOrgId()),
        requested_user:
          reason.indexOf('Delete') === -1 ? this.state.tranferAdmin : ''
      };
      const response = await request(`/create-support-ticket/`, {
        method: 'POST',
        payload
      });
      if (response.url) {
        this.handlesupportTicket(reason, response.url);
        toastr.success('Support Ticket has been created successfully.');
        this.close();
      } else {
        this.setState({
          transferError: 'Already an owner of another Freshping account',
          disable_transfer:
            reason.indexOf('ownership') > -1 || this.state.disable_transfer,
          disable_delete:
            reason.indexOf('Delete') > -1 || this.state.disable_delete
        });
      }
    }
  };

  onCopyClick = () => {
    this.setState({ isCopyButtonClicked: true });
    setTimeout(() => {
      this.setState({ isCopyButtonClicked: false });
    }, 2000);
    
    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Copy API Key', window._na_module);
    }
  };

  resetAPIKey = async () => {
    let data = {};
    data['organization_id'] = this.state.currentOrg.id;
    const response = await request('/reset-organization-token/', {
      method: 'POST',
      payload: data
    });

    this.setState({
      public_api_key: response.organization_token
    });
    this.close();
  };

  getErrorState = () => {
    const { availability, domainNameValid, accountName } = this.state;
    let errorTxt = '';
    if (availability === false) errorTxt = 'Domain not available';
    else if (accountName && accountName.length < 3)
      errorTxt = 'Freshping domain name length atleast 3 characters';
    else if (!domainNameValid)
      errorTxt = 'Enter valid name to set Freshping URL';
    return errorTxt;
  };

  checkAvailability = async (evt, blur) => {
    const { value } = evt.target;
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,30}[a-z0-9])?)$/g;
    const isValid = domainRegex.test(value.trim()) && value.trim().length > 2;
    this.setState({
      accountName: value.trim(),
      domainNameValid: isValid
    });
    if (value.trim().length > 3 && isValid && value !== this.state.name) {
      const response = await request(`/domain_available?domain=${value}`);
      this.setState({
        availability: response.available
      });
    }
  };

  render() {
    const {
      orgname,
      timezone,
      savingAccountInfo,
      currentOrg,
      showModal,
      type,
      accountName,
      public_api_key,
      orgMembers,
      currentLoggedInUser
    } = this.state;

    let admins = [];
    if (
      currentOrg &&
      orgMembers &&
      currentLoggedInUser &&
      currentLoggedInUser[0].role === 'OW'
    ) {
      const filteredAmins = orgMembers.filter(
        orgmem =>
          orgmem.role === 'AD' &&
          orgmem.user_mini.email !== currentOrg.owner_mini.email &&
          orgmem.user_mini.state === 'AC'
      );
      admins = filteredAmins.map(admin => {
        const label = admin.user_mini.email;
        const value = admin.id;
        return {
          label,
          value
        };
      });
    }

    const texttocopy = public_api_key;
    const availabilityError = this.getErrorState();
    return (
      <div className="users-groups-container add-new-user account-settings-container">
        <section className="topbar">
          <span className="tab active-tab ">Account Settings</span>
        </section>
        <div className="account-settings topAdj content">
          <div className="col-6">
            <label htmlFor="name">Account name</label>
            <input
              onChange={e => this.handleChange(e)}
              // onBlur={e => this.editValueChange(e, "orgname")}
              placeholder="Name"
              name="orgname"
              value={orgname ? orgname : ''}
            />
          </div>
          <div className="col-6" style={{ float: 'right' }}>
            <label htmlFor="time_zone">Timezone</label>
            <TimezonePicker
              value={timezone}
              name={'timezone'}
              onChange={e => this.setState({ timezone: e })}
              className={'timezone-picker'}
              inputProps={{
                placeholder: 'Select Timezone...',
                name: 'timezone'
              }}
            />
          </div>
          {accountName !== null ? (
            <div className="col-6">
              <label htmlFor="name">Freshping URL</label>
              <input
                autoComplete="off"
                onChange={e => this.checkAvailability(e)}
                onBlur={e => this.checkAvailability(e, true)}
                name="accountName"
                value={accountName}
                minLength="4"
                maxLength="32"
                defaultValue={accountName}
                style={{
                  boxShadow:
                    (this.state.availability === false ||
                      !this.state.domainNameValid) &&
                    '0 1px 0 0 #f00003'
                }}
              />
              {availabilityError !== '' ? (
                <Error>{availabilityError}</Error>
              ) : (
                this.state.accountName && (
                  <DomainUrl>{this.getDomainUrl()}</DomainUrl>
                )
              )}
            </div>
          ) : null}

          <StyledSensitivity>
            <div className="cardTitle">Alert sensitivity</div>
            <div className="cardContent">
              <span className="senstivity-text">
                The low-sensitivity setting triggers alerts (via email and
                integrations) only when the URL is continuously down for 2
                consecutive monitoring polls. The high-sensitivity setting
                triggers alerts immediately.
              </span>
              <div className="action-btn">
                <StyledEmail>
                  <input
                    type="radio"
                    className={
                      this.state.email_alerts_sensitivity_mode === 'LO'
                        ? 'toggleBtn active'
                        : 'toggleBtn'
                    }
                    name="duration"
                    id="low"
                    onChange={() =>
                      this.handleChange(
                        '',
                        'LO',
                        'email_alerts_sensitivity_mode'
                      )
                    }
                  />
                  <label htmlFor="low"> Low </label>
                  <input
                    type="radio"
                    className={
                      this.state.email_alerts_sensitivity_mode === 'HI'
                        ? 'toggleBtn active'
                        : 'toggleBtn'
                    }
                    name="duration"
                    id="high"
                    onChange={() =>
                      this.handleChange(
                        '',
                        'HI',
                        'email_alerts_sensitivity_mode'
                      )
                    }
                  />
                  <label htmlFor="high"> High </label>
                </StyledEmail>
              </div>
            </div>
          </StyledSensitivity>
          <div className="account-settings-button-container">
            <Button
              inline
              type="primary"
              onClick={this.editAccountInfo.bind(this)}
              disabled={
                savingAccountInfo ||
                !this.state.availability ||
                !this.state.domainNameValid
              }
            >
              {savingAccountInfo ? 'Please wait' : 'Save changes'}
            </Button>
          </div>
        </div>
        {currentOrg ? (
          <KeyCard>
            <h4>Your API key</h4>
            <div className="key-style">
              <div className="public-api-key-textarea">
                <div className="api-key"> {public_api_key} </div>
                {public_api_key ? (
                  <CopyToClipboard text={texttocopy}>
                    <button className="copy" onClick={this.onCopyClick}>
                      <Copy />
                    </button>
                  </CopyToClipboard>
                ) : (
                  ''
                )}
              </div>
              {this.state.isCopyButtonClicked ? (
                <span className="copied">Copied!</span>
              ) : (
                ''
              )}
              <Button
                type="primary"
                inline
                onClick={e => this.raiseL2Ticket('Reset API key')}
              >
                Reset key
              </Button>
            </div>
          </KeyCard>
        ) : null}
        <AccountActions
          Title="Transfer ownership"
          description={this.state.TransferContentText}
          currentOrg={currentOrg}
          LoggedInUserRole={this.state.LoggedInUserRole}
          buttonText="Transfer ownership"
          ticketUrl={this.state.transferTicketUrl}
          actionHandler={this.transferOwnership}
          handleChange={this.handleChange}
          admins={admins}
          tranferAdmin={this.state.tranferAdmin}
          disableButton={this.state.disable_transfer}
        />
        <AccountActions
          Title="Delete this account"
          description={this.state.DeleteContentText}
          currentOrg={currentOrg}
          LoggedInUserRole={this.state.LoggedInUserRole}
          buttonText="Delete account"
          ticketUrl={this.state.deleteTicketUrl}
          actionHandler={this.onDelete}
          disableButton={this.state.disable_delete}
        />
        <ModalTransition>
          {showModal && (
            <InterestModal
              close={this.close}
              createInterest={() => this.getFunction()}
              showModal={showModal}
              resetAPIKey={this.resetAPIKey}
              type={type}
              error={this.state.transferError}
            />
          )}
        </ModalTransition>
      </div>
    );
  }
}

export default AccountSettings;

const AccountActions = props => {
  const {
    Title,
    description,
    currentOrg,
    LoggedInUserRole,
    buttonText,
    ticketUrl,
    actionHandler,
    admins,
    disableButton
  } = props;
  return (
    <StyledCard>
      <h3> {Title} </h3>
      <p>{description ? description : <Skeleton height={14} width="50%" />}</p>
      <CardAction>
        <div className="info">
          <p> Current owner</p>
          <b>
            {currentOrg ? (
              currentOrg.owner_mini.email
            ) : (
              <Skeleton height={14} width="50%" />
            )}
          </b>
        </div>

        <div className="info">
          {LoggedInUserRole === 'OW' && buttonText === 'Transfer ownership' ? (
            <React.Fragment>
              <label htmlFor="time_zone">Transfer to Admin</label>
              <Select
                className="user-type-dropdown"
                placeholder={'admin@company.com'}
                clearable={false}
                searchable={false}
                value={props.tranferAdmin}
                onChange={(e, value) =>
                  props.handleChange(e, value, 'tranferAdmin')
                }
                options={admins && admins.length ? admins : []}
              />
            </React.Fragment>
          ) : null}

          {ticketUrl ? (
            <Fragment>
              <div className="buttonPlaceholder infotext">
                <Processing> Processing </Processing>
              </div>
              <div style={{ textAlign: 'right' }}>
                Track your request{' '}
                <a href={ticketUrl} rel="noopener noreferrer" target="_blank">
                  here
                </a>
              </div>
            </Fragment>
          ) : (
            <div className="buttonPlaceholder">
              {LoggedInUserRole ? (
                <Button
                  inline
                  type="primary"
                  onClick={() => actionHandler()}
                  disabled={
                    (!props.tranferAdmin &&
                      buttonText === 'Transfer ownership') ||
                    disableButton
                  }
                >
                  {LoggedInUserRole === 'AD'
                    ? `Request for ${buttonText}`
                    : buttonText}
                </Button>
              ) : null}
            </div>
          )}
        </div>
      </CardAction>
    </StyledCard>
  );
};

const InterestModal = props => {
  return (
    <Modal
      heading={undefined}
      autoFocus={false}
      width="500px"
      components={{
        Header: () => (
          <StyledHeader>
            <h2>
              {props.type.indexOf('ownership') !== -1
                ? 'Transfer ownership'
                : props.type}
            </h2>
          </StyledHeader>
        ),
        Footer: () => (
          <Footer
            close={props.close}
            create={
              props.type === 'Reset API key'
                ? props.resetAPIKey
                : props.createInterest()
            }
            error={props.error}
            type={props.type}
          />
        )
      }}
    >
      <StyledFeatureInterest>
        <div className="description">
          {/* We’ve received your '{props.type}' request. We’ll reach out to
          you in 3 business days. */}
          {props.type === 'Reset API key'
            ? `Once the API key is reset, all the applications using the old key will stop working.  Are you sure you want to continue?`
            : `Are you sure you'd like to proceed wit this request? ${
                props.type === 'Confirm deletion?'
                  ? 'Your account cannot be recovered after deletion.'
                  : props.type
              }`}
          <div style={{ color: 'red', paddingTop: '10px' }}>{props.error}</div>
        </div>
      </StyledFeatureInterest>
    </Modal>
  );
};

const Footer = props => {
  const [disableBtn, setdisableBtn] = React.useState(false);
  const clickHandler = () => {
    props.create(props.type);
    setdisableBtn(true);
  };
  return (
    <StyledFooter>
      <Button inline onClick={() => props.close()} type="secondary">
        No
      </Button>
      <Button
        inline
        type="primary"
        onClick={() => clickHandler()}
        disabled={props.error || disableBtn}
      >
        {props.type === 'Reset API key' ? 'Yes' : 'Yes, Proceed'}
      </Button>
    </StyledFooter>
  );
};

const Error = styled.div`
  color: #f00003;
  font-size: 12px;
  padding-top: 5px;
`;

const DomainUrl = styled.div`
  font-size: 12px;
  color: #7d7d7d;
  padding-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 372px;
`;

const CardAction = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 15px; */
  div.info {
    width: 45%;
    label {
      font-size: 12px;
      color: #6f7c87;
    }
    p {
      margin-bottom: 5px;
      height: 25px;
    }
    b {
      font-weight: 600;
    }
    .user-type-dropdown {
      margin: 0;
    }

    .Select.is-open {
      box-shadow: none;
    }

    .Select-control {
      border: 0;
      border-bottom: 1px solid #ddd;
      &:hover {
        box-shadow: none;
      }
    }

    .Select-placeholder {
      padding-left: 0;
    }
    .Select.is-focused:not(.is-open) > .Select-control {
      box-shadow: none;
    }

    .Select-value {
      padding-left: 0;
    }
  }
`;

const Processing = styled.div`
  border-radius: 20px;
  border: solid 0.5px var(--border-info-color);
  background-color: var(--bg-info);
  width: fit-content;
  cursor: auto;
  font-weight: 500;
  padding: 5px 18px;
  display: block;
  margin-right: 20px;
  margin-bottom: 5px;
`;

const StyledCard = styled.div`
  padding: 15px;
  max-width: 965px;
  margin: 10px auto;
  min-height: 51px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  border-radius: 3px;
  background-color: #ffffff;

  h2 {
    font-size: 16px;
    font-weight: 600;
  }

  p {
    color: #6f7c87;
    height: 32px;
  }

  .buttonPlaceholder {
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &.infotext {
      margin-top: 0 !important;
    }
  }
`;

const StyledSensitivity = styled.div`
  display: block;
  border-radius: 5px;
  border: solid 1px #efefef;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;

  .cardTitle {
    background-color: #f3f3f3;
    height: 34px;
    padding: 0px 14px;
    line-height: 34px;
  }

  .cardContent {
    padding: 10px 14px;
    .senstivity-text {
      width: 80%;
      display: inline-block;
      vertical-align: middle;
      color: #6f7c87;
    }

    .action-btn {
      display: inline-block;
      vertical-align: middle;
      float: right;
    }
  }
`;

const StyledEmail = styled.div`
  clear: both;
  display: block;
  vertical-align: middle;

  .toggleBtn {
    display: none;

    &:checked + label,
    &.active + label {
      background-color: var(--text-link);
      color: #fff;
      border: 1px solid var(--text-link);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
    }
  }

  .toggleBtn + label {
    float: left;
    padding: 5px 16px;
    cursor: pointer;
    border: 1px solid var(--border-color);
    color: var(--base-color);
    background-color: #fff;
    opacity: 1;
    font-size: 12px;

    &:nth-child(2) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: 0;
    }

    &:last-child {
      border-left: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;

const KeyCard = styled.div`
  padding: 15px;
  max-width: 965px;
  margin: 10px auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  border-radius: 3px;
  background-color: #ffffff;

  .key-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
  }
  .public-api-key-textarea {
    width: 44%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: solid 1px #e6e6e6;
    background-color: #fafafa;
    font-size: 12px;
  }
  .api-key {
    width: 93%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    color: #6f7c87;
  }

  .copy {
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: solid 1px #e2e2e2;
    height: 38px;
    background-color: inherit;
    position: relative;
  }
  .copied {
    position: relative;
    right: 18%;
    font-size: 10px;
  }
`;
