import { fork, take, call, put, all, select } from 'redux-saga/effects';
import { request } from '../utils/Api';
import _ from 'lodash';
import { getOrgId } from '../utils/utility';
// import { requestAndPut } from "./check";
import {
  GET_STATUSPAGES_REQUEST,
  getStatusPagesSuccess,
  getStatusPagesFailure,
  GET_STATUS_CHECKS_REQUEST,
  getStatusPageChecksSuccess,
  getStatusPageChecksFailure,
  SET_ACTIVE_STATUSPAGE,
  setActiveStatusChecksSuccess,
  setActiveStatusChecksFailure,
  UPDATE_STATUSPAGE,
  updateStatusPageSuccess,
  updateStatusPageFailure,
  CREATE_STATUSPAGE,
  CREATE_STATUSPAGE_SUCCESS,
  createStatusPageSuccess,
  createStatusPageFailure,
  CREATE_STATUSPAGE_CNAME,
  createStatusPageCnameSuccess,
  createStatusPageCnameFailure,
  VERIFY_STATUSPAGE_CNAME,
  verifyStatusPageCnameSuccess,
  verifyStatusPageCnameFailure,
  STATUSPAGE_FORM_FIELDS,
  addToast,
  CREATE_CHECKS_STATUSPAGE,
  createStatusPageChecks,
  createStatusPageChecksSuccess,
  createStatusPageChecksFailure,
  DELETE_STATUSPAGE,
  deleteStatusPageSuccess,
  deleteStatusPageFailure,
  deleteCheckFromStatus,
  deleteCheckFromStatusSuccess,
  deleteCheckFromStatusFailure,
  REMOVE_CHECK_FROM_STATUSPAGE,
  updateStatusPageOrder,
  UPDATE_STATUS_PAGE_ORDER,
  updateStatusPageOrderSuccess,
  updateStatusPageOrderFailure
} from '../actions';

function* addToastError(error) {
  yield put(addToast({ toastr: { type: 'warning', message: error } }));
}

export function* requestAndPut(
  requestParameters,
  successAction,
  failureAction,
  meta,
  toastrObj,
  toastrModifier
) {
  const response = yield call(...requestParameters);
  if (
    (response && Object.keys(response).length && response.status !== 404) ||
    response === ''
  ) {
    const filterExternalErrors = [];
    if (response.status === 400) {
      Object.keys(response.response).forEach(key => {
        if (STATUSPAGE_FORM_FIELDS.indexOf(key) === -1) {
          filterExternalErrors.push(response.response[key]);
        }
      });
      yield all(filterExternalErrors.map(error => call(addToastError, error)));
    }
    yield put(
      successAction({
        response,
        meta: toastrObj
          ? {
              meta,
              toastr:
                toastrObj && response.status !== 400
                  ? {
                      ...(toastrModifier
                        ? toastrModifier(response, toastrObj)
                        : toastrObj)
                    }
                  : null
            }
          : meta
      })
    );
  } else {
    yield put(failureAction({ response, id: meta }));
  }
}

export default function* getStatusPagesSaga() {
  while (true) {
    const action = yield take(GET_STATUSPAGES_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/public-status-pages/?organization=${getOrgId()}`],
      getStatusPagesSuccess,
      getStatusPagesFailure,
      action.payload
    );
  }
}

export function* getStatusPageChecksSaga() {
  while (true) {
    const action = yield take(GET_STATUS_CHECKS_REQUEST);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-page-checks/?status_page=${action.payload}`
        // { method: "POST", payload: { http_referal_url: action.payload } }
      ],
      getStatusPageChecksSuccess,
      getStatusPageChecksFailure,
      action.payload
    );
  }
}

export function* setStatusPageAndChecks() {
  while (true) {
    const action = yield take(SET_ACTIVE_STATUSPAGE);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-page-checks/?status_page=${action.payload.id}`
        // {
        // method: "GET",
        // payload: { http_referal_url: action.payload.public_url }
        // }
      ],
      setActiveStatusChecksSuccess,
      setActiveStatusChecksFailure,
      action.payload
    );
  }
}

export function* createChecksToStatusPage(check, statuspage) {
  yield put(
    createStatusPageChecks({
      status_page: statuspage,
      application_check: check.id,
      order: check.order
    })
  );
}

export function* deleteStatusPageChecks(check) {
  yield put(
    deleteCheckFromStatus({
      id: check.id
    })
  );
}

export function* updateStatusPageChecksOrder(check) {
  yield put(
    updateStatusPageOrder({
      id: check.id,
      order: check.order
    })
  );
}

export function* updateStatusPage() {
  while (true) {
    const action = yield take(UPDATE_STATUSPAGE);
    const state = yield select(s => s.statuspages);
    const currentStatusPage =
      state.statuspages.results &&
      state.statuspages.results.filter(
        statuspage => statuspage.id === state.statuspageform.id
      )[0];
    const updatedChecks =
      action.payload.associated_checks &&
      action.payload.associated_checks.filter(c => c.active || c.current);
    const checksToAdd = updatedChecks.filter(
      c =>
        _.findIndex(
          currentStatusPage.checks,
          o => o.application_check === c.id
        ) === -1 && !c.current
    );
    if (checksToAdd.length) {
      yield all(
        checksToAdd.map(
          check =>
            check.active &&
            call(createChecksToStatusPage, check, currentStatusPage.id)
        )
      );
    }

    if (updatedChecks.length) {
      yield all(
        updatedChecks.map(check => call(updateStatusPageChecksOrder, check))
      );
    }

    const checksToRemove = currentStatusPage.checks.filter(
      c =>
        _.findIndex(
          updatedChecks,
          o => o.application_check || o.id === c.application_check
        ) === -1
    );
    if (checksToRemove.length) {
      yield all(
        checksToRemove.map(check => call(deleteStatusPageChecks, check))
      );
    }
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-pages/${action.payload.id}/`,
        { method: 'PUT', payload: action.payload }
      ],
      updateStatusPageSuccess,
      updateStatusPageFailure,
      action.payload,
      {
        type: 'success',
        message: `${action.payload.name} successfully updated`
      }
    );
  }
}

export function* updateStatusPageCheckOrder() {
  while (true) {
    const action = yield take(UPDATE_STATUS_PAGE_ORDER);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-page-checks/${action.payload.id}/`,
        { method: 'PATCH', payload: { order: action.payload.order } }
      ],
      updateStatusPageOrderSuccess,
      updateStatusPageOrderFailure,
      action.payload
    );
  }
}

export function* deleteStatusPage() {
  while (true) {
    const action = yield take(DELETE_STATUSPAGE);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-pages/${action.payload.id}/`,
        { method: 'DELETE' }
      ],
      deleteStatusPageSuccess,
      deleteStatusPageFailure,
      action.payload,
      {
        type: 'success',
        message: `${action.payload.name} successfully deleted`
      }
    );
  }
}

export function* deleteStatusPageCheck() {
  while (true) {
    const action = yield take(REMOVE_CHECK_FROM_STATUSPAGE);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-page-checks/${action.payload.id}/`,
        { method: 'DELETE' }
      ],
      deleteCheckFromStatusSuccess,
      deleteCheckFromStatusFailure,
      action.payload
    );
  }
}

export function* createStatusPage() {
  while (true) {
    const action = yield take(CREATE_STATUSPAGE);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-pages/`,
        { method: 'POST', payload: action.payload }
      ],
      createStatusPageSuccess,
      createStatusPageFailure,
      action.payload,
      { type: 'success', message: `Status page created` }
    );
  }
}

export function* createStatusPageCname() {
  while (true) {
    const action = yield take(CREATE_STATUSPAGE_CNAME);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-pages-generate-cname/?status_page=${action.payload.id}`,
        { method: 'POST', payload: action.payload }
      ],
      createStatusPageCnameSuccess,
      createStatusPageCnameFailure,
      action.payload,
      { type: 'success', message: `Status page CNAME generated` }
    );
  }
}

export function* verifyStatusPageCname() {
  while (true) {
    const action = yield take(VERIFY_STATUSPAGE_CNAME);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-pages-verify/?status_page=${action.payload.id}`,
        { method: 'GET' }
      ],
      verifyStatusPageCnameSuccess,
      verifyStatusPageCnameFailure,
      action.payload,
      { type: 'success', message: `Status page CNAME verified` },
      response => {
        const isVerified = _.get(
          response,
          ['custom_domain_cname', 'is_verified'],
          false
        );
        return isVerified
          ? {
              type: 'success',
              message: `Status page CNAME verified`
            }
          : {
              type: 'warning',
              message: `Status page CNAME not verified`
            };
      }
    );
  }
}

export function* addStatusPageChecks() {
  while (true) {
    const action = yield take(CREATE_CHECKS_STATUSPAGE);
    yield fork(
      requestAndPut,
      [
        request,
        `/public-status-page-checks/`,
        { method: 'POST', payload: action.payload }
      ],
      createStatusPageChecksSuccess,
      createStatusPageChecksFailure,
      action.payload
    );
  }
}

export function* addChecksToStatusPage() {
  while (true) {
    const action = yield take(CREATE_STATUSPAGE_SUCCESS);
    const { meta } = action.payload.meta;
    if (meta.associated_checks.length) {
      yield all(
        meta.associated_checks.map(
          check =>
            check.active &&
            call(createChecksToStatusPage, check, action.payload.response.id)
        )
      );
    }
  }
}
