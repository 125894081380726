import up from '../images/up.svg';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import down from '../images/down_2.svg';
import FilterTabs from '../components/FilterTabs';
import { Pause, Restart, Delete } from '../components/Icons';
import search from '../images/search.svg';
import {
  getBadges,
  getCurrentUserOrg,
  setFilterState,
  toggleInterestModal
} from '../actions';

const confirmationTxtCopy = {
  delete: {
    title: 'Confirm deletion',
    content: {
      one: 'Are you sure you want to delete the selected check?',
      many: 'Are you sure you want to delete the selected checks?'
    },
    btnText: 'Delete',
    element: <Delete />,
    planname: ['GARDEN', 'BLOSSOM', 'SPROUT']
  },
  pause: {
    title: 'Pause Monitoring',
    content: {
      one: 'Do you wish to Pause the selected check from getting monitored?',
      many: 'Do you wish to Pause the selected checks from getting monitored?'
    },
    btnText: 'Pause',
    element: <Pause />,
    planname: ['GARDEN', 'BLOSSOM', 'SPROUT']
  },
  restart: {
    title: 'Restart Monitoring',
    content: {
      one: 'Do you wish to Restart monitoring the selected check?',
      many: 'Do you wish to Restart monitoring the selected checks?'
    },
    btnText: 'Restart',
    element: <Restart />,
    planname: ['GARDEN', 'BLOSSOM', 'SPROUT']
  }
};

class ApplicationStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'all',
      search: ''
    };
    this.searchThrottled = _.debounce(this.setFilterState, 500);
  }

  setFilterState = value => {
    this.props.setFilterState({ search: value });

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Search', window._na_module);
    }
  };

  handleFilterChange = (k, v) => {
    this.setState({ status: v });
    this.props.setFilterState({ status: v, page: 1 });
  };

  handleSearchChange = e => {
    if (document.getElementById('checkall') !== null)
      document.getElementById('checkall').checked = false;
    const { value } = e.target;
    const trimmedValue = value.trim();
    if (this.state.search !== trimmedValue) {
      this.setState({ search: value });
      this.searchThrottled(trimmedValue);
    }
  };

  componentDidMount() {
    this.props.getBadges();
    this.setState({
      ...this.props.filters
    });
  }

  confirmationPopup = type => {
    const { title, content, btnText } = confirmationTxtCopy[type];
    const toastrConfirmOptions = {
      component: () => {
        return (
          <div className="checks_delete">
            <p>
              {this.props.selectedChecks.length > 1
                ? content.many
                : content.one}
            </p>
          </div>
        );
      },
      okText: btnText,
      cancelText: 'Cancel',
      onOk: () => this.props.bulkActions(type)
    };

    toastr.confirm(title, toastrConfirmOptions);
  };

  captureInterest = type => {
    const payload = {
      state: true,
      type
    };
    this.props.toggleInterestModal(payload);
  };

  render() {
    const { org, filters, selectedChecks, deviceSize } = this.props;
    let downCount = 0,
      allLoaded = false,
      upCount = 0,
      pausedCount = 0,
      totalChecks = 0;
    if (org) {
      totalChecks = org.number_of_checks; // checks.totalChecks
      allLoaded = org.num_of_checks_up_down ? true : false;
      if (allLoaded) {
        // this.props.goOnline();
        downCount = org.num_of_checks_up_down.down_count;
        upCount = org.num_of_checks_up_down.up_count;
        pausedCount = org.num_of_checks_up_down.paused_count;
      }
    }
    return (
      <Container>
        <StyledStatusBar>
          {deviceSize && deviceSize === 'device-large' ? (
            <AppStatus>
              {!allLoaded ? (
                <Statusloader style={{ border: 0 }}>
                  <div className="indicator">
                    <svg width="16px" height="12px">
                      <polyline
                        id="back"
                        points="1 6 4 6 6 11 10 1 12 6 15 6"
                      />
                      <polyline
                        id="front"
                        points="1 6 4 6 6 11 10 1 12 6 15 6"
                      />
                    </svg>
                  </div>
                </Statusloader>
              ) : downCount ? (
                <React.Fragment>
                  <img src={down} alt="Down count" />
                  <span className="down">{downCount} Down</span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img src={up} alt="all up" />
                  <span className="up">All Up</span>
                </React.Fragment>
              )}
            </AppStatus>
          ) : null}
          <OverallContainer>
            <CheckInfo>
              <div className="content">
                <div> Total Checks </div>
                <div className="totalcount">
                  {allLoaded ? totalChecks : '-'}
                </div>
              </div>
            </CheckInfo>
            <CheckInfo>
              <div className="content">
                <div> Up </div>
                <div className="upcount"> {allLoaded ? upCount : '-'} </div>
              </div>
            </CheckInfo>
            <CheckInfo>
              <div className="content">
                <div> Down </div>
                <div className="downcount">{allLoaded ? downCount : '-'} </div>
              </div>
            </CheckInfo>
            <CheckInfo>
              <div className="content">
                <Fragment>
                  <div> Paused </div>
                  <div className="pausedcount">
                    {allLoaded ? pausedCount : '-'}{' '}
                  </div>
                </Fragment>
              </div>
            </CheckInfo>
          </OverallContainer>
        </StyledStatusBar>
        {deviceSize && deviceSize === 'device-large' ? (
          <ActionElements>
            {selectedChecks.length ? (
              <div className="check-actions">
                <div className="info">
                  Selected ({selectedChecks.length}/{totalChecks})
                </div>
                {Object.keys(confirmationTxtCopy).map((item, index) => (
                  <Fragment key={index}>
                    {org &&
                    org.subscription.plan_name &&
                    confirmationTxtCopy[item].planname.indexOf(
                      org.subscription.plan_name
                    ) > -1 ? (
                      <StyledBtn onClick={() => this.confirmationPopup(item)}>
                        {confirmationTxtCopy[item].element}
                        <span>{confirmationTxtCopy[item].btnText}</span>
                      </StyledBtn>
                    ) : null}
                  </Fragment>
                ))}
              </div>
            ) : (
              <FilterTabs
                onChange={this.handleFilterChange}
                selectedItem={filters.status}
                planname={org && org.subscription.plan_name}
              />
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <SearchBox>
                <img src={search} alt="search" />
                <input
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Search"
                  defaultValue={this.state.search}
                  onChange={e => this.handleSearchChange(e)}
                />
              </SearchBox>
            </div>
          </ActionElements>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  badges: state.badges,
  filters: state.checks.filterParams,
  checks: state.checks,
  app: state.app
});

const mapDispatchProps = dispatch => ({
  getBadges: id => dispatch(getBadges(id)),
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg()),
  setFilterState: filter => dispatch(setFilterState(filter)),
  toggleInterestModal: state => dispatch(toggleInterestModal(state))
});

export default connect(mapStateToProps, mapDispatchProps)(ApplicationStatus);

const Container = styled.div`
  background-color: #ebeef0;
  position: sticky;
  top: ${props => (props.badgeHeader ? '120px' : '60px')};
  padding-bottom: 0px;
  padding-top: 15px;
  z-index: 1;
`;

const StyledBtn = styled.div`
  cursor: pointer;
  margin-right: 10px;
  background: linear-gradient(180deg, #ffffff 2.56%, #f5f7f9 95.75%);
  border: 1px solid #cfd7df;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  img,
  span {
    display: block;
    padding: 0 5px;
  }
`;

const ActionElements = styled.div`
  display: flex;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  border-radius: 5px;
  height: 57px;
  background: #fff;
  justify-content: space-between;
  position: sticky;
  top: 165px;
  z-index: 1;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 1px solid #e8e8e8;

  .check-actions {
    display: flex;
    align-items: center;

    .info {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.67;
      color: #707c87;
      margin: 0 10px;
    }
  }
`;

const SearchBox = styled.div`
  margin: 11px;
  position: relative;
  input {
    width: 210px;
    height: 35px;
    padding-left: 30px;
    border-radius: 3px;
    background-color: #f2f2f2;
    color: #888;
    border: 0;
  }
  input::placeholder {
    color: black;
    opacity: 1;
  }

  input:focus {
    border: solid 1px #e2e2e2;
    background-color: #ffffff;
    outline: none;
  }

  img {
    width: 13px;
    height: 13xpx;
    position: absolute;
    left: 10px;
    top: 12px;
  }
`;

export const StyledStatusBar = styled.div`
  max-width: 75rem;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 7px;

  .__react_component_tooltip {
    font-weight: normal;
    padding: 10px;
    width: 200px;
  }
`;

const Statusloader = styled.div`
  height: 50px;
  width: 180px;
  margin: 0 auto;
  padding: 14px 10px;
  border-right: 0.5px dashed #afc5cc;
  position: relative;
`;

const CardStyle = `
    border-radius: 3px;
    padding: 18px 10px 12px;
    background-color: #fff;
    box-shadow: 0 1px 0 0 #cfd7df;
    text-decoration: none;
    letter-spacing: 0.2px;
    display: flex;
    justify-content: center;
    align-items: center;
 `;

// const CurrentStatus = styled.div`
//   width: 75%;
//   height: 83px;
//   max-height: 83px;
//   border-radius: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// `;

const AppStatus = styled.div`
  width: 180px;
  text-align: center;
  height: 83px;
  margin-right: 7px;

  ${CardStyle}

  img {
    width: 28px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
  }
  .up {
    color: #4cc149;
    font-size: 22px;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
  }

  .down {
    color: #ff5959;
    font-size: 22px;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
  }
`;

const OverallContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  border-radius: 3px;
  /* overflow: hidden; */
  box-shadow: 0 1px 0 0 #cfd7df;
`;

const CheckInfo = styled.div`
  height: 83px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  ${CardStyle}
  border-radius: 0;
  .content {
    border: 0;
    border-right: 2px solid rgb(235, 239, 243);
    width: 100%;
    text-align: center;
  }

  :last-child .content {
    border-right: 0;
  }

  .totalcount,
  .upcount,
  .downcount,
  .pausedcount {
    transition: color 0.2s ease-in-out;
    font-size: 22px;
    font-weight: 700;
  }

  .upcount {
    color: #4cc149;
  }

  .downcount {
    color: #ff5959;
  }

  .pausedcount {
    color: #6f7c87;
  }
`;
