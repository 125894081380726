import * as Yup from 'yup';

export const getChoices = choices => {
  if (choices === null || choices === undefined) return [];
  const allOptions = [];
  if (Array.isArray(choices)) {
    allOptions.push(...choices);
    return allOptions.map(opt => ({
      stringId: opt,
      id: opt.id || opt,
      value: opt
    }));
  }
  const keys = Object.keys(choices);
  keys.forEach(key => {
    if (Array.isArray(choices[key])) {
      allOptions.push(
        ...choices[key].map(choice => ({
          stringId: choice,
          id: choice.id || choice,
          value: choice
        }))
      );
    } else {
      allOptions.push({
        stringId: key,
        id: choices[key] || key,
        value: key
      });
    }
  });
  return allOptions;
};

export const isMandatory = field => field.required_for_agents || field.required;

export const getValidationSchema = fields => {
  const objectShape = {};
  const customFieldsShape = {};

  return Yup.lazy(values => {
    fields.forEach(field => {
      if (isMandatory(field)) {
        if (field.default) {
          objectShape[field.name] = Yup.string().required(
            `${field.label} is mandatory`
          );
          if (
            field.nested_ticket_fields &&
            field.nested_ticket_fields.length > 0
          ) {
            const fieldChoicesLev1 =
              (field.choices &&
                field.name &&
                values[field.name] &&
                field.choices[values[field.name]]) ||
              {};
            const fieldChoicesLev2 =
              (field.nested_ticket_fields[0] &&
                field.nested_ticket_fields[0].name &&
                values[field.nested_ticket_fields[0].name] &&
                fieldChoicesLev1[values[field.nested_ticket_fields[0].name]]) ||
              [];
            field.nested_ticket_fields.forEach((nesField, index) => {
              if (
                index > 0 &&
                fieldChoicesLev2 &&
                fieldChoicesLev2.length === 0
              )
                return;
              objectShape[nesField.name] = Yup.string().required(
                `${nesField.label} is mandatory`
              );
            });
          }
        } else {
          customFieldsShape[field.name] = Yup.string().required(
            `${field.label} is mandatory`
          );
          if (
            field.nested_ticket_fields &&
            field.nested_ticket_fields.length > 0
          ) {
            const fieldChoicesLev1 =
              (field.choices &&
                field.name &&
                values.custom_fields &&
                values.custom_fields[field.name] &&
                field.choices[values.custom_fields[field.name]]) ||
              {};
            const fieldChoicesLev2 =
              (field.nested_ticket_fields[0] &&
                field.nested_ticket_fields[0].name &&
                values.custom_fields &&
                values.custom_fields[field.nested_ticket_fields[0].name] &&
                fieldChoicesLev1[
                  values.custom_fields[field.nested_ticket_fields[0].name]
                ]) ||
              [];
            field.nested_ticket_fields.forEach((nesField, index) => {
              if (
                index > 0 &&
                fieldChoicesLev2 &&
                fieldChoicesLev2.length === 0
              )
                return;
              customFieldsShape[nesField.name] = Yup.string().required(
                `${nesField.label} is mandatory`
              );
            });
          }
        }
      } else {
        objectShape[field.name] = Yup.string();
      }
      objectShape.custom_fields = Yup.object().shape(customFieldsShape);
    });
    return Yup.object().shape(objectShape);
  });
};
