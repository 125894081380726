import React from 'react';
import styled from 'styled-components';
import ExploreModal from './ExploreModal';
import { FRESHWORKS_PRODUCTS } from '../constants/constant';

export function fireClick(node) {
  if (document.createEvent) {
    var evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, false);
    node.dispatchEvent(evt);
  } else if (document.createEventObject) {
    node.fireEvent('onclick');
  } else if (typeof node.onclick == 'function') {
    node.onclick();
  }
}

class ExploreFeatureCards extends React.Component {
  state = {
    showModal: false,
    selectedProduct: null
  };
  close = () => {
    this.setState({ showModal: false });
  };
  onOpenSignup = (productName, productURL) => {
    if (window._na && window._na_module) {
      window._na.sendFeatureEvent(productName + ' - ' + 'Start Trial', window._na_module);
    }
    
    var openOmnibarEvent = new CustomEvent('showOmnibar');
    var eventTarget = document.querySelector('[data-omnibar-event-target]');
    if (eventTarget) {
      eventTarget.dispatchEvent(openOmnibarEvent);

      setTimeout(() => {
        const productNameUpper =
          productName.substring(0, 1).toUpperCase() +
          productName.substring(1, productName.length);
        const existingAccount = document.querySelector(
          '[data-freshworks-product="' +
            productName +
            '"] .add-new-account, [data-freshworks-product="' +
            productNameUpper +
            '"] .add-new-account'
        );

        if (existingAccount) {
          fireClick(existingAccount);
        } else {
          const newAccount = document.querySelector(
            '[data-freshworks-product="' +
              productName +
              '"], [data-freshworks-product="' +
              productNameUpper +
              '"]'
          );
          if (newAccount) {
            fireClick(newAccount);
          } else {
            window.open(productURL);
          }
        }
      }, 1000);
    } else {
      window.open(productURL);
    }
  };
  render() {
    const profile =
      this.props.userDetails != null ? this.props.userDetails.profile : null;
    return (
      <React.Fragment>
        {this.state.showModal && this.props.userDetails && (
          <ExploreModal
            onClose={this.close}
            userProfile={profile}
            userDetails={this.props.userDetails}
            getCurrentUser={this.props.getCurrentUser}
            product={this.state.selectedProduct}
          />
        )}
        <StyledFeatureCards>
          <div className="cards">
            {FRESHWORKS_PRODUCTS.filter(product => product.isFeatured).map(
              product => (
                <div className="card" key={product.name}>
                  <div className="heading-container">
                    <img src={product.logo} alt={product.name} />
                  </div>
                  <div className="description">{product.description}</div>
                  <>
                    {product.url && (
                      <button
                        className="trial-button"
                        type="button"
                        onClick={() =>
                          this.onOpenSignup(product.name, product.url)
                        }
                      >
                        START TRIAL
                      </button>
                    )}
                    <button
                      className="demo-button"
                      type="button"
                      onClick={() => {
                        this.setState({ selectedProduct: product });
                        this.setState({ showModal: true });
                      }}
                    >
                      Request Demo
                    </button>
                  </>
                </div>
              )
            )}
          </div>
          <div className="bottom-line" />
        </StyledFeatureCards>
      </React.Fragment>
    );
  }
}
const StyledFeatureCards = styled.div`
  img {
    height: 32px;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }
  .bottom-line {
    width: 100%;
    height: 1px;
    background-color: #e1e1e1;
    margin-top: 40px;
    margin-bottom: 28px;
  }
  .card {
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 400px;
    width: 30%;
    height: 280px;
    align-items: center;
    background-color: white;
    background-image: ${props => props.backgroundImage};
    border-radius: 3px;
    :hover {
      transition: all 0.5s ease;
      box-shadow: 0px 8px 8px rgba(101, 101, 101, 0.1);
    }
    .heading-container {
      display: flex;
      align-items: center;
    }
    .heading {
      font-size: 25px;
      color: #242424;
      opacity: 0.7;
      line-height: 0.5;
    }
    .description {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.57;
      letter-spacing: -0.1px;
      text-align: center;
      color: #595959;
      width: 100%;
      margin: 0 auto;
    }
    .trial-button {
      border: none;
      font-weight: 500;
      font-size: 14px;
      border-radius: 3px;
      background-color: #fff;
      color: #282828;
      height: 32px;
      width: 155px;
      padding-bottom: 1px;
      cursor: pointer;
      border: solid 1px #9d9d9d;
      :hover {
        background-color: var(--bg-dark);
        border-color: transparent;
        color: #fff;
      }
    }
    .demo-button {
      border: none;
      color: #4d4d4d;
      font-weight: 500;
      font-size: 14px;
      background: #fff;
      height: 32px;
      width: 155px;
      padding-bottom: 1px;
      border-radius: 3px;
      cursor: pointer;
    }
    .demo-button:hover {
      border: 1px solid #9d9d9d;
      border-radius: 3px;
    }
  }
`;

export default ExploreFeatureCards;
