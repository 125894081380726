import React from "react";
import { Close } from "components/Icons";
import { IconWrapper } from "./style";
import { Slide } from "./transitions";

const Content = props => (
  <div style={{ flex: 1, overflow: "auto" }} {...props} />
);

const DrawerPrimitive = ({ children, onClose, ...props }) => (
  <Slide {...props}>
    {props.in && (
      <IconWrapper onClick={onClose}>
        <Close width="16" height="16" />
      </IconWrapper>
    )}
    <Content>{children}</Content>
  </Slide>
);

export default DrawerPrimitive;
