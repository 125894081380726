import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { Button } from 'freemium-ui';
import { get as getFromObject, set as setObject } from 'lodash';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import { request } from '../../utils/Api';
import CountryCodes from '../../constants/country_codes';
import Loading from '../../components/styled/Loading';

const fields = [
  {
    label: 'First Name',
    name: 'billing_address.first_name',
    isBilling: true,
    inline: 'left'
  },
  {
    label: 'Last Name',
    name: 'billing_address.last_name',
    isBilling: true,
    inline: 'right'
  },
  {
    label: 'Address Line 1',
    name: 'billing_address.billing_addr1',
    isBilling: true
  },
  {
    label: 'Address Line 2',
    name: 'billing_address.billing_addr2',
    isBilling: true
  },
  {
    label: 'City',
    name: 'billing_address.billing_city',
    isBilling: true,
    inline: 'left'
  },
  {
    label: 'Zip/Postal code',
    name: 'billing_address.billing_zip',
    isBilling: true,
    inline: 'right'
  },
  {
    label: 'State',
    name: 'billing_address.billing_state',
    isBilling: true,
    inline: 'left'
  },
  {
    label: 'Country',
    name: 'billing_address.billing_country',
    isBilling: true,
    type: 'dropdown',
    inline: 'right'
  },
  {
    label: 'First Name',
    name: 'shipping_address.first_name',
    isBilling: false,
    inline: 'left'
  },
  {
    label: 'Last Name',
    name: 'shipping_address.last_name',
    isBilling: false,
    inline: 'right'
  },
  {
    label: 'Address Line 1',
    name: 'shipping_address.line1',
    isBilling: false
  },
  {
    label: 'Address Line 2',
    name: 'shipping_address.line2',
    isBilling: false
  },
  {
    label: 'City',
    name: 'shipping_address.city',
    isBilling: false,
    inline: 'left'
  },
  {
    label: 'Zip/Postal code',
    name: 'shipping_address.zip',
    isBilling: false,
    inline: 'right'
  },
  {
    label: 'State',
    name: 'shipping_address.state',
    isBilling: false,
    inline: 'left'
  },
  {
    label: 'Country',
    name: 'shipping_address.country',
    isBilling: false,
    type: 'dropdown',
    inline: 'right'
  }
];
const initialFieldValues = {
  billing_address: {
    billing_addr1: '',
    billing_addr2: '',
    billing_city: '',
    billing_country: '',
    billing_state: '',
    billing_zip: '',
    first_name: '',
    last_name: ''
  },
  shipping_address: {
    city: '',
    country: '',
    first_name: '',
    last_name: '',
    line1: '',
    line2: '',
    state: '',
    zip: ''
  }
};

const AddressForm = props => {
  const [initialValues, setInitialValues] = useState(initialFieldValues);
  const [fieldsObj, setFieldsObj] = useState({});

  useEffect(() => {
    if (props.address) {
      setInitialValues({
        billing_address: {
          ...initialValues.billing_address,
          ...props.address.billing_address
        },
        shipping_address: {
          ...initialValues.shipping_address,
          ...props.address.shipping_address
        },
        isAddressesSame: props.isAddressesSame
      });
    } else {
      (async () => {
        const response = await request(`/payment/address`);
        setInitialValues({
          billing_address: {
            ...initialValues.billing_address,
            ...response.billing_address
          },
          shipping_address: {
            ...initialValues.shipping_address,
            ...response.shipping_address
          },
          isAddressesSame: props.isAddressesSame
        });
      })();
    }
    const fieldsObj = {};
    fields.forEach(field => (fieldsObj[field.name] = field));
    setFieldsObj(fieldsObj);
  }, []);

  const handleSave = async (values, { setErrors }) => {
    const payload = {
      ...values
    };
    if (values.isAddressesSame) {
      payload.shipping_address = {
        city: payload.billing_address.billing_city,
        state: payload.billing_address.billing_state,
        country: payload.billing_address.billing_country,
        zip: payload.billing_address.billing_zip,
        line1: payload.billing_address.billing_addr1,
        line2: payload.billing_address.billing_addr2,
        first_name: payload.billing_address.first_name,
        last_name: payload.billing_address.last_name
      };
    }
    const response = await request(`/payment/address`, {
      method: 'POST',
      payload: {
        ...payload
      }
    });
    if (response.status == 400) {
      let error = {};
      response.response.map(eachError => {
        let [fieldName, value] = eachError.split(':');
        if (values.isAddressesSame) {
          //chargebee throws validaton error only for shipping address zip_code. If the user uses the same address for both billing and shipping form, we have to get that error to show it in billing_address form since that is the only form visible to ur
          fieldName = fieldName.replace('shipping_address', 'billing_address');
          fieldName = fieldName.replace('zip', 'billing_zip');
        }
        error = setObject(error, fieldName.trim(), value.trim());
      });
      setErrors(error);
      toastr.error('Update failed');
    } else {
      props.setAddressDetails(payload);
      props.closeDrawer();
      toastr.success('Changes updated successfully');
    }
  };
  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={handleSave}
      enableReinitialize={true}
      validateOnBlur={false}
      validate={values => {
        const errors = {
          billing_address: {},
          shipping_address: {}
        };
        let isError = false;
        Object.keys(values['billing_address']).map(field => {
          if (!values.billing_address[field]) {
            isError = true;
            errors['billing_address'][field] = `${
              fieldsObj['billing_address.' + field].label
            } is required`;
          }
        });
        if (!values.isAddressesSame) {
          Object.keys(values['shipping_address']).map(field => {
            if (!values.shipping_address[field]) {
              isError = true;
              errors['shipping_address'][field] = `${
                fieldsObj['shipping_address.' + field].label
              } is required`;
            }
          });
        }
        return isError ? errors : {};
      }}
    >
      {formProp => (
        <>
          {initialValues ? (
            <StyledAddressForm>
              <Form>
                <div className="section">
                  <div className="section-title">Billing Address</div>
                  {fields
                    .filter(eachField => eachField.isBilling)
                    .map(eachField => (
                      <div
                        className={`field ${
                          eachField.inline ? `field-${eachField.inline}` : ''
                        }`}
                      >
                        <div className="label">
                          {eachField.label}&nbsp;
                          <span style={{ color: '#f74e4e' }}>*</span>
                          {getFromObject(formProp.touched, eachField.name) && (
                            <div className="error">
                              {getFromObject(formProp.errors, eachField.name)}
                            </div>
                          )}
                        </div>
                        {eachField.type === 'dropdown' ? (
                          <Field
                            as="select"
                            className="select"
                            name={eachField.name}
                          >
                            {Object.keys(CountryCodes).map(country => {
                              return (
                                <option value={country}>
                                  {CountryCodes[country]}
                                </option>
                              );
                            })}
                          </Field>
                        ) : (
                          <Field
                            type="text"
                            className="input"
                            name={eachField.name}
                          />
                        )}
                      </div>
                    ))}
                </div>
                <div className={`section`}>
                  <div className="section-title">Shipping Address</div>
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      name="isAddressesSame"
                      checked={formProp.values.isAddressesSame}
                      onChange={() => {
                        formProp.setFieldValue(
                          'isAddressesSame',
                          !formProp.values.isAddressesSame,
                          false
                        );
                      }}
                    />
                    &nbsp;&nbsp;
                    <label className="label" htmlFor="isAddressesSame">
                      Ship to my billing address
                    </label>
                  </div>

                  <div
                    className="input-section"
                    style={{
                      height: formProp.values.isAddressesSame ? '0px' : '430px'
                    }}
                  >
                    {fields
                      .filter(eachField => !eachField.isBilling)
                      .map(eachField => (
                        <div
                          className={`field ${
                            eachField.inline ? `field-${eachField.inline}` : ''
                          }`}
                        >
                          <div className="label">
                            {eachField.label}&nbsp;
                            <span style={{ color: '#f74e4e' }}>*</span>
                            {getFromObject(
                              formProp.touched,
                              eachField.name
                            ) && (
                              <div className="error">
                                {getFromObject(formProp.errors, eachField.name)}
                              </div>
                            )}
                          </div>
                          {eachField.type === 'dropdown' ? (
                            <Field
                              as="select"
                              className="select"
                              name={eachField.name}
                            >
                              {Object.keys(CountryCodes).map(country => {
                                return (
                                  <option value={country}>
                                    {CountryCodes[country]}
                                  </option>
                                );
                              })}
                            </Field>
                          ) : (
                            <Field
                              type="text"
                              className="input"
                              name={eachField.name}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="button-container">
                  <Button
                    inline
                    type="secondary"
                    btnType="reset"
                    onClick={props.closeDrawer}
                  >
                    Cancel
                  </Button>
                  <Button
                    inline
                    type="primary"
                    disabled={formProp.isSubmitting || !formProp.dirty}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </StyledAddressForm>
          ) : (
            <StyledLoadingWrapper>
              <Loading />
            </StyledLoadingWrapper>
          )}
        </>
      )}
    </Formik>
  );
};
const StyledLoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledAddressForm = styled.div`
  padding: 40px;
  overflow: hidden;
  .section {
    padding-bottom: 24px;
  }
  .input-section {
    overflow: hidden;
    transition: height 0.5s linear;
  }
  .section-title {
    font-size: 18px;
    padding-bottom: 28px;
  }
  .field {
    padding-bottom: 32px;
  }
  .field-left {
    width: 45%;
    display: inline-block;
  }
  .field-right {
    margin-left: 10%;
    width: 45%;
    display: inline-block;
  }
  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #595959;
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .input {
    display: block;
    border: none;
    width: 100%;
    border-bottom: 1px solid #d6d6d6;
    height: 30px;
  }
  .select {
    height: 30px;
    border-radius: 4px;
    border-color: #d6d6d6;
    padding: 0 4px;
    margin-top: 12px;
  }
  .error {
    color: #f75758;
    padding-top: 12px;
    display: inline;
    padding-left: 16px;
    font-weight: 400;
    font-size: 12px;
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    label {
      user-select: none;
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
  .submit-button {
    background: var(--bg-dark);
    border-radius: 3px;
    font-weight: 500;
    color: #fff;
    padding: 8px 16px;
    border: 0;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .cancel-button {
    margin-right: 12px;
    border: none;
    background: transparent;
  }
`;

export default AddressForm;
