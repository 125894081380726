import React, { Fragment } from "react";
import { connect } from "react-redux";
import Pricingplans from "./Plans";
import Estimate from "./Estimate";
import { Container } from "./style";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getOrgId } from "../../utils/utility";
import { getSubscription, getCurrentUserOrg } from "../../actions";
import { request } from "../../utils/Api";
import _ from "lodash";
import Alerts from "../../components/Alerts";

class Pricing extends React.Component {
  state = {
    account_error: false,
    plandata: {
      name: "",
      period: "M"
    },
    planState: "loading",
    plans: [],
    pay_now: false,
    isDrawerOpen: false,
    isUpdatingAddress: false,
    isUpdatingCard: false
  };

  onTry = (name, period, price, cb_plan_id, pay_now) => {
    this.setState({
      plandata: {
        ...this.state.plandata,
        name,
        period,
        price,
        cb_plan_id,
        pay_now
      }
    });
  };

  loadPlans = async () => {
    const response = await request(`/public-plans/`);
    const plans = _.sortBy(response, ["id"]);
    if (plans.length > 0) {
      if (plans[7]["name"] === "SPROUT") {
        const element = plans.splice(7, 1)[0];
        plans.splice(1, 0, element);
      }
      this.setState({
        plans,
        planState: "idle"
      });
    }
  };

  setCheckoutProps = (payload, totalAmt) => {
    this.setState({ hosted: payload, totalAmt });
    this.props.getCurrentUserOrg();
  };

  componentDidMount() {
    this.loadPlans();
  }
  render() {
    const { plans, plandata, planState } = this.state;
    const currentOrg =
      this.props.org &&
      this.props.org.find(org => org.id === Number(getOrgId()));

    let routes = [];
    if (currentOrg) {
      routes = [
        {
          path: `${this.props.url}/pricing`,
          component: () => (
            <Pricingplans
              org={currentOrg}
              onTry={this.onTry}
              plans={plans}
              planState={planState}
              getSubscription={this.props.getSubscription}
            />
          )
        },
        {
          path: `${this.props.url}/estimate`,
          component: () => (
            <Estimate
              currentOrg={currentOrg}
              orgId={getOrgId()}
              plans={plans}
              plandata={plandata}
              setCheckoutProps={this.setCheckoutProps}
            />
          )
        }
      ];
    }

    return (
      <Container className="users-groups-container add-new-user account-settings-container">
        <section className="topbar">
          <span className="tab active-tab ">Pricing and Billing</span>
        </section>
        {alert && alert.message ? <Alerts info={alert} /> : null}
        <section className="pricing-content">
          <Router>
            <Fragment>
              <Switch>
                {routes.map((route, index) => (
                  // Render more <Route>s with the same paths as
                  // above, but different components this time.
                  <Route key={index} path={route.path} exact>
                    {route.component()}
                  </Route>
                ))}
              </Switch>
            </Fragment>
          </Router>
        </section>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  org: state.user.org
});

const mapDispatchProps = dispatch => ({
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg()),
  getSubscription: () => dispatch(getSubscription())
});

export default connect(mapStateToProps, mapDispatchProps)(Pricing);
