import React from 'react';
import { useFormik } from 'formik';
import {
  Input,
  Button,
  Popover,
  Radio,
  Dropdown,
  Checkbox,
  MultiSelectDropdown,
  BoxedRadio
} from 'freemium-ui';
import JsonEditor from 'components/JsonEditor';
import PlaceholderInserter from 'components/PlaceholderInserter';

import {
  FormSection,
  Form,
  FormActions,
  FormField,
  ShowButton,
  FormElements,
  FlexBox,
  RadioBox
} from './styles';
import { Header } from '../styles';
import {
  types,
  getMockedInitialValues,
  dataJsonPlaceholders,
  dataJsonDefault,
  dataJsonGoogleChat,
  dataJsonMSOffice,
  dataJsonSlack,
  dataJsonZapier,
  contents
} from './constants';
import { getInitialValues, getPayload, getValidationSchema } from './utils';

const CreateIntegration = props => {
  const {
    webhookIntegration = {},
    checks = [],
    isAdding = false,
    isSaving = false,
    isDeleting = false,
    onCreate = () => undefined,
    onClose = () => undefined,
    saveIntegration = () => undefined,
    deleteIntegration = () => undefined,
    addIntegration = () => undefined
  } = props;

  const allChecks = {
    all: { id: 'all', name: 'All Checks' }
  };
  checks.forEach(check => {
    allChecks[check.id] = check;
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dataJsonText, setDataJsonText] = React.useState('');
  const dataJsonRef = React.useRef(null);
  const [initialValues, setInitialValues] = React.useState(
    getMockedInitialValues()
  );
  const [showPassword, togglePassword] = React.useState(false);

  const submitHandler = (values, actions) => {
    const payload = getPayload({ values });
    if (payload.id) {
      saveIntegration({
        id: payload.id,
        actions,
        payload
      });
    } else {
      addIntegration({
        onCreate,
        payload,
        actions
      });
    }
  };

  const formik = useFormik({
    validationSchema: getValidationSchema,
    initialValues,
    onSubmit: submitHandler,
    enableReinitialize: true
  });

  React.useEffect(() => {
    if (webhookIntegration.id)
      setInitialValues(getInitialValues({ webhookIntegration }));
    if (webhookIntegration.clone)
      formik.setValues(getInitialValues({ webhookIntegration }), true);
  }, [webhookIntegration]);

  const disablePlaceholder = formik.values.contentEncoding === 'simple';

  const items = [allChecks.all, ...checks];
  const selectedItem =
    formik.values.subscribed_checks &&
    formik.values.subscribed_checks.length > 0
      ? formik.values.subscribed_checks
          .map(checkId => allChecks[checkId])
          .filter(a => a !== undefined)
      : [allChecks.all];

  return (
    <>
      <Header>
        <div className="form-title">
          {webhookIntegration.id ? 'Edit' : 'Create'} Webhook Integration
        </div>
      </Header>
      <Form
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormElements>
          <FormSection>
            <FormField>
              <Input
                id="name"
                name="name"
                placeholder="Name of the webhook"
                label="Webhook Name"
                isRequired
                isErrored={formik.submitCount > 0 && formik.errors.name}
                errorText={
                  formik.submitCount > 0 && formik.errors.name
                    ? formik.errors.name
                    : ''
                }
                value={formik.values.name}
                onChange={e => formik.handleChange(e)}
              />
            </FormField>
            <div className="section-name">
              When any of the below event happens
            </div>
            <div className="form-label">Select the Event type</div>
            <FormField>
              <Dropdown
                defaultSelectedItem={
                  types.find(type => type.id === formik.values.type) || ''
                }
                itemToString={v => v.name}
                itemComponent={v => v.name}
                isErrored={Boolean(formik.errors.type)}
                errorText={formik.errors.type}
                items={types}
                onChange={option => {
                  if (!option || !option.id) return;
                  formik.setFieldValue('type', option.id, true);
                }}
                placeholder="Event type"
              />
            </FormField>
            <FormField>
              <MultiSelectDropdown
                label="For the selected checks"
                id="subscribed_checks"
                name="subscribed_checks"
                items={items}
                selectedItem={selectedItem}
                onChange={changeChecks => {
                  const val =
                    changeChecks.length > 1 &&
                    changeChecks[changeChecks.length - 1].id === 'all'
                      ? []
                      : changeChecks
                          .filter(check => check.id !== 'all')
                          .map(check => check.id);
                  formik.setFieldValue('subscribed_checks', val, true);
                }}
                itemLabelIdentifier="name"
                itemValueIdentifier="id"
                placeholder="Select Check"
              />
            </FormField>
          </FormSection>
          <FormSection>
            <FormField>
              <div className="field-hint">
                Request type: <strong>POST</strong>
              </div>
              <Input
                id="url"
                name="url"
                placeholder="Enter Callback URL"
                label="Trigger the webhook"
                isRequired
                isErrored={formik.submitCount > 0 && formik.errors.url}
                errorText={
                  formik.submitCount > 0 && formik.errors.url
                    ? formik.errors.url
                    : ''
                }
                value={formik.values.url}
                onChange={e => {
                  const url = e.target.value;
                  let data_json = dataJsonDefault;
                  if (url.indexOf('chat.googleapis.com') > -1) {
                    data_json = dataJsonGoogleChat;
                  } else if (url.indexOf('.office.com') > -1) {
                    data_json = dataJsonMSOffice;
                  } else if (url.indexOf('hooks.slack.com') > -1) {
                    data_json = dataJsonSlack;
                  } else if (url.indexOf('zapier') > -1) {
                    data_json = dataJsonZapier;
                  }
                  formik.setFieldValue('data_json', data_json, true);
                  formik.handleChange(e);
                }}
              />
            </FormField>

            <FormField mb={16}>
              <Checkbox
                onChange={e => {
                  const { checked } = e.target;
                  formik.setFieldValue('requireAuth', checked, true);
                  formik.setFieldValue('username', '', true);
                  formik.setFieldValue('password', '', true);
                }}
                checked={formik.values.requireAuth}
              >
                Requires Basic Authentication
              </Checkbox>
            </FormField>
            <FormField>
              {formik.values.requireAuth && (
                <FlexBox>
                  <Input
                    id="username"
                    name="username"
                    isErrored={formik.submitCount > 0 && formik.errors.username}
                    errorText={
                      formik.submitCount > 0 && formik.errors.username
                        ? formik.errors.username
                        : ''
                    }
                    placeholder="username"
                    value={formik.values.username}
                    onChange={e => {
                      formik.setFieldValue('username', e.target.value, true);
                    }}
                  />
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    isErrored={formik.submitCount > 0 && formik.errors.password}
                    errorText={
                      formik.submitCount > 0 && formik.errors.password
                        ? formik.errors.password
                        : ''
                    }
                    placeholder="password"
                    value={formik.values.password}
                    onChange={e => {
                      formik.setFieldValue('password', e.target.value, true);
                    }}
                    endAdornment={
                      <ShowButton onClick={() => togglePassword(!showPassword)}>
                        {showPassword ? 'hide' : 'show'}
                      </ShowButton>
                    }
                  />
                </FlexBox>
              )}
            </FormField>
            <FormField>
              <RadioBox>
                <div>Encoding</div>
                <Radio
                  name="json"
                  value="JSON"
                  checked={formik.values.encoding === 'JSON'}
                  onChange={e => {
                    formik.setFieldValue('encoding', e.target.value, true);
                  }}
                >
                  JSON
                </Radio>
              </RadioBox>
            </FormField>
            <FormField>
              <BoxedRadio.Group
                defaultSelectedValue={formik.values.contentEncoding}
                className="wehbook-radio-group"
                onChange={option => {
                  if (!option) return;
                  if (option === 'simple') {
                    const url = formik.values.url;
                    let data_json = dataJsonDefault;
                    if (url.indexOf('chat.googleapis.com') > -1) {
                      data_json = dataJsonGoogleChat;
                    } else if (url.indexOf('.office.com') > -1) {
                      data_json = dataJsonMSOffice;
                    } else if (url.indexOf('hooks.slack.com') > -1) {
                      data_json = dataJsonSlack;
                    } else if (url.indexOf('zapier') > -1) {
                      data_json = dataJsonZapier;
                    }
                    formik.setFieldValue('data_json', data_json, true);
                  }
                  formik.setFieldValue('contentEncoding', option, true);
                }}
              >
                {contents.map(content => (
                  <BoxedRadio value={content.id}>{content.name}</BoxedRadio>
                ))}
              </BoxedRadio.Group>
            </FormField>
            <FormField>
              <JsonEditor
                id="incident-updation"
                label={
                  <>
                    <FlexBox>
                      <div className="label">Content</div>
                      <div>
                        <div
                          onClick={e =>
                            !disablePlaceholder && setAnchorEl(e.currentTarget)
                          }
                          onKeyDown={({ keyCode, currentTarget }) => {
                            if (keyCode === 13 && !disablePlaceholder)
                              setAnchorEl(currentTarget);
                          }}
                          role="button"
                          tabIndex={0}
                          aria-describedby="insert-data-placeholder"
                          className={`${
                            disablePlaceholder ? 'disabled' : ''
                          } placeholder`}
                        >
                          Insert placeholder
                        </div>
                        <Popover
                          id="insert-data-placeholder"
                          anchorEl={anchorEl}
                          style={{ zIndex: '10' }}
                          onClose={() => setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                        >
                          <PlaceholderInserter
                            placeholders={dataJsonPlaceholders}
                            onSelect={setDataJsonText}
                            expandAll
                            onClose={() => setAnchorEl(null)}
                          />
                        </Popover>
                      </div>
                    </FlexBox>
                    <div className="field-desc">
                      This section lets you write custom API requests. Click on
                      the Insert Placeholder button to include details such as
                      Check ID, Check State, Alarm Description etc... with your
                      request.
                    </div>
                  </>
                }
                height="180px"
                options={{
                  readOnly: formik.values.contentEncoding === 'simple',
                  lineNumbers: 'off',
                  scrollBeyondLastLine: false,
                  scrollBeyondLastColumn: false,
                  minimap: {
                    enabled: false
                  },
                  formatOnPaste: true
                }}
                loading="Loading..."
                formik={formik}
                insertText={dataJsonText}
                onChange={v => formik.setFieldValue('data_json', v, false)}
                setFieldError={err => formik.setFieldError('data_json', err)}
                showErrors={formik.submitCount > 0}
                value={formik.values.data_json}
                editorRef={dataJsonRef}
              />
            </FormField>
          </FormSection>
        </FormElements>
        <FormActions>
          <div>
            {webhookIntegration.id && (
              <Button
                onClick={() => deleteIntegration(formik.values.id)}
                size="normal"
                inline
                htmlType="button"
                disabled={isAdding || isSaving || isDeleting}
                type="danger"
              >
                Remove
              </Button>
            )}
          </div>
          <div>
            <Button
              onClick={onClose}
              size="normal"
              inline
              htmlType="button"
              type="secondary"
            >
              Cancel
            </Button>
            <Button
              size="normal"
              disabled={!formik.dirty || isAdding || isSaving || isDeleting}
              inline
              htmlType="submit"
              type="primary"
            >
              Save
            </Button>
          </div>
        </FormActions>
      </Form>
    </>
  );
};

export default CreateIntegration;
