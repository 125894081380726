import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'freemium-ui';
import { withRouter } from 'react-router-dom';
import CautionCircle from '../../images/caution_circle.svg';
import styled from 'styled-components';
import Switch from '../../components/ui/Switch';
import Drawer from 'shared/components/Drawer';
import AddressForm from './CardDetailsForm';
import config from '../../config';
import {
  Switcher,
  Conditions,
  Loader,
  Plans,
  StyledCardDetails
} from './style';
import _ from 'lodash';
import { PlanCard, ActivePlan, Interest, DowngradeModal } from './common';
import { toggleInterestModal, getCurrentUserOrg } from '../../actions';
import { getOrgId } from '../../utils/utility';
import { request } from '../../utils/Api';
import { toastr } from 'react-redux-toastr';
import queryString from 'query-string';
import { AccountSuspension } from './common';
import { accountMigrationDetails } from '../../constants/constant';
import AnnouncementIcon from '../../images/announcement.svg';
import Alerts from '../../components/Alerts';
import { withHooksDataHOC } from 'utils/withHooksDataHOC';
class Pricingplans extends React.Component {
  state = {
    plantype: 'Annually',
    plans: [],
    downgrade: false,
    isDrawerOpen: false,
    isUpdatingAddress: false,
    isUpdatingCard: false,
    iframeUrl: '',
    cardDetails: {},
    addressDetails: null,
    billing_address_str: '',
    shipping_address_str: '',
    pageLoading: true
  };
  togglePlanType = on => {
    this.setState(
      {
        toggle: on,
        period_unit: on ? 'Y' : 'M'
      },
      () => this.filterPlans(this.props.plans)
    );
  };
  filterPlans = data => {
    const filteredPlans = _.filter(data, {
      period_unit: this.state.period_unit
    });
    this.setState({
      allPlans: data,
      plans: filteredPlans
    });
  };
  closeBanner = () => {
    const { stickyState, setStickyData } = this.props.stickyStateData;
    let headerArray = [];
    if (stickyState) {
      headerArray = headerArray.concat(stickyState);
    }
    const newItem = {
      orgId: Number(getOrgId()),
      headerClosed: true
    };
    headerArray.push(newItem);
    setStickyData(headerArray);
  }
  setDefaults = () => {
    const { interest, state } = queryString.parse(window.location.search);
    let message = '';
    switch (state) {
      case 'updated':
      case 'succeeded':
        message = 'Successfully updated';
        break;
      case 'cancelled':
        message = 'Update cancelled';
        break;
      default:
        break;
    }
    this.setState(
      {
        toggle: true,
        period_unit: 'Y',
        interest: interest || null,
        alert: {
          message,
          type:
            state === 'succeeded' || state === 'updated' ? 'success' : 'error',
          dismissible: true
        }
      },
      () => this.filterPlans(this.props.plans)
    );
  };
  triggerModal = (type, PlanName) => {
    const payload = {
      state: true,
      type,
      PlanName
    };
    this.props.toggleInterestModal(payload);
  };
  closeDowngrade = () => this.setState({ downgrade: false });
  onDowngrade = planId => {
    this.setState({ downgrade: true, newplan: planId });
  };
  switchTrial = async planId => {
    const { addons } = this.props.org.subscription;
    const badge = addons.find(add => add.cb_id.indexOf('badge') > -1);
    if (badge)
      badge.cb_id =
        this.state.period_unit === 'M' ? 'badge_monthly' : 'badge_yearly';
    const payload = {
      plan_id: planId,
      organization: Number(getOrgId()),
      addons: addons || []
    };
    const response = await request(`/update-plan/`, {
      method: 'POST',
      payload
    });
    if (response.hosted_page_url) {
      window.location.href = response.hosted_page_url;
    } else if (response.checkout_method === 'subscription_updated') {
      this.props.getSubscription();
      this.props.history.push(`/settings/pricing?state=updated`);
    }
  };
  updatePaymentMethod = async () => {
    const payload = {
      organization: getOrgId()
    };
    const payment = await request(`/payment/card`, {
      method: 'POST',
      payload
    });
    if (payment.hosted_page_url) {
      this.setState({
        iframeUrl: `${payment.hosted_page_url}#${window.location.href}`
      });
      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Update Card', window._na_module);
      }
    }
  };
  getCardDetails = async () => {
    const payment = await request(`/payment/card`);
    if (payment) {
      this.setState({
        cardDetails: payment
      });
    }
    this.setState({ pageLoading: false });
  };
  setAddressDetails = async address => {
    //here every field is joined with , other than first_name and last_name which is joined with a space in between. Since comma will be inserted in the end, that is stripped separately.
    let billing_address_str = '';
    let shipping_address_str = '';
    if (address['billing_address']) {
      billing_address_str = address['billing_address']['first_name']
        ? `${address['billing_address']['first_name']} `
        : '';
      [
        'last_name',
        'billing_addr1',
        'billing_addr2',
        'billing_city',
        'billing_state',
        'billing_country',
        'billing_zip'
      ].map(field => {
        if (address['billing_address'][field])
          billing_address_str += address['billing_address'][field] + ', ';
      });
      billing_address_str = billing_address_str.substring(
        0,
        billing_address_str.length - 2
      );
    }
    if (address['shipping_address']) {
      shipping_address_str = address['shipping_address']['first_name']
        ? `${address['shipping_address']['first_name']} `
        : '';
      ['last_name', 'line1', 'line2', 'city', 'state', 'country', 'zip'].map(
        field => {
          if (address['shipping_address'][field])
            shipping_address_str += address['shipping_address'][field] + ', ';
        }
      );
      shipping_address_str = shipping_address_str.substring(
        0,
        shipping_address_str.length - 2
      );
    }
    if (address) {
      this.setState({
        addressDetails: address,
        billing_address_str: billing_address_str,
        shipping_address_str: shipping_address_str
      });
    }
  };
  getAddressDetails = async () => {
    const address = await request(`/payment/address`);
    if (address) {
      this.setAddressDetails(address);
    }
  };
  downgradePlan = async planId => {
    const { addons } = this.props.org.subscription;
    const badge = addons.find(add => add.cb_id.indexOf('badge') > -1);
    if (badge)
      badge.cb_id =
        this.state.period_unit === 'M' ? 'badge_monthly' : 'badge_yearly';
    const payload = {
      plan_id: planId,
      organization: Number(getOrgId()),
      addons: addons || []
    };
    const response = await request(`/update-plan/`, {
      method: 'POST',
      payload
    });
    if (response) {
      switch (response.request_status) {
        case 'downgraded':
          this.closeDowngrade();
          this.props.getSubscription();
          toastr.success(`Plan has been downgraded to ${planId}`);
          this.props.history.push(`/settings/pricing?state=downgraded`);
          break;
        case 'checks_exceeded':
          this.setState({
            downgrade: true,
            newplan: planId,
            limit: response.feature_mini
          });
          break;
        case 'integration_exceeded':
          this.setState({
            downgrade: true,
            newplan: planId,
            limit: response.feature_mini
          });
          break;
        case 'no_card':
          break;
        default:
          this.closeDowngrade();
          this.props.getSubscription();
          toastr.success('Subscribtion has been updated');
          this.props.history.push(`/settings/pricing?state=updated`);
          break;
      }
    }
  };
  messageEventListener = e => {
    if (e.origin === config.chargebeeDomain) {
      var messObj = JSON.parse(e.data);
      if (messObj.success) {
        this.getCardDetails();
        this.getAddressDetails();
        this.setState({
          isDrawerOpen: false,
          isUpdatingCard: false,
          iframeUrl: null
        });
        toastr.success('Subscription has been updated successfully');
      }
      if (messObj.cancel) {
        this.setState({
          isDrawerOpen: false,
          iframeUrl: null,
          isUpdatingCard: false
        });
        toastr.error('Update cancelled');
      }
    }
  };
  componentDidMount() {
    this.setDefaults();
    this.getCardDetails();
    this.getAddressDetails();
    window.addEventListener('message', this.messageEventListener, false);
    document.body.classList.remove('remove-overflow');
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.planState != prevProps.planState ||
      this.props.plans != prevProps.plans
    ) {
      this.setDefaults();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.messageEventListener);
  }
  render() {
    const {
      toggle,
      plans,
      period_unit,
      interest,
      downgrade,
      alert
    } = this.state;
    const { subscription, logged_in_user_role, timezone } = this.props.org;
    const { stickyState } = this.props.stickyStateData || [];
    const currentPlan = subscription.cb_plan;
    return (
      <>
        {alert && alert.message ? <Alerts info={alert} /> : null}
        <AccountSuspension subscription={subscription} timezone={timezone} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            margin: '0 20px',
            gap: '10px',
            minHeight: '220px'
          }}
        >
          {!this.state.pageLoading ? (
            <>
              <ActivePlan
                org={this.props.org}
                plans={this.props.plans}
                onTry={this.props.onTry}
              />
              <StyledCardDetails>
                <div className="heading">Payment Information</div>
                <div className="field">
                  Card type :{' '}
                  <span
                    style={{ display: 'block', textTransform: 'uppercase' }}
                  >
                    {this.state.cardDetails.brand || '-'}
                  </span>
                </div>
                <div className="field">
                  Card number :{' '}
                  <span style={{ display: 'block' }}>
                    {this.state.cardDetails.masked_number || '-'}
                  </span>
                </div>
                <Button
                  inline
                  size="mini"
                  type="secondary"
                  onClick={() => {
                    this.updatePaymentMethod();
                    this.setState({
                      isDrawerOpen: true,
                      isUpdatingCard: true
                    });
                  }}
                >
                  {Object.keys(this.state.cardDetails).length !== 0
                    ? 'UPDATE CARD'
                    : 'ADD CARD'}
                </Button>
              </StyledCardDetails>
              {Object.keys(this.state.cardDetails).length !== 0 && (
                <StyledCardDetails>
                  <div className="heading">Address Information</div>
                  <div className="field" title={this.state.billing_address_str}>
                    Billing address :{' '}
                    <span>{this.state.billing_address_str || '-'}</span>
                  </div>
                  <div
                    className="field"
                    title={this.state.shipping_address_str}
                  >
                    Shipping address :{' '}
                    <span>{this.state.shipping_address_str || '-'}</span>
                  </div>
                  <Button
                    type="secondary"
                    inline
                    size="mini"
                    onClick={() => {
                      this.setState({
                        isDrawerOpen: true,
                        isUpdatingAddress: true
                      });
                      if (window._na && window._na_module) {
                        window._na.sendFeatureEvent('Update Address', window._na_module);
                      }
                    }}
                  >
                    {this.state.addressDetails &&
                    Object.keys(this.state.addressDetails).length
                      ? 'UPDATE ADDRESS'
                      : 'ADD ADDRESS'}
                  </Button>
                </StyledCardDetails>
              )}
              <Drawer
                onClose={() =>
                  this.setState({
                    isDrawerOpen: false,
                    isUpdatingAddress: false,
                    isUpdatingCard: false
                  })
                }
                isOpen={this.state.isDrawerOpen}
                width="800px"
              >
                {this.state.isUpdatingAddress && (
                  <AddressForm
                    closeDrawer={() =>
                      this.setState({
                        isDrawerOpen: false,
                        isUpdatingAddress: false
                      })
                    }
                    isAddressesSame={
                      this.state.shipping_address_str === '' ||
                      this.state.billing_address_str ===
                        this.state.shipping_address_str
                    }
                    address={this.state.addressDetails}
                    setAddressDetails={this.setAddressDetails}
                  ></AddressForm>
                )}
                {this.state.isUpdatingCard && (
                  <iframe
                    ref={this.iframeRef}
                    src={this.state.iframeUrl}
                    title="chargebee-payment"
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      padding: '20px 0'
                    }}
                  />
                )}
              </Drawer>
            </>
          ) : (
            <Loader>
              <div className="indicator">
                <svg width="16px" height="12px">
                  <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                  <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                </svg>
              </div>
            </Loader>
          )}
        </div>
        <Switcher>
          <hr />
          <div className="widget">
            <span
              className={period_unit === 'M' ? 'monthly active' : 'monthly'}
            >
              Monthly
            </span>
            <Switch on={toggle} onToggle={on => this.togglePlanType(on)} />
            <span
              className={period_unit === 'Y' ? 'annually active' : 'annually'}
            >
              Annually
            </span>
          </div>
        </Switcher>
        {interest && <Interest state={interest} />}
        {this.props.planState === 'loading' ? (
          <Loader>
            <div className="indicator">
              <svg width="16px" height="12px">
                <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
              </svg>
            </div>
          </Loader>
        ) : (
          <Fragment>
            { !(stickyState && stickyState[0] && stickyState[0].orgId === getOrgId() && stickyState[0].headerClosed) && 
            <UpgradeBanner id="upgrade-suspension">
                <div>
                  <img src={CautionCircle} alt="caution circle" style={{'padding': '2px 2px 18px 2px'}}/>
                </div>
                <></>
                <div style={{ 'border-left': '1px solid #fac386', 'padding-left': '8px', 'margin-right': '2%' }}>
                  The ability to upgrade to a plan has been suspended temporarily. We apologize for any inconvenience this may cause. 
                  If you have any questions or concerns, please visit our&nbsp;
                  <a href="https://support.freshping.io/en/support/solutions/articles/50000006524-suspension-of-new-signups-faqs" target="_blank" rel="noopener noreferrer"> 
                    help center 
                  </a>. Thank you for your understanding.
                  <StyledClose onClick={() => this.closeBanner()}>+</StyledClose>
                </div>
            </UpgradeBanner> }
            <AccountMigrationBanner>
              <div className='banner-header'>
                <img src={AnnouncementIcon} alt="" role='presentation' />
                <h2 className='banner-header-text'>Announcement: Account Transition Details</h2>
              </div>
              <div className='banner-content'>
                <p className='content-info'>
                  As of November 4, 2025, X will retire on March 1, 2025. If your account is up for renewal before that date, it will automatically transition to the free plan.
                </p>
                <h3 className='details-header'>
                  Key details:
                </h3>
                <ul className='details-list'>
                  {accountMigrationDetails.map((detail, index) => (
                    // adding index as key is not recommended, but it's fine for this case since the list is static
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
                <p className='content-info'>Thank you for your understanding as we make this transition.</p>              
              </div>
            </AccountMigrationBanner>
            <Plans>
              {plans.map((plan, i) => (
                <PlanCard
                  isTooltip={true}
                  plan={plan}
                  currentPlan={currentPlan}
                  toggle={toggle}
                  period_unit={period_unit}
                  subscription={subscription}
                  onTry={this.props.onTry}
                  owner={logged_in_user_role !== 'ST'}
                  triggerModal={this.triggerModal}
                  key={`plan-${i}`}
                  downgradePlan={this.onDowngrade}
                  switchTrial={this.switchTrial}
                />
              ))}
            </Plans>
            <Conditions>
              *You may cancel your subscription or trial at any time by
              contacting support@freshping.io. Partial refunds are not
              available.
            </Conditions>
          </Fragment>
        )}
        {downgrade ? (
          <DowngradeModal
            org={this.props.org}
            plans={this.props.plans}
            newplan={this.state.newplan}
            downgradePlan={this.downgradePlan}
            closeDowngrade={this.closeDowngrade}
            limit={this.state.limit}
          />
        ) : null}
      </>
    );
  }
}
const UpgradeBanner = styled.div`
  border: 1px solid #FAC386;
  border-radius: 4px;
  gap: 8px;
  padding: 6px 8px;
  background: #FEF1E1;
  width: 90%;
  height: 48px;
  display: flex;
  place-items: center;
  position: relative;
  margin-left: 5%;
  font-size: 14px;
`
const AccountMigrationBanner = styled.div`
  background-color: #FEECD5;
  padding: 24px;
  color: #27313B;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  margin: 0 24px;

  .banner-header {
    display: flex;
    gap: 4px;
    margin-bottom: 8px;

    .banner-header-text {
      margin: 0;
      color: #27313B;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .banner-content {
    .content-info {
      margin: 0 0 8px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .details-header {
      margin: 0;
      color: #27313B;
      font-size: 14px;
    }

    .details-list {
      padding-left: 24px;
      margin-bottom: 8px;
    }
  }
`;

const StyledClose = styled.span`
  position: absolute;
  right: 7px;
  bottom: 18px;
  cursor: pointer;
  transform: rotate(45deg);
  font-size: 23px;
`
const mapStateToProps = state => ({
  interest: state.app.interest
});
const mapDispatchProps = dispatch => ({
  toggleInterestModal: state => dispatch(toggleInterestModal(state)),
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg())
});
export default withRouter(
  withHooksDataHOC(
  connect(mapStateToProps, mapDispatchProps)(Pricingplans),
  'upgrade-suspension-header'
  )
);