import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ExploreHeader from "./ExploreHeader";
import ExploreFeatureCards from "./ExploreFeatureCards";
import ExploreCards from "../containers/ExploreCards";
import { getOrgId } from "../utils/utility";
import { getCurrentUser } from "../actions";

const ExploreMain = props => {
  const orgs = props.user.org;
  const currentOrg = getOrgId();
  const myOrg = orgs && orgs.find(org => org.id === Number(currentOrg));
  return (
    <Container>
      <ExploreHeader
        userDetails={props.user.user}
        orgName={myOrg ? myOrg.name : ""}
      />
      <JustText>Most Popular Products</JustText>
      <ExploreFeatureCards
        userDetails={props.user.user}
        orgName={myOrg ? myOrg.name : ""}
        getCurrentUser={props.getCurrentUser}
      />
      <ExploreCards
        userDetails={props.user.user}
        getCurrentUser={props.getCurrentUser}
      />
    </Container>
  );
};

const JustText = styled.div`
  font-size: 22px;
  font-weight: 500;
  padding: 20px 0;
  text-align : center;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 64px);
  flex-direction: column;
  margin: 20px 40px 40px;
`;

const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchProps = dispatch => ({
  getCurrentUser: () => dispatch(getCurrentUser())
});
export default connect(mapStateToProps, mapDispatchProps)(ExploreMain);
