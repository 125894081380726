import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'freemium-ui';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import BadgeBlank from '../images/statuspage-empty.svg';
import { Copy } from '../components/Icons';
import { getBadges, deleteBadge, toggleInterestModal } from '../actions';
import { StyledLoading } from '../components/styled/Loading';
import BadgeListItem from './BadgeListItem';
import StyledEndList from '../components/styled/EndList';
import '../styles/status.css';
import { BADGE_PUBLIC_URL } from '../config';
import { badgeAllowedPlans, badgeAllowedAddons } from '../constants/badge';
import { getOrgId } from '../utils/utility';

const mapDispatchToProps = dispatch => ({
  getBadges: id => dispatch(getBadges(id)),
  deleteBadge: badge => dispatch(deleteBadge(badge)),
  toggleInterestModal: state => dispatch(toggleInterestModal(state))
});

const mapStateToProps = state => ({
  badges: state.badges,
  org: state.user.org
});

const HeadContent = () => {
  return (
    <React.Fragment>
      <div className="badge-titlebar">
        <div className="badge-titlebar-name">Name</div>
        <div className="badge-titlebar-image">Your badge</div>
        <div className="badge-titlebar-code">Embed code</div>
      </div>
    </React.Fragment>
  );
};
class Badge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGetBadgeModalOpen: false,
      isCopyCodeClicked: false,
      isImageLoading: true
    };
  }
  componentDidMount() {
    this.props.getBadges();
  }
  captureInterest = type => {
    const payload = {
      state: true,
      type
    };
    this.props.toggleInterestModal(payload);
  };

  requestBadgeAccess = () => {
    this.props.toggleInterestModal({ state: true, type: 'freebadgeaccess' });
  };
  isBadgeAccessible = orgs => {
    const currentOrg = getOrgId();
    const myOrg = orgs.find(org => org.id === Number(currentOrg));
    const currentPlan = myOrg.subscription.cb_plan;
    let currentAddons = myOrg.subscription.addons;
    currentAddons = currentAddons.map(current => {
      return current.cb_id;
    });
    if (
      badgeAllowedPlans.find(plan => {
        return plan === currentPlan;
      })
    ) {
      return true;
    }
    const isAddonFound = badgeAllowedAddons.some(addon =>
      currentAddons.includes(addon)
    );
    return isAddonFound;
  };

  handleImageLoading = () => {
    if (this.state.isImageLoading) this.setState({ isImageLoading: false });
  };

  render() {
    const {
      badges: { appState, badges }, //badgeTicketUrl
      url
    } = this.props;
    // const orgId = Number(getOrgId());
    var badgeTicket = false;
    // var ticketArr = JSON.parse(window.localStorage.getItem("badge-ticket-url"))
    // if(ticketArr) {
    // 	for(var count=0;count<ticketArr.length;count++) {
    // 		if(ticketArr[count].orgId === orgId) {
    // 			badgeTicket = ticketArr[count].ticketUrl
    // 			break
    // 		}
    // 		else {
    // 			badgeTicket = false
    // 		}
    //   }
    // }
    const copyCode = `<a href="https://freshworks.com/website-monitoring" target="_blank"><img src="${BADGE_PUBLIC_URL}"/> </a>`;

    return (
      <div className="status-container">
        {appState !== 'success' || this.props.org === undefined ? (
          <StyledLoading />
        ) : (
          <div>
            {badges.count ? (
              <StyledStatusList>
                <HeadContent />
                {badges.results.map(badge => (
                  <BadgeListItem
                    url={url}
                    badge={badge}
                    key={badge.id}
                    deleteBadge={this.props.deleteBadge}
                  />
                ))}
                <StyledEndList />
              </StyledStatusList>
            ) : this.isBadgeAccessible(this.props.org) ? (
              <StyledBlankStatus>
                <div className="blank-placeholder">
                  <img src={BadgeBlank} alt="Blank Badge Page" />
                </div>
                <Link to={`${url}/new`} href={`${url}/new`} className="link">
                  Create New Badge
                </Link>
              </StyledBlankStatus>
            ) : (
              <React.Fragment>
                <StyledStatusList>
                  <HeadContent />
                  <section className="status-page-content">
                    <Row>
                      <div className="hello">
                        <div className="badge-name">Freshworks</div>
                        <div className="badge-img">
                          {this.state.isImageLoading && (
                            <Skeleton width={150} />
                          )}
                          <img
                            src={`${BADGE_PUBLIC_URL}/badgeimage/round_AVL_CS?theme=dark`}
                            onLoad={this.handleImageLoading}
                            onError={this.handleImageLoading}
                            alt="Your Badge"
                          />
                        </div>
                        <TextareaContainer>
                          <code>{copyCode}</code>
                        </TextareaContainer>
                        <span className="copy">
                          {' '}
                          <Copy />{' '}
                        </span>
                      </div>
                      {badgeTicket && badgeTicket.length ? (
                        <div className="ticket-created">
                          <button className="processing">Processing</button>
                          <div style={{ textAlign: 'center' }}>
                            Track your request{' '}
                            <a
                              href={badgeTicket}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              here
                            </a>
                          </div>
                        </div>
                      ) : (
                        <Button
                          inline
                          type="primary"
                          onClick={this.requestBadgeAccess}
                        >
                          Unlock Free Badge
                        </Button>
                      )}
                    </Row>
                  </section>
                </StyledStatusList>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Badge);

const Row = styled.div`
  .ticket-created {
    font-size: 12px;
    color: #3b3b3b;
    min-width: 145px;
    width: 200px;
    margin-left: 15px;
  }
  .processing {
    border-radius: 11.5px;
    border: solid 0.5px var(--border-warning-color);
    background-color: var(--bg-warning);
    padding: 2px 12px 3px 12px;
    width: fit-content;
    margin-bottom: 4px;
    margin-left: 22px;
    cursor: auto;
  }

  .lock-button {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    left: 579px;
  }

  .copy {
    padding: 5px;
    cursor: pointer;
  }
  .badge-name {
    font-weight: 500;
    color: #4a4a4a;
    line-height: 20px;
    text-transform: capitalize;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .badge-img {
    width: 182px;
  }
  .hello {
    display: inline-flex;
    align-items: center;
    opacity: 0.5;
    pointer-events: none;
    width: inherit;
  }
  display: flex;
  padding: 10px;
  align-items: center;
  max-height: 56px;
  position: relative;
  justify-content: space-between;
`;

const TextareaContainer = styled.div`
  flex: 2;
  padding: 6px 10px 10px 10px;
  border: 1px solid #e7e7e7;
  max-height: 35px;
  overflow: hidden;
  code {
    opacity: 0.5;
    color: #444;
    resize: none;
  }
`;

const StyledStatusList = styled.div`
  .badge-list {
    opacity: 0.5;
    pointer-events: none;
  }
  .empty {
    margin-right: 10px;
    width: 168px;
  }
  .public-status-page-wrapper {
    width: 100%;
    background-color: rgb(248, 249, 251);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px;
    z-index: 1;
    padding: 0;
    margin: 0 auto;
    margin-bottom: 30px;
    position: relative;
    border-bottom: 1px solid rgb(224, 228, 234);
  }
  .public-status-page {
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 80%;
    .heading {
      margin: 0;
    }
  }
  .status-page-content {
    background-color: #f9f9f9;
    padding: 0;
    max-width: 80%;

    .row-title {
      display: flex;
      padding: 5px 20px;
      background: #fff;
      min-height: 85px;
      box-sizing: border-box;
      transition: all 100ms linear;
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px;
      border-radius: 3px;
    }
    .row-title:hover {
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .row-content {
      padding: 15px 20px;
      background: #f9f9f9;
      border-bottom: solid 0.5px #dddddd;
    }
  }
`;

const StyledBlankStatus = styled.div`
  text-align: center;

  .link {
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 300;
    border-radius: 3px;
    background-color: var(--bg-dark);
    cursor: pointer;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.17);
  }
  .blank-placeholder {
    margin-top: 100px;
    margin-bottom: 30px;
  }
`;
