import React from "react";
import styled from "styled-components";
import BadgeCreatePreview from "../images/badge_preview.svg";
import BadgeFooterPreview from "../images/badge_footer_preview.svg";
import { BADGE_PUBLIC_URL } from "../config";

const url = `${BADGE_PUBLIC_URL}/badgeimage/`;

const constructUrl = (badgeformData, isTypeError, isCheckError) => {
  if (isTypeError) {
    return `${url}${badgeformData.badge_meta.shape}_AVL_CS?theme=${badgeformData.badge_meta.theme}&type=nodata`;
  }
  let badgeOptions = badgeformData.metric_types.reduce(
    (result, current) => `${result}_${current}`
  );
  badgeOptions = `${badgeformData.badge_meta.shape}_${badgeOptions}?theme=${badgeformData.badge_meta.theme}&days=${badgeformData.no_of_days_since}`;
  if (isCheckError) {
    return `${url}${badgeOptions}&type=nodata`;
  }
  return url + badgeOptions;
};

const BadgePreview = ({ badgeform, badgeformErrors }) => (
  <BadgeWrapper>
    <div className="preview-title">Badge Preview</div>
    <Badge theme={badgeform.data.badge_meta.theme}>
      <div className="header">
        <span className="action-buttons" />
        <span className="action-buttons" />
        <span className="action-buttons" />
      </div>
      <img className="image" src={BadgeCreatePreview} alt="create" />
      <div className="preview-main">
        <img
          src={constructUrl(
            badgeform.data,
            badgeformErrors.isTypeError,
            badgeformErrors.isCheckError
          )}
          alt="badge"
        />
        <div className="preview-footer">
          <img src={BadgeFooterPreview} alt="Footer" />
        </div>
      </div>
    </Badge>
  </BadgeWrapper>
);
const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: calc(100% - 440px);
  height: 80%;
  .preview-title {
    padding: 5px 0;
    font-size: 18px;
    font-weight: 500;
    color: #4b4b4b;
    margin: 0 5%;
  }
`;
const Badge = styled.div`
  .header {
    width: auto;
    height: 40px;
    border-bottom: solid 1px #dcdcdc;
    background-image: linear-gradient(to bottom, #ffffff, #f3f3f3);
  }
  .action-buttons {
    border-radius: 100px;
    width: 10px;
    height: 10px;
    background-color: #b9b9b9;
    margin: 3px;
  }
  .image {
    margin: 23px auto;
    display: block;
  }
  .preview-footer {
    padding: 0 25px;
    opacity: ${props => (props.theme !== "dark" ? "0.1" : "1")};
  }
  .preview-main {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 70px 40px 30px 50px;
    background-color: ${props =>
      props.theme === "dark" ? "#ebebeb" : "#2d2d2d"};
  }
  border: 1px solid #e0dede;
  background-color: #ffffff;
  width: 90%;
  margin: 0 auto;
`;

export default BadgePreview;
