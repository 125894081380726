import React from 'react';
import styled from 'styled-components';

import CautionOutline from '../images/caution_outline.svg';

const MigrationBanner = () => {
  return (
    <StyledMigrationBanner>
      <div className="caution-icon">
        <img src={CautionOutline} alt="" role="presentation" />
      </div>
      <p className="content">
        Freshping will retire on March 1, 2025; your data will be safe for
        export in the next 30 days, after which your account will be closed..
      </p>
      <a
        className="banner-link"
        href="#"
        target="_blank"
        rel="noopener noreferrer"
      >
        Know More
      </a>
    </StyledMigrationBanner>
  );
};

export default MigrationBanner;

const StyledMigrationBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px;
  font-size: 14px;
  font-weight: 700;
  background-color: #ffe9ee;
  color: #27313b;
  box-shadow: inset 0px -1px 0px #b0d5fc;
  margin-bottom: 8px;

  .caution-icon {
    width: 20px;
    height: 20px;
  }

  .content {
    margin: 0;
  }

  .banner-link {
    color: #2e66d0;
  }
`;
