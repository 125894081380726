import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'freemium-ui';
import Skeleton from 'react-loading-skeleton';
import { toastr } from 'react-redux-toastr';
import '../styles/Checks.css';
import up from '../images/up_2.svg';
import down from '../images/down_2.svg';
import pause from '../images/pause.svg';
import close from '../images/close.svg';
import PropTypes from 'prop-types';
import AreaChart from '../charts/AreaSplineChart';
import Spinner from '../components/styled/Spinner';
import SingleBarChart from '../charts/SingleBarChart';
import { addPlaceholdersInChartData } from '../utils/utility';
import { request } from '../utils/Api';
import styled from 'styled-components';
import { toggleCheckDrawer, getCurrentUserOrg } from '../actions';

class RowData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: '',
      statusColumn: '',
      isLoaded: false,
      toggleDeleteCheckModal: false,
      checksList: []
    };
  }

  onRestartMonitor = (e, result) => {
    e.stopPropagation();
    const toastrConfirmOptions = {
      component: () => {
        const { check } = this.props;
        return (
          <div className="delete-confirm__message">
            <p>
              Check name<span>{check.name}</span>
            </p>
            <p>
              Check URL
              <span className="delete-check-url">
                {JSON.parse(check.data).url}
              </span>
            </p>
          </div>
        );
      },
      okText: 'Restart Monitoring',
      cancelText: 'Cancel',
      onOk: () =>
        this.startMonitorCheck(
          this.props.check.application,
          this.props.check.id,
          this.props.check.name
        )
    };

    toastr.confirm('Restart Monitoring Confirmation', toastrConfirmOptions);
  };

  onPauseMonitor = (e, result) => {
    e.stopPropagation();
    const toastrConfirmOptions = {
      component: () => {
        const { check } = this.props;
        return (
          <div className="delete-confirm__message">
            <p>
              Check name<span>{check.name}</span>
            </p>
            <p>
              Check URL
              <span className="delete-check-url">
                {JSON.parse(check.data).url}
              </span>
            </p>
          </div>
        );
      },
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () =>
        this.pauseMonitorCheck(
          this.props.check.application,
          this.props.check.id,
          this.props.check.name
        )
    };

    toastr.confirm('Pause Monitoring Confirmation', toastrConfirmOptions);
  };

  startMonitorCheck = async (application, id, name) => {
    let data = {
      application: application,
      application_check: id,
      new_state: 'AC'
    };
    await request(`/pause-monitoring/`, { method: 'POST', payload: data });
    this.props.setFilterState({ status: this.props.filterParams.status });
    this.props.getCurrentUserOrg();
    toastr.success(`${name} started monitoring successfully`);

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Restart Monitoring', window._na_module);
    }
  };

  pauseMonitorCheck = async (application, id, name) => {
    let data = {
      application: application,
      application_check: id,
      new_state: 'PS'
    };
    await request(`/pause-monitoring/`, { method: 'POST', payload: data });
    this.props.setFilterState({ status: this.props.filterParams.status });
    this.props.getCurrentUserOrg();
    toastr.success(`${name} paused monitoring successfully`);

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Pause Monitoring', window._na_module);
    }
  };

  onEditCheck = (e, result) => {
    e.stopPropagation();
    if (window.fcWidget) {
      window.fcWidget.hide();
    }

    this.props.toggleCheckDrawer({
      isOpen: true,
      check: result
    });
  };

  openDeleteCheckModal = (e, result) => {
    e.stopPropagation();
    const toastrConfirmOptions = {
      component: () => {
        const { check } = this.props;
        return (
          <div className="delete-confirm__message">
            <p>
              Check name<span>{check.name}</span>
            </p>
            <p>
              Check URL
              <span className="delete-check-url">
                {JSON.parse(check.data).url}
              </span>
            </p>
          </div>
        );
      },
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => this.deleteCheck(this.props.check.id, this.props.check.name)
    };

    toastr.confirm('Delete Check Confirmation', toastrConfirmOptions);
  };

  viewReport = (e, result) => {
    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('View Report', window._na_module);
    }

    this.context.router.history.push('/reports?check_id=' + result.check_id);
  };

  closeDeleteCheckModal = () => {
    this.setState({ toggleDeleteCheckModal: false });
  };

  deleteCheck = async (id, name) => {
    await request(`/checks/${id}/`, {
      method: 'DELETE'
    });
    this.props.setFilterState({ page: null }); // ({ status: this.props.filterParams.status });
    this.props.getCurrentUserOrg();
    toastr.success(`${name} deleted successfully`);

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Delete Check', window._na_module);
    }
  };

  componentDidMount() {
    this.setCheckDefaults();
    this.getResponeTimeGraphData();
  }

  setCheckDefaults = () => {
    var self = this;
    request(`/check-stats-reports/${this.props.CheckId}/?for_hours=24`)
      .catch(error => console.error('Error-->:', error))
      .then(function(response) {
        self.setState({
          result: response,
          isLoaded: true
        });
      });
  };

  componentDidUpdate(prevProps) {
    const prevStatus = prevProps.check.statuses;
    const currentStatus = this.props.check.statuses;
    if (
      prevStatus &&
      currentStatus &&
      currentStatus.appState !== prevStatus.appState
    ) {
      this.setCheckDefaults();
    }
  }

  getResponeTimeGraphData() {
    var self = this;
    request(
      `/check-response-times-reports/${this.props.CheckId}/?for_hours=24&aggregate_by=hours`
    )
      .catch(error => console.error('Error-->:', error))
      .then(function(response) {
        const maxDataLength = 24;
        self.setState({
          graphResult:
            response.response_times.length < maxDataLength
              ? addPlaceholdersInChartData(response, maxDataLength)
              : response
        });
      });
  }

  selectedCheck = event => {
    event.stopPropagation();
    const { id, checked } = event.target;
    this.props.updateChecks(Number(id), checked);
    return false;
  };

  render() {
    var result = this.state.result ? this.state.result : '';
    const checkData = this.props.check.statuses;
    const { checked, check, deviceSize } = this.props;
    const error =
      checkData &&
      ['NR', 'RE', 'DME', 'SME', 'SCE'].indexOf(checkData.state) > -1;
    return (
      <div className="table-body">
        <Divider className={deviceSize}>
          <div
            className="Row Check-item GARDEN"
            key={this.props.CheckId}
            onClick={e => this.viewReport(e, result)}
          >
            {deviceSize && deviceSize === 'device-large' ? (
              <div className="Column checkbox">
                <StyledCheckbox
                  id={this.props.CheckId}
                  onClick={this.selectedCheck}
                >
                  <input
                    id={this.props.CheckId}
                    onClick={this.selectedCheck}
                    type="checkbox"
                    name="checks"
                    checked={checked}
                  />
                  <span className="checkbox-custom rectangular" />
                </StyledCheckbox>
              </div>
            ) : null}
            <div className="Column" style={{ flexDirection: 'column' }}>
              {this.props.check.name && (
                <span className="check-name">{this.props.check.name}</span>
              )}
              {this.state.result ? (
                <span
                  className="check-url"
                  title={check.data ? JSON.parse(check.data).url : ''}
                >
                  <a
                    href={check.data ? JSON.parse(check.data).url : ''}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                  >
                    {check.data ? JSON.parse(check.data).url : ''}
                  </a>
                </span>
              ) : (
                <span className="check-url mt-small">
                  <Skeleton width={170} />
                </span>
              )}
            </div>
            {checkData ? (
              <React.Fragment>
                {checkData.state === 'AV' ? (
                  <div className="Column status-col">
                    <img src={up} alt="up icon" className="up-status-img" />
                  </div>
                ) : checkData.state === 'PS' ? (
                  <div className="Column status-col">
                    <img
                      src={pause}
                      alt="pause icon"
                      className="pause-status-img"
                    />
                  </div>
                ) : error ? (
                  <div className="Column status-col">
                    <img
                      src={down}
                      alt="down icon"
                      className="down-status-img"
                    />
                  </div>
                ) : checkData.appState === 'loading' ||
                  checkData.appState === 'reloading' ||
                  checkData.appState === 'failure' ? (
                  <div className="Column status-col">
                    <Spinner width={20} />
                  </div>
                ) : (
                  <div className="Column status-col circle-skeleton">
                    <Spinner width={20} />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className="Column status-col circle-skeleton">
                <Spinner width={20} />
              </div>
            )}
            <div className="Column">
              {this.state.result ? (
                <React.Fragment>
                  {result.availability_percentage
                    ? parseFloat(
                        Number(result.availability_percentage).toFixed(2)
                      )
                    : '0'}
                  %
                </React.Fragment>
              ) : (
                <Skeleton width={40} />
              )}
            </div>
            <div className="Column availability-col">
              {this.state.result ? (
                <div>
                  {result.duration_seconds_total_downtime
                    ? Math.round(result.duration_seconds_total_downtime / 60) +
                      ' min'
                    : '0 min'}
                  <span className="sub-text">
                    {result.availability_percentage
                      ? ' (' +
                        parseFloat(
                          (
                            100 - Number(result.availability_percentage)
                          ).toFixed(2)
                        ) +
                        '%)'
                      : ''}
                  </span>
                </div>
              ) : (
                <Skeleton width={80} />
              )}
            </div>
          </div>
          {deviceSize && deviceSize === 'device-large' ? (
            <div
              className="Row Check-item GARDEN"
              onClick={e => this.viewReport(e, result)}
            >
              <div className="Column" style={{ width: '24%' }}>
                {this.state.result ? (
                  <React.Fragment>
                    {result.apdex_score ? result.apdex_score : '0'}
                  </React.Fragment>
                ) : (
                  <Skeleton width={40} />
                )}
              </div>
              <div className="Column" style={{ width: '25%' }}>
                {this.state.result ? (
                  <React.Fragment>
                    {result.average_response_time_milliseconds_available
                      ? result.average_response_time_milliseconds_available
                      : '0'}
                    &nbsp;ms
                  </React.Fragment>
                ) : (
                  <Skeleton width={40} />
                )}
              </div>
              <div
                className="Column dashboard-graph col-5"
                onClick={e => e.stopPropagation()}
                style={{ width: '50%' }}
              >
                {this.state.graphResult ? (
                  <div className="dashboard-chart-container">
                    <AreaChart
                      graphResult={this.state.graphResult}
                      isReportsPage={false}
                      checkId={this.props.CheckId}
                      checkDetails={result}
                    />
                    <SingleBarChart
                      graphResult={this.state.graphResult}
                      isReportsPage={false}
                      checkId={this.props.CheckId}
                    />
                  </div>
                ) : (
                  <Skeleton count={3} width={180} />
                )}
                {this.props.isAdmin && (
                  <div className="row-menu">
                    <span className="three-dots" />
                    <ul className="dropdown-content">
                      {checkData && checkData.state === 'PS' ? (
                        <li onClick={e => this.onRestartMonitor(e, result)}>
                          Restart Monitoring
                        </li>
                      ) : (
                        <li onClick={e => this.onPauseMonitor(e, result)}>
                          Pause Monitoring
                        </li>
                      )}
                      <li onClick={e => this.onEditCheck(e, check)}>
                        Edit check
                      </li>
                      <li onClick={e => this.openDeleteCheckModal(e, result)}>
                        Delete check
                      </li>
                      <li onClick={e => this.viewReport(e, result)}>
                        View report
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </Divider>
        <div
          className="modal delete-user delete-check-modal"
          style={
            this.state.toggleDeleteCheckModal
              ? { display: 'block' }
              : { display: 'none' }
          }
        >
          <div className="modal-content">
            <img
              src={close}
              alt="close icon"
              className="close-image"
              onClick={this.closeDeleteCheckModal}
            />
            <div className="modal-header">
              <h4>Confirm deletion</h4>
            </div>
            <div className="modal-body">
              <p>
                Check name<span>{this.props.check.name}</span>
              </p>
              <p>
                Check URL
                <span className="delete-check-url">
                  {this.state.deleteCheckURL}
                </span>
              </p>
            </div>
            <div className="modal-footer">
              <p>Do you want to delete check?</p>
              <div>
                <Button
                  type="secondary"
                  inline
                  onClick={this.closeDeleteCheckModal}
                >
                  Cancel
                </Button>
                <Button inline type="primary" onClick={this.deleteCheck}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RowData.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapDispatchProps = dispatch => ({
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg()),
  toggleCheckDrawer: state => dispatch(toggleCheckDrawer(state))
});

export default connect(null, mapDispatchProps)(RowData);

const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: transparent;

  .Row.GARDEN:first-child .status-col {
    width: 20% !important;
  }

  .Row {
    border: none !important;
    border-bottom: 1px solid #f0f0f0 !important;

    &:first-child {
      width: 54%;
      margin: 0;

      .checkbox,
      > div:first-child {
        width: 50px !important;
      }

      > div:nth-child(2) {
        width: 30% !important;
      }

      .status-col {
        width: 22% !important;
        span {
          margin: 0;
        }
      }

      .table-body .Row > div {
        width: 21%;
      }
    }

    &:last-child {
      width: 46%;
      margin: 0;
    }

    &.default {
      &:first-child {
        width: 60%;
        margin: 0;
      }
      .availability-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22% !important;
      }

      &:last-child {
        width: 40%;
        margin: 0;
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }

  &.device-small .Row:first-child,
  &.device-small .Row:last-child {
    width: 100%;

    > div {
      width: 20% !important;
      text-align: center;
      display: inline-block;
    }

    > div:first-child {
      width: 40% !important;
      margin-top: 24px;
      margin-bottom: 5px;
      text-align: left;
      padding-left: 5px;
    }
  }
`;

export const StyledCheckbox = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 16px;
  width: 16px;
  clear: both;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkbox-custom {
      background-color: var(--text-link);
      border-radius: 3px;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 1px solid var(--text-link);
      &:after {
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        opacity: 1;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        background-color: transparent;
        border-radius: 0;
      }
    }
  }

  .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 16px;
    width: 16px !important;
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid #b8b8b8 !important;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    &:after {
      position: absolute;
      content: '';
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 3px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity: 1;
    }
  }
`;
