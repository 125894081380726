import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router";
import StatusActions from "../components/StatusActions";
import { Copy } from "../components/Icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BADGE_PUBLIC_URL } from "../config";
import Skeleton from "react-loading-skeleton";

class BadgeListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopyButtonClicked: false,
      isImageLoading: true
    };
  }
  statusAction = selected => {
    const { badge, url } = this.props;
    if (selected.label === "Edit") {
      this.props.history.push(`${url}/${badge.id}`);
    }
    if (selected.label === "Delete") {
      this.props.deleteBadge(badge);

      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Delete Badge', window._na_module);
      }
    }
  };

  onCopyClick = () => {
    this.setState({ isCopyButtonClicked: true });
    setTimeout(() => {
      this.setState({ isCopyButtonClicked: false });
    }, 3000);

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Copy Badge Code', window._na_module);
    }
  };

  handleImageLoading = () => {
    if (this.state.isImageLoading) this.setState({ isImageLoading: false });
  };

  render() {
    const { badge } = this.props;
    const path = `${BADGE_PUBLIC_URL}/badge/${badge.id}?${Math.random()}`;
    const copyCode =
      '<a href="' +
      badge.data.target_url +
      '" target="_blank"><img src="' +
      path +
      '"/> </a>';
    return (
      <section className="status-page-content">
        <Row isCopyButtonClicked={this.state.isCopyButtonClicked}>
          <div className="badge-name" title={badge.name}>
            {badge.name}
          </div>
          <div className="badge-img">
            {this.state.isImageLoading && <Skeleton width={150} />}
            <img
              src={path}
              onLoad={this.handleImageLoading}
              onError={this.handleImageLoading}
              alt="Your Badge"
            />
          </div>
          <TextareaContainer>
            <code>{copyCode}</code>
          </TextareaContainer>
          <CopyToClipboard text={copyCode}>
            <button className="copy" onClick={this.onCopyClick}>
              {" "}
              <Copy /> <span className="copy-ack">Copied!</span>{" "}
            </button>
          </CopyToClipboard>

          <div className="statuspage-action badge-action">
            <StatusActions onChange={this.statusAction} />
          </div>
        </Row>
      </section>
    );
  }
}

export default withRouter(BadgeListItem);

const Row = styled.div`
  .copy {
    border: 1px solid #e7e7e7;
    background-color: inherit;
    padding: 5.5px;
    position: relative;
  }
  .copy-ack {
    position: absolute;
    border-radius: 10px;
    top: -25px;
    right: -17px;
    padding: 2px 10px;
    background-color: #fff6eb;
    font-size: 12px;
    visibility: ${props => (props.isCopyButtonClicked ? "visible" : "hidden")};
  }

  .badge-name {
    font-weight: 500;
    color: #4a4a4a;
    line-height: 20px;
    text-transform: capitalize;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 25px;
  }
  .badge-img {
    width: 182px;
  }
  display: flex;
  padding: 10px;
  align-items: center;
`;

const TextareaContainer = styled.div`
  flex: 2;
  padding: 6px 10px 10px 10px;
  border: 1px solid #e7e7e7;
  max-height: 35px;
  overflow: hidden;
  word-break: break-all;
  code {
    opacity: 0.5;
    font-size: 13px;
    color: #444;
    resize: none;
  }
`;
