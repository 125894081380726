/* eslint-disable react/forbid-prop-types */
import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import OutageCard from 'components/OutageCard';

import { OutageAnalysisContainer, Header, OutageList, Center } from './styles';
import { useStateChangeAnalysis } from './hooks';

const getReadableDuration = temp => {
  let result = '';
  result += temp.years()
    ? temp.years() + (temp.years() > 1 ? ' yrs ' : ' yr ')
    : '';
  result += temp.months()
    ? temp.months() + (temp.months() > 1 ? ' mos ' : ' mo ')
    : '';
  result += temp.days()
    ? temp.days() + (temp.days() > 1 ? ' days ' : ' day ')
    : '';
  result += temp.hours()
    ? temp.hours() + (temp.hours() > 1 ? ' hrs ' : ' hr ')
    : '';
  result += temp.minutes()
    ? temp.minutes() + (temp.minutes() > 1 ? ' mins' : ' min')
    : '';
  if (!result.length) {
    result += temp.seconds() ? `${temp.seconds()} secs` : '';
  }
  return result;
};

const calculateDowntimeDuration = (n, c) => {
  const next = moment(n);
  const current = moment(c);
  if (!next.isValid()) {
    return `Ongoing - (${getReadableDuration(
      moment.duration(moment(new Date()).diff(current, 'seconds'), 'seconds')
    )})`;
  }
  const duration = next.diff(current, 'seconds');
  const tempDuration = moment.duration(duration, 'seconds');
  return getReadableDuration(tempDuration);
};

const OutageAnalysis = props => {
  const { outageAnalysisData, org, selectedCheck } = props;
  const {
    data: stateChangeAnalysis = {},
    isError: isStateChangeAnalysisError,
    isLoading: isLoadingStateChangeAnalysis
  } = useStateChangeAnalysis(outageAnalysisData.based_on);

  const { name } = selectedCheck;

  if (isStateChangeAnalysisError)
    return (
      <OutageAnalysisContainer>
        <Center>Network Error</Center>
      </OutageAnalysisContainer>
    );

  if (isLoadingStateChangeAnalysis)
    return (
      <OutageAnalysisContainer>
        <Center>
          <Loader />
        </Center>
      </OutageAnalysisContainer>
    );

  const errorName = get(
    stateChangeAnalysis,
    ['probe_second', 'error_summary'],
    get(stateChangeAnalysis, ['probe_first', 'error_summary'], '')
  );

  return (
    <OutageAnalysisContainer>
      <Header>
        <div>
          <div className="heading">Outage Analysis</div>
          <div className="summary">
            {`${name || ''}${errorName ? ` - ${errorName}` : ''}`}
          </div>
        </div>
        <div>
          <div className="duration">Duration</div>
          <div className="interval">
            {stateChangeAnalysis.check_start_time
              ? calculateDowntimeDuration(
                  stateChangeAnalysis.next_state_change_time,
                  stateChangeAnalysis.check_start_time
                )
              : ''}
          </div>
        </div>
      </Header>
      <OutageList>
        <OutageCard
          org={org}
          stateChangeAnalysis={stateChangeAnalysis}
          probeData={get(stateChangeAnalysis, ['probe_first'], {})}
          name="Outage Started"
        />
        <OutageCard
          org={org}
          stateChangeAnalysis={stateChangeAnalysis}
          probeData={get(stateChangeAnalysis, ['probe_second'], {})}
          name="Outage Confirmed"
        />
      </OutageList>
    </OutageAnalysisContainer>
  );
};

OutageAnalysis.propTypes = {
  org: PropTypes.any,
  selectedCheck: PropTypes.any,
  outageAnalysisData: PropTypes.any
};
OutageAnalysis.defaultProps = {
  org: {},
  selectedCheck: {},
  outageAnalysisData: {}
};

export default OutageAnalysis;
