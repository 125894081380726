import React from "react";
export default function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  React.useEffect(() => {
    if (value) window.localStorage.setItem(key, JSON.stringify(value));
  }, [value]);
  React.useEffect(() => {
    const stickyValue = window.localStorage.getItem(key);
    setValue(JSON.parse(stickyValue));
  }, [key]);
  return [value, setValue];
}
