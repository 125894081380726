import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import logo from "../images/logo.svg";
import suspended from "../images/accountsuspended.svg";
import { getOrgId } from "../utils/utility";
import { request } from "../utils/Api";

class AccountPausedCard extends React.Component {
  activate = async () => {
    const payload = {
      state: "AC"
    };

    const response = await request(`/organizations/${getOrgId()}`, {
      method: "PATCH",
      payload
    });

    if (response) {
      window.location.reload();
    }
  };

  render() {
    return (
      <AccountSuspendedWrapper>
        <SuspensionLayout>
          <img
            className="appLogo"
            src={logo}
            width="200px"
            height="51px"
            alt="freshping logo"
          />
          <img
            className="suspendedimg"
            src={suspended}
            width="277px"
            height="212px"
            alt="account suspended"
          />
          <h2>Account paused</h2>
          <p>This account has been paused due to no login in past 6 months</p>
          <ActionContainer>
            <button
              type="button"
              className="primary-btn"
              onClick={() => this.activate(getOrgId())}
            >
              Activate Now
            </button>
          </ActionContainer>
        </SuspensionLayout>
      </AccountSuspendedWrapper>
    );
  }
}

const mapStateToProps = state => ({
  org: state.user.org
});

export default connect(
  mapStateToProps
)(AccountPausedCard);

const ActionContainer = styled.div`
  text-align: center;
  button {
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    user-select: none;
    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.05);
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-right: 10px;
  }
`;

const SuspensionLayout = styled.div`
  width: 635px;
  border-radius: 11px;
  box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin: 30px auto;
  padding: 10px;
  margin-bottom: 20px;

  .appLogo,
  .suspendedimg {
    margin: 31px auto;
    display: block;
  }

  h2 {
    font-size: 41px;
    font-weight: 500;
  }

  p {
    font-size: 20px;
    font-weight: normal;
    color: #8f8f8f;
  }
`;

const AccountSuspendedWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #f6f6f6;
  padding: 50px 10px;
  margin: 0 auto;
  text-align: center;
  user-select: none;

  p.info {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    color: #555555;

    .contactus {
      color: #596fed;
      cursor: pointer;
      margin: 0 5px;
    }
  }
`;
