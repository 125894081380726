import React, { Fragment } from 'react';
// import popper from 'popper.js'
import Skeleton from 'react-loading-skeleton';
import '../styles/Settings.css';
import trash from '../images/delete.svg';
// import close from "../images/close.svg";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { request } from '../utils/Api';
import { getRoleName, getOrgId } from '../utils/utility';
import SubscriberChecks from '../containers/SubscriberChecks';
import MemberChecks from '../containers/MemberChecks';
import { toastr } from 'react-redux-toastr';
import ReactTooltip from 'react-tooltip';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import styled from 'styled-components';
import { StyledFeatureInterest } from '../components/CaptureInterst';
import Switch from '../components/ui/Switch';

class UserManagementRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberDelete: {},
      applicationDelete: {},
      toggleDeleteUserModal: false,
      confirmRemoveAppDialog: false,
      submitStatus: false
    };
  }
  componentDidMount() {
    this.getOrgSubscribers();
    // this.getOrgMembers();
  }

  async getOrgSubscribers() {
    const subscribers = await request(
      `/organization-subscribers/?organization=${getOrgId()}`
    );
    this.props.setSubscribers(subscribers.results);
  }
  // async getOrgMembers() {
  //   const members = await request(
  //     `/organization-members/?organization=${getOrgId()}`
  //   );
  //   this.props.setMembers(members.results);
  // }

  // getWeeklyAlertsInfo = async(id) => {
  //   const members = await request(
  //     `/organization-members/?organization=${getOrgId()}`
  //   );
  // };

  openDeleteUserModal(member, type) {
    this.setState({
      toggleDeleteUserModal: true,
      memberDelete: member,
      type: type
    });
  }
  closeDeleteUserModal = () => {
    this.setState({ toggleDeleteUserModal: false, submitStatus: false });
  };

  deleteUser = async () => {
    this.setState({
      submitStatus: true
    });
    const config = {
      method: 'DELETE'
    };
    const url =
      this.state.type === 'Subscriber'
        ? `/organization-subscribers/${this.state.memberDelete.id}`
        : `/organization-members/${this.state.memberDelete.id}`;
    await request(url, config);
    if (this.state.type === 'Subscriber')
      this.props.deleteSubscriber(this.state.memberDelete.id);
    else this.props.deleteMember(this.state.memberDelete.id);

    this.closeDeleteUserModal();
    // this.props.getCurrentUserOrg();
  };
  changeRoleOfUser = async () => {
    this.setState({
      submitStatus: true
    });
    // var self = this;
    var url = `/organization-members/${this.state.userIdToChangeRole}`;
    // var header = new Headers({
    //   Authorization: "token " + localStorage.getItem("auth-token"),
    //   "content-type": "application/json"
    // });
    var data = {
      role: this.state.userNewRoleToConfirm
    };
    const response = await request(url, { method: 'PATCH', payload: data });
    if (response) {
      toastr.success('User role updated successfully');
      this.props.changeUserRole(response);
      this.closeConfirmRoleChangeDialog();
    }
  };
  closeConfirmRoleChangeDialog() {
    this.setState({
      confirmRoleChange: false,
      userNameToChangeRole: false,
      userNewRoleNameToConfirm: false,
      userNewRoleToConfirm: false,
      userIdToChangeRole: '',
      submitStatus: false
    });
  }
  handleChange(evt, user) {
    var name = user.user_mini.full_name
      ? user.user_mini.full_name
      : user.user_mini.email;
    var role = evt.value;
    var id = user.id;
    this.setState({
      confirmRoleChange: role !== user.role,
      confirmNewRole: evt.label,
      userNameToChangeRole: name,
      userNewRoleNameToConfirm: getRoleName(role),
      userNewRoleToConfirm: role,
      userIdToChangeRole: id
    });
  }

  changeWeeklyEmails = async (id, state) => {
    var url = `/organization-members/${id}`;
    var data = {
      disable_weekly_report_emails: !state
    };
    const response = await request(url, { method: 'PATCH', payload: data });
    if (response) {
      this.props.changeUserRole(response);
      toastr.success('User preferences updated successfully');
    }
  };

  changeCheckAlerts = async (id, state) => {
    var url = `/organization-members/${id}`;
    var data = {
      disable_alert_emails: !state
    };
    const response = await request(url, { method: 'PATCH', payload: data });
    if (response) {
      this.props.changeUserRole(response);
      toastr.success('User preferences updated successfully');
    }
  };

  render() {
    const { subscribers, members, owner, loggedInuser } = this.props;
    return (
      <div className="users-container user-management">
        {members && members.length > 0 && owner ? (
          members.map(member => (
            <section className="row" key={`members-${member.id}`}>
              <div className="user-info">
                <div className="badge profile-pic">
                  {member.user_mini
                    ? member.user_mini.full_name
                      ? member.user_mini.full_name[0].toUpperCase()
                      : member.user_mini.email[0].toUpperCase()
                    : member.email[0].toUpperCase()}
                </div>
                <div className="full-name">
                  {member.user_mini
                    ? member.user_mini.full_name
                    : member.full_name}
                </div>
                <div
                  className="user-mini-email"
                  title={
                    member.user_mini ? member.user_mini.email : member.email
                  }
                >
                  {member.user_mini ? member.user_mini.email : member.email}
                </div>
              </div>
              {member.user_mini && member.user_mini.email === owner ? (
                <div className="user-type subscriber-type"> Owner </div>
              ) : member.user_mini &&
                member.user_mini.email === loggedInuser.email ? (
                <div className="user-type subscriber-type"> Admin </div>
              ) : (
                <div className="user-type">
                  <Select
                    name={member.id ? member.id.toString() : ''}
                    className="user-type-dropdown"
                    style={{ boxShadow: 'none' }}
                    value={member.role}
                    clearable={false}
                    searchable={false}
                    onChange={(e, value) => this.handleChange(e, member)}
                    options={[
                      { value: 'AD', label: 'Admin' },
                      { value: 'ST', label: 'Read only' }
                    ]}
                  />
                </div>
              )}
              <MemberChecks
                openConfirmRemoveApp={this.openConfirmRemoveApp}
                orgId={this.props.orgId}
                name={
                  member.user_mini
                    ? member.user_mini.full_name
                    : member.full_name
                }
                memberId={member.user}
                member={member}
              />
              <div className="weekly-report">
                <Switch
                  onToggle={on => this.changeCheckAlerts(member.id, on)}
                  on={
                    !member.disable_alert_emails ||
                    member.disable_alert_emails === null
                  }
                  readOnly={
                    member.user_mini &&
                    member.user_mini.email === owner &&
                    owner !== loggedInuser.email
                  }
                />
              </div>
              <div className="weekly-report">
                <Switch
                  onToggle={on => this.changeWeeklyEmails(member.id, on)}
                  on={
                    !member.disable_weekly_report_emails ||
                    member.disable_weekly_report_emails === null
                  }
                  readOnly={
                    member.user_mini &&
                    member.user_mini.email === owner &&
                    owner !== loggedInuser.email
                  }
                />
              </div>
              {member.user_mini &&
              (member.user_mini.email === owner ||
                member.user_mini.email === loggedInuser.email) ? (
                <div className="delete-user-icon" />
              ) : (
                <div
                  className="tooltip popup delete-user-icon"
                  onClick={() => this.openDeleteUserModal(member, 'User')}
                >
                  <img
                    src={trash}
                    data-tip="Delete this user"
                    alt="delete user"
                  />
                </div>
              )}
              <ReactTooltip place="bottom" type="dark" effect="solid" />
            </section>
          ))
        ) : (
          <section className="row">
            <div className="user-info">
              <div className="profile-pic" style={{ lineHeight: 'inherit' }}>
                <Skeleton circle={true} height={28} width={28} />
              </div>
              <div className="full-name">
                <Skeleton height={14} width="80%" />
              </div>
              <div className="user-mini-email">
                <Skeleton height={14} width="80%" />
              </div>
            </div>
            <div className="user-type subscriber-type">
              <Skeleton height={14} width="40%" />
            </div>
            <div className="weekly-report" style={{ width: '20%' }}>
              <Skeleton height={14} width="40%" />
            </div>
            <div className="weekly-report">
              <Skeleton height={14} width="30%" />
            </div>
            <div className="weekly-report">
              <Skeleton height={14} width="30%" />
            </div>
          </section>
        )}

        {subscribers &&
          subscribers.length > 0 &&
          subscribers.map(subscriber => (
            <section className="row" key={`subscribers-${subscriber.id}`}>
              <div className="user-info">
                <div className="badge profile-pic">
                  {subscriber.name
                    ? subscriber.name[0].toUpperCase()
                    : subscriber.email[0].toUpperCase()}
                </div>
                <div className="full-name">{subscriber.name}</div>
                <div className="user-mini-email" title={subscriber.email}>
                  {subscriber.email}
                </div>
              </div>
              <div className="user-type subscriber-type">Contact</div>
              <SubscriberChecks
                openConfirmRemoveApp={this.openConfirmRemoveApp}
                orgId={this.props.orgId}
                name={subscriber.name}
                id={subscriber.id}
                subscriber={subscriber}
              />
              <div className="weekly-report"> &nbsp;</div>
              <div className="weekly-report"> &nbsp;</div>
              <div
                className="tooltip popup delete-user-icon"
                onClick={() =>
                  this.openDeleteUserModal(subscriber, 'Subscriber')
                }
              >
                <img
                  src={trash}
                  data-tip="Delete this user"
                  alt="delete user"
                />
              </div>
              <ReactTooltip place="bottom" type="dark" effect="solid" />
            </section>
          ))}

        <ModalTransition>
          <Fragment>
            {this.state.toggleDeleteUserModal && (
              <Modal
                heading={undefined}
                autoFocus={false}
                shouldCloseOnOverlayClick={true}
                width="500px"
              >
                <StyledHeader>
                  <h2> Do you want to delete this {this.state.type}? </h2>
                </StyledHeader>
                <StyledFeatureInterest>
                  {/* <h4>Do you want to delete this {this.state.type}?</h4> */}
                  <StyledUserInfo>
                    <div className="row">
                      <b> User Name</b>
                      <span className="delete-user-name">
                        {this.state.memberDelete.name
                          ? this.state.memberDelete.name
                          : this.state.memberDelete.user_mini
                          ? this.state.memberDelete.user_mini.full_name
                          : '--'}
                      </span>
                    </div>
                    <div className="row">
                      <b> User Email</b>
                      <span className="delete-user-email">
                        {this.state.memberDelete.email
                          ? this.state.memberDelete.email
                          : this.state.memberDelete.user_mini
                          ? this.state.memberDelete.user_mini.email
                          : ''}
                      </span>
                    </div>
                    {/* {this.state.type === "User" && ( */}
                    <div className="row">
                      <b>User Role</b>
                      <span className="delete-user-name">
                        {this.state.type !== 'User'
                          ? 'Contact'
                          : getRoleName(this.state.memberDelete.role)}
                      </span>
                    </div>
                    {/* )} */}
                  </StyledUserInfo>
                </StyledFeatureInterest>
                <StyledFooter style={{ background: '#fff' }}>
                  <button
                    onClick={this.closeDeleteUserModal}
                    className="secondary-btn"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.deleteUser}
                    disabled={this.state.submitStatus}
                    className="primary-btn"
                  >
                    {this.state.submitStatus ? 'Please wait' : 'Delete'}
                  </button>
                </StyledFooter>
              </Modal>
            )}
          </Fragment>
          <Fragment>
            {this.state.confirmRoleChange && (
              <Modal heading={undefined} autoFocus={false} width="500px">
                <StyledHeader>
                  <h2>User role change - Confirmation</h2>
                </StyledHeader>
                <StyledFeatureInterest>
                  <div className="description">
                    Do you want to change the role of{' '}
                    <span className="dynamic-values">
                      {this.state.userNameToChangeRole}
                    </span>{' '}
                    to{' '}
                    <span className="dynamic-values">
                      {this.state.confirmNewRole}
                    </span>
                    ?
                  </div>
                </StyledFeatureInterest>
                <StyledFooter style={{ background: '#fff' }}>
                  <button
                    onClick={e => this.closeConfirmRoleChangeDialog(e)}
                    className="secondary-btn"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={e => this.changeRoleOfUser(e)}
                    className="primary-btn"
                    disabled={this.state.submitStatus}
                  >
                    {this.state.submitStatus ? 'Please wait' : 'Change'}
                  </button>
                </StyledFooter>
              </Modal>
            )}
          </Fragment>
        </ModalTransition>
      </div>
    );
  }
}
export default UserManagementRow;

const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  transition: box-shadow 200ms;
  z-index: 1;
  box-shadow: none;
  padding: 10px 0;
  h2 {
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #4f4f4f;
  }
`;

const StyledUserInfo = styled.div`
  background: #f6f6f6;
  margin: 0 auto;
  padding: 12px 20px;
  .row {
    display: flex;
    margin-bottom: 5px;
    b {
      display: block;
      width: 100px;
    }
  }
`;

const StyledFooter = styled.footer`
  border-radius: 3px;
  background-color: #f9f9f9;
  min-height: 55px;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  button {
    width: 100px;
    margin-left: 10px;
    user-select: none;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
`;
