/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import { pick, omit } from 'lodash';
import {
  CheckType,
  CheckInterval,
  SslExpiry,
  PerfAlert,
  CheckTypeProtocolPrefix,
  CheckTypeProtocolPrefixMultiSockets,
  ResponseStringValues,
  ResponseCodeValues,
  SupportedProtocolRegex
} from 'constants/check';

const defaultFeatures = [
  'data',
  'application',
  'name',
  'url',
  'notes',
  'email_alert_users_mode',
  'email_alert_subscribers_mode',
  'email_alert_members',
  'preferred_edge_locations_csv',
  'email_alert_subscribers',
  'monitering_interval',
  'command_string',
  'sucess_string',
  'error_string',
  'dns_type',
  'dns_value'
];

export function getActiveFeatures(plans, currentPlan) {
  const features = plans
    ? plans
        .filter(plan => plan.name === currentPlan)
        .reduce((a, v) => [...a, ...v.feature_json.map(feat => feat.key)], [])
    : [];

  if (features.indexOf('alert_sensitivity') > -1) {
    features.push('email_alerts_min_outage_duration');
  }
  return features;
}

export function isSectionAvailable(plans, featureKey, currentPlan) {
  const sectionPlan = plans
    ? plans
        .sort((a, b) => a.price - b.price)
        .find(plan => !!plan.feature_json.find(feat => feat.key === featureKey))
    : null;

  const available = sectionPlan
    ? currentPlan === 'BLOSSOM'
      ? sectionPlan.name === currentPlan
      : currentPlan === 'GARDEN'
      ? sectionPlan.name === 'BLOSSOM' || sectionPlan.name === 'GARDEN'
      : currentPlan === 'ESTATE'
      ? sectionPlan.name === 'BLOSSOM' ||
        sectionPlan.name === 'GARDEN' ||
        sectionPlan.name === 'ESTATE'
      : false
    : false;

  return {
    ...sectionPlan,
    available
  };
}

export function getCheckFormData(values, org, activeFeatures) {
  const allFeatures = [...defaultFeatures, ...activeFeatures];
  /**
   * There is a key difference in performance_alert feature as "performance_alerting"
   * So handling that by manually adding performance_alert
   * when performance_alerting is enabled
   */
  if (allFeatures.indexOf('performance_alerting') > -1) {
    allFeatures.push('performance_alert');
  }
  const isTCPorUDP =
    values.type === CheckType.TCP || values.type === CheckType.UDP;
  const isDNS = values.type === CheckType.DNS;
  // Add default protocol prefix if there are nothing spectified
  const url = values.url.match(SupportedProtocolRegex)
    ? values.url.trim()
    : `${CheckTypeProtocolPrefix[values.type]}${values.url.trim()}`;
  const dataNode = {
    basic_authentication: values.basic_authentication,
    custom_header: values.custom_header.reduce(
      (a, v) => (v.key ? { ...a, [v.key]: v.value } : a),
      {}
    ),
    ignore_ssl_verification: values.ignore_ssl_verification,
    performance_alert: {
      duration: values.performance_alert_duration,
      is_active: values.performance_alert_active,
      threshold: values.performance_alert_threshold
    },
    request_timeout: values.request_timeout,
    ssl_expiry_alert: {
      fifteen_days: values[`ssl_expiry_alert_${SslExpiry.FIFTEEN}`],
      on_expiry: values[`ssl_expiry_alert_${SslExpiry.EXPIRY}`],
      seven_days: values[`ssl_expiry_alert_${SslExpiry.SEVEN}`]
    },
    ...(values.status_check.value.length && {
      status_check: {
        [values.status_check.key]: values.status_check.value
      }
    }),
    ...(values.string_check.value.length && {
      string_check: {
        [values.string_check.key]: values.string_check.value
      }
    }),
    ...(isTCPorUDP && {
      command_string: values.command_string
    }),
    ...(isTCPorUDP && {
      sucess_string: values.sucess_string
    }),
    ...(isTCPorUDP && {
      error_string: values.error_string
    }),
    ...(isDNS && {
      dns_type: values.dns_type
    }),
    ...(isDNS && {
      dns_value: values.dns_value
    }),
    url: isTCPorUDP && values.port ? `${url}:${values.port}` : url
  };

  const formData = omit(values, ['status_check', 'string_check']);
  const allData = {
    ...formData,
    preferred_edge_locations_csv:
      values.preferred_edge_locations_csv === 'any'
        ? null
        : values.preferred_edge_locations_csv,
    application: org.applications.results[0].id,
    monitering_interval: Number(values.monitering_interval),
    email_alerts_min_outage_duration: values.email_alerts_min_outage_duration,
    data: JSON.stringify(pick(dataNode, allFeatures))
  };

  return pick(allData, allFeatures);
}

function getPortFromURL(url) {
  const regex = /^(tcp|udp|icmp-ping|dns-resolve|tcp):\/\/[^:\/]+(?::(\d+))?/;
  const match = url ? url.match(regex) : null;
  if (match === null) {
    return null;
  }
  return match[2] ? match[2] : null;
}

function filterPortFromUrl(url) {
  const regex = /^(tcp|udp):\/\/[^:\/]+(?::(\d+))?/;
  const match = url ? url.match(regex) : null;
  return match ? url.substring(0, url.indexOf(':', 6)) : url;
}

export function getCheckType(url) {
  return Object.keys(CheckTypeProtocolPrefixMultiSockets).find(
    key => {
      if (key == CheckType.SOCKET) {
        return url.indexOf(CheckTypeProtocolPrefixMultiSockets[key][0]) !==
          -1 || url.indexOf(CheckTypeProtocolPrefixMultiSockets[key][1]) !== -1
          ? key
          : false;
      }
      return url.indexOf(CheckTypeProtocolPrefixMultiSockets[key]) > -1
        ? key
        : false;
    }
    // eslint-disable-next-line function-paren-newline
  );
}

export function getCheckFormInitialState(check = {}, applicationMemebers) {
  const dataNode = check ? JSON.parse(check.data) : {};
  return {
    type: check ? getCheckType(dataNode.url) : CheckType.HTTPS,
    url: check ? filterPortFromUrl(dataNode.url) : 'https://',
    port:
      check && getPortFromURL(dataNode.url)
        ? getPortFromURL(dataNode.url)
        : null,
    command_string:
      check && dataNode.command_string ? dataNode.command_string : '',
    sucess_string:
      check && dataNode.sucess_string ? dataNode.sucess_string : '',
    error_string: check && dataNode.sucess_string ? dataNode.error_string : '',
    dns_type: check && dataNode.dns_type ? dataNode.dns_type : '',
    dns_value: check && dataNode.dns_value ? dataNode.dns_value : '',
    name: check ? check.name : '',
    monitering_interval:
      check && check.monitering_interval
        ? check.monitering_interval
        : CheckInterval.FIVEMIN,
    request_timeout:
      check && dataNode.request_timeout ? dataNode.request_timeout : 30,
    basic_authentication:
      check && dataNode.basic_authentication
        ? dataNode.basic_authentication
        : {
            basic_auth_username: '',
            basic_auth_password: ''
          },
    email_alerts_min_outage_duration:
      check && check.email_alerts_min_outage_duration
        ? check.email_alerts_min_outage_duration
        : 0,
    string_check:
      check && dataNode.string_check
        ? {
            key: Object.keys(dataNode.string_check)[0],
            value: Object.values(dataNode.string_check)[0]
          }
        : {
            key: ResponseStringValues.CONTAINS,
            value: ''
          },
    status_check:
      check && dataNode.status_check
        ? {
            key: Object.keys(dataNode.status_check)[0],
            value: Object.values(dataNode.status_check)[0]
          }
        : {
            key: ResponseCodeValues.CONSIDERUP,
            value: ''
          },
    custom_header:
      check &&
      dataNode.custom_header &&
      Object.keys(dataNode.custom_header).length
        ? Object.keys(dataNode.custom_header).map(key => ({
            key,
            value: dataNode.custom_header[key]
          }))
        : [
            {
              key: '',
              value: ''
            }
          ],
    ignore_ssl_verification:
      check && dataNode && dataNode.ignore_ssl_verification !== undefined
        ? dataNode.ignore_ssl_verification
        : 'true',
    [`ssl_expiry_alert_${SslExpiry.FIFTEEN}`]:
      check && dataNode.ssl_expiry_alert
        ? dataNode.ssl_expiry_alert.fifteen_days
        : false,

    [`ssl_expiry_alert_${SslExpiry.SEVEN}`]:
      check && dataNode.ssl_expiry_alert
        ? dataNode.ssl_expiry_alert.seven_days
        : false,

    [`ssl_expiry_alert_${SslExpiry.EXPIRY}`]:
      check && dataNode.ssl_expiry_alert
        ? dataNode.ssl_expiry_alert.on_expiry
        : false,

    target_response_time:
      check && check.target_response_time ? check.target_response_time : 1000,
    performance_alert_active:
      check && dataNode.performance_alert
        ? dataNode.performance_alert.is_active
        : PerfAlert.OFF,
    performance_alert_threshold:
      check && dataNode.performance_alert
        ? dataNode.performance_alert.threshold
        : 2500,
    performance_alert_duration:
      check && dataNode.performance_alert
        ? dataNode.performance_alert.duration
        : 0,
    email_alert_members: check
      ? check.email_alert_members
      : applicationMemebers.length
      ? [applicationMemebers[0].value]
      : [],
    email_alert_users_mode: 'SAM', // hardcoding default value
    email_alert_subscribers_mode: 'SAS', // hardcoding default value
    email_alert_subscribers: check ? check.email_alert_subscribers : [],
    preferred_edge_locations_csv: check
      ? check.preferred_edge_locations_csv
      : 'any',
    notes: check && check.notes ? check.notes : ''
  };
}
