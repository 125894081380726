import React from 'react';
import {
  Toggle,
  Popover,
  Drawer,
  Menu,
  Button,
  Confirmation
} from 'freemium-ui';
import InfoIcon from 'images/info-red.svg';
import freshdesk from 'images/freshdesk.svg';
import teamInbox from 'images/team-inbox.svg';
import SlaManagement from 'images/sla-management.svg';
import Loader from 'components/Loader';
import optionDot from 'images/option-dot.svg';
import collisionDetection from 'images/collision-detection.svg';
import scheduleReports from 'images/schedule-reports.svg';

import CreateIntegration from './CreateIntegration';
import IntegrationForm from './IntegrationForm';
import {
  Card,
  CardHeader,
  CardList,
  Center,
  EmptyData,
  ErrorCard,
  IconButton,
  ModalContent
} from './styles';
import {
  useAddFdIntegration,
  useDeleteFdIntegration,
  useFdIntegration,
  useSaveFdIntegration
} from './hooks';
import { FormErrorCard } from './IntegrationForm/styles';

const options = [
  {
    id: 'edit',
    name: 'Edit'
  },
  {
    id: 'delete',
    name: 'Delete'
  }
];

const FreshDeskIntegration = props => {
  const {
    updateIntegrationCount = () => {},
    isIntegrationAllowed = true
  } = props;
  const [showAuthScreen, toggleAuthScreen] = React.useState(false);
  const [showDeletePopup, toggleDeletePopup] = React.useState(false);
  const [openDrawer, toggleDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fdIntegration, setFdIntegration] = React.useState({});

  const {
    data: fdIntegrations = [],
    isError: isFdError,
    isLoading: isLoadingFdIntegrations
  } = useFdIntegration();
  const { mutate: addIntegration, isLoading: isAdding } = useAddFdIntegration({
    updateIntegrationCount
  });
  const {
    mutate: saveIntegration,
    isLoading: isSaving,
    variables: savePayload
  } = useSaveFdIntegration();
  const {
    mutate: deleteIntegration,
    isLoading: isDeleting
  } = useDeleteFdIntegration({ updateIntegrationCount });

  const onCreate = selectedIntegration => {
    setFdIntegration(selectedIntegration);
    toggleAuthScreen(false);
  };

  const onClose = () => {
    toggleDrawer(false);
    setFdIntegration({});
  };

  const handleMenuOption = (option, selectedIntegration) => {
    if (anchorEl) setAnchorEl(null);
    switch (option.id) {
      case 'edit':
        toggleDrawer(true);
        toggleAuthScreen(!selectedIntegration.is_valid);
        setFdIntegration(selectedIntegration);
        break;
      case 'save':
        saveIntegration({
          id: selectedIntegration.id,
          payload: {
            state: selectedIntegration.state === 'AC' ? 'DA' : 'AC'
          },
          mutate: true,
          statusChange: true
        });
        break;
      case 'delete':
        toggleDeletePopup(true);
        setFdIntegration(selectedIntegration);
        break;
      default:
    }
  };

  React.useEffect(() => {
    if (fdIntegrations.length > 0 && fdIntegration.id) {
      const newFdIntegration = fdIntegrations.find(
        i => i.id === fdIntegration.id
      );
      setFdIntegration(newFdIntegration);
    }
  }, [fdIntegrations]);

  return (
    <Card>
      <Confirmation
        isOpen={showDeletePopup && fdIntegration.id}
        onDismiss={() => {
          toggleDeletePopup(false);
        }}
        title="Delete Confirmation"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        onConfirm={() => {
          deleteIntegration(fdIntegration.id);
          setFdIntegration({});
          toggleDrawer(false);
          toggleDeletePopup(false);
        }}
      >
        Are you sure you want to delete{' '}
        <strong>{fdIntegration.freshdesk_domain}</strong> freshdesk integration?
      </Confirmation>
      <CardHeader>
        <div>
          <img src={freshdesk} alt="Freshdesk Logo" />
          <span>Freshdesk</span>
        </div>
        <div>
          <Button
            size="normal"
            onClick={() => {
              toggleAuthScreen(true);
              toggleDrawer(true);
              setFdIntegration({});
            }}
            disabled={
              !isIntegrationAllowed || isFdError || isLoadingFdIntegrations
            }
            className={
              !isIntegrationAllowed || isFdError || isLoadingFdIntegrations
                ? 'disabled'
                : ''
            }
            inline
            type="link"
          >
            &#43; Create Integration
          </Button>
        </div>
      </CardHeader>
      {!isLoadingFdIntegrations && fdIntegrations.length > 0 ? (
        <CardList>
          <colgroup>
            <col span="1" style={{ width: '80%' }} />
            <col span="1" style={{ width: '10%' }} />
            <col span="1" style={{ width: '10%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Domain</th>
              <th>Status</th>
              <th className="center">Action</th>
            </tr>
          </thead>
          <tbody>
            {fdIntegrations.map(integration => (
              <tr key={integration.id}>
                <td>
                  <div className="name">
                    <span>{integration.freshdesk_domain}</span>
                    {!integration.is_valid && (
                      <ErrorCard>
                        <img src={InfoIcon} alt="info" />
                        <div>
                          {integration.errorMessage || 'Invalid configuration'}
                        </div>
                        <div
                          role="none"
                          className="config"
                          onClick={() =>
                            handleMenuOption({ id: 'edit' }, integration)
                          }
                        >
                          Edit
                        </div>
                      </ErrorCard>
                    )}
                    {integration.is_valid && integration.ticket_status && (
                      <FormErrorCard
                        color="#12344d"
                        margin="unset"
                        background="#ffecf0"
                        borderColor="#ffd0d6"
                        className="error-card"
                      >
                        <img src={InfoIcon} alt="info" />
                        <div className="divider" />
                        <div>Unable to create last ticket,</div>
                        <div className="message">
                          {integration.ticket_status}
                        </div>
                      </FormErrorCard>
                    )}
                  </div>
                </td>
                <td>
                  <Toggle
                    on={integration.state === 'AC'}
                    hasIcon={false}
                    handleChange={() =>
                      handleMenuOption({ id: 'save' }, integration)
                    }
                    disabled={isSaving && savePayload.id === integration.id}
                  />
                </td>
                <td className="center">
                  <IconButton
                    role="button"
                    tabIndex={0}
                    onClick={e => setAnchorEl(e.currentTarget)}
                    onKeyDown={({ keyCode, currentTarget }) => {
                      if (keyCode === 13) setAnchorEl(currentTarget);
                    }}
                    aria-describedby={`${integration.id}`}
                  >
                    <img src={optionDot} alt="." />
                  </IconButton>
                  <Popover
                    id={`${integration.id}`}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <Menu
                      options={options}
                      onSelect={v => handleMenuOption(v, integration)}
                    />
                  </Popover>
                </td>
              </tr>
            ))}
          </tbody>
        </CardList>
      ) : (
        <EmptyData>
          {isLoadingFdIntegrations ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <>
              <p className="header-line">
                Intuitive, feature-rich, affordable customer support software.{' '}
                <a
                  href="https://freshworks.com/freshdesk/collaboration?utm_source=Freemium_Freshping&utm_medium=referral&utm_campaign=Freshping_Integration_Signup_Nudge"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </p>
              <div className="features">
                <div className="feature-item">
                  <img src={teamInbox} alt="team-inbox icon" />
                  <span>Team inbox</span>
                </div>
                <div className="divider" />
                <div className="feature-item">
                  <img src={SlaManagement} alt="sla-management icon" />
                  <span>SLAs & Reminders</span>
                </div>
                <div className="divider" />
                <div className="feature-item">
                  <img
                    src={collisionDetection}
                    alt="collision-detection icon"
                  />
                  <span>Agent Collision Detection</span>
                </div>
                <div className="divider" />
                <div className="feature-item">
                  <img src={scheduleReports} alt="schedule-reports icon" />
                  <span>Scheduled Reports</span>
                </div>
              </div>
            </>
          )}
        </EmptyData>
      )}
      <Drawer
        isOpen={openDrawer}
        onDismiss={() => toggleDrawer(false)}
        isFullHeight
        size="medium"
        clickOutToClose={false}
        placement="right"
      >
        <ModalContent>
          {showAuthScreen ? (
            <CreateIntegration
              onClose={onClose}
              addIntegration={addIntegration}
              saveIntegration={saveIntegration}
              deleteIntegration={() => {
                handleMenuOption({ id: 'delete' }, fdIntegration);
              }}
              onCreate={onCreate}
              isAdding={isAdding}
              isSaving={isSaving}
              toggleAuthScreen={toggleAuthScreen}
              isDeleting={isDeleting}
              fdIntegration={fdIntegration}
            />
          ) : (
            <IntegrationForm
              fdIntegration={fdIntegration}
              onClose={onClose}
              toggleAuthScreen={toggleAuthScreen}
            />
          )}
        </ModalContent>
      </Drawer>
    </Card>
  );
};

export default FreshDeskIntegration;
