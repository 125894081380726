/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ScrollSpyContainer = styled.div`
  margin-top: 20px;
  .section-title {
    border-radius: 3px;
    line-height: 2;
    padding: 5px 14px;
    color: #4c4c4c;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;
    cursor: pointer;
    &.active {
      background-color: var(--color-smoke-50);
      color: var(--base-color);
    }
  }

  .menu-item {
    cursor: pointer;
    margin-left: 20px;
    line-height: 2.5;
    &.hide {
      display: none;
    }
    &.active {
      color: #000;
      font-weight: 600;
    }
  }
`;
