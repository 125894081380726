/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { uniq } from "lodash";
import useOnOutsideClick from "shared/hooks/onOutsideClick";
import KeyCodes from "constants/keyCodes";
import Icon from "shared/components/Icon";
import Dropdown from "../Select/Dropdown";
import {
  StyledSelect,
  ValueContainer,
  ChevronIcon,
  Placeholder,
  ValueMulti,
  ValueMultiItem
  // AddMore
} from "../Select/styles";

const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["normal", "empty"]),
  dropdownWidth: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number
  ]),
  formValues: PropTypes.any,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  isInline: PropTypes.bool,
  formName: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  // onChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
  onCreate: PropTypes.func,
  isMulti: PropTypes.bool,
  hideSearch: PropTypes.bool,
  withClearValue: PropTypes.bool,
  renderValue: PropTypes.func,
  renderOption: PropTypes.func
};

const defaultProps = {
  className: undefined,
  variant: "normal",
  dropdownWidth: undefined,
  name: undefined,
  value: undefined,
  defaultValue: undefined,
  placeholder: "Select",
  invalid: false,
  isInline: false,
  onCreate: undefined,
  isMulti: true,
  hideSearch: false,
  withClearValue: true,
  renderValue: undefined,
  renderOption: undefined,
  formValues: undefined,
  setFieldValue: undefined
};

const CustomSelectField = ({
  formValues,
  className,
  variant,
  dropdownWidth,
  name,
  value: propsValue,
  defaultValue,
  placeholder,
  invalid,
  options,
  isInline,
  // onChange,
  setFieldValue,
  onCreate,
  isMulti,
  withClearValue,
  hideSearch,
  formName: PropFieldType,
  renderValue: propsRenderValue,
  renderOption: propsRenderOption
}) => {
  const [stateValue, setStateValue] = useState(
    defaultValue || (isMulti ? [] : null)
  );
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [alertValues, setalertValues] = useState([]);
  const isControlled = propsValue !== undefined;
  const value = isControlled ? propsValue : stateValue;
  const $selectRef = useRef();
  const $inputRef = useRef();

  useEffect(() => {
    const users = [];
    PropFieldType.map(p => {
      users.push(...formValues[p]);
      return p;
    });
    setalertValues(users);
  }, []);

  const activateDropdown = () => {
    if (isDropdownOpen) {
      $inputRef.current && $inputRef.current.focus();
    } else {
      setDropdownOpen(true);
    }
  };

  const deactivateDropdown = () => {
    setDropdownOpen(false);
    setSearchValue("");
    // eslint-disable-next-line no-unused-expressions
    $selectRef.current.focus && $selectRef.current.focus();
  };

  useOnOutsideClick($selectRef, isDropdownOpen, deactivateDropdown);

  const preserveValueType = newValue => {
    const areOptionValuesNumbers = options.some(
      option => typeof option.value === "number"
    );

    if (areOptionValuesNumbers) {
      // if (isMulti) {
      //   return newValue.map(Number);
      // }
      if (newValue) {
        return Number(newValue);
      }
    }
    return newValue;
  };

  const handleChange = option => {
    if (!isControlled) {
      setStateValue(preserveValueType(option.value));
    }
    const selected = preserveValueType(option.value);
    if (PropFieldType.indexOf(option.formFieldName) > -1) {
      const fieldValues = uniq([...formValues[option.formFieldName], selected]);
      setFieldValue(option.formFieldName, fieldValues);
      setalertValues([...alertValues, selected]);
    }
  };

  const removeOptionValue = optionValue => {
    const removedOption = getOption(optionValue);
    if (removedOption) {
      setalertValues(alertValues.filter(val => val !== optionValue));
      setFieldValue(
        removedOption.formFieldName,
        formValues[removedOption.formFieldName].filter(
          val => val !== optionValue
        )
      );
    }
  };

  const handleFocusedSelectKeydown = event => {
    if (isDropdownOpen) return;

    if (event.keyCode === KeyCodes.ENTER) {
      event.preventDefault();
    }
    if (
      event.keyCode !== KeyCodes.ESCAPE &&
      event.keyCode !== KeyCodes.TAB &&
      !event.shiftKey
    ) {
      setDropdownOpen(true);
    }
  };

  const getOption = optionValue =>
    options.find(option => option.value === optionValue);
  const getOptionLabel = optionValue =>
    (getOption(optionValue) || { label: "" }).label;
  const isValueEmpty = isMulti ? !alertValues.length : !getOption(value);
  return (
    <StyledSelect
      className={className}
      variant={variant}
      innerRef={$selectRef}
      tabIndex="0"
      isInline={isInline}
      onKeyDown={handleFocusedSelectKeydown}
      invalid={invalid}
    >
      <ValueContainer
        variant={variant}
        data-testid={name ? `select:${name}` : "select"}
        onClick={activateDropdown}
      >
        {isValueEmpty && <Placeholder>{placeholder}</Placeholder>}

        {!isValueEmpty && !isMulti && propsRenderValue
          ? propsRenderValue({ value })
          : getOptionLabel(value)}

        {!isValueEmpty && isMulti && (
          <ValueMulti variant={variant}>
            {alertValues.map(optionValue =>
              propsRenderValue ? (
                propsRenderValue({
                  value: optionValue,
                  removeOptionValue: () => removeOptionValue(optionValue)
                })
              ) : (
                <ValueMultiItem
                  key={optionValue}
                  onClick={() => removeOptionValue(optionValue)}
                >
                  {getOptionLabel(optionValue)}
                  <Icon type="close" size={14} />
                </ValueMultiItem>
              )
            )}
          </ValueMulti>
        )}

        {(!isMulti || isValueEmpty) && variant !== "empty" && (
          <ChevronIcon type="chevron-down" top={1} />
        )}
      </ValueContainer>

      {isDropdownOpen && (
        <Dropdown
          iscustomSelect
          dropdownWidth={dropdownWidth}
          value={alertValues}
          isInline={isInline}
          isValueEmpty={isValueEmpty}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          $selectRef={$selectRef}
          $inputRef={$inputRef}
          deactivateDropdown={deactivateDropdown}
          options={options}
          onChange={handleChange}
          onCreate={onCreate}
          isMulti={isMulti}
          hideSearch={hideSearch}
          withClearValue={withClearValue}
          propsRenderOption={propsRenderOption}
        />
      )}
    </StyledSelect>
  );
};

CustomSelectField.propTypes = propTypes;
CustomSelectField.defaultProps = defaultProps;

export default CustomSelectField;
