import React from 'react';
import styled from 'styled-components';
import ExploreIllustration from '../images/explore_illustration.svg';

const ExploreHeader = () => (
  <StyledExploreHeader>
    <div>
      <div className="description">
        Best-in-class Customer Engagement Software Suite
      </div>
      <div>
        {'Start your '}
        <span className="special-text">21-Day Free Trial Now</span>
      </div>
    </div>
    <img alt="illustration" src={ExploreIllustration} />
  </StyledExploreHeader>
);

const StyledExploreHeader = styled.div`
  background-color: rgb(255, 254, 254);
  border: 1px solid rgb(255, 255, 255);
  box-sizing: border-box;
  box-shadow: rgb(0 0 0 / 14%) 0px 2px 2px;
  border-radius: 4px;
  padding: 20px 44px;
  margin-bottom: 36px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  .description {
    font-weight: bold;
    font-size: 22px;
    line-height: 162.34%;
    color: rgb(40, 40, 40);
    max-width: 350px;
    padding-bottom: 12px;
  }
  .special-text {
    font-weight: 500;
    color: var(--color-brand);
  }
  img {
    width: 222px;
    height: 144px;
    position: relative;
    bottom: -8px;
  }
`;
export default ExploreHeader;
