import { getOrgId } from 'utils/utility';

export const dataJsonPlaceholders = [
  {
    id: 'check',
    name: 'Check',
    options: [
      {
        id: 'check_id',
        name: 'Check ID',
        placeholder: '{check_id}'
      },
      {
        id: 'check_name',
        name: 'Check Name',
        placeholder: '{check_name}'
      },
      {
        id: 'check_url',
        name: 'Check URL',
        placeholder: '{check_url}'
      }
    ]
  },
  {
    id: 'check_request',
    name: 'Check Request',
    options: [
      {
        id: 'request_location',
        name: 'Request Location',
        placeholder: '{request_location}'
      },
      {
        id: 'request_datetime',
        name: 'Request Datetime',
        placeholder: '{request_datetime}'
      },
      {
        id: 'request_timeout',
        name: 'Request Timeout',
        placeholder: '{request_timeout}'
      }
    ]
  },
  {
    id: 'check_response',
    name: 'Check Response',
    options: [
      {
        id: 'text',
        name: 'Response Text',
        placeholder: '{text}'
      },
      {
        id: 'response_state',
        name: 'Response State',
        placeholder: '{response_state}'
      },
      {
        id: 'response_status_code',
        name: 'Response Status Code',
        placeholder: '{response_status_code}'
      },
      {
        id: 'response_time',
        name: 'Response Time',
        placeholder: '{response_time}'
      },
      {
        id: 'response_summary',
        name: 'Response Summary',
        placeholder: '{response_summary}'
      }
    ]
  }
];

export const dataJsonDefault = JSON.stringify(
  {
    text: '{text}',
    check_id: '{check_id}',
    check_name: '{check_name}',
    check_url: '{check_url}',
    request_location: '{request_location}',
    request_datetime: '{request_datetime}',
    request_timeout: '{request_timeout}',
    response_state: '{response_state}',
    response_status_code: '{response_status_code}',
    response_time: '{response_time}',
    response_summary: '{response_summary}'
  },
  '',
  2
);

export const dataJsonGoogleChat = JSON.stringify(
  {
    text: '{text}'
  },
  '',
  2
);

export const dataJsonMSOffice = JSON.stringify(
  {
    text: '{text}',
    themeColor: '4cc149',
    '@type': 'MessageCard'
  },
  '',
  2
);

export const dataJsonSlack = JSON.stringify(
  {
    attachments: [
      {
        color: '#4cc149',
        author_name: '{text}',
        title: '{check_url}'
      }
    ]
  },
  '',
  2
);

export const dataJsonZapier = JSON.stringify(
  {
    organization_name: '',
    organization_id: 0,
    webhook_type: 'AL',
    check_state_name: '{response_state}',
    application_id: 0,
    check_id: '{check_id}',
    http_status_code: '{response_status_code}',
    request_start_time: '{request_datetime}',
    application_name: '',
    request_url: '{check_url}',
    response_time: '{response_time}',
    check_name: '{check_name}'
  },
  '',
  2
);

export const types = [
  {
    name: 'All',
    id: 'AL'
  },
  {
    name: 'Up / Down',
    id: 'AT'
  },
  {
    name: 'Performance Degraded',
    id: 'PE'
  },
  {
    name: 'Pause / Restarted',
    id: 'PS'
  }
];

export const contents = [
  {
    name: 'Simple',
    id: 'simple'
  },
  {
    name: 'Advanced',
    id: 'advanced'
  }
];

export const getMockedInitialValues = () => ({
  state: 'AC',
  name: '',
  type: 'AL',
  contentEncoding: 'simple',
  encoding: 'JSON',
  url: '',
  subscribed_checks: [],
  requireAuth: false,
  username: '',
  password: '',
  organization: getOrgId(),
  data_json: dataJsonDefault
});
