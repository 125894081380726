import { useQuery } from 'react-query';
import { request } from 'utils/Api';
import { getOrgId } from 'utils/utility';

export const useOrganizations = () =>
  useQuery('organizations', async () => {
    const response = await request('/organizations/');
    return (response && response.results) || [];
  });

export const useOrganizationById = (orgId = getOrgId()) =>
  useQuery(['organizations', `${orgId}`], async () => {
    const response = await request(`/organizations/${orgId}`);
    return response || {};
  });

export const useChecks = (orgId = getOrgId()) =>
  useQuery('checks', async () => {
    const response = await request(
      `/checks/?application__organization=${orgId}&ordering=id&page=1&page_size=10/`
    );
    return (response && response.results) || [];
  });
