import styled from 'styled-components';

export const Card = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: solid 1px #ebf3eb;
  background-image: linear-gradient(to bottom, #fffafb, #ffffff);
  margin-bottom: 16px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  :not(:last-child) {
    margin-bottom: 24px;
  }
  span {
    font-size: 16px;
    font-weight: 600;
  }
  img {
    max-width: 40px;
    margin-right: 16px;
  }
  button.disabled {
    opacity: 50%;
  }
`;

export const CardList = styled.table`
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14);
  border-spacing: 16px;
  thead {
    background-color: #eef5f2;
  }
  th {
    font-weight: normal;
    padding: 0 16px;
  }
  td {
    font-weight: normal;
    padding: 8px 16px;
    max-width: 0;
  }
  tr {
    height: 54px;
  }
  th.center,
  td.center {
    text-align: center;
  }
  td div.name {
    span {
      margin-right: 16px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const EmptyData = styled.div`
  p.header-line {
    margin-bottom: 24px;
  }
  & > div.features {
    display: flex;
    justify-content: space-around;
    align-items: center;
    & > div.divider {
      height: 24px;
      border-right: 1px solid #e1e1e1;
    }
    & > div.feature-item {
      padding-right: 16px;
      img {
        width: 24px;
        margin-right: 8px;
      }
    }
  }
`;

export const IconButton = styled.div`
  cursor: pointer;
`;

export const ModalContent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.div`
  padding: 24px;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  justify-content: space-between;
  .form-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #343434;
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const Center = styled.div`
  width: 100%;
  display: grid;
  place-content: center;
`;

export const TestCell = styled.div`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
  color: var(--text-link);
`;

export const ModalBody = styled.div`
  padding: 16px;
  div.modal-title {
    font-size: 16px;
    color: #4f4f4f;
    margin-bottom: 16px;
  }
  div.modal-description {
    color: #6f7c87;
    font-weight: 400;
    margin-bottom: 16px;
  }
`;

export const ModalFooter = styled.div`
  padding: 16px;
  background: #f9f9f9;
  border-top: 1px solid #ebeef0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
