import {
  GET_APPLICATIONS_REQUEST,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_FAILURE,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS
  // GET_APPLICATION_FAILURE
} from "../actions";

export default function applicationReducer(
  state = {
    isLoading: false,
    hasErrored: false,
    refresh: false,
    applications: {},
    application: {}
  },
  action
) {
  switch (action.type) {
    case GET_APPLICATION_REQUEST:
      return {
        ...state,
        application: {
          ...state.application,
          [action.payload]: {
            ...state.application[action.payload],
            isLoading: false
          }
        }
      };
    case GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        applications: {}
      };
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        hasErrored: false,
        isLoading: false,
        refresh: true,
        applications: action.payload
      };
    case GET_APPLICATION_SUCCESS:
      // if (!(action.payload.results && action.payload.results.length)) {
      //   return state;
      // }
      return {
        ...state,
        applications: {
          ...state.applications,
          results: state.applications.results.map(application => {
            if (action.payload.meta === application.id) {
              return { ...application, application: action.payload.response };
            } else {
              return application;
            }
          })
        }
      };
    case GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        hasErrored: true,
        isLoading: false
      };
    default:
      return state;
  }
}
