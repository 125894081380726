import {
  GET_BADGE_REQUEST,
  GET_BADGE_SUCCESS,
  GET_BADGE_FAILURE,
  SET_BADGE_FORM,
  RESET_BADGE_ERRORS,
  SET_BADGE_ERRORS,
  UPDATE_BADGE,
  UPDATE_BADGE_SUCCESS,
  UPDATE_BADGE_FAILURE,
  CREATE_BADGE,
  CREATE_BADGE_SUCCESS,
  CREATE_BADGE_FAILURE,
  DELETE_BADGE_SUCCESS,
  SET_ACTIVE_BADGE,
  SET_ACTIVE_BADGE_SUCCESS,
  SET_BADGE_URL
} from "../actions";

import { filterByOrganization } from "../utils/utility";

export const initialBadgeState = {
  appState: "idle",
  badgeTicketUrl: "",
  badges: {
    count: 0,
    results: []
  },
  formState: "idle",
  badgeformErrors: {
    name: "",
    target_url: "#",
    isCheckError: false,
    isTypeError: false
  },
  badgeform: {
    organization: null,
    name: "",
    data: {
      target_url:
        "http://freshworks.com/website-monitoring?utm_source=status_badge&utm_medium=status_badge",
      no_of_days_since: 30,
      checks: [],
      metric_types: ["AVL", "CS"],
      badge_meta: {
        shape: "round",
        theme: "dark"
      }
    }
  }
};

export default function badgeReducer(state = initialBadgeState, action) {
  switch (action.type) {
    case DELETE_BADGE_SUCCESS:
      return {
        ...state,
        badges: {
          ...state.badges,
          results: state.badges.results.filter(
            badge => badge.id !== action.payload.meta.meta.id
          ),
          count: state.badges.results.length - 1
        }
      };
    case RESET_BADGE_ERRORS:
      return {
        ...state,
        badgeformErrors: initialBadgeState.badgeformErrors
      };
    case SET_BADGE_ERRORS:
      return {
        ...state,
        badgeformErrors: {
          ...state.badgeformErrors,
          ...action.payload
        }
      };
    case CREATE_BADGE:
    case UPDATE_BADGE:
      return {
        ...state,
        formState: "loading"
      };
    case UPDATE_BADGE_SUCCESS:
    case CREATE_BADGE_SUCCESS:
      if (action.payload.response.status === 400) {
        const { badgeformErrors } = initialBadgeState;
        Object.keys(action.payload.response.response).forEach(key => {
          badgeformErrors[key] = action.payload.response.response[key];
        });
        return {
          ...state,
          formState: "error",
          badgeformErrors
        };
      }
      return {
        ...state,
        formState: "success",
        badgeform: {
          ...state.badgeform,
          ...action.payload.response
        }
      };
    case UPDATE_BADGE_FAILURE:
    case CREATE_BADGE_FAILURE:
      return {
        ...state,
        formState: "failed"
      };
    case SET_ACTIVE_BADGE:
      return {
        ...state,
        formState: "idle",
        badgeform: {
          ...state.badgeform,
          ...action.payload
        }
      };
    case SET_BADGE_FORM:
      return {
        ...state,
        badgeform: {
          ...state.badgeform,
          ...action.payload
        }
      };
    case SET_ACTIVE_BADGE_SUCCESS:
      return {
        ...state,
        formState: "ready"
      };
    case GET_BADGE_REQUEST:
      return {
        ...state,
        appState: "loading"
      };
    case GET_BADGE_SUCCESS:
      const filteredResults = filterByOrganization(
        action.payload.response.results
      );
      return {
        ...state,
        appState: "success",
        badges: {
          count: filteredResults.length,
          results: filteredResults
        }
      };
    case GET_BADGE_FAILURE:
      return {
        ...state,
        appState: "failure"
      };
    case SET_BADGE_URL:
      return {
        ...state,
        badgeTicketUrl: action.payload.url
      };

    default:
      return state;
  }
}
