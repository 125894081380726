import moment from 'moment';
import momentTimezone from 'moment-timezone';

export function padNumber(number) {
  let string = `${number}`;
  string = string.length < 2 ? `0${string}` : string;
  return string;
}
export function capitalizeFirstLetter(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
}
export function getNextDate(date) {
  date = new Date(date);
  const next_date = new Date(date.setDate(date.getDate() + 1));
  const formatted = `${next_date.getUTCFullYear()}-${padNumber(
    next_date.getUTCMonth() + 1
  )}-${padNumber(next_date.getUTCDate())}`;
  return formatted;
}

export function excludeTypeOfUrl(url) {
  const parts = url.split('//');
  return parts[parts.length - 1];
}

export function getStartEndOfDay(date) {
  const start = moment.utc(date).startOf('day');
  const end = moment.utc(date).endOf('day');

  return { start, end };
}

export function calcIncidentDuration(time1, time2) {
  const hms = '12:29:25';
  const a = hms.split(':');
  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  const seconds1 = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  let diff = (seconds1 - seconds) / 60;
  if (diff.toString().length === 1) diff = `0${diff.toString()}`;
  return diff;
}

export function getPortNumberFromURL(url) {
  if (url) {
    const tempArr = url.split(':');
    return tempArr[tempArr.length - 1];
  }
}

export function convertDateToMonthDay(str) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const d = new Date(str);
  const day = d.getDate();
  const month = monthNames[d.getMonth()];
  return `${month} ${day}`;
}

export function getFirstLastDates() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const today = new Date();
  let d;
  let month;
  let year;
  const dateArr = [];
  for (let i = 3; i >= 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = d.getMonth();
    year = d.getFullYear();
    dateArr.push({
      firstDate: new Date(`${d.getFullYear()}-${month + 1}-01`),
      lastDate: new Date(new Date(year, d.getMonth() + 1, 1) - 1),
      month: monthNames[d.getMonth()]
    });
  }
  return dateArr;
}

export function getCurrentMonth() {
  const d = new Date();
  const n = d.getMonth() + 1;
  return n;
}
export function getTwoMonthsBackDates(dates, status, stats_data) {
  dates = dates || [];
  status = status || [];
  stats_data = stats_data || [];
  const month =
    getCurrentMonth() > 2 ? getCurrentMonth() - 2 : 10 + getCurrentMonth();

  const year =
    month > 10 ? new Date().getFullYear() - 1 : new Date().getFullYear();

  const FirstDay = moment(new Date(year, month - 1, 1)).format('YYYY-MM-DD');
  const LastDay = moment(new Date(year, month, 0)).format('YYYY-MM-DD');

  const numOfDays = moment(LastDay).diff(moment(FirstDay), 'days');
  const CurrentDatePopulate = dates.filter(
    ob => Number(ob.substring(5, 7)) === month
  );
  const indexStart = dates.indexOf(CurrentDatePopulate[0]);
  const indexEnd = dates.lastIndexOf(
    CurrentDatePopulate[CurrentDatePopulate.length - 1]
  );
  const CurrentStatusPopulate = status.slice(indexStart, indexEnd + 1);
  let i = 0;
  let arr = [];

  CurrentStatusPopulate.map(eachStatus => {
    if (i <= numOfDays) {
      const newDate = moment(FirstDay)
        .add(i, 'd')
        .format('YYYY-MM-DD');
      i = i + 1;
      arr.push({
        date: newDate,
        status: eachStatus,
        stats_data: null
      });
    }
    return eachStatus;
  });

  if (CurrentStatusPopulate.length < numOfDays) {
    for (; i <= numOfDays; i += 1) {
      const newDate = moment(FirstDay)
        .add(i, 'd')
        .format('YYYY-MM-DD');

      arr.push({
        date: newDate,
        status: null,
        stats_data: null
      });
    }
  }

  return arr;
}

export function getPreviousMonthDates(dates, status, stats_data) {
  dates = dates || [];
  status = status || [];
  stats_data = stats_data || [];

  const month = getCurrentMonth() > 1 ? getCurrentMonth() - 1 : 12;
  const year =
    month > 11 ? new Date().getFullYear() - 1 : new Date().getFullYear();
  const FirstDay = new Date(year, month - 1, 1);
  const LastDay = new Date(year, month, 0);
  const numOfDays = moment(LastDay).diff(moment(FirstDay), 'days');
  const CurrentDatePopulate = dates.filter(
    ob => Number(ob.substring(5, 7)) === month
  );
  const indexStart = dates.indexOf(CurrentDatePopulate[0]);
  const indexEnd = dates.lastIndexOf(
    CurrentDatePopulate[CurrentDatePopulate.length - 1]
  );
  const CurrentStatusPopulate = status.slice(indexStart, indexEnd + 1);

  let i = 0;
  let arr = [];
  CurrentStatusPopulate.map(eachStatus => {
    if (i <= numOfDays) {
      const newDate = moment(FirstDay)
        .add(i, 'd')
        .format('YYYY-MM-DD');
      i = i + 1;
      arr.push({
        date: newDate,
        status: eachStatus,
        stats_data: null
      });
    }
    return eachStatus;
  });

  if (CurrentStatusPopulate.length < numOfDays) {
    for (; i <= numOfDays; i += 1) {
      const newDate = moment(FirstDay)
        .add(i, 'd')
        .format('YYYY-MM-DD');

      arr.push({
        date: newDate,
        status: null,
        stats_data: null
      });
    }
  }

  return arr;
}

export function getCompleteMonthDates(
  month,
  monthStart,
  monthEnd,
  dates,
  status,
  stats_data
) {
  const arr2 = [];
  dates = dates || [];
  status = status || [];
  stats_data = stats_data || [];
  const beginDates = dates[0].substring(8, 10);
  const beginMonth = dates[0].substring(5, 7);
  const beginyear = dates[0].substring(0, 4);
  const beginformat = `${beginyear}-${beginMonth}-`;
  const fillYear = Number(moment(month).format('YYYY'));
  const fillMonth = Number(moment(month).format('M'));
  if (moment(month).format('YYYY') < moment(beginyear).format('YYYY')) {
    if (moment(month).format('M') < 12 + moment(beginMonth).format('M')) {
      for (
        let i = moment(month).format('M');
        i < 12 + Number(moment(beginMonth).format('M'));
        i++
      ) {
        for (
          let j = 1;
          j <= Number(new Date(fillYear, fillMonth, 0).getDate());
          j++
        ) {
          if (i <= 12) {
            arr2.push({
              date: `${moment(month).format('YYYY')}-${moment(month).format(
                'M'
              )}-${j}`,
              status: null,
              stats_data: null
            });
          } else {
            arr2.push({
              date: `${moment(beginyear).format('YYYY')}-${moment(
                i - 12
              ).format('M')}-${j}`,
              status: null,
              stats_data: null
            });
          }
        }
      }
    }
  } else if (moment(month).format('M') < moment(beginMonth).format('M')) {
    for (
      let i = moment(month).format('M');
      i < Number(moment(beginMonth).format('M'));
      i++
    ) {
      for (
        let j = 1;
        j <= Number(new Date(fillYear, fillMonth, 0).getDate());
        j++
      ) {
        arr2.push({
          date: `${moment(month).format('YYYY')}-${moment(month).format(
            'M'
          )}-${j}`,
          status: null,
          stats_data: null
        });
      }
    }
  }
  for (let i = 1; i < beginDates; i++) {
    arr2.push({ date: beginformat + i, status: null, stats_data: null });
  }

  for (var i = 0; i < dates.length; i++) {
    // let month = (getCurrentMonth() > 1 ) ? getCurrentMonth() - 1 : 12;
    // if(dates[i].substring(5,7) == month)
    arr2.push({ date: dates[i], status: status[i], stats_data: stats_data[i] });
  }
  const endDates = Number(dates[i - 1].substring(8, 10));
  const endMonth = dates[i - 1].substring(5, 7);
  const endYear = dates[i - 1].substring(0, 4);
  const endformat = `${endYear}-${endMonth}-`;
  const endDateOfMonth = moment(dates[i - 1])
    .endOf('month')
    .format('DD');
  if (endDates < endDateOfMonth) {
    for (let i = endDates + 1; i <= endDateOfMonth; i++) {
      arr2.push({ date: endformat + i, status: null, stats_data: null });
    }
  }
  return arr2;
}

export function getCurrentMonthDates(dates, status, stats_data) {
  // const arr3 = [];
  dates = dates || [];
  status = status || [];
  stats_data = stats_data || [];
  const month = getCurrentMonth();
  const year = new Date().getFullYear();

  const FirstDay = new Date(year, month - 1, 1);
  const LastDay = new Date(year, month, 0);
  // const new_date = moment(FirstDay)
  //   .add(10, "d")
  //   .format("YYYY-MM-DD");
  const numOfDays = moment(LastDay).diff(moment(FirstDay), 'days');
  const CurrentDatePopulate = dates.filter(
    ob => Number(ob.substring(5, 7)) === month
  );
  const indexStart = dates.indexOf(CurrentDatePopulate[0]);
  const indexEnd = dates.lastIndexOf(
    CurrentDatePopulate[CurrentDatePopulate.length - 1]
  );
  const CurrentStatusPopulate = status.slice(indexStart, indexEnd + 1);

  let i = 0;
  let arr = [];

  CurrentStatusPopulate.map(eachStatus => {
    if (i <= numOfDays) {
      const newDate = moment(FirstDay)
        .add(i, 'd')
        .format('YYYY-MM-DD');
      i = i + 1;
      arr.push({
        date: newDate,
        status: eachStatus,
        stats_data: null
      });
    }
    return eachStatus;
  });

  if (CurrentStatusPopulate.length < numOfDays) {
    for (; i <= numOfDays; i += 1) {
      const newDate = moment(FirstDay)
        .add(i, 'd')
        .format('YYYY-MM-DD');

      arr.push({
        date: newDate,
        status: null,
        stats_data: null
      });
    }
  }

  return arr;
}

export function getPreviousSiblings(el, filter) {
  let siblings = 0;
  while ((el = el.previousSibling)) {
    if (!filter || filter(el)) siblings += el.offsetHeight;
  }
  return siblings;
}

export function scrollTo(id, e, clickTrue) {
  const element_to_scroll_to = document.getElementById(id);
  element_to_scroll_to.scrollIntoView({ behavior: 'smooth', block: 'start' });

  if (clickTrue) {
    const active = document.querySelectorAll('.Reports-sidebar li a.active');
    for (let j = 0; j < active.length; j++) {
      active[j].className = 'inactive';
    }
    e.target.className = 'active';
  }
}
export function URLQueryParams(queryString) {
  if (!queryString) {
    queryString = window.location.search.slice(1);
  }
  const params = {};

  let queries;

  let temp;

  let i;

  let l;
  // Split into key/value pairs
  queries = queryString.split('&');
  // Convert the array of strings into an object
  for (i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split('=');
    params[temp[0]] = temp[1];
  }
  return params;
}
export function findAvg(arr) {
  if (arr.length > 0) {
    const sum = arr.reduce((a, b) => a + b);
    const avg = sum / arr.length;
    return Math.round(avg);
  }
  return '';
}
export function getOrgId() {
  const activeOrg = window.localStorage.getItem('active-org');
  // return window.location.pathname.split("/")[2];
  return Number(activeOrg);
}
export function getNameFromUrl(url) {
  const urlParts = url
    .replace('http://', '')
    .replace('https://', '')
    .split(/[/?#]/);
  const hostNameArr = urlParts[0].split('.');
  const domainName = hostNameArr[hostNameArr.length - 2];
  return domainName;
}
export function getRoleName(role) {
  if (role === 'AD') return 'Admin';
  return 'Read Only';
}
export function existingApplication(applications, newApp, newAppName) {
  return applications.some(application =>
    newAppName
      ? newApp === application.id
      : newApp === application.name && newAppName
      ? newAppName === application.name
      : true
  );
}
export function validateFormFields(formElements) {
  let isValid = true;
  for (let i = 0; i < formElements.length; i++) {
    if (formElements[i].style.display !== 'none') {
      if (!formElements[i].checkValidity()) {
        isValid = false;
        if (
          formElements[i].nextElementSibling &&
          formElements[i].nextElementSibling.classList
            .toString('')
            .indexOf('validation-msg') > -1
        ) {
          formElements[i].nextElementSibling.style.display = 'none';
        } else if (
          formElements[i].parentNode.nextElementSibling &&
          formElements[i].parentNode.nextElementSibling.classList
            .toString('')
            .indexOf('validation-msg') > -1
        ) {
          formElements[i].parentNode.nextElementSibling.style.display = 'none';
        }
        const parent = formElements[i].parentNode;
        formElements[i].classList.add('invalid-input');
        const span = document.createElement('span');
        span.setAttribute('class', 'validation-msg');
        var props;
        let label = '';
        for (props in formElements[i].attributes) {
          if (formElements[i].attributes[props].name === 'data-labeltext') {
            label = formElements[i].attributes[props].nodeValue;
            break;
          }
        }
        const msg = label.length
          ? document.createTextNode(`Please fill in ${label}`)
          : document.createTextNode(formElements[i].validationMessage);
        span.appendChild(msg);
        if (formElements[i].id === 'freshdesk-domain-url') {
          span.setAttribute(
            'class',
            'validation-msg domain-url-validation-msg'
          );
          parent.parentNode.insertBefore(span, parent.nextSibling);
        } else if (formElements[i].id === 'freshservice-domain-url') {
          span.setAttribute(
            'class',
            'validation-msg domain-url-validation-msg'
          );
          parent.parentNode.insertBefore(span, parent.nextSibling);
        } else if (formElements[i].id === 'twilio-phone-number') {
          span.setAttribute(
            'class',
            'validation-msg twilio-phone-validation-msg'
          );
          parent.parentNode.insertBefore(span, parent.nextSibling);
        } else {
          parent.insertBefore(span, formElements[i].nextSibling);
        }
        return isValid;
      }
      formElements[i].classList.remove('invalid-input');
      if (
        formElements[i].nextElementSibling &&
        formElements[i].nextElementSibling.classList
          .toString('')
          .indexOf('validation-msg') > -1
      ) {
        formElements[i].nextElementSibling.style.display = 'none';
      } else if (
        formElements[i].parentNode.nextElementSibling &&
        formElements[i].parentNode.nextElementSibling.classList
          .toString('')
          .indexOf('validation-msg') > -1
      ) {
        formElements[i].parentNode.nextElementSibling.style.display = 'none';
      }
    }
  }
  return isValid;
}
export function isFutureDate(date) {
  if (new Date(date) > new Date()) return true;
  return false;
}
export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}

export function getStateFromStats(stats) {
  let state = null;
  // there is no unit of time duration, example aggregate_by=none for last 1 hour
  if (stats.start && stats.start === stats.end) {
    if (stats.duration_seconds_paused !== null) {
      state = 'PS';
    } else if (stats.duration_seconds_not_responding !== null) {
      state = 'NR';
    } else if (stats.duration_seconds_reporting_error !== null) {
      state = 'RE';
    } else if (stats.duration_seconds_available !== null) {
      state = 'AV';
    }
  } else if (stats.duration_seconds_paused) {
    state = 'PS';
  } else if (stats.duration_seconds_not_responding) {
    state = 'NR';
  } else if (stats.duration_seconds_reporting_error) {
    state = 'RE';
  } else if (stats.duration_seconds_available) {
    state = 'AV';
  }
  return state;
}

export function addPlaceholdersInChartData(response, maxDataLength) {
  const dummyData = {
    average_response_time_milliseconds_available: null,
    average_response_time_milliseconds_reporting_error: null,
    duration_seconds_available: null,
    duration_seconds_not_responding: null,
    duration_seconds_reporting_error: null,
    end: response.response_times[0] ? response.response_times[0].end : null,
    start: response.response_times[0] ? response.response_times[0].start : null
  };
  while (response.response_times.length < maxDataLength) {
    response.response_times.unshift(dummyData);
  }
  return response;
}

export const getTimezoneDate = (dateTime, timezone) => {
  const userReadableDate = momentTimezone
    .tz(dateTime, timezone || 'UTC')
    .format('D MMMM YYYY');
  return userReadableDate;
};

export const filterByOrganization = results => {
  const currentOrg = getOrgId();
  const filteredResults = results.filter(
    result => result.organization === Number(currentOrg)
  );
  return filteredResults;
};

export const datewithTimezone = (date, timezone) =>
  momentTimezone.tz(date, timezone || 'UTC');

export const getReadableDuration = date => {
  const years = date.years()
    ? date.years() + (date.years() > 1 ? ' yrs ' : ' yr ')
    : null;
  const months = date.months()
    ? date.months() + (date.months() > 1 ? ' mos ' : ' mo ')
    : null;
  const days = date.days()
    ? date.days() + (date.days() > 1 ? ' days ' : ' day ')
    : null;
  const hours = date.hours()
    ? date.hours() + (date.hours() > 1 ? ' hrs ' : ' hr ')
    : null;
  const minutes = date.minutes() ? `${date.minutes()} mins ` : null;
  const seconds = date.seconds() ? `${date.seconds()} secs` : null;

  let result = '';
  if (years) result += years;
  if (months) result += months;
  if (days) result += days;
  if (hours) result += hours;
  if (minutes) result += minutes;
  if (seconds) result += seconds;
  return result;
};

export const getFreshIdUrl = currentOrg => {
  return `https://${currentOrg.freshid_organization}`;
};

export const getLogoutUrl = currentOrg => {
  const freshIdAPIUrl = getFreshIdUrl(currentOrg);
  const redirectUri = encodeURIComponent(
    `${window.location.origin}/freshid/logout/`
  );
  const logOutUrl = `${freshIdAPIUrl}/api/v2/logout?post_logout_redirect_uri=${redirectUri}`;
  return logOutUrl;
};
