import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InfoIcon from 'images/info-red.svg';
import { Input, Button } from 'freemium-ui';
import { getOrgId } from 'utils/utility';

import {
  AuthErrorCard,
  FormSection,
  Form,
  FieldBox,
  FormActions,
  FormField,
  BackButton
} from './styles';
import { Header } from '../styles';

const CreateIntegration = props => {
  const {
    fsIntegration = {},
    isAdding = false,
    isSaving = false,
    isDeleting = false,
    toggleAuthScreen = () => undefined,
    onCreate = () => undefined,
    onClose = () => undefined,
    saveIntegration = () => undefined,
    deleteIntegration = () => undefined,
    addIntegration = () => undefined
  } = props;

  const [initialValues, setInitialValues] = React.useState({
    freshservice_api_key: '',
    data_json: '',
    organization: getOrgId(),
    state: 'AC',
    freshservice_domain: ''
  });

  const submitHandler = (values, actions) => {
    if (values.id) {
      saveIntegration({
        id: values.id,
        actions,
        payload: values,
        fetchFields: !values.is_valid
      });
    } else {
      addIntegration({
        onCreate,
        fetchFields: true,
        payload: values,
        actions
      });
    }
  };

  const formik = useFormik({
    validationSchema: () =>
      Yup.object().shape({
        freshservice_domain: Yup.string()
          .trim()
          .required('Freshservice Domain is required.'),
        freshservice_api_key: Yup.string()
          .min(3, 'Too Short!')
          .trim()
          .required('Freshservice Auth key is required.')
      }),
    initialValues,
    onSubmit: submitHandler,
    enableReinitialize: true
  });

  React.useEffect(() => {
    if (fsIntegration.id) setInitialValues(fsIntegration);
  }, [fsIntegration]);

  return (
    <>
      {fsIntegration.id && (
        <BackButton
          tabIndex={0}
          onKeyDown={({ keyCode }) => {
            if (keyCode === 13) toggleAuthScreen(false);
          }}
          onClick={() => toggleAuthScreen(false)}
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 1L1 6L6 11"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Back</span>
        </BackButton>
      )}
      <Header>
        <div className="form-title">
          {fsIntegration.id ? 'Edit' : 'Create'} Freshservice Integration
        </div>
      </Header>
      {!formik.values.is_valid && formik.values.id && (
        <AuthErrorCard className="error-card">
          <img src={InfoIcon} alt="info" />
          <div className="divider" />
          <div>Freshservice integration is not valid</div>
        </AuthErrorCard>
      )}
      <Form
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <FormSection>
          <FormField>
            <Input
              id="freshservice_domain"
              name="freshservice_domain"
              placeholder="Enter your Freshservice URL here"
              label="Freshservice Account URL"
              isRequired
              isErrored={
                formik.submitCount > 0 && formik.errors.freshservice_domain
              }
              errorText={
                formik.submitCount > 0 && formik.errors.freshservice_domain
                  ? formik.errors.freshservice_domain
                  : ''
              }
              value={formik.values.freshservice_domain}
              onChange={e => formik.handleChange(e)}
              endAdornment={<FieldBox>.freshservice.com</FieldBox>}
            />
            <div className="field-hint">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.freshstatus.io/en/support/solutions/articles/50000003316-freshstatus-freshdesk-integration#Where-can-I-find-my-Freshdesk-account-URL?"
              >
                Where can I find my Freshservice account url?
              </a>
            </div>
          </FormField>
          <FormField>
            <Input
              id="freshservice_api_key"
              name="freshservice_api_key"
              placeholder="Enter your Auth key"
              label="Freshservice Auth Key"
              isRequired
              isErrored={
                formik.submitCount > 0 && formik.errors.freshservice_api_key
              }
              errorText={
                formik.submitCount > 0 && formik.errors.freshservice_api_key
                  ? formik.errors.freshservice_api_key
                  : ''
              }
              value={formik.values.freshservice_api_key}
              onChange={e => formik.handleChange(e)}
            />
            <div className="field-hint">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.freshstatus.io/en/support/solutions/articles/50000003316-freshstatus-freshdesk-integration#Where-can-I-find-my-Freshdesk-API-key?"
              >
                Where can I find my Freshservice API key?
              </a>
            </div>
          </FormField>
        </FormSection>
        <FormActions>
          <div>
            {fsIntegration.id && (
              <Button
                onClick={() => deleteIntegration(formik.values.id)}
                size="normal"
                inline
                htmlType="button"
                disabled={isAdding || isSaving || isDeleting}
                type="danger"
              >
                Remove
              </Button>
            )}
          </div>
          <div>
            <Button
              onClick={onClose}
              size="normal"
              inline
              htmlType="button"
              type="secondary"
            >
              Cancel
            </Button>
            <Button
              size="normal"
              disabled={!formik.dirty || isAdding || isSaving || isDeleting}
              inline
              htmlType="submit"
              type="primary"
            >
              Authorize
            </Button>
          </div>
        </FormActions>
      </Form>
    </>
  );
};

export default CreateIntegration;
