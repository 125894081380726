import React from "react";
import Layout from "../components/Layout";
import Checks from "../containers/Checks.js";

class ChecksPage extends React.Component {
  render() {
	  return (
	    <Layout page="Dashboard">
	      <Checks />
	    </Layout>
	  );
  }
}

export default ChecksPage;