/* eslint-disable no-nested-ternary */
const apiVersion = "v1";
const hostname = window && window.location && window.location.hostname;
export const orgDomainName = hostname.split(".")[0];
export const env =
  hostname.indexOf("freshping-sta91ng.io") > -1
    ? "staging"
    : hostname.indexOf("freshping.io") > -1
    ? "production"
    : "development"; // 'production' or 'development'

const production = {
  APP: "freshping",
  ENV: "production",
  OB_ROLE: "admin",
  HOST: "https://api.freshping.io",
  badgePublicUrl: "https://statuspage.freshping.io",
  clientId: "187900829975335884",
  signupUrl: "https://www.freshworks.com/website-monitoring/login",
  freshsuccessApiKey: "dqn29parrzqxumpvio3dt9p11uzpa4lo",
  freshsuccessAuthKey: "66ee0364da1d8868605d6a6433ebad59",
  chargebeeDomain: "https://freshping.chargebee.com"
};

const staging = {
  APP: "freshping",
  ENV: "staging",
  OB_ROLE: "admin",
  HOST: "https://api.freshping-sta91ng.io",
  badgePublicUrl: "https://statuspage.freshping-sta91ng.io",
  clientId: "152664184247945837",
  signupUrl: "https://staging-fw.freshworks.com/website-monitoring/login",
  freshsuccessApiKey: false,
  freshsuccessAuthKey: false,
  chargebeeDomain: "https://freshping-test.chargebee.com"
};

const development = {
  APP: "freshping",
  ENV: "qa",
  HOST: "https://api.freshping-sta91ng.io",
  OB_ROLE: "admin",
  badgePublicUrl: "https://statuspage.freshping-sta91ng.io",
  signupUrl: "https://staging-fw.freshworks.com/website-monitoring/login",
  freshsuccessApiKey: false,
  freshsuccessAuthKey: false,
  chargebeeDomain: "https://freshping-test.chargebee.com"
};

const config = {
  development,
  staging,
  production
};

export default config[env];

export const API_ROOT = `${config[env].HOST}/${apiVersion}`;
export const BADGE_PUBLIC_URL = `${config[env].badgePublicUrl}`;
export const SIGN_UP_URL = `${config[env].signupUrl}`;

export const FRESHSUCCESS_API_KEY = `${config[env].freshsuccessApiKey}`;
export const FRESHSUCCESS_AUTH_KEY = `${config[env].freshsuccessAuthKey}`;
