import React from 'react';
import { Close as CloseIcon } from 'components/Icons';
import dropdownIcon from 'images/dropdown.svg';

import { Container, Header, Item, Tag } from './style';

const PlaceholderInserter = ({
  onClose = () => undefined,
  expandAll = false,
  placeholders = [],
  onSelect = () => undefined
}) => {
  const [maximized, setMaximized] = React.useState({});

  React.useEffect(() => {
    if (expandAll && placeholders && placeholders.length > 0) {
      const max = {};
      placeholders.forEach(placeholder => {
        max[placeholder.id] = true;
      });
      setMaximized(max);
    }
  }, []);

  return (
    <Container onClick={e => e.stopPropagation()}>
      <Header>
        <div className="heading">Placeholder</div>
        <div className="close-placeholder" role="none" onClick={onClose}>
          <CloseIcon width={18} height={18} />
        </div>
      </Header>
      <div>
        {placeholders.map(item => (
          <Item key={item.id}>
            <div
              className="icon"
              role="none"
              onClick={() =>
                !expandAll &&
                setMaximized({ ...maximized, [item.id]: !maximized[item.id] })
              }
            >
              {!expandAll && <img src={dropdownIcon} alt="open" />}
            </div>
            <div className="data">
              {item.name && (
                <div
                  className="group-name"
                  role="none"
                  onClick={() =>
                    !expandAll &&
                    setMaximized({
                      ...maximized,
                      [item.id]: !maximized[item.id]
                    })
                  }
                >
                  {item.name}
                </div>
              )}
              {maximized[item.id] && (
                <div className="group-options">
                  {item.options &&
                    item.options.map(option => (
                      <Tag
                        key={option.id}
                        onClick={() => onSelect(option.placeholder)}
                      >
                        {option.name}
                      </Tag>
                    ))}
                </div>
              )}
            </div>
          </Item>
        ))}
      </div>
    </Container>
  );
};

export default PlaceholderInserter;
