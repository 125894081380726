import React from "react";
import { connect } from "react-redux";
import Report from "../containers/Report.js";
import "../styles/Reports.css";
import Layout from "../components/Layout";

const Reports = (props) => {
  const { org, history } = props;
  return (
    <Layout page="Reports">
      {org ? <Report history={history} org={org} /> : null}
    </Layout>
  );
};

const mapStateToProps = state => ({ org: state.user.org });

export default connect(
  mapStateToProps,
  {}
)(Reports);
