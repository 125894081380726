import React from 'react';
import { connect } from 'react-redux';
import '../styles/Settings.css';
import UserManagementRow from '../containers/UserManagementRow';
import close from '../images/close.svg';
import invite from '../images/invite_user.svg';
import { Button } from 'freemium-ui';
// import Select from 'react-select';
import 'react-select/dist/react-select.css';
// import { API_ROOT } from '../config';
import { request } from '../utils/Api';
import {
  getOrgId,
  validateFormFields,
  capitalizeFirstLetter
} from '../utils/utility';
import { getCurrentUserOrg } from '../actions';
import { toastr } from 'react-redux-toastr';

const mapStateToProps = state => ({
  org: state.user.org,
  user: state.user.user
});

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUsers: true,
      invitingUsersToOrg: false,
      fullNameCreated: false,
      full_name: '',
      add_to_organization_applications: true,
      toggleInviteUserPopup: false,
      refreshPage: false,
      error: null,
      role: 'AD'
    };
    this.closeInviteUserPopup = this.closeInviteUserPopup.bind(this);
  }

  openInviteUserPopup() {
    this.setState({ toggleInviteUserPopup: true });
  }
  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keyup', this.handleEsc, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keyup', this.handleEsc, false);
  }

  popupRef = React.createRef();

  handleClick = e => {
    if (this.popupRef.current && !this.popupRef.current.contains(e.target)) {
      this.setState({ toggleInviteUserPopup: false });
    }
  };
  handleEsc = e => {
    if (e.which === 27) {
      this.setState({ toggleInviteUserPopup: false, full_name: '' });
    }
  };
  closeInviteUserPopup(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ toggleInviteUserPopup: false, full_name: '', error: null });
  }
  handleChange(evt, value, name) {
    // todo refactor
    if (name === 'full_name') {
      this.setState({ fullNameCreated: true });
    }
    if (name === 'email' && evt.target && evt.target.value === '') {
      this.setState({ fullNameCreated: false });
    }
    this.setState(
      {
        [evt.target ? evt.target.name : name]: evt.target
          ? evt.target.value
          : evt.value
      },
      function() {
        if (name === 'email' && !this.state.fullNameCreated) {
          var email = this.state.email
            .split('@')[0]
            .split('.')[0]
            .split(/[^A-Za-z]/)[0];
          this.setState({ full_name: capitalizeFirstLetter(email) });
        }
      }
    );
  }
  inviteUserToOrg = async e => {
    e.preventDefault();
    var formElements =
      document.forms['invite-user-to-organization-form'].elements;
    if (!validateFormFields(formElements)) return;
    this.setState({ invitingUsersToOrg: true });
    const currentOrg = this.props.org.find(
      org => org.id === Number(getOrgId())
    );
    const newRole =
      currentOrg.number_of_users === currentOrg.maximum_users_allowed;
    if (this.state.role === 'subscriber' || newRole) {
      this.submitInviteUser(e);
      return;
    }
    const {
      role,
      email,
      full_name,
      add_to_organization_applications
    } = this.state;

    var payload = {
      organization: getOrgId(),
      role: role ? role : 'ST',
      email: email,
      full_name: full_name,
      add_to_organization_applications: add_to_organization_applications
    };
    const response = await request('/invite-user-to-organization/', {
      method: 'POST',
      payload
    });
    if (response) this.setState({ invitingUsersToOrg: false });
    if (response.non_field_errors) {
      this.setState({
        error: response.response.non_field_errors[0]
      });
    } else if (response.email) {
      toastr.success('User invited successfully');
      this.setState({
        members: [...this.state.members, response],
        toggleInviteUserPopup: false,
        full_name: ''
      });
      this.props.getCurrentUserOrg();

      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Invite Organisation Member', window._na_module);
      }
    } else {
      this.setState({
        error: response.response.non_field_errors[0] || response.email[0]
      });
    }
  };
  submitInviteUser = async e => {
    e.preventDefault();
    const { email, full_name, add_to_organization_applications } = this.state;
    const body = {
      email,
      name: full_name,
      add_to_organization_applications,
      organization: getOrgId()
    };
    const response = await request('/organization-subscribers/', {
      method: 'POST',
      payload: body
    });
    if (response) this.setState({ invitingUsersToOrg: false });
    if (response.non_field_errors) {
      this.setState({
        error: response.response.non_field_errors[0]
      });
    } else if (response.id) {
      toastr.success('User invited successfully');
      this.setState({
        subscribers: this.state.subscribers.length
          ? [...this.state.subscribers, response]
          : [response],
        toggleInviteUserPopup: false,
        full_name: ''
      });
      this.getOrganizationMembers();

      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Invite Contact', window._na_module);
      }
    } else {
      this.setState({
        error: response.response.non_field_errors[0]
      });
    }
  };

  deleteSubscriber = id => {
    this.setState({
      subscribers: this.state.subscribers.filter(
        subscriber => subscriber.id !== id
      )
    });
    this.getOrganizationMembers();

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Delete Contact', window._na_module);
    }
  };

  deleteMember = id => {
    this.setState({
      members: this.state.members.filter(member => member.id !== id)
    });
    // this.getOrganizationMembers();
    // this.props.getCurrentUserOrg();

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Delete Organisation Member', window._na_module);
    }
  };

  changeUserRole = changedUser => {
    let members = [...this.state.members];
    let index = members.findIndex(member => member.id === changedUser.id);
    members[index] = changedUser;
    this.setState({ members });
  };

  getOwner() {
    if (this.props.org) {
      let results = this.props.org.filter(org => org.id === Number(getOrgId()));
      if (results)
        this.setState({
          owner: results[0].owner_mini.email
        });
    }
  }
  setSubscribers = subscribers => {
    this.setState({ subscribers });
  };
  setMembers = members => {
    this.setState({ members }, function() {
      this.getOwner();
    });
  };

  componentDidMount() {
    this.getOrganizationMembers();
  }

  getOrganizationMembers = async () => {
    const orgUsers = await request(
      `/organization-members/?organization=${getOrgId()}`
    );
    if (orgUsers) {
      this.setMembers(orgUsers.results);
    }
  };

  render() {
    const { subscribers, invitingUsersToOrg, members, error } = this.state; //addingSubscriber
    const currentOrg =
      this.props.org &&
      this.props.org.find(org => org.id === Number(getOrgId()));
    const showAdduser =
      this.props.org &&
      currentOrg.number_of_users < currentOrg.maximum_users_allowed;
    const owner = this.props.org && currentOrg.owner_mini.email;
    return (
      <div className="users-groups-container add-new-user account-settings-container">
        <section className="topbar users-header">
          <span className="tab" onClick={this.handleTabClick}>
            Users{' '}
            {members &&
              currentOrg &&
              `(${members.length}/${currentOrg.maximum_users_allowed})`}
          </span>
          {this.state.showUsers && (
            <div
              ref={this.popupRef}
              style={{ right: '15px', position: 'absolute' }}
              className="tooltip popup add-new-user"
              onClick={e => this.openInviteUserPopup(e)}
            >
              <Button type="secondary" inline>
                <img className="invite-user-image" src={invite} alt="invite" />{' '}
                Invite User
              </Button>
              {this.state.toggleInviteUserPopup ? (
                <div
                  className="modal-body popuptext create-group-popup"
                  id="invite-user-modal"
                >
                  <span className="close-icon-span">
                    <img
                      src={close}
                      alt="Close icon"
                      className="close-icon"
                      onClick={e => this.closeInviteUserPopup(e)}
                    />
                  </span>
                  <form
                    className="invite-user-form"
                    id="invite-user-to-organization-form"
                  >
                    <label htmlFor="email">
                      Email<span className="mandatory"> *</span>
                    </label>
                    <input
                      name="email"
                      type="email"
                      autoComplete="off"
                      required
                      onChange={(e, value) =>
                        this.handleChange(e, value, 'email')
                      }
                    />
                    {error ? <span className="errormsg"> {error} </span> : null}
                    <label htmlFor="full_name">Name</label>
                    <input
                      name="full_name"
                      tye="text"
                      autoComplete="off"
                      onChange={(e, value) =>
                        this.handleChange(e, value, 'full_name')
                      }
                      value={this.state.full_name}
                    />
                    <label htmlFor="role">
                      Role<span className="mandatory"> *</span>
                    </label>
                    <fieldset id="role" className="role">
                      <label disabled={!showAdduser}>
                        <input
                          type="radio"
                          value="AD"
                          name="role"
                          onChange={(e, value) =>
                            this.handleChange(e, value, 'role')
                          }
                          defaultChecked={
                            this.state.role === 'AD' && showAdduser
                          }
                          disabled={!showAdduser}
                        />
                        Admin
                        <span>Has full control</span>
                      </label>
                      <label disabled={!showAdduser}>
                        <input
                          type="radio"
                          value="ST"
                          name="role"
                          onChange={(e, value) =>
                            this.handleChange(e, value, 'role')
                          }
                          defaultChecked={
                            this.state.role === 'ST' && showAdduser
                          }
                          disabled={!showAdduser}
                        />
                        Read only
                        <span>
                          View only permission for dashboard, checks &amp;
                          reports
                        </span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="subscriber"
                          name="role"
                          onChange={(e, value) =>
                            this.handleChange(e, value, 'role')
                          }
                          defaultChecked={!showAdduser}
                        />
                        Contact
                        <span>
                          No login, only alerts are sent (Not considered for
                          pricing)
                        </span>
                      </label>
                    </fieldset>
                    <div className="modal-footer">
                      <Button
                        inline
                        type="primary"
                        disabled={invitingUsersToOrg}
                        onClick={e => this.inviteUserToOrg(e)}
                      >
                        {invitingUsersToOrg ? 'Please wait' : 'Add'}
                      </Button>
                    </div>
                  </form>
                </div>
              ) : null}
              {!this.state.toggleInviteUserPopup ? (
                <span className="tooltiptext">
                  Invite new user to this account
                </span>
              ) : null}
            </div>
          )}
        </section>
        <section
          className="content topAdj"
          style={{ maxWidth: '90%', padding: '0' }}
        >
          <div className="header" style={{ zIndex: '1' }}>
            <span className="name">Name</span>
            <span className="email">Email</span>
            <span className="type">Role</span>
            <span className="checks">Checks</span>
            <span className="report">Checks Alert</span>
            <span className="report"> Weekly Report</span>
            <span className="action"> Action</span>
          </div>

          {this.state.showUsers ? (
            <UserManagementRow
              subscribers={subscribers}
              members={members}
              owner={owner}
              deleteSubscriber={this.deleteSubscriber}
              deleteMember={this.deleteMember}
              setSubscribers={this.setSubscribers}
              setMembers={this.setMembers}
              changeUserRole={this.changeUserRole}
              orgId={this.props.orgId}
              getCurrentUserOrg={this.props.getCurrentUserOrg}
              loggedInuser={this.props.user}
            />
          ) : null}
        </section>
      </div>
    );
  }
}

export default connect(mapStateToProps, { getCurrentUserOrg })(UserSettings);
