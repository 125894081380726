import React from 'react';
import { connect } from 'react-redux';
import 'styles/Settings.css';
import { getOrgId } from 'utils/utility';
import { useOrganizationById } from 'shared/hooks';
import { getCurrentUserOrg as getCurrentUserOrgAction } from 'actions';
import IntegrationItems from 'containers/IntegrationItems';

const Integrations = props => {
  const { getCurrentUserOrg } = props;
  const { data: currentOrg = {}, refetch } = useOrganizationById(getOrgId());
  const updateIntegrationCount = (fetchUsingRedux = true) => {
    if (fetchUsingRedux) getCurrentUserOrg();
    refetch();
  };

  const isIntegrationAllowed =
    currentOrg.number_of_integrations < currentOrg.maximum_integrations_allowed;
  return (
    <div className="users-groups-container account-settings-container">
      <section className="topbar">
        <span className="tab active-tab ">
          Integrations
          {` (${currentOrg.number_of_integrations}/${currentOrg.maximum_integrations_allowed})`}
        </span>
      </section>
      <IntegrationItems
        isIntegrationAllowed={isIntegrationAllowed}
        updateIntegrationCount={updateIntegrationCount}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  org: state.user.org
});

const mapDispatchToProps = dispatch => ({
  getCurrentUserOrg: () => dispatch(getCurrentUserOrgAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
