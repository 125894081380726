import React from 'react';
import styled from 'styled-components';

class Alert extends React.Component {
  state = {
    show: false
  }

  close = () => this.setState({show: false});
  open = () => this.setState({show: true}) 
  
  componentDidMount() {
    this.open();
  }

  render() {
    const {message, type, dismissible} = this.props.info;
    const classNames = `${type} ${dismissible ? 'alert-dismissible': ''}`
    return (
      <React.Fragment>
        {this.state.show ? <AlertWrapper className={classNames}>
          {message}
          <button type="button" className="clear" aria-label="close" onClick={() => this.close()}> <span aria-hidden='true'> x</span> </button>
        </AlertWrapper> : null}
      </React.Fragment>
    );
  }
}

export default Alert;

const AlertWrapper = styled.div`
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: opacity .15s linear;
  
  &.success {
    color: #155724;
    background-color: #d4edda;
    box-shadow: 0 3px 3px #c3e6cb;
  }
  
  &.info {
    color: #004085;
    background-color: #cce5ff;
    box-shadow: 0 3px 3px #b8daff;
  }

  &.error {
    color: #721c24;
    background-color: #f8d7da;
    box-shadow: 0 3px 3px #f5c6cb;
  }

  &.alert-dismissible {
    padding-right: 4rem;
    .clear {
      position: absolute;
      top: calc(50% - 22px);
      right: 0;
      padding: 0 1.25rem;
      color: inherit;
     
    }
  }
  .clear {
    float: right;
    font-size: 1.5rem;
    font-weight: 400;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background: transparent;
    border: 0;
  } 
`;