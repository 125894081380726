/* eslint-disable no-confusing-arrow */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/Input';
import refresh from 'images/refresh.svg';
import RadioGroup from 'shared/components/RadioGroup';
import Select from 'shared/components/Select';
import { get } from 'lodash';
import { getNameFromUrl } from 'utils/utility';
import {
  CheckInputCopy,
  CheckType,
  CheckTypeProtocolPrefix
} from 'constants/check';

import {
  FieldLabel,
  FieldError,
  StyledField,
  StyledRow,
  AutoloadButton
} from '../Form/style';
import { StyledCheckType } from './style';
import { RecordTypes } from './constants';

const propTypes = {
  className: PropTypes.string,
  isEdit: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // name: PropTypes.string.isRequired,
  checkOptions: PropTypes.array,
  min: PropTypes.number,
  metric: PropTypes.string,
  max: PropTypes.number
};

const defaultProps = {
  className: undefined,
  value: undefined,
  metric: '',
  min: 0,
  max: 10
};

const CustomCheckType = props => {
  const {
    className,
    checkOptions,
    formValues,
    isEdit,
    setFieldValue,
    formError,
    isLoadingDns,
    formTouched,
    autoloadDnsRecord
    // value,
    // metric,
    // min,
    // max,
    // onChange
  } = props;

  const { label, placeholder } = CheckInputCopy[formValues.type];
  const urlError = get(formTouched, 'url') && get(formError, 'url');
  const selectedOptions = checkOptions.filter(
    opt =>
      opt.value === formValues.type || opt.value.indexOf(formValues.type) > -1
  );

  const [, urlWithoutPrefix = ''] = (formValues.url || '').split(
    CheckTypeProtocolPrefix[formValues.type]
  ) || ['', ''];

  return (
    <StyledCheckType className={className}>
      <StyledField />
      <StyledField>
        <div>
          <FieldLabel htmlFor="type">Check Type</FieldLabel>
        </div>
        <RadioGroup
          name="type"
          value={isEdit ? selectedOptions[0].value : formValues.type}
          label="Check Type"
          options={isEdit ? selectedOptions : checkOptions}
          onChange={value => {
            if (!isEdit) {
              setFieldValue('url', CheckTypeProtocolPrefix[value]);
              setFieldValue('type', value);
            }
          }}
        />
      </StyledField>
      <StyledField>
        <div>
          <FieldLabel htmlFor="url">
            {label}
            <span>*</span>
          </FieldLabel>
        </div>
        <Input
          onBlurSideEffect={(value, values) => {
            if (
              typeof values.name !== 'undefined' &&
              values.name.length === 0
            ) {
              setFieldValue('name', getNameFromUrl(value));
            }
          }}
          invalid={urlError}
          placeholder={placeholder}
          formValues={formValues}
          onChange={value => {
            setFieldValue('url', value);
          }}
          value={formValues.url}
          name="url"
        />
        {urlError && <FieldError>{urlError}</FieldError>}
      </StyledField>
      {(formValues.type === CheckType.TCP ||
        formValues.type === CheckType.UDP) && (
        <>
          <StyledField>
            <div>
              <FieldLabel htmlFor="port">
                Port number
                <span>*</span>
              </FieldLabel>
            </div>
            <Input
              invalid={get(formTouched, 'port') && get(formError, 'port')}
              placeholder="1 to 65536"
              formValues={formValues}
              onChange={value => {
                setFieldValue('port', value);
              }}
              value={formValues.port}
              name="port"
            />
            {get(formTouched, 'port') && get(formError, 'port') && (
              <FieldError>{get(formError, 'port')}</FieldError>
            )}
          </StyledField>
          <StyledField>
            <div>
              <FieldLabel htmlFor="command_string">
                Command string
                {formValues.type === CheckType.UDP && <span>*</span>}
              </FieldLabel>
            </div>
            <Input
              invalid={
                get(formTouched, 'command_string') &&
                get(formError, 'command_string')
              }
              placeholder={`Enter the string you'd like to send to the ${formValues.type} socket`}
              formValues={formValues}
              onChange={value => {
                setFieldValue('command_string', value);
              }}
              value={formValues.command_string}
              name="command_string"
            />
            {get(formTouched, 'command_string') &&
              get(formError, 'command_string') && (
                <FieldError>{get(formError, 'command_string')}</FieldError>
              )}
          </StyledField>
          <StyledField>
            <div>
              <FieldLabel htmlFor="sucess_string">Success string</FieldLabel>
            </div>
            <Input
              error={get(formTouched, 'sucess_string')}
              placeholder="Enter the string we should look for in the response"
              formValues={formValues}
              onChange={value => {
                setFieldValue('sucess_string', value);
              }}
              value={formValues.sucess_string}
              name="sucess_string"
            />
            {get(formTouched, 'sucess_string') && (
              <FieldError>{get(formError, 'sucess_string')}</FieldError>
            )}
          </StyledField>
          <StyledField>
            <div>
              <FieldLabel htmlFor="error_string">Error string</FieldLabel>
            </div>
            <Input
              error={get(formTouched, 'error_string')}
              placeholder="Enter string to check non existance in response"
              formValues={formValues}
              onChange={value => {
                setFieldValue('error_string', value);
              }}
              value={formValues.error_string}
              name="error_string"
            />
            {get(formTouched, 'error_string') && (
              <FieldError>{get(formError, 'error_string')}</FieldError>
            )}
          </StyledField>
        </>
      )}
      {formValues.type === CheckType.DNS && (
        <>
          <StyledRow>
            <div>
              <div>
                <FieldLabel htmlFor="dns_type">
                  Record Type
                  <span>*</span>
                </FieldLabel>
              </div>
              <Select
                withClearValue={false}
                options={RecordTypes}
                name="dns_type"
                value={formValues.dns_type}
                onChange={val => setFieldValue('dns_type', val)}
              />
              {get(formTouched, 'dns_type') && get(formError, 'dns_type') && (
                <FieldError>{get(formError, 'dns_type')}</FieldError>
              )}
            </div>
            <div>
              <div>
                <FieldLabel htmlFor="dns_value">
                  Record Value
                  <span>*</span>
                </FieldLabel>
              </div>
              <AutoloadButton
                onClick={() => {
                  if (urlWithoutPrefix && formValues.dns_type)
                    autoloadDnsRecord(
                      urlWithoutPrefix,
                      formValues.dns_type,
                      answers => {
                        if (answers) setFieldValue('dns_value', answers);
                      }
                    );
                }}
                className={`${
                  urlWithoutPrefix && formValues.dns_type ? '' : 'disabled'
                }`}
              >
                <img
                  className={isLoadingDns ? 'loading' : ''}
                  src={refresh}
                  alt="refresh"
                />
                <span>Autoload</span>
              </AutoloadButton>
              <Input
                invalid={
                  get(formTouched, 'dns_value') && get(formError, 'dns_value')
                }
                formValues={formValues}
                onChange={value => {
                  setFieldValue('dns_value', value);
                }}
                value={formValues.dns_value}
                name="dns_value"
                placeholder="Enter comma-separated values"
              />
              {get(formTouched, 'dns_value') && get(formError, 'dns_value') && (
                <FieldError>{get(formError, 'dns_value')}</FieldError>
              )}
            </div>
          </StyledRow>
        </>
      )}
    </StyledCheckType>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default CustomCheckType;
