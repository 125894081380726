import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import user from "./user";
import app from "./app";
import applications from "./applications";
import checks from "./checks";
import org from "./org";
import badges from "./badge";
import statuspages from "./statuspages";

export default function createReducer(injectedReducers) {
  return combineReducers({
    routing: routerReducer,
    user,
    app,
    org,
    statuspages,
    checks,
    badges,
    toastr: toastrReducer,
    applications,
    ...injectedReducers
  });
}
