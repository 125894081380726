import React from 'react';
import isequal from 'lodash.isequal';

import {
  Dropdown,
  Input,
  TextArea,
  Checkbox,
  MultiSelectDropdown
} from 'freemium-ui';
import { Error, FlexBox } from './styles';
import { dropdownFieldTypes, stringDropdownFieldTypes } from './constants';

const MultiComponents = props => {
  const { field, formik, setInitialError } = props;
  if (
    dropdownFieldTypes.includes(field.type) &&
    ((field.options && field.options.length === 0) ||
      field.options === undefined)
  ) {
    field.type = 'text';
  }
  const dropDownIdField = stringDropdownFieldTypes.includes(field.type)
    ? 'stringId'
    : 'id';
  const allChoices = {};
  if (field && field.options && field.options.length > 0) {
    field.options.forEach(choice => {
      allChoices[choice[dropDownIdField]] = choice;
    });
  }
  let isErrored = false;
  let isErroredLev2 = false;
  let isErroredLev3 = false;
  let errorText = '';
  let errorTextLev2 = '';
  let errorTextLev3 = '';
  let value = '';
  let valueLev2 = '';
  let valueLev3 = '';
  let choices = [];
  let choicesLev2 = [];
  let choicesLev3 = [];
  let selected = {};
  let selectedLev2 = {};
  let selectedLev3 = {};

  const nameLev2 =
    (field.nested_ticket_fields &&
      field.nested_ticket_fields[0] &&
      field.nested_ticket_fields[0].name) ||
    '';
  const nameLev3 =
    (field.nested_ticket_fields &&
      field.nested_ticket_fields[1] &&
      field.nested_ticket_fields[1].name) ||
    '';

  if (field.default) {
    isErrored = formik.submitCount > 0 && formik.errors[field.name];
    errorText =
      formik.submitCount > 0 && formik.errors[field.name]
        ? formik.errors[field.name]
        : '';

    value = formik.values[field.name] || '';
    if (field.nested_ticket_fields) {
      isErroredLev2 =
        formik.submitCount > 0 && nameLev2 && formik.errors[nameLev2];
      isErroredLev3 =
        formik.submitCount > 0 && nameLev3 && formik.errors[nameLev3];
      errorTextLev2 =
        (formik.submitCount > 0 && nameLev2 && formik.errors[nameLev2]) || '';
      errorTextLev3 =
        (formik.submitCount > 0 && nameLev3 && formik.errors[nameLev3]) || '';
      valueLev2 = (nameLev2 && formik.values[nameLev2]) || '';
      valueLev3 = (nameLev3 && formik.values[nameLev3]) || '';
    }
  } else {
    isErrored =
      formik.submitCount > 0 &&
      formik.errors.custom_fields &&
      formik.errors.custom_fields[field.name];
    errorText =
      (formik.submitCount > 0 &&
        formik.errors.custom_fields &&
        formik.errors.custom_fields[field.name]) ||
      '';
    value =
      (formik.values.custom_fields &&
        formik.values.custom_fields[field.name]) ||
      '';
    if (field.nested_ticket_fields) {
      isErroredLev2 =
        formik.submitCount > 0 &&
        nameLev2 &&
        formik.errors.custom_fields &&
        formik.errors.custom_fields[nameLev2];
      isErroredLev3 =
        formik.submitCount > 0 &&
        nameLev3 &&
        formik.errors.custom_fields &&
        formik.errors.custom_fields[nameLev3];
      errorTextLev2 =
        (formik.submitCount > 0 &&
          nameLev2 &&
          formik.errors.custom_fields &&
          formik.errors.custom_fields[nameLev2]) ||
        '';
      errorTextLev3 =
        (formik.submitCount > 0 &&
          nameLev3 &&
          formik.errors.custom_fields &&
          formik.errors.custom_fields[nameLev3]) ||
        '';
      valueLev2 =
        (nameLev2 &&
          formik.values.custom_fields &&
          formik.values.custom_fields[nameLev2]) ||
        '';
      valueLev3 =
        (nameLev3 &&
          formik.values.custom_fields &&
          formik.values.custom_fields[nameLev3]) ||
        '';
    }
  }

  const defaultSelectedItem = value
    ? field.options.find(choice => choice[dropDownIdField] === value)
    : '';

  if (field.nested_ticket_fields) {
    choices = Object.keys(field.choices || {});
    choicesLev2 = Object.keys((field.choices && field.choices[value]) || {});
    choicesLev3 =
      (field.choices &&
        field.choices[value] &&
        field.choices[value][valueLev2]) ||
      [];
    selected = value ? choices.find(choice => choice === value) : '';
    selectedLev2 = value
      ? choicesLev2.find(choice => choice === valueLev2)
      : '';
    selectedLev3 = valueLev3
      ? choicesLev3.find(choice => choice === valueLev3)
      : '';
  }

  const handleValueChange = (val, name, defaultField) => {
    if (!defaultField) {
      formik.setFieldValue(
        'custom_fields',
        {
          ...formik.values.custom_fields,
          [name]: val
        },
        true
      );
    } else {
      formik.setFieldValue(name, val, true);
    }
  };

  const handleBulkValuesChange = (val, defaultField) => {
    if (!defaultField) {
      formik.setValues(
        {
          ...formik.values,
          custom_fields: {
            ...formik.values.custom_fields,
            ...val
          }
        },
        true
      );
    } else {
      formik.setValues(
        {
          ...formik.values,
          ...val
        },
        true
      );
    }
  };

  React.useEffect(() => {
    const valuesToReplace = {};
    if (
      value &&
      defaultSelectedItem === undefined &&
      field.name &&
      dropdownFieldTypes.includes(field.type)
    ) {
      valuesToReplace[field.name] = undefined;
    }
    if (
      value &&
      selected === undefined &&
      field.name &&
      field.nested_ticket_fields &&
      field.nested_ticket_fields.length > 0
    ) {
      valuesToReplace[field.name] = undefined;
    }
    if (valueLev2 && selectedLev2 === undefined && nameLev2) {
      valuesToReplace[nameLev2] = undefined;
    }
    if (valueLev3 && selectedLev3 === undefined && nameLev3) {
      valuesToReplace[nameLev3] = undefined;
    }
    if (Object.keys(valuesToReplace).length === 0) return;
    let isDeepEqual = false;
    if (field.default) {
      const val = {
        ...formik.values,
        ...valuesToReplace
      };
      isDeepEqual = isequal(val, formik.initialValues);
      formik.setValues(val, true);
    } else {
      const val = {
        ...formik.values.custom_fields,
        ...valuesToReplace
      };
      isDeepEqual = isequal(val, formik.initialValues);
      formik.setFieldValue('custom_fields', val, true);
    }
    if (!isDeepEqual) setInitialError(true);
  });

  switch (field.type) {
    case 'ticket_type':
    case 'source':
    case 'status':
    case 'priority':
    case 'group':
    case 'dropdown':
    case 'agent':
      return (
        <Dropdown
          defaultSelectedItem={defaultSelectedItem || ''}
          itemToString={v => v.value}
          itemComponent={v => v.value}
          isErrored={isErrored}
          errorText={errorText}
          items={field.options}
          onChange={option => {
            if (!option) return;
            handleValueChange(
              option[dropDownIdField],
              field.name,
              field.default
            );
          }}
          disabled={false}
          placeholder={field.description || field.label}
        />
      );
    case 'field':
      return (
        <FlexBox>
          <Dropdown
            defaultSelectedItem={selected || ''}
            itemToString={v => v}
            itemComponent={v => v}
            isErrored={isErrored}
            errorText={errorText}
            items={choices}
            onChange={option => {
              if (!option) return;
              handleBulkValuesChange(
                {
                  [field.name]: option,
                  [nameLev2]: undefined,
                  [nameLev3]: undefined
                },
                field.default
              );
            }}
            placeholder={field.description || field.label}
          />
          <Dropdown
            defaultSelectedItem={selectedLev2 || ''}
            itemToString={v => v}
            itemComponent={v => v}
            isErrored={isErroredLev2}
            errorText={errorTextLev2}
            items={choicesLev2}
            onChange={option => {
              if (!option) return;
              handleBulkValuesChange(
                {
                  [nameLev2]: option,
                  [nameLev3]: undefined
                },
                field.default
              );
            }}
            placeholder={field.nested_ticket_fields[0].label}
          />
          {valueLev2 && choicesLev3.length > 0 && (
            <Dropdown
              defaultSelectedItem={selectedLev3 || ''}
              itemToString={v => v}
              itemComponent={v => v}
              isErrored={isErroredLev3}
              errorText={errorTextLev3}
              items={choicesLev3}
              onChange={option => {
                if (!option) return;
                handleValueChange(option, nameLev3, field.default);
              }}
              placeholder={field.nested_ticket_fields[1].label}
            />
          )}
        </FlexBox>
      );
    case 'number':
    case 'decimal':
      return (
        <Input
          id={field.id}
          name={field.name}
          isErrored={isErrored}
          type="number"
          errorText={errorText}
          placeholder={field.description || field.label}
          value={value}
          onKeyDown={e => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onChange={e =>
            handleValueChange(Number(e.target.value), field.name, field.default)
          }
        />
      );
    case 'date':
      return (
        <Input
          id={field.id}
          name={field.name}
          isErrored={isErrored}
          type="date"
          errorText={errorText}
          placeholder={field.description || field.label}
          value={value}
          onChange={e =>
            handleValueChange(e.target.value, field.name, field.default)
          }
        />
      );
    case 'paragraph':
    case 'description':
      return (
        <TextArea
          id={field.id}
          name={field.name}
          isErrored={isErrored}
          errorText={errorText}
          placeholder={field.description || field.label}
          value={value}
          onChange={e =>
            handleValueChange(e.target.value, field.name, field.default)
          }
        />
      );
    case 'checkbox':
      return (
        <>
          <Checkbox
            onChange={e =>
              handleValueChange(e.target.checked, field.name, field.default)
            }
            checked={value}
          />
          {isErrored && errorText && <Error>{errorText}</Error>}
        </>
      );
    case 'multi_select_dropdown':
      return (
        <MultiSelectDropdown
          id={field.id}
          name={field.name}
          items={field.options}
          selectedItem={
            value && value.length > 0 ? value.map(id => allChoices[id]) : []
          }
          isErrored={isErrored}
          errorText={errorText}
          onChange={val =>
            handleValueChange(
              val.map(choice => choice[dropDownIdField]),
              field.name,
              field.default
            )
          }
          itemLabelIdentifier="value"
          itemValueIdentifier={dropDownIdField}
          placeholder={field.description}
        />
      );
    default:
      return (
        <Input
          id={field.id}
          name={field.name}
          isErrored={isErrored}
          errorText={errorText}
          placeholder={field.description || field.label}
          value={value}
          onKeyDown={e => {
            if (e.keyCode === 13) e.preventDefault();
          }}
          onChange={e =>
            handleValueChange(e.target.value, field.name, field.default)
          }
        />
      );
  }
};

export default MultiComponents;
