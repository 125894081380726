/* eslint-disable import/first */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button } from 'freemium-ui';
import { request } from '../utils/Api';
import { URLQueryParams } from '../utils/utility';
import expandArrow from '../images/expand-arrow.svg';
import emptyLogo from '../images/emptyCheck.png';
import {
  getCurrentUserOrg,
  toggleCheckDrawer,
  setFilterState,
  getChecks
} from '../actions';
import '../styles/Reports.css';
import 'react-select/dist/react-select.css';
import 'antd/dist/antd.css';
class SelectedCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: [],
      checkUrl: ''
    };
  }

  setDefaults = async () => {
    const self = this;
    const activeOrg = window.localStorage.getItem('active-org');
    const response = await request(
      `/checks/?application__organization=${activeOrg}`
    );
    if (response && activeOrg) {
      if (response.status === 401) {
        localStorage.removeItem('auth-token');
        self.context.router.history.push('/login');
        return;
      }
      if (!response.count) {
        self.setState({
          noChecks: true
        });
      } else {
        const filteredObjects = response.results.filter(
          result =>
            result.application_mini.logged_in_user_role &&
            result.application_mini.organization == Number(activeOrg)
        );
        self.setState(
          {
            result: filteredObjects
          },
          function() {
            self.getOptions(self.state.result);
            let checkId = self.state.result[0].id;
            let checkURL = JSON.parse(self.state.result[0].data).url;
            if (window.location.search) {
              const check = URLQueryParams(window.location.search);
              checkId = check['?check_id'];
              checkURL = JSON.parse(self.state.result[0].data).url;
              for (let count = 0; count < self.state.result.length; count++) {
                if (self.state.result[count].id === Number(checkId)) {
                  checkURL = JSON.parse(self.state.result[count].data).url;
                  break;
                }
              }
              if (checkURL === JSON.parse(self.state.result[0].data).url)
                checkId = self.state.result[0].id;
            }
            self.props.onChange('', checkId, checkURL);
          }
        );
      }
      this.setState({
        currentOrg: activeOrg
      });
    }
  };

  componentDidMount() {
    this.setDefaults();
    this.props.getAllChecksRequest();
  }

  onEditCheck() {
    if (window.fcWidget) {
      window.fcWidget.hide();
    }
    const { checks } = this.props.checks;
    const checkInfo =
      checks.length && checks.find(c => c.id === this.state.checkId);
    if (checkInfo) {
      this.props.toggleCheckDrawer({
        isOpen: true,
        check: checkInfo
      });
    } else {
      request(`/checks/${this.state.checkId}`).then(data => {
        if (data) {
          this.props.toggleCheckDrawer({
            isOpen: true,
            check: data
          });
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.checkId !== nextProps.checkId) {
      // this.getSelectedCheckData(nextProps.checkId);
      this.setState({
        checkId: nextProps.checkId
      });
    }
  }

  // getSelectedCheckData = async id => {
  //   const response = await request(`/checks/${id}`);
  //   this.setState({ selectedCheckData: response, checkId: id });
  // };

  // closeModal(e) {
  //   if (window.fcWidget) {
  //     window.fcWidget.show();
  //   }
  //   e.preventDefault();
  //   document.body.classList.remove("remove-overflow");
  //   this.setState({ toggleEditCheckModal: false });
  // }

  handleClick() {
    if (window.fcWidget) {
      window.fcWidget.hide();
    }
    document.getElementById('add-check-modal').style.display = 'block';
    document.body.classList.add('remove-overflow');
  }

  onChecksChange = e => {
    this.setState({ checkUrl: e.target.selectedOptions[0].innerText });
  };

  getOptions = result => {
    const activeOrg = window.localStorage.getItem('active-org');
    const options = [];
    this.state.result &&
      this.state.result.forEach(result => {
        if (
          result.application_mini.logged_in_user_role &&
          result.application_mini.organization === Number(activeOrg)
        ) {
          let checkName = result.data
            ? result.name
              ? result.name
              : JSON.parse(result.data).url
            : '';
          options.push({
            value: result.id,
            label: checkName,
            checkUrl: result.data ? JSON.parse(result.data).url : '',
            title: checkName
          });
        } else {
          return '';
        }
      });
    this.setState({ options });
  };

  render() {
    const { isLoaded, org, deviceSize } = this.props;
    let isAdmin = false;
    org &&
      org.forEach(el => {
        if (
          el.id === Number(this.state.currentOrg) &&
          el.logged_in_user_role === 'AD'
        ) {
          isAdmin = true;
        }
      });
    // const currentOrg =
    //   org && org.find(org => org.id === Number(this.state.currentOrg));
    if (isLoaded) {
      return (
        <div>
          <div className="selected-checks-container">
            <div className="reports-heading">
              <div style={{ display: 'inline-block', maxWidth: '25%' }}>
                <SearchBox>
                  <Select
                    name="form-field-name"
                    className="checks-dropdown custom-dropdown"
                    value={this.props.checkId}
                    clearable={false}
                    searchable
                    onChange={(e, value, text) =>
                      this.props.onChange(e, value, text)
                    }
                    options={this.state.options}
                    onClick={e => {
                      this.text.selectionStart = this.text.length;
                    }}
                  />
                </SearchBox>
              </div>

              {deviceSize && deviceSize === 'device-large' ? (
                <span className="selectedURL">
                  <img src={expandArrow} alt="open" />
                  <a
                    target="_blank"
                    href={this.props.checkUrl}
                    rel="noopener noreferrer"
                  >
                    {this.props.checkUrl}
                  </a>
                </span>
              ) : null}
              {deviceSize && deviceSize === 'device-large' && isAdmin ? (
                <Button
                  inline
                  className=" edit-check"
                  type="secondary"
                  onClick={e => this.onEditCheck(e)}
                >
                  Edit Check
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        {this.state.noChecks ? (
          <StyledEmptyContainer>
            <img
              src={emptyLogo}
              className="emptyLogo"
              alt="Dashboard no checks"
            />
            <div className="empty-check_text">
              Seems like you haven't added any check to get reports
            </div>
          </StyledEmptyContainer>
        ) : (
          <div className="page-loader">
            <div className="Check-Card-Loader">
              <div className="indicator">
                <svg width="16px" height="12px">
                  <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                  <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
// export default SelectedCheck;
const mapStateToProps = state => ({
  checks: state.checks,
  org: state.user.org,
  filterParams: state.checks.filterParams
});

const mapDispatchToProps = dispatch => ({
  getAllChecksRequest: () => dispatch(getChecks()),
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg),
  setFilterState: filter => dispatch(setFilterState(filter)),
  toggleCheckDrawer: state => dispatch(toggleCheckDrawer(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedCheck);

const StyledEmptyContainer = styled.div`
  display: inline-block;
  margin: 150px auto;
  text-align: center;

  .emptyLogo {
    width: 25%;
  }

  .empty-check_text {
    margin: 15px;
    color: #6f7c87;
    font-size: 14px;
  }

  .empty-check_tip {
    font-size: 12px;
    color: #6f7c87;
  }
`;

const SearchBox = styled.div`
  position: relative;
  .Select.is-open .Select-value {
    opacity: 0.4;
  }
  .Select-input > input {
    height: 24px;
  }
  .Select-multi-value-wrapper {
    height: 32px;
  }
`;
