/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable arrow-parens */
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from './Report';
import AreaSplineChart from '../charts/AreaSplineChart';
import SingleBarChart from '../charts/SingleBarChart';
import { FeatureLock } from '../components/styled/AddCheckModal';
import lock from '../images/lock.svg';

const Availability = props => {
  const data = props.checkData;
  const {
    isapdex,
    isavgResponse,
    isResponsegraph,
    checkIsLoading,
    selectedTime,
    deviceSize
  } = props;
  const isAvailable =
    isapdex.length && isavgResponse.length && isResponsegraph.length;
  return (
    <div className="sub-container" style={{ position: 'relative' }}>
      <div id="availability-performance">
        {!isAvailable && (
          <FeatureLock>
            <div className="feature-lock">
              <Link to={`/settings/pricing?interest=responsegraph`}>
                <img src={lock} alt="lock" height="20px" width="12px" />
                <span>GARDEN</span>
              </Link>
            </div>
            <div className="feature-text">
              <div>
                Apdex, Avg. response time &amp; Response time graph are in
                Garden plan.
              </div>
            </div>
          </FeatureLock>
        )}
        <RepsoneMetrics className={!isAvailable ? 'disabled' : ''}>
          <div className="Chartbox">
            <AreaSplineChart
              graphResult={props.graphResult}
              isReportsPage
              checkId={data.check_id}
              checkDetails={data || ''}
              pricingPlan={props.pricingPlan}
              isResponsegraph={isResponsegraph}
              deviceSize={deviceSize}
            />
            <SingleBarChart
              graphResult={props.graphResult}
              isReportsPage
              checkId={data ? data.check_id : ''}
              selectedTime={selectedTime}
            />
          </div>
        </RepsoneMetrics>
        {deviceSize && deviceSize === 'device-large' ? (
          <AvailabilityData className={!isAvailable ? 'disabled' : ''}>
            <div className="response-infobox">
              <div className="ib-metric">
                <span className="info-title">Apdex</span>
                {checkIsLoading ? (
                  <SkeletonWrapper>
                    <Skeleton height={32} width={80} />
                  </SkeletonWrapper>
                ) : (
                  <p className="info-value">{data.apdex_score || '--'}</p>
                )}
              </div>
            </div>
            <div className="response-infobox infobox-inline">
              <div className="ib-metric">
                <span className="info-title">Avg. response time</span>
                {checkIsLoading ? (
                  <SkeletonWrapper>
                    <Skeleton height={32} width={80} />
                  </SkeletonWrapper>
                ) : (
                  <p className="info-value">
                    {isavgResponse && isavgResponse.length
                      ? data.average_response_time_milliseconds_available
                        ? `${data.average_response_time_milliseconds_available} ms`
                        : '0'
                      : '--'}
                  </p>
                )}
              </div>
            </div>
          </AvailabilityData>
        ) : null}
      </div>
    </div>
  );
};
export default Availability;

const RepsoneMetrics = styled.div`
  width: 100%;
  max-width: 75rem;
  position: relative;
  .Chartbox {
    vertical-align: middle;
    margin-right: 30px;
    margin-top: 15px;
  }

  &.disabled {
    opacity: 0.2;
    background: #fbfbfb;
    pointer-events: none;
    user-select: none;
    cursor: not-allowed;
  }
`;

const AvailabilityData = styled.div`
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
  position: relative;
  .info-value {
    padding-top: 0px;
  }
  .infobox-inline {
    margin-top: 8px;
  }
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
    user-select: none;
    cursor: not-allowed;
  }
`;
