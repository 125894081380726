import { lighten, darken, rgba, transparentize } from 'polished';

import palette from './palette';

const {
  jungle,
  jade,
  dodger,
  elephant,
  raven,
  chateau,
  zircon,
  porcelain,
  smoke,
  athens,
  sunrise,
  scotchMist,
  permission,
  ferrari,
  white,
  transparent,
  black,
  skylight,
  passion,
  peach,
  babylon,
  coral,
  S50,
  azure
} = palette;

export default {
  body: {
    background: {
      primary: porcelain, // post login
      secondary: athens // pre login
    }
  },
  primary: elephant,
  link: '#2c5cc5',
  white,
  black,
  text: {
    primary: palette.E900,
    secondary: raven,
    light: athens,
    disabled: palette.S100,
    placeholder: chateau,
    error: permission,
    success: jungle,
    label: palette.S700,
    warning: sunrise,
    extraLight: palette.E700,
    link: '#2c5cc5'
  },
  toolTip: {
    normal: palette.S800,
    success: palette.E500,
    error: palette.E400,
    ferrari,
    black
  },
  label: {
    primary: palette.S700
  },
  success: {
    color: palette.elephant,
    background: '#dff0d8'
  },
  warning: {
    color: palette.elephant,
    background: '#FEF1E1'
  },
  info: {
    color: palette.elephant,
    background: '#d9edf7'
  },
  error: {
    color: palette.elephant,
    background: '#ff595912'
  },
  icon: {
    primary: raven,
    secondary: elephant
  },
  border: {
    primary: palette.S100,
    secondary: palette.S50,
    hover: palette.S700,
    active: elephant,
    disabled: palette.S50,
    error: passion
  },
  background: {
    primary: porcelain,
    secondary: palette.S10,
    hover: smoke,
    disabled: palette.S50,
    selected: palette.E600,
    error: lighten(0.4, permission),
    light: palette.S25
  },
  layout: {
    asidePane: athens
  },
  errorText: {
    primary: passion
  },
  components: {
    menu: {
      background: palette.white,
      menuItem: {
        color: '#12344D',
        hover: {
          background: palette.S50
        },
        disabled: {
          color: '#CFD7DF'
        },
        selected: {
          background: palette.S50
        }
      }
    },
    table: {
      header: {
        background: '#F5F7F9',
        text: '#475867'
      },
      body: {
        background: palette.white,
        text: '#12344D',
        selected: '#E5F2FD',
        hover: '#F5F7F9'
      }
    },
    alert: {
      background: scotchMist
    },
    input: {
      primary: {
        borderColor: palette.S100
      }
    },
    checkbox: {
      backgroundColor: azure,
      borderColor: azure
    },
    radio: {
      hoverBorderColor: azure,
      backgroundColor: azure,
      borderColor: '#fbd0ae'
    },
    button: {
      danger: {
        backgroundColor: ferrari,
        backgroundGradient: {
          from: passion,
          to: ferrari
        },
        hover: ferrari,
        borderColor: ferrari,
        color: white,
        disabled: {
          borderColor: coral,
          backgroundGradient: {
            from: babylon,
            to: coral
          },
          color: peach
        },
        active: {
          borderColor: babylon,
          backgroundColor: ferrari
        }
      },
      link: {
        backgroundColor: transparent,
        backgroundGradient: {
          from: transparent,
          to: transparent
        },
        hover: palette.S50,
        borderColor: transparent,
        color: azure,
        disabled: {
          borderColor: transparent,
          backgroundGradient: {
            from: transparent,
            to: transparent
          },
          color: '#149999'
        },
        active: {
          borderColor: transparent,
          backgroundColor: transparent
        }
      },
      buttonGroup: {
        normal: palette.E300
      }
    },
    inlineMessage: {
      error: permission,
      warning: sunrise,
      info: dodger,
      success: jade
    },
    inlineBanner: {
      border: {
        success: transparentize(0.7, jungle),
        info: transparentize(0.7, dodger),
        warning: transparentize(0.7, sunrise),
        error: transparentize(0.7, permission)
      },
      background: {
        success: transparentize(0.9, jungle),
        info: transparentize(0.9, dodger),
        warning: transparentize(0.9, sunrise),
        error: transparentize(0.9, permission)
      }
    },
    panel: {
      gradientStart: white,
      gradientEnd: athens,
      border: zircon,
      background: smoke,
      level: {
        warning: {
          backgroundColor: scotchMist,
          borderColor: darken(0.2, scotchMist)
        },
        danger: {
          backgroundColor: lighten(0.35, permission),
          borderColor: lighten(0.1, permission)
        }
      }
    },
    confirmModal: {
      background: palette.S50,
      border: zircon
    },
    modal: {
      overlayBackground: transparentize(0.5, palette.E900)
    },
    tag: {
      background: {
        success: lighten(0.6, jungle),
        error: lighten(0.4, permission),
        neutral: lighten(0.4, '#149999'),
        warning: lighten(0.4, sunrise),
        info: 'transparent'
      },
      border: {
        success: lighten(0.1, jungle),
        error: lighten(0.2, permission),
        neutral: lighten(0.2, '#149999'),
        warning: lighten(0.2, sunrise),
        info: palette.S100
      },
      color: {
        success: jungle,
        error: permission,
        neutral: '#149999',
        warning: sunrise,
        info: palette.E900
      }
    },
    railNavigation: {
      backgroundColor: palette.E900,
      activeColor: rgba(white, 0.3)
    },
    listCard: {
      borderLeft: '#149999',
      highlight: lighten(0.4, dodger),
      background: smoke
    },
    tabs: {
      color: palette.clara,
      hoverBg: '#ffeedb',
      disabledBg: palette.S50,
      disabledColor: '#fbd0ae',
      selectedBorderColor: palette.clara,
      selectedColor: palette.clara
    },
    tooltip: {
      backgroundColor: palette.E900
    },
    accordion: {
      header: {
        borderColor: zircon
      },
      section: {
        background: palette.S10,
        borderColor: palette.S50
      }
    },
    toast: {
      success: jungle,
      error: permission,
      loading: '#149999',
      warning: sunrise
    }
  },
  pseudo: {
    focus: palette.S100,
    hover: S50,
    highlight: skylight
  }
};
