import { delay, eventChannel } from "redux-saga";
import queryString from "query-string";
import { call, all, put, fork, take, select } from "redux-saga/effects";
import { request } from "../utils/Api";
import { getOrgId } from "../utils/utility";
import {
  OFFLINE,
  ONLINE,
  goOnline,
  goOffline,
  refreshCounter,
  getChecks,
  getCheck,
  GET_ALL_CHECK_STATUSES,
  GET_APPLICATIONS_REQUEST,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_FAILURE,
  GET_CHECKS_REQUEST,
  getChecksSuccess,
  getCheckStatuses,
  getCurrentUserOrg,
  SET_FILTER_STATE
} from "../actions";

const POLLING_DELAY = 60000;
const POLL_RESET_COUNT = 60;

export function* getApplications() {
  while (true) {
    yield take(GET_APPLICATIONS_REQUEST);
    const response = yield call(request, "/applications/");
    if (response && Object.keys(response).length) {
      if (response.results.length) {
        yield all(response.results.map(check => call(getCheckApi, check.id)));
        yield put({
          type: GET_APPLICATIONS_SUCCESS,
          payload: response
        });
      } else {
        yield put({
          type: GET_APPLICATIONS_FAILURE,
          payload: response
        });
      }
    }
  }
}

export function* getAllChecksStatuses() {
  while (true) {
    yield take(GET_ALL_CHECK_STATUSES);
    yield put(goOffline());
    const state = yield select();
    yield all(
      state.checks.checks.map(check => call(getCheckStatusesApi, check.id))
    );
  }
}

export function* routeChangeWatcher() {
  while (true) {
    const action = yield take("@@router/LOCATION_CHANGE");
    const isDashboard = action.payload.pathname.indexOf("dashboard") > -1;
    if (isDashboard) {
      yield put(goOnline());
    } else {
      yield put(goOffline());
    }
  }
}

export function* getChecksAndFilteredOrg() {
  while (true) {
    // const action = yield take(GET_CHECKS_REQUEST);
    yield take(GET_CHECKS_REQUEST);
    const filters = yield select(state => state.checks.filterParams);
    const params = {};
    Object.keys(filters).forEach(prop => {
      if (filters[prop] && filters[prop] !== "all") {
        params[prop] = filters[prop];
      }
    });
    const qp = queryString.stringify({
      application__organization: getOrgId(),
      ...params
    });
    yield put(goOffline());
    const response = yield call(request, `/checks/?${qp}`);
    // const response = yield call(request, `/checks/?application__organization=${getOrgId()}`);
    if (response && Object.keys(response).length) {
      // if (response.results.length) {
      yield put(
        getChecksSuccess({
          response: { results: response.results, totalResults: response.count }
        })
      );
      yield all(
        response.results.map(check => call(getCheckStatusesApi, check.id))
      );
      // } else {
      //   yield put(getChecksSuccess({ response: { results: response.results } }));
      //   // yield put(getChecksFailure({ response }));
      // }
    }
  }
}

function* getCheckApi(id) {
  yield put(getCheck(id));
}

function* getCheckStatusesApi(id) {
  yield put(getCheckStatuses(id));
}

function* applicationPollingWorker() {
  while (true) {
    yield call(delay, POLLING_DELAY);
    yield put(getCurrentUserOrg());
    yield put(getChecks());
    yield put(refreshCounter(60));
  }
}

function* updateCounter(count) {
  yield put(refreshCounter(count));
}

function* refreshCounterWorker() {
  while (true) {
    yield call(delay, 1000);
    yield call(
      updateCounter,
      yield select(state => {
        if (state.app.counter === 0) {
          return POLL_RESET_COUNT;
        }
        return state.app.counter - 1;
      })
    );
  }
}

function* launch(workers) {
  for (const worker of workers) {
    yield fork(worker);
  }
}

export function* manager() {
  const workers = [applicationPollingWorker, refreshCounterWorker];
  while (true) {
    const task = yield fork(launch, workers);
    yield take(OFFLINE);
    task.cancel();
    yield take(ONLINE);
  }
}

function createVisibilityChannel() {
  return eventChannel(emit => {
    const change = () => {
      emit(document.hidden);
    };
    document.addEventListener("visibilitychange", change);
    return () => {
      document.removeEventListener("visibilitychange", change);
    };
  });
}

export function* watcher() {
  const channel = createVisibilityChannel();
  while (true) {
    const action = (yield take(channel)) ? goOffline() : goOnline();
    if (window.location.href.indexOf("dashboard") > -1) {
      yield put(action);
    }
  }
}

export function* setFilterState() {
  while (true) {
    yield take(SET_FILTER_STATE);
    yield put(getChecks());
  }
}
