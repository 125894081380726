import styled from 'styled-components';

import { color } from 'shared/utils/styles';

export const RadioOption = styled.label`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  border: solid 1px
    ${props => (props.isActive ? 'var(--text-link)' : 'var(--border-color)')};
  border-right: none;
  background-color: ${props => (props.isActive ? 'var(--text-link)' : '#fff')};
  color: ${props => (props.isActive ? 'var(--color-milk)' : color.textNormal)};
  font-size: 11px;
  min-width: 45px;
  padding: 0 6px;
  cursor: pointer;
  user-select: none;
  input[type='radio'] {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &:last-child {
    &.active {
      border-right: solid 1px var(--text-link);
    }
  }
  &.active + label {
    border-left: solid 1px var(--text-link);
  }

  &:hover {
    color: var(--base-color);
    background-color: var(--color-smoke-25);
  }
  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-right: solid 1px var(--border-color);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
export const Options = styled.div`
  position: relative;
  display: flex;
  height: 32px;
  width: 100%;
  justify-content: ${props => props.justify};
`;

export const StyledRadioGroup = styled.div`
  height: 100%;
  width: ${props => (props.inline ? 'auto' : '100%')};
  margin-top: ${props => (props.inline ? '0' : '10px')};
`;
