/* eslint-disable camelcase */
import React from "react";
import { withRouter } from "react-router-dom";
import { FP_USER_LOCALSTATE } from "../constants/constant";

class tokenLogin extends React.Component {
  componentWillMount() {
    const { auth_token, organization_id } = this.props.match.params;
    window.localStorage.setItem("auth-token", auth_token);
    window.localStorage.setItem("active-org", organization_id);
    if (window.localStorage.getItem(FP_USER_LOCALSTATE) !== "visited") {
      window.localStorage.setItem(FP_USER_LOCALSTATE, "first");
    }
    this.props.history.push("/");
  }

  render() {
    return <div />;
  }
}

export default withRouter(tokenLogin);
