/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
import React from 'react';
import matchSorter from 'match-sorter';
import styled, { css } from 'styled-components';
import { ChevronDown } from 'components/Icons';

import { color, font, mixin, zIndexValues } from 'shared/utils/styles';

export const ChevronIcon = styled(ChevronDown)`
  margin-left: auto;
  font-size: 18px;
  color: ${color.textMedium};
`;

export const ClearIcon = styled.span`
  position: absolute;
  top: 4px;
  right: 7px;
  padding: 5px;
  font-size: 16px;
  color: ${color.textMedium};
  ${mixin.clickable}
`;

export const StyledSelect = styled.div`
  position: relative;
  cursor: pointer;
  min-width: 40px;
  ${font.size(14)}
  ${props => props.variant === 'empty' && `display: inline-block;`}
  ${props =>
    props.variant === 'normal' &&
    css`
      width: ${p => (p.isInline ? 'auto' : '100%')};
      border-bottom: 1px solid ${color.borderLightest};
      &:hover {
        border-bottom: 1px solid ${color.primary};
      }
    `}
  &:focus {
    outline: none;
    ${props =>
      props.variant === 'normal' &&
      css`
        border-bottom: 1px solid ${color.borderLightest};
        background: #fff;
      }
    `}
  }
  ${props =>
    props.invalid &&
    css`
      &,
      &:focus {
        border: 1px solid ${color.danger};
        box-shadow: none;
      }
    `}
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${props =>
    props.variant === 'normal' &&
    css`
      min-height: 37px;
      padding: 5px 5px 5px 5px;
    `}
`;

export const Placeholder = styled.div`
  color: ${color.textLight};
`;

export const ValueMulti = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${props => props.variant === 'normal' && `padding-top: 5px;`}
`;

export const ValueMultiItem = styled.div`
  margin: 0 5px 5px 0;
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  color: ${color.textDarkest};
  background: ${color.backgroundMedium};
  ${font.bold}
  ${font.size(12)}
  i {
    margin-left: 4px;
  }
`;

export const AddMore = styled.div`
  display: inline-block;
  margin-bottom: 3px;
  padding: 3px 0;
  ${font.size(12.5)}
  cursor: pointer;
  color: ${color.textLink};
  ${font.medium}
  &:hover, &:visited, &:active {
    color: ${color.textLink};
  }
  &:hover {
    text-decoration: underline;
  }
  i {
    margin-right: 3px;
    vertical-align: middle;
    font-size: 14px;
  }
`;

export const Dropdown = styled.div`
  z-index: ${zIndexValues.dropdown};
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0 0 4px 4px;
  background: #fff;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
  ${props => (props.width ? `width: ${props.width}px;` : 'width: 100%;')}
`;

export const DropdownInput = styled.input`
  padding: 10px 14px 8px;
  width: 100%;
  border: none;
  color: ${color.textDarkest};
  background: none;
  &:focus {
    outline: none;
  }
`;

export const Options = styled.div`
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Option = styled.div`
  padding: 5px 5px;
  word-break: break-word;
  cursor: pointer;
  &:last-of-type {
    margin-bottom: 0px;
  }
  &.option-is-active {
    background: var(--color-smoke-50);
  }
`;

export const OptionsNoResults = styled.div`
  padding: 10px 15px 11px;
  color: ${color.textLight};
`;

const Item = styled('li')(
  {
    position: 'relative',
    cursor: 'pointer',
    display: 'block',
    border: 'none',
    height: 'auto',
    textAlign: 'left',
    borderTop: 'none',
    lineHeight: '1em',
    color: 'rgba(0,0,0,.87)',
    fontSize: '1rem',
    textTransform: 'none',
    fontWeight: '400',
    boxShadow: 'none',
    padding: '.8rem 1.1rem',
    whiteSpace: 'normal',
    wordWrap: 'normal'
  },
  ({ isActive, isSelected }) => {
    const styles = [];
    if (isActive) {
      styles.push({
        color: 'rgba(0,0,0,.95)',
        background: 'rgba(0,0,0,.03)'
      });
    }
    if (isSelected) {
      styles.push({
        color: 'rgba(0,0,0,.95)',
        fontWeight: '700'
      });
    }
    return styles;
  }
);
const onAttention = '&:hover, &:focus';
const Input = styled('input')(
  {
    width: '100%', // full width - icon width/2 - border
    fontSize: 14,
    wordWrap: 'break-word',
    lineHeight: '1em',
    outline: 0,
    whiteSpace: 'normal',
    minHeight: '2em',
    background: '#fff',
    display: 'inline-block',
    padding: '1em 2em 1em 1em',
    color: 'rgba(0,0,0,.87)',
    boxShadow: 'none',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: '.30rem',
    transition: 'box-shadow .1s ease,width .1s ease',
    [onAttention]: {
      borderColor: '#96c8da',
      boxShadow: '0 2px 3px 0 rgba(34,36,38,.15)'
    }
  },
  ({ isOpen }) =>
    isOpen
      ? {
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
          [onAttention]: {
            boxShadow: 'none'
          }
        }
      : null
);

const Label = styled('label')({
  fontWeight: 'bold',
  display: 'block',
  marginBottom: 10
});

const BaseMenu = styled('ul')(
  {
    padding: 0,
    marginTop: 0,
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    maxHeight: '20rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    outline: '0',
    transition: 'opacity .1s ease',
    borderRadius: '0 0 .28571429rem .28571429rem',
    boxShadow: '0 2px 3px 0 rgba(34,36,38,.15)',
    borderColor: '#96c8da',
    borderTopWidth: '0',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: 'solid'
  },
  ({ isOpen }) => ({
    border: isOpen ? null : 'none'
  })
);

const Menu = React.forwardRef((props, ref) => (
  <BaseMenu innerRef={ref} {...props} />
));

const ControllerButton = styled('button')({
  backgroundColor: 'transparent',
  border: 'none',
  position: 'absolute',
  right: 0,
  top: 0,
  cursor: 'pointer',
  width: 47,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center'
});

function ArrowIcon({ isOpen }) {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={16}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
      transform={isOpen ? 'rotate(180)' : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}

function XIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={12}
      fill="transparent"
      stroke="#979797"
      strokeWidth="1.1px"
    >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  );
}

function getItems(filter, allItems) {
  return filter
    ? matchSorter(allItems, filter, {
        keys: ['label']
      })
    : allItems;
}

function getStringItems(filter) {
  return getItems(filter).map(({ name }) => name);
}

function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

async function getItemsAsync(filter, { reject }) {
  await sleep(Math.random() * 2000);
  if (reject) {
    // this is just so we can have examples that show what happens
    // when there's a request failure.
    throw new Error({ error: 'request rejected' });
  }
  return getItems(filter);
}

const itemToString = i => (i ? i.name : '');

export {
  Menu,
  ControllerButton,
  Input,
  Item,
  ArrowIcon,
  XIcon,
  Label,
  css,
  itemToString,
  getItems,
  getStringItems,
  getItemsAsync
};
