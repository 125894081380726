import React from 'react';
import { useFormik } from 'formik';
import isequal from 'lodash.isequal';
import EmptyFieldsIcon from 'images/empty-fields.svg';
import Loader from 'components/Loader';
import PlusIcon from 'images/plus-blue.svg';
import MinusIcon from 'images/minus-red.svg';
import InfoIcon from 'images/info-red.svg';
import { Button, Dropdown } from 'freemium-ui';

import {
  Form,
  FieldList,
  FieldListHeader,
  IconHolder,
  FormErrorCard,
  EmptyField,
  OptionalField,
  Center,
  SubSection,
  Actions,
  FieldListItem,
  StickyAddNew
} from './styles';

import { Header } from '../styles';

import { useFdFields, useSaveFdIntegration } from '../hooks';
import { getValidationSchema } from './utils';
import MultiComponents from './MultiComponents';

let order = 1;

const IntegrationForm = props => {
  const {
    fdIntegration = {},
    onClose = () => {},
    toggleAuthScreen = () => {}
  } = props;
  const { data_json } = fdIntegration || {};
  const [addField, toggleAdd] = React.useState(false);
  const [showInitialError, setInitialError] = React.useState(false);
  const [fieldOrder, setFieldOrder] = React.useState({});

  const jsonData = data_json ? JSON.parse(data_json) : {};
  const [initialValues, setInitialValues] = React.useState({
    ...jsonData,
    custom_fields: {
      ...jsonData.custom_fields
    }
  });

  const {
    mutate: saveFdIntegration,
    isLoading: isFdSaving
  } = useSaveFdIntegration();
  const { data: fields = [], isFetching: isLoadingFields } = useFdFields(
    fdIntegration
  );

  const submitHandler = (values, actions) => {
    toggleAdd(false);
    const payload = {
      ...fdIntegration,
      data_json: JSON.stringify(values)
    };
    saveFdIntegration({ id: payload.id, payload, actions });
  };

  const formik = useFormik({
    validationSchema: () => getValidationSchema(fields),
    initialValues,
    onSubmit: submitHandler,
    enableReinitialize: true
  });

  const fieldOptions = fields.filter(field =>
    field.default
      ? formik.values[field.name] === undefined && !field.isMandatory
      : (formik.values.custom_fields &&
          formik.values.custom_fields[field.name]) === undefined &&
        !field.isMandatory
  );

  const fieldsToShow = fields
    .filter(
      field =>
        field.isMandatory ||
        (field.default
          ? formik.values[field.name] !== undefined
          : (formik.values.custom_fields &&
              formik.values.custom_fields[field.name]) !== undefined)
    )
    .sort((a, b) => {
      if (!a.isMandatory === !b.isMandatory)
        return (fieldOrder[a.name] || -1) - (fieldOrder[b.name] || -1);
      return !b.isMandatory ? -1 : 1;
    });

  React.useEffect(() => {
    if (fields && fields.length === 0) return;
    const newCurrentData = {};
    const newEmptyInitialValues = {
      custom_fields: {}
    };
    const initialize = (fld, isNestedField = false) => {
      if (
        jsonData[fld.name] ||
        jsonData[fld.name] === '' ||
        (jsonData.custom_fields &&
          (jsonData.custom_fields[fld.name] ||
            jsonData.custom_fields[fld.name] === ''))
      ) {
        if (fld.default) {
          newCurrentData[fld.name] = jsonData[fld.name];
        } else {
          if (!newCurrentData.custom_fields) {
            newCurrentData.custom_fields = {};
          }
          newCurrentData.custom_fields[fld.name] =
            jsonData.custom_fields[fld.name];
        }
      }

      if (fld.isMandatory) {
        // If dependent has only 2 fields but mandatory then 3rd field can be omited
        if (isNestedField && !jsonData[fld.name]) return;
        if (!fld.default) {
          if (!newEmptyInitialValues.custom_fields) {
            newEmptyInitialValues.custom_fields = {};
          }
          newEmptyInitialValues.custom_fields[fld.name] = '';
        } else {
          newEmptyInitialValues[fld.name] = '';
        }
      }
    };

    fields.forEach(field => {
      // If field is deleted in fdesk delete it from formik
      initialize(field);
      if (field.nested_ticket_fields && field.nested_ticket_fields.length > 0) {
        field.nested_ticket_fields.forEach(fld =>
          initialize(
            {
              ...fld,
              isMandatory: field.isMandatory,
              default: field.default
            },
            true
          )
        );
      }
    });
    const valuesToSave = {
      ...newEmptyInitialValues,
      ...newCurrentData
    };
    if (formik.submitCount > 0) {
      // Initialize the form after save
      setInitialError(false);
      setInitialValues(valuesToSave);
    } else {
      const isDeepEqual = isequal(formik.initialValues, valuesToSave);
      if (!isDeepEqual) {
        setInitialError(true);
      }
      formik.setValues(valuesToSave, true);
    }
  }, [fields, fdIntegration]);

  const onDeleteField = field => {
    let newValues = {};
    const valuesToChange = {};
    valuesToChange[field.name] = undefined;
    if (field.nested_ticket_fields && field.nested_ticket_fields.length > 0) {
      field.nested_ticket_fields.forEach(fld => {
        valuesToChange[fld.name] = undefined;
      });
    }
    if (field.default) {
      newValues = { ...formik.values, ...valuesToChange };
    } else {
      newValues = {
        ...formik.values,
        custom_fields: {
          ...(formik.values.custom_fields || {}),
          ...valuesToChange
        }
      };
    }
    formik.setValues(newValues, true);
  };

  if (isLoadingFields)
    return (
      <Center>
        <Loader />
      </Center>
    );

  const statusError = Boolean(fdIntegration && fdIntegration.ticket_status);
  const validError = Boolean(!formik.isValid);
  const initialError = Boolean(showInitialError && formik.dirty);

  return (
    <>
      <Form
        statusError={statusError}
        validError={validError}
        initialError={initialError}
      >
        <Header>
          <div>
            <div className="form-title">Edit Freshdesk Integration</div>
            {fdIntegration.id && (
              <div className="description">
                {fdIntegration.freshdesk_domain}.freshdesk.com
              </div>
            )}
          </div>
          <div
            className="action"
            role="button"
            tabIndex={0}
            onKeyDown={({ keyCode }) => {
              if (keyCode === 13) toggleAuthScreen(true);
            }}
            onClick={() => {
              toggleAuthScreen(true);
            }}
          >
            Edit Authentication
          </div>
        </Header>
        {statusError && (
          <FormErrorCard
            color="#12344d"
            background="#ffecf0"
            borderColor="#ffd0d6"
            className="error-card"
          >
            <img src={InfoIcon} alt="info" />
            <div className="divider" />
            <div>Unable to create last ticket,</div>
            <div className="message">{fdIntegration.ticket_status}</div>
          </FormErrorCard>
        )}
        {validError && !initialError && (
          <FormErrorCard
            color="#262626"
            background="#fef1e1"
            borderColor="#fddbb5"
            className="error-card"
          >
            <img src={InfoIcon} alt="info" />
            <div className="divider" />
            <div>Fill all Freshdesk mandatory fields to create the tickets</div>
          </FormErrorCard>
        )}
        {initialError && (
          <FormErrorCard
            color="#262626"
            background="#fef1e1"
            borderColor="#fddbb5"
            className="error-card"
          >
            <img src={InfoIcon} alt="info" />
            <div className="divider" />
            <div>
              Ticket fields have been modified in Freshdesk. Please update and{' '}
              <strong>Save</strong> again
            </div>
          </FormErrorCard>
        )}
        {(fieldsToShow && fieldsToShow.length > 0) ||
        addField ||
        formik.dirty ? (
          <>
            <SubSection>
              <div className="integration-heading">
                Choose the fields needed for freshdesk ticket creation
              </div>
              <FieldList>
                <FieldListHeader>
                  <div>Fields from Freshdesk</div>
                  <div>Values</div>
                </FieldListHeader>
                {fieldsToShow.map(field => {
                  const { isMandatory } = field;
                  return (
                    <FieldListItem key={field.id} id={field.name}>
                      <div>
                        <IconHolder
                          onClick={() => !isMandatory && onDeleteField(field)}
                        >
                          {!isMandatory && <img src={MinusIcon} alt="info" />}
                        </IconHolder>
                        <div>{field.label}</div>
                        {isMandatory && <div className="required">*</div>}
                      </div>
                      <div>
                        <MultiComponents
                          formik={formik}
                          field={field}
                          setInitialError={setInitialError}
                        />
                      </div>
                    </FieldListItem>
                  );
                })}
                {addField && fieldOptions.length > 0 && (
                  <FieldListItem>
                    <div>
                      <IconHolder onClick={() => toggleAdd(false)}>
                        <img src={MinusIcon} alt="info" />
                      </IconHolder>
                      <Dropdown
                        type="input"
                        defaultSelectedItem={null}
                        itemToString={v => v.label}
                        itemComponent={v => v.label}
                        filterKey="label"
                        items={fieldOptions}
                        placeholder="Add Field"
                        onChange={field => {
                          toggleAdd(false);
                          setFieldOrder({
                            ...fieldOrder,
                            [field.name]: order
                          });
                          order += 1;
                          if (field.default) {
                            formik.setFieldValue(field.name, '', true);
                          } else {
                            const oldCustomFields = {
                              ...formik.values.custom_fields
                            };
                            formik.setFieldValue(
                              'custom_fields',
                              {
                                ...oldCustomFields,
                                [field.name]: ''
                              },
                              true
                            );
                          }
                        }}
                      />
                    </div>
                    <div />
                  </FieldListItem>
                )}
              </FieldList>
              <StickyAddNew>
                {fieldOptions.length > 0 && (
                  <OptionalField onClick={() => toggleAdd(true)}>
                    <img src={PlusIcon} alt="info" />
                    <div className="text">Add Optional fields</div>
                  </OptionalField>
                )}
              </StickyAddNew>
            </SubSection>
            <Actions showBorder>
              <div className="action-left" />
              <div className="action-right">
                <Button
                  size="normal"
                  disabled={
                    formik.isSubmitting || isLoadingFields || isFdSaving
                  }
                  onClick={e => {
                    e.preventDefault();
                    onClose();
                  }}
                  inline
                  type="secondary"
                >
                  Cancel
                </Button>
                <Button
                  size="normal"
                  disabled={
                    formik.isSubmitting ||
                    !formik.dirty ||
                    isLoadingFields ||
                    isFdSaving
                  }
                  onClick={e => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                  inline
                  type="primary"
                >
                  {isFdSaving ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </Actions>
          </>
        ) : (
          <EmptyField>
            <img src={EmptyFieldsIcon} alt="info" />
            <div className="heading">No Mandatory fields</div>
            <div className="description">
              Choose the optional fields needed for freshdesk ticket creation
            </div>
            {fieldOptions.length > 0 && (
              <OptionalField onClick={() => toggleAdd(true)}>
                <img src={PlusIcon} alt="info" />
                <div className="text">Add Optional fields</div>
              </OptionalField>
            )}
          </EmptyField>
        )}
      </Form>
    </>
  );
};

export default IntegrationForm;
