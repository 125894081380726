import * as Yup from 'yup';

export const isMandatory = field => field.required_for_agents || field.required;

export const getValidationSchema = fields => {
  const objectShape = {};
  const customFieldsShape = {};
  return Yup.lazy(values => {
    fields.forEach(field => {
      if (isMandatory(field)) {
        if (field.default_field) {
          objectShape[field.name] = Yup.string().required(
            `${field.label} is mandatory`
          );
          if (field.nested_fields && field.nested_fields.length > 0) {
            const choices = {};
            const selected =
              field.choices && field.choices.length > 0
                ? field.choices.find(
                    choice => choice.value === values[field.name]
                  )
                : {};
            choices['0'] = (selected && selected.nested_options) || [];
            const selectedLev2 = choices['0'].find(
              choice =>
                choice.value ===
                ((field.nested_fields &&
                  field.nested_fields[0] &&
                  field.nested_fields[0].name &&
                  values[field.nested_fields[0].name]) ||
                  {})
            );
            choices['1'] = (selectedLev2 && selectedLev2.nested_options) || [];
            field.nested_fields.forEach((nesField, index) => {
              if (choices[index] && choices[index].length === 0) return;
              objectShape[nesField.name] = Yup.string().required(
                `${nesField.label} is mandatory`
              );
            });
          }
        } else {
          customFieldsShape[field.name] = Yup.string().required(
            `${field.label} is mandatory`
          );
          if (field.nested_fields && field.nested_fields.length > 0) {
            const choices = {};
            const selected =
              field.choices && field.choices.length > 0
                ? field.choices.find(
                    choice => choice.value === values.custom_fields[field.name]
                  )
                : {};
            choices['0'] = (selected && selected.nested_options) || [];
            const selectedLev2 = choices['0'].find(
              choice =>
                choice.value ===
                ((field.nested_fields &&
                  field.nested_fields[0] &&
                  field.nested_fields[0].name &&
                  values.custom_fields &&
                  values.custom_fields[field.nested_fields[0].name]) ||
                  {})
            );
            choices['1'] = (selectedLev2 && selectedLev2.nested_options) || [];
            field.nested_fields.forEach((nesField, index) => {
              if (choices[index] && choices[index].length === 0) return;
              customFieldsShape[nesField.name] = Yup.string().required(
                `${nesField.label} is mandatory`
              );
            });
          }
        }
      } else {
        objectShape[field.name] = Yup.string();
      }
      objectShape.custom_fields = Yup.object().shape(customFieldsShape);
    });
    return Yup.object().shape(objectShape);
  });
};
