import React from 'react';
import { toastr } from 'react-redux-toastr';

import 'styles/Settings.css';
import { request } from 'utils/Api';
import { getOrgId } from 'utils/utility';
import Switch from 'components/ui/Switch';
import StatusActions from 'components/StatusActions';

class TwilioCreatedIntegrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  toggleTwilioIntegration(integration) {
    const self = this;
    const data = {
      state: integration.state === 'AC' ? 'DA' : 'AC',
      organization: getOrgId()
    };
    request(`/integrations/sms-alerters/${integration.id}`, {
      method: 'PATCH',
      payload: data
    })
      .catch(error => console.error('Error-->:', error))
      .then((response) => {
        if (response && response.id) self.props.changeState(response);
      });
  }

  toggleSwitch(integration) {
    this.setState(
      prevState => ({
        twilioIntegrations: this.props.twilioIntegrations.map(integ =>
          (integ.id === integration.id
            ? { ...integ, state: integ.state === 'AC' ? 'DA' : 'AC' }
            : integ))
      }),
      () => {
        this.toggleTwilioIntegration(integration);
      }
    );
  }
  statusAction(selected, integration) {
    if (selected.label === 'Edit') {
      this.props.openTwilioAddIntModal('', integration, 'false');
    }
    if (selected.label === 'Delete') {
      const toastrConfirmOptions = {
        component: () => (
          <div className="delete-confirm__message statuspage_delete">
            <p className="name">
                Twilio Phone Number<span>{integration.twilio_number}</span>
            </p>
            <p className="name">
                Twilio Recipients<span>{integration.alert_traget_numbers}</span>
            </p>
          </div>
        ),
        okText: 'Delete',
        cancelText: 'Cancel',
        onOk: () => this.props.deleteTwilioIntegration(integration.id)
      };

      toastr.confirm(
        'Delete Twilio SMS Alert Confirmation',
        toastrConfirmOptions
      );
    }
  }
  render() {
    const twilioIntegrations = this.state.twilioIntegrations
      ? this.state.twilioIntegrations
      : this.props.twilioIntegrations;
    return (
      <div className="created-integrations-container twilio-created-integrations">
        {twilioIntegrations && twilioIntegrations.length > 0 && (
          <div className="row integrations-heading">
            <div className="col">Twilio Number</div>
            <div className="col" />
            <div className="col align-center slack-status">Status</div>
            <div className="col align-center twilio-recipients">
              Twilio Recipients
            </div>
            <div className="align-center" style={{ width: '8%' }}>
              Action
            </div>
          </div>
        )}
        {twilioIntegrations &&
          twilioIntegrations.length > 0 &&
          twilioIntegrations.map((integration, index) => (
            <div className="row" key={integration.id}>
              <div className="col">{integration.twilio_number}</div>
              <div className="col" />
              <div className="col align-center slack-status">
                <Switch
                  onToggle={() => this.toggleSwitch(integration)}
                  on={integration.state === 'AC'}
                />
              </div>
              <div className="col align-center twilio-recipients">
                {integration.alert_traget_numbers
                  ? integration.alert_traget_numbers.split(',').length
                  : '0'}
              </div>
              <div className="statuspage-action">
                <StatusActions
                  onChange={e => this.statusAction(e, integration)}
                />
              </div>
            </div>
          ))}
      </div>
    );
  }
}
export default TwilioCreatedIntegrations;
