import React from "react";
import { URLQueryParams } from "../utils/utility";

class SlackIntegrationRouter extends React.Component {
  componentWillMount() {
    const { domain_name } = this.props.match.params;
    const queryParams = URLQueryParams();
    const origin = window.location.origin.replace("app", domain_name)
    window.location.href = `${origin}/slack/oauth?code=${queryParams.code}&state=${queryParams.state}`;
  }
  render() {
    return <div />;
  }
}

export default SlackIntegrationRouter;
