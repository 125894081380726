/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Toggle, Button } from 'freemium-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TickIcon, Copy } from '../components/Icons';
import StatusPagePreview from '../components/StatusPagePreview';
import { StyledLoading } from '../components/styled/Loading';
import { scrollTo, getOrgId } from '../utils/utility';
import { updateChecksOrder, toggleInterestModal } from '../actions';
import FilledLock from '../images/filled_lock.svg';

const regexExp = /^$|^([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.?){1,}(?:\.[a-zA-Z]{2,})+$/i;

function isStatusPageValid(statuspageform) {
  return (
    statuspageform.name &&
    statuspageform.name.length &&
    statuspageform.associated_checks.length
  );
}

const mapDispatchToProps = dispatch => ({
  updateChecksOrder: (startPosition, dropPosition) =>
    dispatch(updateChecksOrder({ start: startPosition, end: dropPosition })),
  toggleInterestModal: state => dispatch(toggleInterestModal(state))
});

const getItemStyle = (isDragging, draggableStyle) => ({
  boxShadow: isDragging
    ? `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`
    : '',
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#f4f4f4' : 'white'
});

class StatusViewer extends Component {
  constructor(props) {
    super(props);

    this.toggleAdvanced = this.toggleAdvanced.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.toggleChecks = this.toggleChecks.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.generateCname = this.generateCname.bind(this);
    this.verifyCname = this.verifyCname.bind(this);
    this.toggleGetSSL = this.toggleGetSSL.bind(this);
    this.checkCnameGenerateDisabled = this.checkCnameGenerateDisabled.bind(
      this
    );
    this.state = {
      advanced: false,
      isCnameGenerateDisabled: true,
      initialFormData: this.props.statuspageform,
      isCopyButtonClicked: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const isDisabled = this.checkCnameGenerateDisabled(
      nextProps.statuspageform.custom_domain,
      (nextProps.statuspageformErrors &&
        nextProps.statuspageformErrors.custom_domain) ||
        ''
    );
    const newState = { isCnameGenerateDisabled: isDisabled };
    if (!this.props.advanced && nextProps.statuspageformErrors.custom_domain) {
      this.setState({ ...newState, advanced: true });
    } else {
      this.setState({ ...newState });
    }
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    this.props.updateChecksOrder(result.source.index, result.destination.index);
  }

  toggleAdvanced() {
    this.setState(
      prevState => ({
        advanced: !prevState.advanced
      }),
      () => {
        scrollTo('advanced-settings-body');
      }
    );
  }

  submitForm() {
    const {
      statuspageform,
      checks,
      createStatusPage,
      resetStatusPageErrors,
      updateStatusPage,
      statuspageformErrors
    } = this.props;
    if (!Object.values(statuspageformErrors).every(v => v === '')) {
      return;
    }
    resetStatusPageErrors();
    statuspageform.associated_checks.forEach((check, parentindex) => {
      const matchedObject = checks.find(selected => {
        if ('application_check' in check) {
          return selected.id === check.application_check;
        }
        return selected.id === check.id;
      });
      statuspageform.associated_checks[parentindex].order = matchedObject.order;
    });
    if (statuspageform.id === -1) {
      createStatusPage(statuspageform);

      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Create Status Page', window._na_module);
      }
    } else {
      updateStatusPage(statuspageform);
      if (!this.state.isCnameGenerateDisabled) {
        this.generateCname();
      }

      if (window._na && window._na_module) {
        window._na.sendFeatureEvent('Edit Status Page', window._na_module);
      }
    }
  }

  toggleChecks(selected, selectedChecks) {
    this.props.updateChecksOrder(0, 0);
    this.props.setStatusPageForm({
      associated_checks: selectedChecks.map(check => {
        if (check.id === selected.id) {
          return {
            ...check,
            active: !check.active,
            deleteCheck: !!check.active
          };
        }
        return {
          ...check,
          active: typeof check.active === 'undefined' ? false : check.active
        };
      })
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { setStatusPageErrors, setStatusPageForm } = this.props;
    if (name === 'custom_domain') {
      const custom_domain_error = !new RegExp(regexExp).test(value)
        ? 'Invalid Domain'
        : '';
      const isDisabled = this.checkCnameGenerateDisabled(
        value,
        custom_domain_error
      );
      this.setState({ isCnameGenerateDisabled: isDisabled });
      setStatusPageErrors({ custom_domain: custom_domain_error });
    }

    const payload = {};
    if (name === 'ga_tracking_id' || name === 'custom_domain') {
      payload[name] = value && value.length ? value : null;
    } else {
      payload[name] = value && value.length ? value : '';
    }
    setStatusPageForm(payload);
  }

  checkCnameGenerateDisabled(value, custom_domain_error) {
    const { statuspageform = {} } = this.props;
    const { initialFormData = {} } = this.state;
    let disabled = false;

    if (
      statuspageform.id === -1 ||
      !value ||
      (typeof value === 'string' && value.length === 0) ||
      value === initialFormData.custom_domain ||
      (custom_domain_error && custom_domain_error.length > 0) ||
      (value === initialFormData.custom_domain &&
        statuspageform.custom_domain_cname &&
        statuspageform.custom_domain_cname.key)
    ) {
      disabled = true;
    }

    if (
      initialFormData.custom_domain &&
      (!statuspageform.custom_domain_cname ||
        !statuspageform.custom_domain_cname.key)
    ) {
      disabled = false;
    }
    return disabled;
  }

  generateCname() {
    const {
      statuspageform,
      createStatusPageCname,
      statuspageformErrors = {}
    } = this.props;
    if (statuspageform.id > 0) {
      const payload = {
        id: statuspageform.id,
        custom_domain:
          statuspageform &&
          statuspageform.custom_domain &&
          statuspageform.custom_domain.length
            ? statuspageform.custom_domain
            : null
      };
      createStatusPageCname(payload);
      const isDisabled = this.checkCnameGenerateDisabled(
        statuspageform.custom_domain,
        statuspageformErrors.custom_domain || ''
      );
      this.setState({
        initialFormData: statuspageform,
        isCnameGenerateDisabled: isDisabled
      });
    }
  }

  verifyCname() {
    const { statuspageform, verifyStatusPageCname } = this.props;
    if (statuspageform.id > 0) {
      const payload = {
        id: statuspageform.id
      };
      verifyStatusPageCname(payload);
    }
  }

  toggleGetSSL(type) {
    const payload = {
      state: true,
      type,
      custom_domain: this.state.custom_domain
    };
    this.props.toggleInterestModal(payload);
  }

  onCopyClick = () => {
    this.setState({ isCopyButtonClicked: true });
    setTimeout(() => {
      this.setState({ isCopyButtonClicked: false });
    }, 3000);
  };

  render() {
    const {
      statuspageform,
      checks,
      formState,
      gotoList,
      statuspageformErrors
    } = this.props;
    const isFormValid = isStatusPageValid(statuspageform);
    const selectedChecks =
      checks &&
      checks.length &&
      statuspageform.associated_checks &&
      checks.map(check => {
        const currentCheck = statuspageform.associated_checks.find(
          selected =>
            check.id ===
            (selected.application_check
              ? selected.application_check
              : selected.id)
        );
        if (currentCheck) {
          return {
            ...check,
            active:
              typeof currentCheck.active !== 'undefined' ||
              typeof currentCheck.current === 'undefined'
                ? currentCheck.active
                : true,
            order: check.order === 0 ? currentCheck.order : check.order
          };
        }
        return check;
      });

    const currentOrg =
      this.props.org &&
      this.props.org.find(org => org.id === Number(getOrgId()));
    const { subscription } = currentOrg || {};

    const { isCnameGenerateDisabled } = this.state;
    return (
      <section className="status-page-viewer">
        <div className="statuspage-controls">
          <div className="viewer-controls">
            <Card isEnabled>
              <div className="header">
                <div>
                  <h4>
                    {statuspageform.id !== -1 ? 'Edit' : 'Create'} public status
                    page
                  </h4>
                </div>
              </div>
              <div className="container">
                <label htmlFor="name">Status page name</label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  className={`${
                    statuspageformErrors.name ? 'has-errored' : ''
                  }`}
                  name="name"
                  placeholder="Company Name"
                  autoComplete="off"
                  value={statuspageform.name}
                />
                {statuspageformErrors.name && (
                  <span className="helptext">{statuspageformErrors.name}</span>
                )}

                <div className="viewer-checks">
                  <div className="checks-header">Checks</div>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          <ul className="checks-list">
                            {checks &&
                            selectedChecks &&
                            (statuspageform.id === -1 ||
                              formState !== 'idle' ||
                              formState !== 'error') ? (
                              selectedChecks.map((check, index) => (
                                <Draggable
                                  draggableId={check.id}
                                  key={index}
                                  index={index}
                                >
                                  {(pvd, sShot) => (
                                    <React.Fragment>
                                      <div
                                        ref={pvd.innerRef}
                                        {...pvd.dragHandleProps}
                                        style={getItemStyle(
                                          sShot.isDragging,
                                          pvd.draggableProps.style
                                        )}
                                      >
                                        <li
                                          className="checks-content"
                                          key={check.id}
                                        >
                                          <div className="grip-span">
                                            <span className="checks-grip" />
                                            {check.name
                                              ? check.name
                                              : `id-${check.id}`}{' '}
                                          </div>
                                          <Toggle
                                            hasIcon={false}
                                            on={check.active}
                                            handleChange={() =>
                                              this.toggleChecks(
                                                check,
                                                selectedChecks
                                              )
                                            }
                                          />
                                        </li>
                                      </div>
                                      {pvd.placeholder}
                                    </React.Fragment>
                                  )}
                                </Draggable>
                              ))
                            ) : (
                              <StyledLoading />
                            )}
                          </ul>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div>
                  <label htmlFor="logo_url">Logo URL</label>
                  <input
                    type="text"
                    onChange={this.handleChange}
                    name="logo_url"
                    className={`${
                      statuspageformErrors.logo_url ? 'has-errored' : ''
                    }`}
                    autoComplete="off"
                    value={statuspageform.logo_url}
                  />
                  {statuspageformErrors.logo_url && (
                    <span className="helptext">
                      {statuspageformErrors.logo_url}
                    </span>
                  )}
                  {statuspageform.logo_url && (
                    <span className="as_logo-preview">
                      <img src={statuspageform.logo_url} alt="logo-preview" />
                    </span>
                  )}
                </div>
                <div>
                  <label htmlFor="ga_tracking_id">
                    Google Analytics tracking ID (optional)
                  </label>
                  <input
                    type="text"
                    onChange={this.handleChange}
                    name="ga_tracking_id"
                    className={`${
                      statuspageformErrors.ga_tracking_id ? 'has-errored' : ''
                    }`}
                    autoComplete="off"
                    placeholder="UA-123456-7"
                    value={statuspageform.ga_tracking_id}
                  />
                </div>
                <div>
                  <label htmlFor="custom_domain">Custom domain URL</label>
                  <label className="custom_domain_help_label">
                    The custom domain URL can be used to access the public
                    status page only if the CNAME record is generated and
                    verified.
                    {statuspageform.id === -1 && (
                      <p>
                        Generate CNAME button will be enabled after status page
                        creation
                      </p>
                    )}
                  </label>
                  <div className="id-field">
                    <span className="id-field--protocol">http://</span>
                    <input
                      type="text"
                      className={`${
                        statuspageformErrors.custom_domain ? 'has-errored' : ''
                      }`}
                      onChange={this.handleChange}
                      name="custom_domain"
                      placeholder="status.yourdomain.com"
                      autoComplete="off"
                      value={statuspageform.custom_domain}
                    />
                  </div>
                  <ReactTooltip
                    isMultiline
                    place="bottom"
                    type="dark"
                    effect="solid"
                  />
                  {statuspageformErrors.custom_domain && (
                    <span className="helptext">
                      {statuspageformErrors.custom_domain}
                    </span>
                  )}
                  <span className="as_form-help">
                    <a
                      href="https://support.freshping.io/support/solutions/articles/237635"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How to update your DNS for this custom domain?
                    </a>
                  </span>
                </div>
              </div>
            </Card>
            {isCnameGenerateDisabled &&
            statuspageform &&
            statuspageform.custom_domain &&
            statuspageform.custom_domain_cname &&
            statuspageform.custom_domain_cname.key &&
            statuspageform.custom_domain_cname.value ? (
              <Card isCopyButtonClicked={this.state.isCopyButtonClicked}>
                <div className="header">
                  <div>
                    <h4>CNAME Verification </h4>
                  </div>
                  {statuspageform.custom_domain_cname &&
                  statuspageform.custom_domain_cname.is_verified ? (
                    <div className="custom_domain_verified">
                      <TickIcon width="16" height="16" viewBox="0 0 24 18" />{' '}
                      Verified
                    </div>
                  ) : (
                    statuspageform.custom_domain &&
                    isCnameGenerateDisabled &&
                    statuspageform.custom_domain_cname && (
                      <Button
                        size="mini"
                        inline
                        type="primary"
                        onClick={this.verifyCname}
                        disabled={
                          !(
                            statuspageform.custom_domain_cname &&
                            statuspageform.custom_domain_cname.key
                          ) ||
                          !(
                            statuspageform.custom_domain_cname &&
                            statuspageform.custom_domain_cname.value
                          )
                        }
                      >
                        Verify
                      </Button>
                    )
                  )}
                </div>
                <div className="container">
                  <div className="field">
                    <label htmlFor="logo_url">CNAME name</label>
                    <input
                      type="text"
                      readOnly="true"
                      name="cname_key"
                      value={
                        statuspageform.custom_domain_cname &&
                        statuspageform.custom_domain_cname.key
                          ? `${statuspageform.custom_domain_cname.key}.${statuspageform.custom_domain}`
                          : ''
                      }
                    />

                    <CopyToClipboard
                      text={`${statuspageform.custom_domain_cname.key}.${statuspageform.custom_domain}`}
                      dd
                      cxaa
                    >
                      <button className="copy" onClick={this.onCopyClick}>
                        <Copy />
                      </button>
                    </CopyToClipboard>
                    <span className="copy-ack"> Copied !</span>
                  </div>

                  <div className="field">
                    <label htmlFor="logo_url">CNAME value</label>
                    <input
                      type="text"
                      readOnly="true"
                      name="cname_value"
                      value={
                        statuspageform.custom_domain_cname &&
                        statuspageform.custom_domain_cname.value
                          ? `${statuspageform.custom_domain_cname.value}.fpverify.freshping.io`
                          : ''
                      }
                    />
                    <CopyToClipboard
                      text={`${statuspageform.custom_domain_cname.value}.fpverify.freshping.io`}
                    >
                      <button className="copy" onClick={this.onCopyClick}>
                        <Copy />
                      </button>
                    </CopyToClipboard>
                  </div>
                  <div className="right-aligned">
                    {subscription &&
                    subscription.status === 'active' &&
                    subscription.plan_name === 'GARDEN' ? (
                      <Button
                        inline
                        size="mini"
                        type="secondary"
                        onClick={() => this.toggleGetSSL('getssl')}
                      >
                        <img
                          src={FilledLock}
                          alt="lock"
                          height="10px"
                          width="10px"
                        />{' '}
                        Get SSL
                      </Button>
                    ) : (
                      <Button
                        size="mini"
                        type="secondary"
                        inline
                        data-tip="SSL is available on Garden plan, not in trial plan"
                      >
                        <img
                          src={FilledLock}
                          alt="lock"
                          height="10px"
                          width="10px"
                        />{' '}
                        Get SSL
                      </Button>
                    )}
                  </div>
                </div>
              </Card>
            ) : null}
          </div>
          <div className="statuspage-form-action">
            <Button type="secondary" inline onClick={gotoList}>
              Cancel
            </Button>
            <Button
              type="primary"
              inline
              disabled={!isFormValid || formState === 'loading'}
              onClick={this.submitForm}
            >
              {formState === 'loading'
                ? 'Please wait'
                : statuspageform.id > 0
                ? isCnameGenerateDisabled
                  ? 'Update'
                  : 'Update and generate CNAME'
                : statuspageform.custom_domain
                ? 'Create and generate CNAME'
                : 'Create'}
            </Button>
          </div>
        </div>

        <div className="browser-window">
          <div className="top-bar">
            <div className="circles">
              <div className="circle circle-red" />
              <div className="circle circle-yellow" />
              <div className="circle circle-green" />
            </div>
          </div>
          <StatusPagePreview
            statuspageform={statuspageform}
            selectedChecks={selectedChecks}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  org: state.user.org
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusViewer);

const Card = styled.div`
  border: 1px solid #e4e2e2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 1rem;
  .header {
    padding: 15px;
    background: #f5f5f5;
    border-bottom: 1px solid #e4e2e2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .secondarytext {
      color: var(--secondary-color);
      font-size: 12px;
    }

    h4 {
      padding: 5px 0;
      font-size: 14px;
      margin-bottom: 0;
    }

    .custom_domain_verified {
      background: linear-gradient(180deg, #ffffff 2.56%, #f5f7f9 95.75%);
      border: 1px solid var(--border-color);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #183247;
    }
  }

  .container {
    background: #fff;
    padding: 15px;
    input,
    textarea {
      margin: 0px 0 30px 0;
      display: inline-block;
      box-sizing: border-box;
      height: 30px;
      background-color: #ffffff;
      box-shadow: 0 1px 0 0 #dadfe3;
      border: none;
      border-radius: 0;
      width: 100%;
      color: #222222;
      padding: 0;
    }

    .right-aligned {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    .as_form-help {
      font-size: 12px;
      text-align: right;
      padding-top: 4px;
      display: block;
      opacity: 0.8;
      color: var(--text-link);
    }
    .as_logo-preview {
      border-radius: 4px;
      background-color: #f9f9f9;
      border: solid 1px #dedede;
      text-align: center;
      display: block;
      padding: 5px 0;
      margin-bottom: 10px;
      img {
        max-height: 90px;
        max-width: 100%;
        padding: 5px 20px;
        box-sizing: border-box;
      }
    }
    .custom_domain_help_label {
      padding-bottom: 10px;
      padding-top: 5px;
      font-size: 11px;
      opacity: 0.8;
      > p {
        padding-top: 5px;
        margin: 0;
      }
    }

    .field {
      position: relative;

      input {
        padding-right: 40px;
      }

      button.copy {
        border: none;
        position: absolute
        right: 0;
        top: 14px;
        padding: 5px 10px;
        background: none;
        &;:hover {
          background: #f1f1f1;
        }
      }

      .copy-ack {
        position: absolute;
        top: 58px;
        right: 10px;
        font-size: 12px;
        display: ${props => (props.isCopyButtonClicked ? 'block' : 'none')};
      }
    }
  }
`;
