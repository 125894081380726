import React from 'react';
import useStickyState from './hooks/localstorage';

export const withHooksDataHOC = (Component,key) => {
  return (props) => {
    const [items, setItems] = useStickyState(null,key);

      return <Component 
                stickyStateData={{ stickyState: items, setStickyData: setItems }} 
                {...props} 
            />;
  };
};