import styled from "styled-components";

// import { color } from "shared/utils/styles";

export const StyledCheckType = styled.div`
  position: relative;
  width: 100%;
`;

export default {};
