import React from 'react';
import { NavLink } from 'react-router-dom';
import Downshift from 'downshift';
import styled from 'styled-components';
import Report from '../images/report-1.svg';
import Settings from '../images/settings-1.svg';
import Dashborad from '../images/dashboard-1.svg';
import Browser from '../images/public_status.svg';
import BadgePage from '../images/badge-icon.svg';
import Explore from '../images/explore-icon.svg';
import Info from '../images/helpInfo-icon.svg';
import { Logo } from './Icons';
import Tooltip from './styled/Tooltip';

const Sidebar = ({ isAdmin, deviceSize }) => {
  // const orgID = getOrgId();
  const helpInfoUrls = [
    {
      label: 'Monitoring IPs',
      href:
        'https://support.freshping.io/support/solutions/articles/237620-what-are-the-monitoring-locations-and-their-ips-'
    },
    {
      label: 'FAQ',
      href: 'https://support.freshping.io/support/solutions'
    },
    {
      label: 'Community',
      href: 'https://community.freshworks.com/freshping-10012'
    },
    {
      label: 'Contact us',
      href: 'https://support.freshping.io/support/tickets/new'
    },
    {
      label: 'Privacy policy',
      href: 'https://www.freshworks.com/privacy/'
    }
  ];

  return (
    <aside className="App-sidebar">
      <div className="logo">
        <NavLink to="/">
          <Logo />
        </NavLink>
      </div>
      <ul className="App-sidebar__menu">
        <li>
          <Tooltip position="right" content="Dashboard">
            <NavLink to="/dashboard" activeClassName="active-menu">
              <img src={Dashborad} alt="Dashborad Logo" />
            </NavLink>
          </Tooltip>
        </li>
        <li>
          <Tooltip position="right" content="Reports">
            <NavLink to="/reports" activeClassName="active-menu">
              <img src={Report} alt="Report Logo" />
            </NavLink>
          </Tooltip>
        </li>
        <li>
          {deviceSize && deviceSize === 'device-large' && isAdmin && (
            <Tooltip position="right" content="Status">
              <NavLink to="/status" activeClassName="active-menu">
                <img src={Browser} alt="Browser Logo" />
              </NavLink>
            </Tooltip>
          )}
        </li>

        <li>
          {deviceSize && deviceSize === 'device-large' && isAdmin && (
            <Tooltip position="right" content="Badge">
              <NavLink to="/badge" activeClassName="active-menu">
                <img src={BadgePage} alt="Badge Logo" />
              </NavLink>
            </Tooltip>
          )}
        </li>

        <li>
          {deviceSize && deviceSize === 'device-large' && isAdmin && (
            <Tooltip position="right" content="Settings">
              <NavLink
                to="/settings/users"
                activeClassName="active-menu"
                isActive={() => window.location.href.indexOf('/settings/') > -1}
              >
                <img src={Settings} alt="Settings Logo" />
              </NavLink>
            </Tooltip>
          )}
        </li>

        {deviceSize && deviceSize === 'device-large' ? (
          <li className="new-button-holder">
            <Tooltip position="right" content="Explore">
              <NavLink to="/explore" activeClassName="active-menu">
                <img src={Explore} alt="Explore Logo" />
              </NavLink>
            </Tooltip>
          </li>
        ) : null}

        <li className="helpIcon">
          <StyleDropdown>
            <Downshift>
              {({ getItemProps, isOpen, toggleMenu }) => (
                <div>
                  <div className="btn-group" style={{ position: 'relative' }}>
                    <div className="infoLogo-wrapper" onMouseEnter={toggleMenu}>
                      <img className="helplogo" src={Info} alt="Help Logo" />
                    </div>
                    <div
                      className={`dropdown droplist-menu ${isOpen &&
                        'dropdown-menu--open'}`}
                      onMouseLeave={toggleMenu}
                    >
                      {helpInfoUrls.map(item => (
                        <a
                          {...getItemProps({ item })}
                          key={item.label}
                          className="dropdown__item"
                          style={{ cursor: 'pointer' }}
                          href={item.href}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {item.label}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </Downshift>
          </StyleDropdown>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;

const StyleDropdown = styled.div`
  .infoLogo-wrapper {
    &:before {
      font-size: 0.5rem;
      font-family: Sailec-Bold, Helvetica, sans-serif;
      background-color: #ef7c00;
      letter-spacing: 0.5px;
      border-radius: 20px;
      top: -20px;
      left: -4px;
      height: 0;
      line-height: 14px;
      position: absolute;
      content: '';
      display: inline-block;
    }
  }
  .dropdown {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.15);
    border: 0;
    padding: 0;
    min-width: 125px;
    bottom: 178px;
    left: 35px;
    border-radius: 2px;
    border-top: 1px #ebeef0 solid;
    text-align: left;
    position: relative;
    &:before {
      bottom: 100%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      border-color: rgba(229, 229, 229, 0);
      border-bottom-color: #ebeef0;
      border-width: 6px;
      left: -12px;
      top: 78%;
      transform: rotate(270deg);
    }
    &.droplist-menu > a:hover {
      background-color: #f3f5f7;
    }
    a.dropdown__item {
      border-bottom: 1px #f2f2f2 solid;
      background-color: #fff;
      padding: 10px 15px;
      border-radius: 2px;
      margin: 0;
      display: block;
      line-height: 1.42857;
      white-space: nowrap;
      text-decoration: none;
      text-align: left;
      height: unset;
      width: unset;
      color: #333;
    }
  }
`;
