/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import styled, { css } from "styled-components";

import { color, font } from "shared/utils/styles";

export const KeyValueSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;
export const StyledKeyValue = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => (props.isMulti ? "column" : "row")};
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  .field-wrap {
    padding-top: 20px;
    width: ${props =>
      props.isMulti ? "calc(50% - 35px)" : "calc(50% - 15px)"};
    &.one-third {
      width: 29%;
    }
    &.two-third {
      width: 65%;
    }
  }
`;

export const IconButton = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  background: none;
  display: flex;
  align-items: center;
  height: 37px;
`;

export const AddOptionButton = styled.button`
  margin-top: 15px;
  outline: none;
  border: none;
  box-shadow: none;
  background: none;
  display: flex;
  align-items: center;
  height: 37px;
  font-weight: 600;
  color: ${color.primary};
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.09px;
  color: #595959;
  padding: 0 0 5px 0;
`;

export const InputElement = styled.input`
  height: 100%;
  width: 100%;
  padding: 0 7px;
  min-height: 37px;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid ${color.borderLightest};
  color: ${color.textDarkest};
  // background: ${color.backgroundLightest};
  transition: all 0.1s;
  ${font.regular}
  ${font.size(14)}
  ${props => props.hasIcon && "padding-left: 32px;"}
  &:hover {
    // background: ${color.backgroundLight};
    border-bottom-color: ${color.primary};
  }
  &:focus {
    // background: #fff;
    border-bottom-color: ${color.primary};
    // box-shadow: 0 0 0 1px ${color.borderInputFocus};
  }
  ${props =>
    props.invalid &&
    css`
      &,
      &:focus {
        border: 1px solid ${color.danger};
        box-shadow: none;
      }
    `}
`;
