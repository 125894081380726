import React from "react";
import ReactDOM from "react-dom";
import { TablePagination } from "@trendmicro/react-paginations";
import "@trendmicro/react-paginations/dist/react-paginations.css";
import styled from "styled-components";

class Pagination extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    totalChecks: 0
  };

  onPageChange = ({ page }) => {
    this.setState({
      page
    });
    this.props.onpaginate(page);
  };

  removeKeyword = () => {
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      const child = node.querySelector(
        ".paginations---pagination-records---YOUAz"
      );
      child.innerHTML = child.innerHTML
        .replace("Records: ", "")
        .replace("/", " of ");
    }
  };

  componentDidMount() {
    this.removeKeyword();
  }

  componentDidUpdate() {
    this.removeKeyword();
  }

  render() {
    const { pageSize } = this.state;
    const { params, totalChecks } = this.props;
    return (
      <Paginator>
        <TablePagination
          type="minor"
          page={params.page}
          pageLength={pageSize}
          totalRecords={totalChecks}
          onPageChange={this.onPageChange}
          prevPageRenderer={() => <i className="fa fa-angle-left" />}
          nextPageRenderer={() => <i className="fa fa-angle-right" />}
        />
      </Paginator>
    );
  }
}

export default Pagination;

const Paginator = styled.div`
  width: 160px;
  margin-bottom: 10px;

  ul {
    position: relative;
    li {
      position: absolute;
      &:first-child {
        right: 125px;
      }
    }
  }

  .paginations---pagination-records---YOUAz {
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    width: 95px;
    text-align: center;
  }

  .paginations---table-pagination-block---Cx6IH > div {
    margin: 8px 0;
  }
`;
