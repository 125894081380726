// @flow
import "whatwg-fetch";
import { API_ROOT } from "../config";

/*todo: Refactor this after URL constants */
function sanitizeUrl(url) {
  let urlParts = url.split("?");

  if (!urlParts[0].endsWith("/")) {
    urlParts[0] = urlParts[0] + "/";
  }
  return urlParts.join("?");
}

export class ServerError extends Error {
  response: Object;

  constructor(response: Object, ...params: any): Error {
    super(...params);

    Error.captureStackTrace(this, ServerError);

    this.name = "ServerError";
    this.response = {};

    return this;
  }
}

export function parseError(error: string): string {
  return error || "Something went wrong";
}

export function request(url: string, options: Object = {}): Promise<*> {
  const config = {
    method: "GET",
    ...options
  };
  const errors = [];

  if (!url) {
    errors.push("url");
  }

  if (
    !config.payload &&
    config.method !== "GET" &&
    config.method !== "DELETE"
  ) {
    errors.push("payload");
  }

  if (errors.length) {
    throw new Error(`Error! You must pass \`${errors.join("`, `")}\``);
  }
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "token " + localStorage.getItem("auth-token"),
    "Freshping-org-id": localStorage.getItem("active-org"),
    ...config.headers
  };

  const params: Object = {
    headers,
    method: config.method
  };

  if (params.method !== "GET") {
    params.body = JSON.stringify(config.payload);
  }

  return fetch(`${API_ROOT}${sanitizeUrl(url)}`, params).then(
    async response => {
      if (response.status > 299) {
        const contentType = response.headers.get("content-type");

        if (response.status === 401) {
          localStorage.removeItem("auth-token");
          const responseJson = await response.json();
          return { response: responseJson, status: response.status };
        }

        if (response.status === 404) {
          return { status: response.status };
        }

        if (response.status === 400) {
          const responseJson = await response.json();
          return { response: responseJson, status: response.status };
        }

        const error = new ServerError(response.statusText);

        if (contentType && contentType.includes("application/json")) {
          error.response = {
            status: response.status,
            data: await response.json()
          };
        } else {
          error.response = {
            status: response.status,
            data: await response.text()
          };
        }

        throw error;
      } else {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else if (contentType && contentType.includes("text/csv")) {
          return response.text();
        }
        return response.text();
      }
    }
  ).catch(err => {
    throw new ServerError();
  });
}
