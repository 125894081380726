import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import Input from 'shared/components/Input';
import Select from 'shared/components/Select';
import Slider from 'shared/components/Slider';
import CustomSelectField from 'shared/components/CustomSelectField';
import CustomCheckType from 'shared/components/CustomCheckType';
import KeyValue from 'shared/components/KeyValue';
import Checkbox from 'shared/components/Checkbox';
import RadioGroup from 'shared/components/RadioGroup';
import RadioGroupWithMenu from 'shared/components/RadioGroupWithMenu';
import Textarea from 'shared/components/Textarea';

import { StyledField, FieldLabel, FieldTip, FieldError } from './style';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  disableTypeCondition: PropTypes.func,
  disableConditionKeyVal: PropTypes.func,
  tip: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string
};

const defaultProps = {
  className: undefined,
  label: undefined,
  tip: undefined,
  disableTypeCondition: undefined,
  disableConditionKeyVal: undefined,
  disabled: false,
  error: undefined,
  name: undefined
};

const generateField = FormComponent => {
  const FieldComponent = ({
    className,
    label,
    tip,
    error,
    inline,
    name,
    requiredField,
    disabled,
    disableConditionKeyVal,
    disableTypeCondition,
    ...otherProps
  }) => {
    const fieldId = uniqueId('form-field-');

    const unmountField =
      (disableTypeCondition &&
        disableTypeCondition(otherProps.formValues.type)) ||
      false;

    return (
      <>
        {!unmountField ? (
          <StyledField
            disabled={
              disabled ||
              (disableTypeCondition &&
                disableTypeCondition(otherProps.formValues.type)) ||
              (disableConditionKeyVal &&
                otherProps.formValues[disableConditionKeyVal.key] ===
                  disableConditionKeyVal.value)
            }
            inline={inline}
            noHeader={!label && !tip}
            className={className}
            hasLabel={!!label}
            data-testid={name ? `form-field:${name}` : 'form-field'}
          >
            <div>
              {label && (
                <FieldLabel htmlFor={fieldId}>
                  {label}
                  {requiredField && <span>*</span>}
                </FieldLabel>
              )}
              {tip && <FieldTip>{tip}</FieldTip>}
            </div>
            <FormComponent
              id={fieldId}
              invalid={!!error}
              name={name}
              inline={inline}
              {...otherProps}
            />
            {error && <FieldError>{error}</FieldError>}
          </StyledField>
        ) : null}
      </>
    );
  };

  FieldComponent.propTypes = propTypes;
  FieldComponent.defaultProps = defaultProps;

  return FieldComponent;
};

export default {
  Input: generateField(Input),
  Checkbox: generateField(Checkbox),
  Textarea: generateField(Textarea),
  KeyValue: generateField(KeyValue),
  Select: generateField(Select),
  CustomSelectField: generateField(CustomSelectField),
  RadioGroup: generateField(RadioGroup),
  RadioGroupWithMenu: generateField(RadioGroupWithMenu),
  Slider: generateField(Slider),
  CustomCheckType: generateField(CustomCheckType)
};
