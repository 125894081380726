/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Fragment } from 'react';
import Heatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import {
  convertDateToMonthDay,
  getFirstLastDates,
  getTwoMonthsBackDates,
  getPreviousMonthDates,
  getCurrentMonthDates,
  isFutureDate,
  getStartEndOfDay
} from '../utils/utility';
import down from '../images/down_2.svg';
import up from '../images/up_2.svg';
import { request } from '../utils/Api';

class HistoricalUpTime extends React.Component {
  getTooltipInfo = async (value, event) => {
    let element = '';
    if (event && event.target) element = event.target;

    if (!value) return;

    const start_date = getStartEndOfDay(value.date).start._d.toISOString();
    const end_date = getStartEndOfDay(value.date).end._d.toISOString();
    const stats_url = `/check-stats-reports/${this.props.checkID}/?start_time=${start_date}&end_time=${end_date}`;
    const result = await request(`${stats_url}`);
    const elements = document.getElementsByClassName('heat-map-custom-tooltip');
    for (let i = 0; i < elements.length; i++) {
      elements[i].parentNode.removeChild(elements[i]);
    }
    if (element && result) {
      // const bound = element.getBoundingClientRect();
      const parentDiv = element.parentNode.parentNode.parentNode.parentNode;
      element.style.cursor = 'default';
      const left =
        element.getBoundingClientRect().left -
        parentDiv.getBoundingClientRect().left -
        60; // 120 is width of tooltip container, take half for tooltip left position
      const top =
        element.getBoundingClientRect().top -
        parentDiv.getBoundingClientRect().top -
        62; // 46 is height of tooltip container, take full height for top position
      var parser = new DOMParser();
      if (result && !result.availability_percentage) {
        var html_to_insert = `<div class="heat-map-custom-tooltip no-data" style='left:${left}px; top:${top}px'><p class="detail-value date">${convertDateToMonthDay(
          value.date
        )}</p><p class="check-not-added">Check not added</p>
					<div class="arrow"></div>
					</div>`;
        // var parser = new DOMParser();
        var newNode = parser.parseFromString(html_to_insert, 'text/html')
          .documentElement;
        if (element.insertAdjacentHTML) {
          parentDiv.appendChild(
            newNode.getElementsByTagName('body')[0].firstElementChild
          );
        } else {
          var range = document.createRange();
          var frag = range.createContextualFragment(html_to_insert);
          parentDiv.appendChild(frag);
        }
      } else if (!value.status) {
        // const left =
        //   element.getBoundingClientRect().left -
        //   parentDiv.getBoundingClientRect().left -
        //   60; // 120 is width of tooltip container, take half for tooltip left position
        // const top =
        //   element.getBoundingClientRect().top -
        //   parentDiv.getBoundingClientRect().top -
        //   62; // 46 is height of tooltip container, take full height for top position
        var html_to_insert1 = `<div class="heat-map-custom-tooltip no-data" style='left:${left}px; top:${top}px'><p class="detail-value date">${convertDateToMonthDay(
          value.date
        )}</p><p class="check-not-added">Yet to monitor</p>
				<div class="arrow"></div>
				</div>`;
        // var parser = new DOMParser();
        var newNode1 = parser.parseFromString(html_to_insert1, 'text/html')
          .documentElement;
        if (element.insertAdjacentHTML) {
          parentDiv.appendChild(
            newNode1.getElementsByTagName('body')[0].firstElementChild
          );
        } else {
          var range1 = document.createRange();
          var frag1 = range1.createContextualFragment(html_to_insert1);
          parentDiv.appendChild(frag1);
        }
      } else {
        const left =
          element.getBoundingClientRect().left -
          parentDiv.getBoundingClientRect().left -
          99; // 200 is width of tooltip container, take half for tooltip left position
        const top =
          element.getBoundingClientRect().top -
          parentDiv.getBoundingClientRect().top -
          185; // 155 is height of tooltip container, take full height for top position

        var html_to_insert2 = `<div class="heat-map-custom-tooltip" style='left:${left}px; top:${top}px'>
			<div class="col-6">
				<p class="detail-value date">${convertDateToMonthDay(value.date)}</p>
				<img src=${
          value.status === 'AV' || value.status === 'PS' ? up : down
        } alt="down icon" className="down-status-img" />
				<p class="up">${
          result
            ? `${
                result.availability_percentage === 100.0
                  ? parseInt(result.availability_percentage)
                  : result.availability_percentage
              }% UP`
            : ''
        }</p>
			</div>
			<div class="heat-map-tooltip-details">
				<p class="detail-title">Downtime: <span class="detail-value">${
          result.duration_seconds_total_downtime
            ? `${Math.round(result.duration_seconds_total_downtime / 60)} mins`
            : '--'
        }</span></p>
				<p class="detail-title">Incidents: <span class="detail-value">${
          result ? result.outages_count_total : ''
        }</span></p>
				<p class="detail-title">Avg. response Time: <span class="detail-value">${
          result.average_response_time_milliseconds_available
            ? `${result.average_response_time_milliseconds_available} ms`
            : '--'
        }</span></p>
				<p class="detail-title">Apdex: <span class="detail-value">${
          result ? result.apdex_score : ''
        }</span></p>
			</div>
			<div class="arrow"></div>
		</div>`;

        // var parser = new DOMParser();
        var newNode2 = parser.parseFromString(html_to_insert2, 'text/html')
          .documentElement;
        if (element.insertAdjacentHTML) {
          parentDiv.appendChild(
            newNode2.getElementsByTagName('body')[0].firstElementChild
          );
        } else {
          var range2 = document.createRange();
          var frag2 = range2.createContextualFragment(html_to_insert);
          parentDiv.appendChild(frag2);
        }
      }
    }

    // })
  };

  hideTooltip(value, evt) {
    const elements = document.getElementsByClassName('heat-map-custom-tooltip');
    for (let i = 0; i < elements.length; i++) {
      elements[i].parentNode.removeChild(elements[i]);
    }
  }
  getReponseDates() {
    if (this.props.data) {
      const result = this.props.data.map(a =>
        a.check_start_time.substring(0, 10).replace('T', '')
      );
      return result;
    }
  }
  getStartDate() {
    if (this.props.data) {
      const result = this.props.data.map(a => a.check_start_time);
      return result;
    }
  }
  getStatsData() {
    if (this.props.data) {
      const result = this.props.data.map(a => a.stats_data);
      return result;
    }
  }
  getEndDate() {
    if (this.props.data) {
      const result = this.props.data.map(a => a.check_end_time);
      return result;
    }
  }
  getCheckId() {
    if (this.props.data) {
      const result = this.props.data.map(a => a.check_id);
      return result;
    }
  }
  getResponseStatus(date) {
    if (this.props.data) {
      const status = this.props.data.map(a => a.new_state);
      return status;
    }
  }
  render() {
    const { deviceSize } = this.props;
    return (
      <div
        className={deviceSize + ' sub-container calendar-container'}
        id="historical-uptime"
      >
        <h4>
          Historical uptime <span className="sub-title">(Last 90days) - In UTC / GMT +0:00</span>
        </h4>
        {this.props.data ? (
          <Fragment>
            <div className="calendar-heat-maps" onMouseLeave={this.hideTooltip}>
              <div className="heat-map">
                <div className="month">{getFirstLastDates()[1].month}</div>
                <Heatmap
                  startDate={getFirstLastDates()[0].lastDate}
                  endDate={getFirstLastDates()[1].lastDate}
                  values={getTwoMonthsBackDates(
                    this.getReponseDates(),
                    this.getResponseStatus(),
                    this.getStatsData()
                  )}
                  classForValue={value => {
                    if (
                      value &&
                      (value.status === 'AV' || value.status === 'PS')
                    ) {
                      return 'color-green';
                    } else if (
                      value &&
                      (value.status === 'NR' ||
                        value.status === 'RE' ||
                        value.status === 'DME' ||
                        value.status === 'SME' ||
                        value.status === 'SCE')
                    ) {
                      return 'color-red';
                    }
                    return 'color-empty';
                  }}
                  showWeekdayLabels={false}
                  showMonthLabels={false}
                  horizontal={false}
                  gutterSize={15}
                  //   tooltipDataAttrs={this.getTooltipInfo}
                  onMouseOver={(event, value) =>
                    this.getTooltipInfo(value, event)
                  }
                  onMouseLeave={(event, value) =>
                    this.hideTooltip(value, event)
                  }
                />
              </div>

              <div className="heat-map">
                <div className="month">{getFirstLastDates()[2].month}</div>
                <Heatmap
                  startDate={getFirstLastDates()[1].lastDate}
                  endDate={getFirstLastDates()[2].lastDate}
                  values={getPreviousMonthDates(
                    this.getReponseDates(),
                    this.getResponseStatus(),
                    this.getStatsData()
                  )}
                  classForValue={value => {
                    if (
                      value &&
                      (value.status === 'AV' || value.status === 'PS')
                    ) {
                      return 'color-green';
                    } else if (
                      value &&
                      (value.status === 'NR' ||
                        value.status === 'RE' ||
                        value.status === 'DME' ||
                        value.status === 'SME' ||
                        value.status === 'SCE')
                    ) {
                      return 'color-red';
                    }
                    return 'color-empty';
                  }}
                  showWeekdayLabels={false}
                  showMonthLabels={false}
                  horizontal={false}
                  gutterSize={15}
                  //   tooltipDataAttrs={this.getTooltipInfo}
                  onMouseOver={(event, value) =>
                    this.getTooltipInfo(value, event)
                  }
                  onMouseLeave={(event, value) =>
                    this.hideTooltip(value, event)
                  }
                />
              </div>

              <div className="heat-map">
                <div className="month">{getFirstLastDates()[3].month}</div>
                <Heatmap
                  startDate={getFirstLastDates()[2].lastDate}
                  endDate={getFirstLastDates()[3].lastDate}
                  values={getCurrentMonthDates(
                    this.getReponseDates(),
                    this.getResponseStatus(),
                    this.getStatsData()
                  )}
                  classForValue={value => {
                    if (
                      value &&
                      (value.status === 'AV' || value.status === 'PS')
                    ) {
                      return 'color-green';
                    } else if (
                      value &&
                      (value.status === 'NR' ||
                        value.status === 'RE' ||
                        value.status === 'DME' ||
                        value.status === 'SME' ||
                        value.status === 'SCE')
                    ) {
                      return 'color-red';
                    } else if (
                      value &&
                      value.date &&
                      isFutureDate(value.date)
                    ) {
                      return 'color-futuredate';
                    }
                    return 'color-empty';
                  }}
                  showWeekdayLabels={false}
                  showMonthLabels={false}
                  horizontal={false}
                  gutterSize={15}
                  //   tooltipDataAttrs={this.getTooltipInfo}
                  onMouseOver={(event, value) =>
                    this.getTooltipInfo(value, event)
                  }
                  onMouseLeave={(event, value) =>
                    this.hideTooltip(value, event)
                  }
                />
              </div>
            </div>
            <ReactTooltip place="top" type="light" multiline html />
          </Fragment>
        ) : (
          <div className="Check-Card-Loader">
            <div className="indicator">
              <svg width="16px" height="12px">
                <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
              </svg>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default HistoricalUpTime;
