import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { toggleInterestModal } from '../actions';
import FreshdeskLogo from '../images/freshdesk-logo.svg';
import BadgePreview from '../images/badge_header_preview.svg';
import CautionOutline from '../images/caution_outline.svg';
import { getOrgId } from '../utils/utility';
import useStickyState from '../utils/hooks/localstorage';

const Banner = ({ toggleInterestModal, setBanner, banner, routerHistory }) => {
  const [items, setItems] = useStickyState(null, `${banner}-header`);
  useEffect(() => {
    if (items) setBanner();
  }, [items]);
  const requestBadgeAccess = () => {
    toggleInterestModal({ state: true, type: 'freebadgeaccess' });
  };
  const closeBanner = type => {
    let headerArray = [];
    if (items) {
      headerArray = headerArray.concat(items);
    }
    const newItem = {
      orgId: Number(getOrgId()),
      headerClosed: true
    };
    headerArray.push(newItem);
    setItems(headerArray);
  };

  return (
    <div>
      {banner === 'signup-suspension' ? (
        <SuspensionBanner id="signup-suspension" className='top-header'>
          <div className="badge-header-image">
            <img src={CautionOutline} alt="caution" style={{width: '19px', height: '17px'}}/>
          </div>
          <div style={{'font-weight': '500', size: '14px', 'line-height': '20px'}}>
            Effective immediately, support for our free plan customers has been suspended. 
            We apologize for any inconvenience this may cause. If you have any questions or concerns, please visit our 
            <a href="https://support.freshping.io/en/support/solutions/articles/50000006524-suspension-of-new-signups-faqs" target="_blank" rel="noopener noreferrer"> help center</a>. 
            Thank you for your understanding.
          </div>
          <StyledClose onClick={() => closeBanner(banner)}>+</StyledClose>
        </SuspensionBanner>
      ) : (
        <StyledBanner id="explore-page-banner">
          {banner === 'explore' ? (
            <>
              <img src={FreshdeskLogo} alt="freshdesk" />
              <h3 className="badge-header-text" style={{ marginLeft: '20px' }}>
                Start serving your customer better with our free products and tools?
              </h3>
              <button
                className="badge-header-button"
                onClick={async () => {
                  await closeBanner(banner);
                  
                  if (window._na && window._na_module) {
                    window._na.sendFeatureEvent('Explore Banner', window._na_module);
                  }
                  routerHistory.push('/explore');
                }}
              >
                Explore
              </button>
            </>
          ) : (
            <>
              <div className="badge-header-image">
                <img src={BadgePreview} alt="badge preview" />
              </div>
              <h3 className="badge-header-text">
                Get real-time status badges for FREE
              </h3>
              <button className="badge-header-button" onClick={requestBadgeAccess}>
                Submit request
              </button>
            </>
          )}
          <StyledClose onClick={() => closeBanner(banner)}>+</StyledClose>
        </StyledBanner>
      )}
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  toggleInterestModal: state => dispatch(toggleInterestModal(state))
});

export default connect(null, mapDispatchToProps)(Banner);

const SuspensionBanner = styled.div`
  justify-content: start;
  gap: 12px;
  padding: 12px 32px;
  background-color: #FEF1E1;
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  position: relative;
`

const StyledBanner = styled.div`
  width: 100%;
  height: 59px;
  background-image: linear-gradient(to bottom, #f7f7f7, #ebebeb);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledClose = styled.span`
  position: absolute;
  right: 30px;
  bottom: 13px;
  cursor: pointer;
  transform: rotate(45deg);
  font-size: 27px;
`;
