export const RecordTypes = [
  {
    value: 'A',
    label: 'A'
  },
  {
    value: 'AAAA',
    label: 'AAAA'
  },
  {
    value: 'CNAME',
    label: 'CNAME'
  },
  {
    value: 'MX',
    label: 'MX'
  },
  {
    value: 'NS',
    label: 'NS'
  },
  // {
  //   value: 'PTR',
  //   label: 'PTR'
  // },
  {
    value: 'SOA',
    label: 'SOA'
  },
  {
    value: 'SRV',
    label: 'SRV'
  },
  {
    value: 'TXT',
    label: 'TXT'
  }
];

export const Dattta = {
  ON: true,
  OFF: false
};
