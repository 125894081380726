import React from 'react';
import PropTypes from 'prop-types';
import { getQueryVariable } from '../utils/utility';
import { request } from '../utils/Api';

class freshidLogout extends React.Component {
  componentDidMount() {
    const response = request('/user-logout/', {
      method: 'POST',
      payload: {}
    });
    if (response) {
      this.doLogout();
    }
  }

  doLogout = () => {
    localStorage.clear();
    const redirect_uri =
      getQueryVariable('post_logout_redirect_uri') ||
      getQueryVariable('redirect_uri');
    if (redirect_uri && redirect_uri.indexOf('http') === 0)
      window.location = redirect_uri;
    else window.location = window.location.origin;
  };
  render() {
    return <div />;
  }
}
freshidLogout.contextTypes = {
  router: PropTypes.object.isRequired
};
export default freshidLogout;
