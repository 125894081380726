import React from 'react';
import styled, { css } from 'styled-components';

const returnTooltip = props => {
  switch (props.tipLocation) {
    case 'top-left':
      return `
          &:after {
            bottom: calc(100% + 4px);
            right: 0;
          }
          &:before {
            bottom: 100%;
            right: 0;
            transform: translateX(-100%);
            border-bottom-width: 0;
            border-top-color: #333;
          }
      `;
    case 'top-right':
      return `
          &:after {
            bottom: calc(100% + 4px);
            left: 0;
          }
          &:before {
            bottom: 100%;
            left: 0;
            transform: translateX(100%);
            border-bottom-width: 0;
            border-top-color: #333;
          }
      `;
    case 'top':
      return `
          &:after {
            bottom: calc(100% + 8px);
            left: 50%;
            transform: translateX(-50%);
          }
          &:before {
            bottom: calc(100% + 3px);
            left: 50%;
            transform: translateX(-50%);
            border-bottom-width: 0;
            border-top-color: #333;
          }
      `;
    case 'right':
    default:
      return `
          &:after {
            top: 50%;
            left: calc(100% + 9px);
            transform: translateY(-50%);
          }
          &:before{
            top: calc(50% - 5px);
            left: calc(100% + 4px);
            border-left-width: 0;
            border-right-color: #333;
          }
      `;
    case 'bottom-left':
      return `
          &:after {
            top: calc(100% + 4px);
            right: 0;
          }
          &:before {
            top: 100%;
            right: 0;
            transform: translateX(-100%);
            border-top-width: 0;
            border-bottom-color: $#333;
          }
      `;
    case 'bottom-right':
      return `
          &:after {
            top: calc(100% + 4px);
            left: 0;
          }
          &:before {
            top: 100%;
            left: 0;
            transform: translateX(100%);
            border-top-width: 0;
            border-bottom-color: #333;
          }
      `;
    case 'bottom':
      return `
        &:after {
          top: calc(100% + 8px);
          left: 50%;
          transform: translateX(-50%);
        }
        &:before {
          top: calc(100% + 3px);
          left: 50%;
          transform: translateX(-50%);
          border-top-width: 0;
          border-bottom-color: #333;
        }
      `;
    case 'left':
      return `
          &:after {
            right: calc(100% + 4px);
            top: 50%;
            transform: translateY(-50%);
          }
          &:before{
            right: 100%;
            top: calc(50% - 5px);
            border-right-width: 0;
            border-left-color: #333;
          }
      `;
  }
};

export const Tooltip = props => css`
  position: relative;

  &:after,
  &:before {
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    opacity: 0;
    display: block;
    text-transform: none;
  }

  &:before {
    content: '';
    z-index: 6002;
    border: 6px solid transparent;
  }

  &:after {
    content: ${props.tipText ? `'${css.escape(props.tipText)}'` : "''"};
    z-index: 6001;
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica', 'Segoe',
      sans-serif;
    font-size: 14px;
    font-weight: 500;
    min-width: 8px;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 12px;
    border-radius: 3px;
    background: #333;
    color: #ffffff;
  }

  ${props.tipText ? returnTooltip(props) : ''};

  &:hover:after,
  &:hover:before {
    opacity: 1;
    transition: opacity 0.1s ease-in 0.1s;
  }

  @media (max-width: 768px) {
    &:after,
    &:before {
      display: none;
    }
  }
`;

export const InlineSvg = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: inherit;
  fill: ${props => (props.color ? `${props.color}` : 'currentColor')};
`;

export const SvgWrapper = styled.div`
  display: inline-block;
  flex: 0 0 ${props => (props.size ? `${props.size}` : '32px')};
  width: ${props => (props.size ? `${props.size}` : '32px')};
  height: ${props => (props.size ? `${props.size}` : '32px')};
  min-width: ${props => (props.size ? `${props.size}` : '32px')};
  min-height: ${props => (props.size ? `${props.size}` : '32px')};
  position: relative;
  color: inherit;

  @media (min-width: 768px) {
    ${props => (props.tipText ? Tooltip(props) : '')};
  }

  ${props =>
    props.count &&
    css`
      background-color: transparent;
      &:after {
        content: ${props.count ? `'${props.count}'` : `''`};
        position: absolute;
        left: calc(100% - 12px);
        top: -2px;
        font-size: 14px;
        font-weight: 600;
        background: #2f2f65;
        color: ${process.env.NODE_ENV === 'production' ? '#393939' : 'grey'};
        border-radius: 8px;
        padding: 2px 4px;
        border: 2px solid
          ${process.env.NODE_ENV === 'production' ? '#393939' : 'grey'};
      }
    `};
`;

export const Glyph = ({ glyph }) => {
  switch (glyph) {
    case 'caution':
      return (
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M21.806 16.659L12.154.652a1.348 1.348 0 0 0-2.308 0L.194 16.659a1.348 1.348 0 0 0 1.154 2.044h19.304a1.348 1.348 0 0 0 1.154-2.044zM11.007 5.537c.555 0 1.024.313 1.024.867 0 1.691-.2 4.122-.2 5.813 0 .441-.483.626-.824.626-.455 0-.838-.185-.838-.626 0-1.691-.2-4.122-.2-5.813 0-.554.455-.867 1.038-.867zm.014 10.475a1.095 1.095 0 0 1-1.094-1.094c0-.597.469-1.094 1.094-1.094.583 0 1.08.497 1.08 1.094 0 .583-.497 1.094-1.08 1.094z"
        />
      );
    case 'refresh':
      return (
        <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z" />
      );
    case 'addicon':
      return (
        <path d="M17.6 17.6h4.8a1.6 1.6 0 1 0 0-3.2h-4.8V9.6a1.6 1.6 0 1 0-3.2 0v4.8H9.6a1.6 1.6 0 1 0 0 3.2h4.8v4.8a1.6 1.6 0 1 0 3.2 0v-4.8z" />
      );
    case 'success':
      return (
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M3.506 20.494c-4.675-4.675-4.675-12.313 0-16.988 4.675-4.675 12.313-4.675 16.988 0 4.675 4.675 4.675 12.313 0 16.988-4.675 4.675-12.313 4.675-16.988 0zM4.83 19.17c3.943 3.944 10.397 3.944 14.34 0 3.944-3.943 3.944-10.397 0-14.34C15.227.886 8.773.886 4.83 4.83.886 8.773.886 15.227 4.83 19.17zm2.343-7.293l2.977 2.977 6.995-7.028a.973.973 0 0 1 1.38 1.372l-7.684 7.72a.973.973 0 0 1-1.377.002l-3.667-3.667a.973.973 0 0 1 1.376-1.376z"
        />
      );
    case 'close':
      return (
        <path d="M17.992 16l8.796-8.796a1.409 1.409 0 0 0-1.992-1.992L16 14.008 7.204 5.212a1.409 1.409 0 0 0-1.992 1.992L14.008 16l-8.796 8.796a1.409 1.409 0 0 0 1.992 1.992L16 17.992l8.796 8.796a1.409 1.409 0 0 0 1.992-1.992L17.992 16z" />
      );
    case 'addcustomheader':
      return (
        <path d="M17.992 16l8.796-8.796a1.409 1.409 0 0 0-1.992-1.992L16 14.008 7.204 5.212a1.409 1.409 0 0 0-1.992 1.992L14.008 16l-8.796 8.796a1.409 1.409 0 0 0 1.992 1.992L16 17.992l8.796 8.796a1.409 1.409 0 0 0 1.992-1.992L17.992 16z" />
      );
    case 'notification':
      return (
        <path
          d="M4.559 15.903H.727A.74.74 0 0 1 0 15.15a.74.74 0 0 1 .727-.754h.647v-6.36c0-3.393 2.376-6.21 5.499-6.765a1.23 1.23 0 0 1-.004-.1C6.869.526 7.375 0 8 0s1.131.525 1.131 1.172c0 .033-.001.066-.004.099 3.123.554 5.5 3.372 5.5 6.764v6.361h.646a.74.74 0 0 1 .727.754.74.74 0 0 1-.727.753H11.44C11.205 17.653 9.755 19 8 19s-3.205-1.347-3.441-3.097zm1.48 0c.217.913 1.012 1.59 1.961 1.59.949 0 1.744-.677 1.962-1.59H6.038zm7.133-1.507v-6.36c0-2.96-2.316-5.358-5.172-5.358S2.828 5.077 2.828 8.035v6.361h10.344z"
          fill="#777"
        />
      );
    case 'lock':
      return (
        <path
          fill="#149999"
          fillRule="evenodd"
          d="M1.369 2.859V2.54a2.223 2.223 0 0 1 4.446 0v.318h.299a1 1 0 0 1 1 1v4.224a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3.86a1 1 0 0 1 1-1h.369zm1.111 0h2.223V2.54a1.112 1.112 0 1 0-2.223 0v.318zm1.077 3.557a.89.89 0 1 0 0-1.779.89.89 0 0 0 0 1.779z"
        />
      );
    case 'exclamation':
      return (
        <path
          fill="#D72D30"
          fillRule="nonzero"
          d="M14.5 0C6.492 0 0 6.492 0 14.5 0 22.509 6.492 29 14.5 29 22.509 29 29 22.51 29 14.5 29 6.492 22.508 0 14.5 0zm0 26.281c-6.507 0-11.781-5.274-11.781-11.781 0-6.507 5.274-11.781 11.781-11.781 6.507 0 11.781 5.274 11.781 11.781 0 6.507-5.274 11.781-11.781 11.781zm0-19.922c-1.038 0-1.823.542-1.823 1.418v8.029c0 .876.785 1.416 1.824 1.416 1.013 0 1.824-.562 1.824-1.416v-8.03c0-.854-.81-1.417-1.824-1.417zm0 12.672c-.997 0-1.809.812-1.809 1.81s.812 1.81 1.81 1.81 1.809-.812 1.809-1.81-.811-1.81-1.81-1.81z"
        />
      );
    case 'upload':
      return (
        <path
          fill="#bbb"
          fillRule="nonzero"
          d="M11.868 4.062a3.33 3.33 0 0 0 .069-.676c0-1.87-1.55-3.386-3.46-3.386a3.464 3.464 0 0 0-3.17 2.028 2.648 2.648 0 0 0-1.429-.416c-1.386 0-2.518 1.053-2.612 2.386A2.118 2.118 0 0 0 0 5.926c0 1.172.97 2.122 2.168 2.122H5.3c.154 0 .162-.167.162-.167V6.52a.214.214 0 0 0-.217-.212h-.592c-.119 0-.158-.076-.088-.17l1.806-2.385c.071-.094.187-.094.258 0l1.806 2.385c.07.094.03.17-.088.17h-.592a.214.214 0 0 0-.216.212v1.364s.009.164.168.164h3.125c1.197 0 2.168-.95 2.168-2.122 0-.805-.458-1.505-1.132-1.864z"
        />
      );
    case 'email':
      return (
        <g fill="#8C8C8C" fillRule="nonzero" opacity=".56">
          <path d="M1.15 2.923l1.373.954c.763.529 1.347.936 1.753 1.221l.284.204c.145.105.266.19.362.254.095.065.211.138.348.218.136.08.264.14.384.18.12.04.232.06.335.06H6.002c.103 0 .215-.02.335-.06.12-.04.249-.1.385-.18a7.066 7.066 0 0 0 .709-.472l.284-.204 3.132-2.175c.326-.227.598-.502.816-.823.219-.32.328-.658.328-1.01 0-.295-.106-.547-.318-.756a1.034 1.034 0 0 0-.752-.315H1.07C.727.02.463.135.278.367.093.6 0 .89 0 1.237c0 .281.123.586.368.913.245.328.506.586.783.773z" />
          <path d="M11.322 3.64A139.07 139.07 0 0 0 7.99 5.947c-.255.187-.46.333-.62.438a3.82 3.82 0 0 1-.632.321c-.263.11-.508.164-.736.164H5.99c-.228 0-.473-.054-.736-.164a3.822 3.822 0 0 1-.632-.32 15.847 15.847 0 0 1-.62-.44C3.4 5.508 2.292 4.738.677 3.64A3.544 3.544 0 0 1 0 3.057V8.37c0 .295.105.547.314.756.21.21.462.315.757.315h9.85c.294 0 .546-.105.756-.315.21-.21.314-.461.314-.756V3.057a3.69 3.69 0 0 1-.669.582z" />
        </g>
      );
    case 'phonenumber':
      return (
        <path
          fill="#8C8C8C"
          fillRule="nonzero"
          d="M11.976 9.47a.58.58 0 0 1-.178.508l-1.687 1.677a.95.95 0 0 1-.298.216 1.23 1.23 0 0 1-.361.114l-.076.007a1.724 1.724 0 0 1-.165.006c-.161 0-.421-.027-.78-.082-.36-.055-.8-.191-1.32-.407-.52-.216-1.11-.54-1.769-.972-.66-.432-1.361-1.025-2.105-1.779a13.916 13.916 0 0 1-1.472-1.677 11.953 11.953 0 0 1-.938-1.48 7.98 7.98 0 0 1-.533-1.233 6.548 6.548 0 0 1-.241-.953 3.152 3.152 0 0 1-.05-.629l.012-.254c.017-.118.055-.239.114-.362a.952.952 0 0 1 .216-.298L2.032.182a.558.558 0 0 1 .405-.178c.11 0 .208.032.292.095a.883.883 0 0 1 .216.235l1.357 2.58a.62.62 0 0 1 .063.444.792.792 0 0 1-.215.407l-.622.622a.212.212 0 0 0-.044.083.314.314 0 0 0-.02.095c.035.178.11.381.23.61a5.6 5.6 0 0 0 .468.743c.212.293.512.63.901 1.01.38.39.719.693 1.015.91.296.215.543.374.742.476.198.101.35.163.456.184l.159.032a.302.302 0 0 0 .082-.02.212.212 0 0 0 .083-.044l.723-.737a.776.776 0 0 1 .533-.203c.143 0 .257.025.342.076h.013l2.448 1.448c.177.11.283.25.317.42z"
          opacity=".56"
        />
      );
    case 'organization':
      return (
        <g fill="#8C8C8C" fillRule="nonzero" opacity=".56">
          <path d="M9.89 1.406V12H12V1.406H9.89zm.704 6.328v-.703h.703v.703h-.703zm.703.704v.703h-.703v-.704h.703zm-.703-2.11v-.703h.703v.703h-.703zm0-1.406v-.703h.703v.703h-.703zm0-1.406v-.704h.703v.704h-.703zM2.11 1.406H0V12h2.11V1.406zM1.405 4.22v.703H.703v-.703h.703zm-.703-.703v-.704h.703v.704H.703zm.703 2.109v.703H.703v-.703h.703zm0 1.406v.703H.703v-.703h.703zm0 1.407v.703H.703v-.704h.703zM4.922 2.109h2.156v1H4.922zM4.922 7.711h2.156v1H4.922zM4.922 4.922h2.156v1H4.922zM6.352 9.117h1V12h-1zM4.922 9.117h1V12h-1z" />
          <path d="M9.188 0H2.811v12H4.22V7.008H7.78V12h1.407V0zm-4.97 1.406h3.563v2.086H4.22V1.406zm0 4.899V4.219h3.563v2.086H4.22z" />
        </g>
      );
    case 'orgsize':
      return (
        <path
          fill="#8C8C8C"
          fillRule="nonzero"
          d="M6.985 5.284c1.225 0 2.177-1.27 2.177-2.585v-.412C9.162.972 8.21.039 6.985.039S4.808.972 4.808 2.287v.412c0 1.314.952 2.585 2.177 2.585zm-4.362.425c.35 0 1.309-.455 1.309-1.397v-.699c0-.942-.692-1.397-1.309-1.397-.596 0-1.308.455-1.308 1.397v.699c0 .942.977 1.397 1.308 1.397zm7.84 1.746l-.004-.02a1.683 1.683 0 0 0-.048-.241v-.002a1.494 1.494 0 0 0-.239-.488c-.188-.262-.474-.529-.937-.79-.245-.137-.352-.2-.504-.2-.24 0-.45.188-.805.513l-.322.296c-.012.01-.359.5-.619.5s-.607-.49-.619-.5l-.322-.296c-.354-.325-.564-.513-.805-.513-.151 0-.287.063-.504.2-.45.284-.748.527-.937.789l-.002.002a1.494 1.494 0 0 0-.285.728c0 .008-.003.015-.003.023a2.42 2.42 0 0 0-.015.266v1.921c0 .62.234.873.873.873h5.238c.645 0 .873-.239.873-.873v-1.92c0-.092-.005-.18-.015-.268zm.891-1.746c.35 0 1.309-.455 1.309-1.397v-.699c0-.942-.691-1.397-1.309-1.397-.596 0-1.308.455-1.308 1.397v.699c0 .942.977 1.397 1.308 1.397zm-10.36.945A1.889 1.889 0 0 0 0 8.334v.873c0 .633.213.873.873.873H2.62V7.46l-.437-.436c-.462-.407-.851-.562-1.19-.37zm1.652.824c.022.01 0-.247 0 0zm11.324.856c0-.48-.181-1.217-.993-1.68-.339-.192-.728-.037-1.19.37l-.436.436v2.62h1.746c.66 0 .873-.24.873-.873v-.873zm-2.587-.856c0-.247-.022.01 0 0z"
          opacity=".56"
        />
      );
    case 'employeerole':
      return (
        <g fill="#8C8C8C" fillRule="nonzero" opacity=".56">
          <path d="M11.143 9.286C9.685 8.73 8.609 8.264 8.609 8.264L7.482 11.82l-.154.48-.502-1.425c1.158-1.612-.086-1.692-.305-1.692h-.01-.006c-.219 0-1.468.08-.31 1.692l-.501 1.426-.155-.48-1.126-3.557s-.876.572-2.334 1.127a2.32 2.32 0 0 0-.667.358c-.322.31-.616 1.652-.88 4.025h11.932c-.703-2.925-1.143-4.42-1.321-4.488z" />
          <path d="M3.489 5.093c.197.705.384.897.523.913.128.838.774 1.9 1.831 2.27.433.154.913.154 1.346-.006C8.229 7.896 8.897 6.834 9.025 6c.14-.01.326-.207.523-.912.273-.961-.016-1.105-.261-1.079.048-.133.08-.266.107-.395.416-2.488-.812-2.573-.812-2.573S8.38.651 7.845.352C7.488.14 6.985-.027 6.33.032a2.2 2.2 0 0 0-.603.117 2.59 2.59 0 0 0-.662.342 3.44 3.44 0 0 0-.684.566 2.705 2.705 0 0 0-.736 1.297c-.107.4-.08.817.005 1.266.021.133.059.261.107.395-.251-.022-.54.117-.267 1.078z" />
        </g>
      );
    default:
      return null;
  }
};

const Icons = props => {
  const {
    size = 32,
    tipText,
    tipLocation,
    count,
    onClick,
    glyph,
    dataCy,
    color,
    viewBox
  } = props;

  return (
    <SvgWrapper
      data-cy={dataCy}
      size={size}
      tipText={tipText}
      tipLocation={tipLocation}
      count={count}
      className="icon"
      onClick={onClick}
    >
      <InlineSvg
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="1.414"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
        viewBox={viewBox || '0 0 32 32'}
        color={color}
        preserveAspectRatio="xMidYMid meet"
        fit
        id={glyph}
      >
        <title id="title">{glyph}</title>
        <Glyph glyph={glyph} />
      </InlineSvg>
    </SvgWrapper>
  );
};

export default Icons;

export const CustomIcons = type => {
  switch (type) {
    case 'dashboard':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="14"
          viewBox="0 0 20 14"
        >
          <g fill="#FFF" fillRule="evenodd" transform="translate(0 -1.262)">
            <rect width="9.143" height="1.169" y="3.508" rx=".585" />
            <rect width="9.143" height="1.169" y="8.185" rx=".585" />
            <path d="M15.4 13.75l2.885-2.887a.5.5 0 0 1 .708 0l.12.12a.5.5 0 0 1 0 .707l-3.334 3.334-.007.007a.5.5 0 0 1-.707 0l-3.428-3.427a.5.5 0 0 1 0-.708l.101-.1a.5.5 0 0 1 .708 0l2.953 2.953zm.045-12.456c.062.024.12.061.17.111l3.426 3.427a.5.5 0 0 1 0 .707l-.1.101a.5.5 0 0 1-.708 0l-2.958-2.958-2.881 2.88a.5.5 0 0 1-.707 0l-.12-.12a.5.5 0 0 1 0-.706L14.9 1.402a.5.5 0 0 1 .545-.108z" />
            <rect width="5.714" height="1.169" y="12.863" rx=".585" />
          </g>
        </svg>
      );
    default:
      return null;
  }
};
