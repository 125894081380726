import * as Yup from 'yup';
import {
  getMockedInitialValues,
  dataJsonDefault,
  dataJsonGoogleChat,
  dataJsonMSOffice,
  dataJsonSlack,
  dataJsonZapier
} from './constants';

Yup.addMethod(Yup.string, 'authCheck', function(message) {
  return this.test('authCheck', message, function(val) {
    const { requireAuth } = this.parent;
    return requireAuth ? Boolean(val) : true;
  });
});

Yup.addMethod(Yup.string, 'isParseable', function(message) {
  return this.test('isParseable', message, val => {
    try {
      JSON.parse(val);
      return true;
    } catch (e) {
      return false;
    }
  });
});

export const getValidationSchema = () =>
  Yup.object().shape({
    state: Yup.string()
      .trim()
      .required('State is required'),
    name: Yup.string()
      .nullable('Webhook name is required')
      .trim()
      .min(3, 'Webhook name should be longer than 3 characters')
      .max(128, 'Webhook name should be limited to 128 characters')
      .required('Webhook name is required'),
    type: Yup.string(),
    organization: Yup.number().required('Org Id is required'),
    data_json: Yup.string().isParseable('Error in parsing'),
    username: Yup.string().authCheck('Username is required'),
    password: Yup.string().authCheck('Password is required'),
    encoding: Yup.string(),
    requireAuth: Yup.boolean(),
    url: Yup.string()
      .url('URL should be vaild')
      .required('Callback URL is required')
  });

export const getPayload = ({ values }) => {
  const {
    id,
    name,
    type,
    url,
    requireAuth,
    username,
    password,
    contentEncoding,
    subscribed_checks,
    state,
    data_json,
    organization
  } = values;
  const meta = {
    username: requireAuth ? username : '',
    password: requireAuth ? password : '',
    data_json:
      contentEncoding === 'advanced'
        ? JSON.parse((data_json && data_json.replaceAll('↵', '\n')) || '')
        : null
  };
  const payload = {
    state,
    name,
    subscribed_checks,
    type,
    url,
    meta: JSON.stringify(meta),
    organization
  };
  return id ? { id, ...payload } : payload;
};

export const getInitialValues = ({ webhookIntegration }) => {
  const { clone, id, state, name, type, url, subscribed_checks, meta } =
    webhookIntegration || {};
  const { username, password, data_json } = JSON.parse(meta || '{}');
  const initialValues = getMockedInitialValues();
  if (!id && !clone) return initialValues;
  const currentValues = { id, ...initialValues };
  const checks =
    typeof subscribed_checks === 'string'
      ? JSON.parse(subscribed_checks)
      : subscribed_checks || [];
  currentValues.name = name;
  currentValues.type = type;
  currentValues.subscribed_checks = checks;
  currentValues.state = state;
  currentValues.url = url;
  currentValues.requireAuth = Boolean(username) && Boolean(password);
  currentValues.username = username || '';
  currentValues.password = password || '';
  currentValues.contentEncoding = data_json ? 'advanced' : 'simple';

  if (data_json) {
    currentValues.data_json = JSON.stringify(data_json || {}, '', 2)
  } else {
    let data_json_simple = dataJsonDefault;
    if (url.indexOf('chat.googleapis.com') > -1) {
      data_json_simple = dataJsonGoogleChat;
    } else if (url.indexOf('.office.com') > -1) {
      data_json_simple = dataJsonMSOffice;
    } else if (url.indexOf('hooks.slack.com') > -1) {
      data_json_simple = dataJsonSlack;
    } else if (url.indexOf('zapier') > -1) {
      data_json_simple = dataJsonZapier;
    }
    currentValues.data_json = data_json_simple
  }

  return currentValues;
};
