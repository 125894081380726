import React from "react";
import styled, { css } from "styled-components";

const tooltipStyles = {
  top: css`
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 8px;
  `,
  bottom: css`
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;
  `,
  left: css`
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 8px;
  `,
  right: css`
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 8px;
  `
};

const arrowStyles = {
  top: css`
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-top-width: 6px;
    border-bottom-width: 0;
    border-top-color: #1a1a1a;
  `,
  bottom: css`
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-bottom-width: 6px;
    border-top-width: 0;
    border-bottom-color: #1a1a1a;
  `,
  left: css`
    left: 100%;
    top: 50%;
    margin-top: -5px;
    border-left-width: 6px;
    border-right-width: 0;
    border-left-color: #1a1a1a;
  `,
  right: css`
    right: 100%;
    top: 50%;
    margin-top: -5px;
    border-right-width: 6px;
    border-left-width: 0;
    border-right-color: #1a1a1a;
  `
};

const StyledTooltip = styled.div`
  position: absolute;
  background: #1a1a1a;
  color: #fff;
  border-radius: 3px;
  width: ${props => (props.width ? props.width : "max-content")};
  text-align: center;
  padding: 8px 8px 7px;
  line-height: normal;
  font-size: 12px;
  pointer-events: none;
  z-index: 9;
  opacity: ${props => (props.defaultVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  ${props => tooltipStyles[props.position]};
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: 5.5px solid transparent;
    ${props => arrowStyles[props.position]};
  }
`;

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  text-transform: initial;
  &:hover {
    ${StyledTooltip} {
      opacity: 1;
    }
  }
`;

const Tooltip = ({ content, ...props }) => (
  <TooltipWrapper>
    <StyledTooltip className="cosmos-tooltip" {...props}>
      {content}
    </StyledTooltip>
    {props.children}
  </TooltipWrapper>
);

Tooltip.defaultProps = {
  content: null,
  position: "top",
  defaultVisible: false
};

export default Tooltip;
