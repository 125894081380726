import React, { Component } from 'react';
import { Button } from 'freemium-ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  getStatusPages,
  getStatusPageChecks,
  deleteStatusPage
} from '../actions';
import { StyledLoading } from '../components/styled/Loading';
import StatusListItem from './StatusListItem';
import StyledEndList from '../components/styled/EndList';
import BlankStatus from '../images/blank-status.svg';
import '../styles/status.css';

const mapDispatchToProps = dispatch => ({
  getStatusPages: id => dispatch(getStatusPages(id)),
  deleteStatusPage: statuspage => dispatch(deleteStatusPage(statuspage)),
  getStatusChecks: id => dispatch(getStatusPageChecks(id))
});

const mapStateToProps = state => ({
  statuspages: state.statuspages
});

class Status extends Component {
  componentDidMount() {
    this.props.getStatusPages();
  }
  render() {
    const {
      statuspages: { appState, statuspages },
      url,
      getStatusChecks
    } = this.props;
    return (
      <div className="status-container">
        {appState !== 'success' ? (
          <StyledLoading />
        ) : (
          <div>
            {statuspages.count ? (
              <StyledStatusList>
                <section className="public-status-page-wrapper">
                  <div className="public-status-page">
                    <p className="heading">
                      Public status page{' '}
                      {statuspages.results.length && (
                        <span>({statuspages.results.length}/5)</span>
                      )}
                    </p>
                    {statuspages.results.length < 5 ? (
                      <Link to={`${url}/new`} href={`${url}/new`}>
                        <Button type="secondary" inline>
                          Create Status Page
                        </Button>
                      </Link>
                    ) : null}
                  </div>
                </section>
                {statuspages.results.map(statuspage => (
                  <StatusListItem
                    url={url}
                    deleteStatusPage={this.props.deleteStatusPage}
                    statuspage={statuspage}
                    key={statuspage.id}
                    getStatusChecks={getStatusChecks}
                  />
                ))}
                <StyledEndList />
              </StyledStatusList>
            ) : (
              <StyledBlankStatus>
                <div className="blank-placeholder">
                  <img src={BlankStatus} alt="blank status" />
                </div>
                <Link to={`${url}/new`} href={`${url}/new`}>
                  <Button type="primary" inline>
                    Create New
                  </Button>
                </Link>
              </StyledBlankStatus>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Status);

const StyledStatusList = styled.div`
  .public-status-page-wrapper {
    width: 100%;
    background-color: rgb(248, 249, 251);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px;
    z-index: 1;
    padding: 0;
    margin-bottom: 30px;
    position: relative;
    border-bottom: 1px solid rgb(224, 228, 234);
  }
  .public-status-page {
    padding: 0;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .heading {
      margin: 0;
      padding: 0;
    }
  }
  .status-page-content {
    border-radius: 4px;
    background-color: #f9f9f9;
    cursor: default;
    padding: 0;

    .row-title {
      display: flex;
      padding: 5px 20px;
      background: #fff;
      min-height: 85px;
      box-sizing: border-box;
      transition: all 100ms linear;
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 2px 0px;
      border-radius: 3px;
    }
    .row-title:hover {
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .row-content {
      padding: 15px 20px;
      background: #f9f9f9;
      border-bottom: solid 0.5px #dddddd;
    }
  }
`;

const StyledBlankStatus = styled.div`
  text-align: center;
  .blank-placeholder {
    margin-top: 100px;
    margin-bottom: 30px;
  }
`;
