import React from 'react';
import styled from 'styled-components';
import { Button } from 'freemium-ui';
import { connect } from 'react-redux';
import logo from '../images/logo.svg';
import suspended from '../images/accountsuspended.svg';
import {
  getCurrentUserOrg,
  toggleInterestModal,
  getSubscription
} from '../actions';
import CaptureInterestModal from '../components/CaptureInterst';
import { getOrgId } from '../utils/utility';
import { request } from '../utils/Api';
// import { DOWNGRADE_CHECKS } from "../constants/constant";
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import {
  StyledHeader,
  StyledFooter,
  StyledFeatureInterest
} from '../components/CaptureInterst';

class AccountSuspensionCard extends React.Component {
  state = {
    showDowngradeError: false
  };

  captureInterest = type => {
    const payload = {
      state: true,
      type
    };
    this.props.toggleInterestModal(payload);
  };

  createInterest = async reason => {
    const payload = {
      reason: `Request for ${reason}`,
      organization: Number(getOrgId()),
      plan_requested: 'sprout'
    };
    await request(`/create-support-ticket/`, {
      method: 'POST',
      payload
    });
  };

  paynow = async () => {
    const { cb_plan, addons } = this.props.userOrg.subscription;
    const payload = {
      plan_id: cb_plan,
      organization: Number(getOrgId()),
      addons,
      pay_now: true
    };
    const response = await request(`/update-plan/`, {
      method: 'POST',
      payload
    });

    if (response.hosted_page_url) {
      window.location.href = response.hosted_page_url;
    } else if (response.checkout_method === 'subscription_updated') {
      window.location.href = `${window.location.host}/settings/pricing?state=updated`;
    }
  };

  downgradeToSprout = async () => {
    const payload = {
      plan_id: 'sprout',
      organization: Number(getOrgId()),
      addons: []
    };

    const response = await request(`/update-plan/`, {
      method: 'POST',
      payload
    });

    if (Object.keys(response.limit_violation_with_addons).length) {
      this.setState({
        limits: response.limit_violation_with_addons,
        showDowngradeError: true
      });
    } else {
      this.props.getSubscription();
      // this.getCurrentUserOrg();
      // toastr.success(`Plan has been downgraded to sprout`);
      window.location.reload();
    }
  };

  close = () => this.setState({ showDowngradeError: false });

  render() {
    return (
      <AccountSuspendedWrapper>
        <SuspensionLayout>
          <img
            className="appLogo"
            src={logo}
            width="200px"
            height="51px"
            alt="freshping logo"
          />
          <img
            className="suspendedimg"
            src={suspended}
            width="277px"
            height="212px"
            alt="account suspended"
          />
          <h2>Account suspended</h2>
          <p>This account has been suspended due to payment issue</p>
          <ActionContainer>
            <Button
              inline
              type="secondary"
              onClick={() => this.downgradeToSprout(getOrgId())}
            >
              Downgrade to Sprout
            </Button>
            <Button
              inline
              type="primary"
              className="primary-btn"
              onClick={() => this.paynow(getOrgId())}
            >
              Pay now
            </Button>
          </ActionContainer>
        </SuspensionLayout>
        <p className="info">
          If you have any payment issues, please
          <span
            className="contactus"
            onClick={() => this.captureInterest('access')}
          >
            contact us
          </span>
          for support
        </p>
        <CaptureInterestModal />
        {this.state.showDowngradeError ? (
          <DowngradeModal
            limits={this.state.limits}
            captureInterest={this.createInterest}
            close={this.close}
          />
        ) : null}
      </AccountSuspendedWrapper>
    );
  }
}

const mapStateToProps = state => ({
  org: state.user.org
});

const mapDispatchProps = dispatch => ({
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg()),
  toggleInterestModal: state => dispatch(toggleInterestModal(state)),
  getSubscription: () => dispatch(getSubscription())
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(AccountSuspensionCard);

export const DowngradeModal = props => (
  <ModalTransition>
    <Modal
      heading={undefined}
      autoFocus={false}
      shouldCloseOnOverlayClick
      width="500px"
      onClose={props.close}
      components={{
        Header: () => (
          <StyledHeader>
            <h2> Unable to downgrade to Sprout </h2>
          </StyledHeader>
        ),
        Footer: () => (
          <StyledFooter>
            <button
              onClick={() => props.close()}
              style={{ width: '100px' }}
              className="secondary-btn"
            >
              No
            </button>
            <button
              onClick={() => props.captureInterest('Downgrade to Sprout')}
              className="primary-btn"
            >
              Yes, let me know
            </button>
          </StyledFooter>
        )
      }}
    >
      <StyledFeatureInterest className="text-grey">
        {/* A support ticket has been created for the Downgrade request. */}
        <p>
          Sprout plan only allows <b>50 Checks</b>, <b>5 Integrations</b>,{' '}
          <b>10 users</b>, and <b>5 statuspages</b>. You seem to have exceeded
          plan limits.
        </p>
        <p> If you want to downgrade, we would create a support request.</p>
      </StyledFeatureInterest>
    </Modal>
  </ModalTransition>
);

const ActionContainer = styled.div`
  text-align: center;
  button {
    padding: 6px 0;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    user-select: none;
    box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.05);
    text-transform: uppercase;
    padding: 10px 20px;
    margin-bottom: 20px;
    margin-right: 10px;
  }
`;

const SuspensionLayout = styled.div`
  width: 600px;
  border-radius: 11px;
  box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin: 30px auto;
  padding: 10px;
  margin-bottom: 20px;

  .appLogo,
  .suspendedimg {
    margin: 31px auto;
    display: block;
  }

  h2 {
    font-size: 41px;
    font-weight: 500;
  }

  p {
    font-size: 20px;
    font-weight: normal;
    color: #8f8f8f;
  }
`;

const AccountSuspendedWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #f6f6f6;
  padding: 50px 10px;
  margin: 0 auto;
  text-align: center;
  user-select: none;

  p.info {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    color: #555555;

    .contactus {
      color: #596fed;
      cursor: pointer;
      margin: 0 5px;
    }
  }
`;
