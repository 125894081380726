import React from 'react';
import Editor, { monaco } from '@monaco-editor/react';
import { Container, ErrorText, Label } from './style';

const JsonEditor = ({
  editorRef,
  onChange = () => undefined,
  setFieldError = () => undefined,
  showErrors,
  insertText,
  label,
  id,
  value,
  ...rest
}) => {
  const [errors, setErrors] = React.useState([]);
  const handleEditorDidMount = (getEditorValue, editor) => {
    let monacoInstance = {};
    editorRef.current = editor;
    monaco.init().then(x => {
      monacoInstance = x;
      x.editor.setModelMarkers(editor.getModel());
    });
    editor.onDidChangeModelContent(() => onChange(getEditorValue()));
    editor.onDidChangeModelDecorations(() => {
      const model = editor.getModel();
      if (model === null || model.getModeId() !== 'json') return;
      const owner = model.getModeId();
      const markers = monacoInstance.editor.getModelMarkers({ owner });
      const { path } = model._associatedResource || {};
      const markerErrors = markers
        .filter(
          eachMarker =>
            eachMarker.severity === 8 && eachMarker.resource.path === path
        )
        .map(
          marker =>
            `${marker.endLineNumber}:${marker.endColumn} - ${marker.message}`
        );
      setErrors(markerErrors);
      setFieldError(markerErrors.length > 0 ? markerErrors : undefined);
    });
  };

  React.useEffect(() => {
    if (insertText) {
      const editor = editorRef.current;
      const position = editor.getPosition();
      const op = {
        range: {
          startColumn: position.column,
          endColumn: position.column,
          endLineNumber: position.lineNumber,
          startLineNumber: position.lineNumber
        },
        text: insertText,
        forceMoveMarkers: true
      };
      editor.executeEdits('source', [op]);
      editor.focus();
    }
  }, [insertText]);

  return (
    <>
      {label && <Label>{label}</Label>}
      <Container error={errors.length > 0 && showErrors}>
        <Editor
          id={id}
          theme="light"
          language="json"
          wrapperClassName="editor"
          value={value}
          editorDidMount={handleEditorDidMount}
          {...rest}
        />
        {errors.length > 1 && showErrors ? (
          <ErrorText>{`${errors.length} errors`}</ErrorText>
        ) : (
          errors[0] && showErrors && <ErrorText>{errors[0] || ''}</ErrorText>
        )}
      </Container>
    </>
  );
};

export default JsonEditor;
