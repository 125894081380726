import React from 'react';
import styled from 'styled-components';
import '../styles/Reports.css';
import {
  getStateFromStats,
  padNumber,
  addPlaceholdersInChartData,
  getOrgId,
  datewithTimezone,
  getTimezoneDate
} from '../utils/utility';
import { request } from '../utils/Api';
import SelectedCheck from './SelectedCheck';
import PastIncidents from './PastIncidents';
import Availability from './Availability';
import PerformanceMetrics from './PerformanceMetrics';
import HistorticalUpTime from './HistoricalUpTime';
import moment from 'moment';
import { DEFAULT_RESPONESE_TIME } from '../constants/constant';
import { connect } from 'react-redux';
import { getCurrentUserOrg } from '../actions';

const DEFAULT_TIMEFILTER = 24;

class Report extends React.Component {
  constructor() {
    super();
    this.state = {
      checkId: '',
      checkUrl: '',
      SelectedCheckResult: '',
      checkState: 'loading',
      downTimeHistoryResult: '',
      historicalUptimeResult: '',
      time: DEFAULT_TIMEFILTER,
      graphResult: '',
      isLoaded: false,
      months: [],
      calenderResults: '',
      isResponseGraphAvailable: false,
      accountFeatures: 'idle', // | success
      pastIncidentsData: [],
      timeValid: true,
      deviceSize: null
    };

    window.addEventListener('resize', this.handleResize.bind(this));
  }
  getReportStartEndTime = () => {
    //specify the format of startDate and endDate so that the date parsers process it properly
    const duration =
      moment(this.state.endDate, 'DD-MMM-YYYY').diff(
        moment(this.state.startDate, 'DD-MMM-YYYY'),
        'days'
      ) + 1;
    const { timezone } = this.props.org;
    const aggregate = duration > 7 ? 'days' : 'hours';
    let reportStart, reportEnd;
    reportStart = datewithTimezone(
      moment(this.state.startDate, 'DD-MMM-YYYY')
        .startOf('day')
        .utc(),
      timezone || 'UTC'
    ).toISOString();
    reportEnd = datewithTimezone(
      moment(this.state.endDate, 'DD-MMM-YYYY')
        .endOf('day')
        .utc(),
      timezone || 'UTC'
    ).toISOString();
    return [reportStart, reportEnd, aggregate];
  };

  handleResize = () => {
    let deviceSize = 'device-large';

    if (typeof window !== 'undefined' && window.innerWidth < 1000) {
      deviceSize = 'device-small';
    }

    this.setState({ deviceSize: deviceSize });
  };

  handleChange = (e, value, text) => {
    this.setState(
      {
        checkId: e ? e.value : value ? value : this.state.checkId,
        checkUrl: e
          ? e.checkUrl
            ? e.checkUrl
            : e.label
          : text
          ? text
          : this.state.checkUrl
      },
      () => {
        this.getCheckData();
        this.getResponseTimeGraphData();
        this.getPastIncidentsData();
        this.getCalanderData();
        this.changeURLCheckId();
      }
    );
  };

  handleTimeChange = (e, value) => {
    this.setState(
      { time: e ? e.value : value, timeRangeChange: e ? e.value : value },
      function() {
        this.getCheckData();
        if (this.state.isResponsegraph && this.state.isResponsegraph.length) {
          this.getResponseTimeGraphData();
        }
      }
    );
  };

  changeURLCheckId = () =>
    this.props.history.push('?check_id=' + this.state.checkId);

  getCheckData = () => {
    this.setState({ checkState: 'loading' });
    const self = this;
    const [reportStart, reportEnd] = this.getReportStartEndTime();
    request(
      `/check-stats-reports/${this.state.checkId}/?start_time=${reportStart}&end_time=${reportEnd}`
    )
      .catch(error => console.error('Error-->:', error))
      .then(function(response) {
        self.setState({
          SelectedCheckResult: response,
          checkId: response.check_id,
          checkState: 'success'
        });
      });
  };

  getPastIncidentsData = (checkId, page, pageLength) => {
    const self = this;
    const checkIdInfo = checkId ? checkId : this.state.checkId;
    page = page ? page : 1;
    pageLength = pageLength ? pageLength : 10;
    if (this.state.pastIncidentsData) {
      this.setState({ pastIncidentsData: null });
    }

    request(
      `/check-state-changes/?check_id=${checkIdInfo}&page=${page}&page_size=${pageLength}&filterincidents=true`
    )
      .catch(error => console.error('Error-->:', error))
      .then(function(response) {
        let totalCount = response.count;
        let filtered = response.results.filter(function(item) {
          return item.new_state !== 'AV';
        });
        self.setState({
          pastIncidentsData: filtered ? filtered : [],
          incidentsCount: totalCount,
          page,
          pageLength
        });
        self.setState({ response });
      });
  };

  getResponseTimeGraphData = async () => {
    const isResponseGraphAvailable = await this.getFeatures(this.props.org);
    if (!isResponseGraphAvailable) {
      this.setState({ graphResult: DEFAULT_RESPONESE_TIME, isLoaded: true });
      return;
    }
    const [reportStart, reportEnd, aggregate] = this.getReportStartEndTime();
    const url = `/check-response-times-reports/${this.state.checkId}/?start_time=${reportStart}&end_time=${reportEnd}&aggregate_by=${aggregate}`;
    request(url)
      .catch(error => console.error('Error-->:', error))
      .then(response => {
        this.loadGraph(response);
      });
  };

  loadGraph = resp => {
    const responseValues = resp.response_times;
    const uniqueValues = [...new Set(responseValues)];
    const maxDataLength = uniqueValues.length;
    this.setState({
      graphResult:
        this.state.time === 1 && resp.response_times.length < maxDataLength
          ? addPlaceholdersInChartData(resp, maxDataLength)
          : resp,
      isLoaded: true
    });
  };

  getCalanderData = () => {
    const self = this;
    const checkId = this.state.checkId;
    const url = `/check-response-times-reports/${checkId}/?for_hours=2256&aggregate_by=days`;
    request(url)
      .catch(error => console.error('Error-->:', error))
      .then(async function(response) {
        const result = response.response_times.map(function(a) {
          return {
            check_start_time: a.start,
            check_end_time: a.end,
            new_state: getStateFromStats(a)
          };
        });

        //today and yesterday are combined so removing
        result.pop();

        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const utc_today_start = `${today.getFullYear()}-${padNumber(
          today.getMonth() + 1
        )}-${today.getDate()}T00:00`;
        const utc_today_end = `${today.getFullYear()}-${padNumber(
          today.getMonth() + 1
        )}-${today.getDate()}T23:59:59.999`;
        const utc_yesterday_start = `${yesterday.getFullYear()}-${padNumber(
          yesterday.getMonth() + 1
        )}-${yesterday.getDate()}T00:00`;
        const utc_yesterday_end = `${yesterday.getFullYear()}-${padNumber(
          yesterday.getMonth() + 1
        )}-${yesterday.getDate()}T23:59:59.999`;

        const url = `/check-stats-reports/${checkId}/?start_time=${utc_yesterday_start}&end_time=${utc_yesterday_end}`;
        request(url)
          .catch(error => console.error('Error-->:', error))
          .then(function(response) {
            result.push({
              check_start_time: utc_yesterday_start,
              check_end_time: utc_yesterday_end,
              new_state: getStateFromStats(response)
            });
            const url = `/check-stats-reports/${checkId}/?start_time=${utc_today_start}&end_time=${utc_today_end}`;
            request(url)
              .catch(error => console.error('Error-->:', error))
              .then(function(response) {
                result.push({
                  check_start_time: utc_today_start,
                  check_end_time: utc_today_start,
                  new_state: getStateFromStats(response)
                });
                self.setState({
                  calendarResults: result
                });
              });
          });
      });
  };

  loadDefaultMonths = () => {
    const monthsArr = [];
    for (let i = 3; i >= 0; i--) {
      const startTime = moment()
        .subtract(i, 'months')
        .startOf('month')
        .utc()
        .format();
      const endTime = moment()
        .subtract(i, 'months')
        .endOf('month')
        .utc()
        .format();
      monthsArr.push({
        startTime,
        endTime
      });
    }
    this.setState({
      months: monthsArr
    });
  };

  getFeatures = async org => {
    if (this.state.accountFeatures === 'idle') {
      return new Promise(async (resolve, reject) => {
        let currentOrg = org
          ? org.find(org => org.id === Number(getOrgId()))
          : null;
        let currentPlan = currentOrg
          ? currentOrg.subscription
            ? currentOrg.subscription.plan_name
            : ''
          : '';
        // const self = this;
        let plans = null;
        if (!this.state.plans) {
          plans = await request(`/public-plans/?name=${currentPlan}`);
          this.setState({ plans });
          this.setState({ pricingPlan: currentPlan.toLowerCase() });
        } else {
          plans = this.state.plans;
        }
        let featurePlan = '';
        if (plans && currentPlan) {
          for (let i = 0; i < plans.length; i++) {
            if (plans[i].name === currentPlan) {
              featurePlan = plans[i];
            }
          }

          const features = featurePlan ? featurePlan.feature_json : '';
          const isapdex = features
            ? features.filter(item => item.key === 'target_response_time')
            : [];

          const isavgResponse = features
            ? features.filter(item => item.key === 'avg_response_metrics')
            : [];

          let isResponsegraph = features
            ? features.filter(item => item.key === 'response_time_graph')
            : [];

          const isIncidentLog = features
            ? features.filter(item => item.key === 'incident_log')
            : [];
          const result = [];
          result[0] = isapdex;
          result[1] = isavgResponse;
          result[2] = isResponsegraph;
          result[3] = isIncidentLog;
          if (!this.state.result) {
            this.setState({
              result,
              isResponsegraph,
              accountFeatures: 'success',
              isResponseGraphAvailable: isResponsegraph.length
            });
          }
          resolve(isResponsegraph.length);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(this.state.isResponseGraphAvailable);
      });
    }
  };

  handleRangeChange = async value => {
    // const { timezone } = this.props.org;
    // const startDate = datewithTimezone(
    //   moment(value[0], 'DD/MMM/YYYY')
    //     .startOf('day')
    //     .utc(),
    //   timezone || 'UTC'
    // ).toISOString();
    // const endDate = datewithTimezone(
    //   moment(value[1], 'DD/MMM/YYYY')
    //     .endOf('day')
    //     .utc(),
    //   timezone || 'UTC'
    // ).toISOString();
    const duration = moment(value[1]).diff(moment(value[0]), 'days') + 1;
    this.setState(
      {
        startDate: value[0],
        endDate: value[1],
        time: duration * 24,
        timeValid: duration < 93
      },
      () => {
        if (duration < 93) {
          this.getCheckData();
          this.getResponseTimeGraphData();
        }

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent('Date Range', window._na_module);
        }
      }
    );
  };

  componentDidMount() {
    this.props.getCurrentUserOrg();
    this.loadDefaultMonths();
    this.getFeatures(this.props.org);
    const startDate = getTimezoneDate(moment().utc(), this.props.org.timezone);
    const endDate = getTimezoneDate(moment().utc(), this.props.org.timezone);
    this.setState({
      startDate,
      endDate
    });
  }

  componentWillMount() {
    this.handleResize();
  }

  render() {
    const { checks } = this.props;
    const {
      isLoaded,
      checkState,
      timeValid,
      deviceSize,
      pricingPlan
    } = this.state; //incidentsCount,
    const result = this.state.result;
    const isapdex = result ? result[0] : [];
    const isavgResponse = result ? result[1] : [];
    const isResponsegraph = result ? result[2] : [];
    const isIncidentLog = result ? result[3] : [];
    const data = this.state.SelectedCheckResult;
    const starting = this.state.months.length
      ? this.state.months[this.state.months.length - 1].startTime
      : '';
    const ending = this.state.months.length ? this.state.months[0].endTime : '';
    const activeTimeValue = this.state.timeRangeChange
      ? this.state.timeRangeChange
      : DEFAULT_TIMEFILTER;
    const checkIsLoading = checkState === 'loading';

    return (
      <div>
        <SelectedCheck
          onChange={this.handleChange}
          isLoaded={isLoaded}
          checkUrl={this.state.checkUrl}
          checkId={this.state.checkId}
          deviceSize={deviceSize}
        />
        <div className="reports-wrapper">
          {isLoaded ? (
            <div className="reports-container">
              <PerformanceMetrics
                checkIsLoading={checkIsLoading}
                incidentsCount={this.state.SelectedCheckResult.incidents_count}
                activeTimeValue={activeTimeValue}
                performanceData={data}
                timezone={this.props.org.timezone}
                handleRangeChange={this.handleRangeChange}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                timeValid={timeValid}
                deviceSize={deviceSize}
                planname={pricingPlan}
              />
              <Availability
                checkID={this.state.checkId}
                checkData={this.state.SelectedCheckResult}
                checkIsLoading={checkIsLoading}
                graphResult={this.state.graphResult}
                pricingPlan={this.state.pricingPlan}
                isapdex={isapdex}
                isavgResponse={isavgResponse}
                isResponsegraph={isResponsegraph}
                selectedTime={this.state.time}
                deviceSize={deviceSize}
              />

              <HistorticalUpTime
                data={this.state.calendarResults}
                checkID={this.state.checkId}
                months={this.state.months}
                start={starting}
                end={ending}
                deviceSize={deviceSize}
              />

              <div className="datepickerWrapper">
                <PastIncidents
                  result={this.state.pastIncidentsData}
                  getPastIncidentsData={this.getPastIncidentsData}
                  checkID={this.state.checkId}
                  pricingPlan={this.state.pricingPlan}
                  totalRecords={
                    this.state.incidentsCount ? this.state.incidentsCount : 0
                  }
                  selectedCheck={checks.find(c => c.id === this.state.checkId)}
                  checkData={this.state.SelectedCheckResult}
                  isIncidentLog={isIncidentLog}
                  page={this.state.page}
                  pageLength={this.state.pageLength}
                  deviceSize={deviceSize}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  organisation: state.user.org,
  checks: state.checks.checks
});

export default connect(mapStateToProps, { getCurrentUserOrg })(Report);

export const SkeletonWrapper = styled.div`
  padding-left: 12px;
  position: relative;
  top: -2px;
`;
