import React, { Component } from "react";
import { request } from "../utils/Api";
import close from "../images/close.svg";

class SubscriberChecks extends Component {
  state = {
    applications: [],
    checks: []
  };
  componentDidMount() {
    this.getSubscriberAndAllApplications();
  }

  getSubscriberAndAllApplications = async () => {
    // const { orgId } = this.props; //id,
    // var results = [],
    //   self = this,
    //   res = [];
    // var promises = [];
    /*const applications = await request(`/applications/?organization=${orgId.orgId}`);
    const response = await request(`/application-subscribers/?subscriber=${id}&application=${applications.results[0].id}`);
    var userApplications = response.results;
    for(var i=0; i<userApplications.length; i++) {
      var promise = request('/checks/?application='+userApplications[i].application);
        promises.push(promise);
    }
    Promise.all(promises).then(function(response) {
      results = (response).map((o) => o.results);

      for(var i=0; i<results.length; i++){
        for(var j=0; j<results[i].length; j++){
          if(results[i][j].email_alert_subscribers_mode === "AAS")
            res.push(results[i][j]);
          else if(results[i][j].email_alert_subscribers_mode === "SAS" && results[i][j].email_alert_subscribers.indexOf(userApplications[i].id) !== -1)
            res.push(results[i][j]);
        }
      }
      self.setState({
        checks: res
      });
    });*/
    const checks = await request(
      `/checks/?alerts_target_email=${encodeURIComponent(
        this.props.subscriber.email
      )}`
    );
    this.setState({
      checks: checks.results
    });
  };
  openConfirmRemoveApp = (e, application) => {
    const { id, name } = this.props;
    this.setState({
      confirmRemoveAppDialog: true,
      applicationDelete: { id, name, application }
    });
  };
  closeConfirmRemoveAppDialog = () => {
    this.setState({
      confirmRemoveAppDialog: false
    });
  };
  render() {
    const { checks } = this.state; //userApplications, applications,
    return (
      <div className="user-apps">
        <div className="expand-applications">
          <span className="hidden-app-count">{checks.length} Checks</span>
          <div className="tooltip-dropdown">
            {checks.map((check, index) => {
              return <p key={check.id}>{JSON.parse(check.data).url}</p>;
            })}
          </div>
        </div>
        {this.state.confirmRemoveAppDialog && (
          <div
            className="modal delete-user"
            style={
              this.state.confirmRemoveAppDialog
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="modal-content delete-user-from-org-modal">
              <img
                src={close}
                alt="close icon"
                className="close-image"
                onClick={this.closeConfirmRemoveAppDialog}
              />
              <div className="modal-body">
                <div className="modal-header">
                  <h4>
                    Do you want to remove{" "}
                    <span className="dynamic-values">
                      {this.state.applicationDelete.application &&
                        this.state.applicationDelete.application
                          .application_mini.name}
                    </span>{" "}
                    from the group{" "}
                    <span className="dynamic-values">
                      {this.state.applicationDelete.name} ?
                    </span>
                  </h4>
                </div>
                <div className="modal-footer delete-user-from-org-modal-footer">
                  <button
                    className="cancel-btn secondary-btn"
                    onClick={this.closeConfirmRemoveAppDialog}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-danger"
                    onClick={this.removeAppFromUser}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SubscriberChecks;
