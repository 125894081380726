import { useQuery, useMutation } from 'react-query';
import { request } from 'utils/Api';
import queryClient from 'utils/client';
import { toastr } from 'react-redux-toastr';

export const useWebhookIntegration = () =>
  useQuery('webhookIntegration', async () => {
    const response = await request('/integrations/webhooks/');
    return response && response.results && response.results.length > 0
      ? response.results
      : [];
  });

export const useAddWebhookIntegration = ({ updateIntegrationCount } = {}) =>
  useMutation(
    async ({ payload }) => {
      const response = await request('/integrations/webhooks/', {
        method: 'POST',
        payload
      });
      return response;
    },
    {
      onSuccess: (response, { actions, onCreate }) => {
        if (updateIntegrationCount) updateIntegrationCount(false);
        if (actions) actions.setSubmitting(false);
        if (response.id) {
          queryClient.invalidateQueries('webhookIntegration');
          toastr.success('Webhook created successfully');
          if (onCreate) onCreate(response);
        } else {
          toastr.error('Webhook creation failed');
        }

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent('Webhook Create Integration', window._na_module);
        }
      },
      onError: () => {
        if (updateIntegrationCount) updateIntegrationCount(false);
        toastr.error('Webhook creation failed');
      }
    }
  );

export const useSaveWebhookIntegration = () =>
  useMutation(
    async ({ id, payload }) => {
      const response = await request(`/integrations/webhooks/${id}/`, {
        method: 'PATCH',
        payload
      });
      if ((response && response.status > 399) || response === undefined)
        throw new Error(JSON.stringify((response && response.response) || {}));
      return response;
    },
    {
      onSuccess: (response, { statusChange, actions }) => {
        queryClient.setQueryData('webhookIntegration', integrations =>
          integrations.map(integration =>
            integration.id === response.id ? response : integration
          )
        );
        let text = 'Webhook integration modified';
        if (statusChange) {
          text = `Webhook integration ${response.name} ${
            response.state === 'AC' ? 'enabled' : 'disabled'
          }`;
        }
        if (actions) actions.setSubmitting(false);
        toastr.success(text);

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent('Webhook Edit Integration', window._na_module);
        }
      },
      onError: (error, { actions, mutate = false }, snapshotValue) => {
        const errorMessage = JSON.parse((error && error.message) || '{}');
        if (actions) actions.setSubmitting(false);
        if (mutate)
          queryClient.setQueryData('webhookIntegration', snapshotValue);
        toastr.error(
          (errorMessage &&
            errorMessage.non_field_errors &&
            errorMessage.non_field_errors[0]) ||
            'Save webhook integration failed'
        );
      },
      onMutate: ({ payload, id, mutate = false }) => {
        if (!mutate) return {};
        const previousValue = queryClient.getQueryData('webhookIntegration');
        queryClient.setQueryData('webhookIntegration', integrations =>
          integrations.map(integration =>
            integration.id === id ? { ...integration, ...payload } : integration
          )
        );
        return previousValue;
      }
    }
  );

export const useDeleteWebhookIntegration = ({ updateIntegrationCount } = {}) =>
  useMutation(
    async id => {
      await request(`/integrations/webhooks/${id}/`, {
        method: 'DELETE'
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('webhookIntegration');
        toastr.success('Webhook integration deleted');
        if (updateIntegrationCount) updateIntegrationCount(false);

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent('Webhook Delete Integration', window._na_module);
        }
      }
    }
  );

export const useTestWebhookIntegration = ({ callback }) =>
  useMutation(
    async ({ id, meta }) => {
      const response = await request('/integrations/webhook-test/', {
        method: 'POST',
        payload: {
          test_data: meta,
          webhook: id
        }
      });
      return response;
    },
    {
      onSuccess: response => {
        if (response && response.test_data && response.webhook) {
          toastr.success('Webhook tested successfully');
          callback();
        } else {
          toastr.error('Webhook testing failed');
        }
        return response;
      },
      onError: () => {
        toastr.error('Webhook testing failed');
      }
    }
  );
