import React from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';

class AreaSplineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: this.props.graphResult
    };
  }

  getAvgResponseTimeData = () => {
    const responseData = this.state.graphData;
    const data = responseData.response_times;
    const result = data.map((a, index) => {
      if (
        a.average_response_time_milliseconds_available === null &&
        a.duration_seconds_available !== null &&
        a.duration_seconds_paused === 0
      )
        a.average_response_time_milliseconds_available = this.getAvgResponseTime(
          index
        );
      return a;
    });
    responseData.response_times = result;
    this.setState({
      graphData: responseData
    });
  };

  getAvgResponseTime = index => {
    const { response_times } = this.state.graphData;
    let prevResp = 0;
    let nextResp = 0;
    if (index === 0) {
      prevResp = response_times[index + 1];
      nextResp = response_times[index + 1];
    } else if (index === response_times.length - 1) {
      prevResp = response_times[index - 1];
      nextResp = response_times[index - 1];
    } else {
      for (let i = 1; i <= 4; i++) {
        if (
          response_times[index - i] &&
          response_times[index - i]
            .average_response_time_milliseconds_available !== null &&
          i <= 3
        ) {
          prevResp = response_times[index - 1];
          break;
        } else {
          prevResp = response_times[index + 1];
        }
      }

      for (let i = 1; i <= 4; i++) {
        if (
          response_times[index + i] &&
          response_times[index + i]
            .average_response_time_milliseconds_available !== null &&
          i <= 3
        ) {
          nextResp = response_times[index + 1];
          break;
        } else {
          nextResp = response_times[index - 1];
        }
      }
    }

    const total =
      (parseInt(prevResp.average_response_time_milliseconds_available) ||
        0 + parseInt(nextResp.average_response_time_milliseconds_available) ||
        0) / 2;

    return total;
  };

  getYAxisData = () => {
    let result = [];
    if (this.state.graphData) {
      const data = this.state.graphData.response_times;
      result = data.map((a, index) => [
        new Date(a.start).toUTCString(),
        a.average_response_time_milliseconds_available !== null
          ? a.average_response_time_milliseconds_available
          : -1
      ]);
      return result;
    }
  };

  getXAxisData = () => {
    let result = [];
    if (this.state.graphData) {
      const data = this.state.graphData.response_times;
      result = data.map(a =>
        moment(new Date(a.start).toUTCString()).format('MMM D, YY hh:mm:ss A')
      );
      return result;
    }
  };

  tooltipDisplayData = (date, responseTime, stats) => {
    let tooltipCont = `
      <div class="heat-map-custom-tooltip graph-tooltip">
        <div class="col-6">
          <p class="detail-value date">${date}</p>
        </div>
          <div class="heat-map-tooltip-details">`;
    tooltipCont += `<p class="detail-title">Response Time: <span class="detail-value">${responseTime +
      ' ms'}</span></p>`;
    tooltipCont += `</div></div>`;
    return tooltipCont;
  };

  showTooltipData = () => {
    const { response_times } = this.state.graphData;
    const self = this;
    let tooltipCont = '';
    let tooltipData = {
      useHTML: true,
      backgroundColor: 'rgba(255,255,255,0)',
      borderWidth: 0,
      shadow: false,
      formatter: function() {
        const point = this;
        const datetimeString = new Date(point.x).toISOString().slice(0, -7);
        if (response_times.length) {
          // const matchData = response_times.filter((item, i, arr) => {
          //   let { start, end } = item;
          //   if (
          //     start.indexOf(datetimeString) > -1 ||
          //     end.indexOf(datetimeString) > -1
          //   ) {
          //     return arr[i];
          //   }
          // });
          const matchData = response_times.filter(
            item =>
              item.start.indexOf(datetimeString) > -1 ||
              item.end.indexOf(datetimeString) > -1
          );
          if (matchData.length) {
            const {
              average_response_time_milliseconds_available
            } = matchData[0];
            tooltipCont = self.tooltipDisplayData(
              point.x,
              average_response_time_milliseconds_available !== null
                ? point.y
                : 'N/A'
            );
          }
        }
        return tooltipCont;
      },
      style: {
        color: '#585858',
        fontSize: '12px',
        padding: '0'
      }
    };
    let tooltipDashboardData = {
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      headerFormat: '',
      borderColor: null,
      formatter: function() {
        return `<span style="font-size:12px"><b>${this.y} ms</b></span>`;
      },
      // pointFormat: `<span style="font-size:12px"><b>{point.y} ms</b></span>`,
      style: {
        color: '#585858',
        fontSize: '10px',
        padding: 0
      }
    };
    if (this.props.isReportsPage) return tooltipData;
    else return tooltipDashboardData;
  };

  paintGraph = () => {
    const id = !this.props.isReportsPage
      ? this.props.checkId + '-container'
      : 'container';
    const yAxisEnabled = this.props.isReportsPage ? true : false;
    let lineWidth = 2;
    let yAxisStructure = {
      visible: yAxisEnabled,
      labels: yAxisEnabled,
      title: '',
      gridLineWidth: 1,
      minorGridLineWidth: 1,
      min: 0,
      crosshairs: true,
      labels: {
        formatter: function() {
          return this.value;
        }
        // step: 2
      }
    };
    if (
      this.props.checkDetails &&
      !this.props.checkDetails.maximum_response_time_milliseconds_available
    ) {
      lineWidth = 2;
      yAxisStructure = {
        visible: yAxisEnabled,
        labels: yAxisEnabled,
        title: '',

        gridLineWidth: 1,
        minorGridLineWidth: 1,
        min: this.props.checkDetails
          .minimum_response_time_milliseconds_available
          ? this.props.checkDetails.minimum_response_time_milliseconds_available
          : 0,
        max: 1,
        labels: {
          formatter: function() {
            return this.value;
          }
          // step: 1
        }
      };
    }

    const YAxisData = this.getYAxisData();
    const xAxisData = this.getXAxisData();

    const chart = Highcharts.chart(id, {
      chart: {
        spacing: this.props.isReportsPage ? [15, 15, 15, 15] : [0, 5, 5, 0],
        type: 'spline'
        // scrollablePlotArea: {
        //   minWidth: 600,
        //   scrollPositionX: 1
        // }
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      legend: {
        align: 'top',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 50,
        y: -3,
        useHTML: true
      },

      xAxis: {
        labels: false,
        visible: false,
        categories: xAxisData,
        labels: {
          overflow: 'justify'
        }
      },
      yAxis: yAxisStructure,
      plotOptions: {
        areaspline: {
          marker: {
            enabled: false
          },
          lineWidth: lineWidth,
          states: {
            hover: {
              lineWidth: 2
            }
          }
        }
      },
      tooltip: this.showTooltipData(),
      series: [
        {
          type: 'areaspline',
          pointWidth: 1,
          showInLegend: false,
          name: '',
          data: YAxisData,
          connectNulls: true,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, '#c1ddff'],
              [
                1,
                Highcharts.Color('#c1ddff')
                  .setOpacity(0)
                  .get('rgba')
              ]
            ]
          }
        }
      ]
    });
    if (chart) chart.reflow();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.graphResult !== prevProps.graphResult) {
      this.setState({ graphData: this.props.graphResult }, function() {
        this.paintGraph();
      });
    }
  }

  componentDidMount() {
    // this.getAvgResponseTimeData();
    this.paintGraph();
  }

  render() {
    const avg = this.props.checkDetails
      ? this.props.checkDetails.average_response_time_milliseconds_available
      : '';
    const max = this.props.checkDetails
      ? this.props.checkDetails.maximum_response_time_milliseconds_available
      : '';
    const min = this.props.checkDetails
      ? this.props.checkDetails.minimum_response_time_milliseconds_available
      : '';
    return (
      <div className={this.props.deviceSize + ' chart-container'}>
        {this.props.isReportsPage && (
          <div className="chart-info">
            <div className="chart-info-left">
              <div>
                <span className="unit">ms</span>
              </div>
              <div>
                <span className="blue-circle" />
                <span className="chart-info-label">Response time</span>
              </div>
              <div>
                <span className="green-circle" />
                <span className="chart-info-label">Uptime</span>{' '}
              </div>
              <div>
                <span className="red-circle" />
                <span className="chart-info-label">Downtime</span>
              </div>
            </div>
            <div className="chart-info-right">
              <span className="avg">
                Avg : <span className="val">{avg ? avg : 0} ms</span>
              </span>
              <span className="min">
                Min : <span className="val">{min ? min : 0} ms</span>
              </span>
              <span className="max">
                Max : <span className="val">{max ? max : 0} ms</span>
              </span>
            </div>
          </div>
        )}
        {this.props.isReportsPage && (
          <div id="container" className="reports-chart" />
        )}
        {!this.props.isReportsPage && (
          <div
            id={this.props.checkId + '-container'}
            className="dashboard-chart"
            width="inherit"
            height="inherit"
          />
        )}
      </div>
    );
  }
}

export default AreaSplineChart;
