import { fork, take } from "redux-saga/effects";
import { request } from "../utils/Api";
import { getOrgId } from "../utils/utility";
import { requestAndPut } from "./";
import {
  ORG_APPLICATION_REQUEST,
  getOrgApplicationsSuccess,
  getOrgApplicationsFailure,
  getAppMembersSuccess,
  getAppMembersFailure,
  APP_MEMBERS_REQUEST,
  APP_SUBSCRIBERS_REQUEST,
  ORG_MEMBERS_REQUEST,
  getOrgMembersSuccess,
  getOrgMembersFailure,
  EDGE_LOCATION_REQUEST,
  getEdgeLocationSuccess,
  getEdgeLocationFailure,
  getAppSubscribersSuccess,
  getAppSubscribersFailure
} from "../actions";

export function* getEdgeLocations() {
  while (true) {
    yield take(EDGE_LOCATION_REQUEST);
    yield fork(
      requestAndPut,
      [request, "/edge-locations/"],
      getEdgeLocationSuccess,
      getEdgeLocationFailure
    );
  }
}

export function* getOrganizationMembers() {
  while (true) {
    yield take(ORG_MEMBERS_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/organization-members/?organization=${getOrgId()}`],
      getOrgMembersSuccess,
      getOrgMembersFailure
    );
  }
}

export function* getApplicationSubscribers() {
  while (true) {
    const action = yield take(APP_SUBSCRIBERS_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/application-subscribers/?application=${action.payload}`],
      getAppSubscribersSuccess,
      getAppSubscribersFailure
    );
  }
}

export function* getApplicationMembers() {
  while (true) {
    const action = yield take(APP_MEMBERS_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/application-members/?application=${action.payload}`],
      getAppMembersSuccess,
      getAppMembersFailure
    );
  }
}

export function* getOrgApplications() {
  while (true) {
    yield take(ORG_APPLICATION_REQUEST);
    yield fork(
      requestAndPut,
      [request, `/applications/?organization=${getOrgId()}`],
      getOrgApplicationsSuccess,
      getOrgApplicationsFailure
    );
  }
}
