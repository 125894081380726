/* eslint-disable no-use-before-define */
/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Button } from 'freemium-ui';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import Scrollspy from 'shared/components/ScrollSpy';
import { Form } from 'shared/components';
import { Close } from 'components/Icons';
import { request } from 'utils/Api';
import { getOrgId } from 'utils/utility';
import {
  getCheckFormData,
  getCheckFormInitialState,
  getActiveFeatures,
  isSectionAvailable
} from 'utils/check';
import {
  checkTypeOptions,
  CheckType,
  CheckIntervalOptions,
  CHECK_FORM_CONFIG,
  ALERT_SECTIONS,
  PerfAlert,
  SslExpiry,
  SslExpiryCopy,
  SslAlertOptions,
  PerfAlertOptions,
  PerfAlertDebounceOptions,
  AlertDebounceOptions,
  ResponseStringCheckKey,
  ResponseCodeCheckKey
} from 'constants/check';
import { FieldLabel, FieldTip } from 'shared/components/Form/style';
import {
  getCurrentUser,
  addCheckSuccess,
  editCheckSuccess,
  getChecks,
  getCurrentUserOrg,
  getOrgMembers,
  getCheckStatuses,
  setFilterState
} from 'actions';

import NestedSection from './NestedSection';
import {
  FormElement,
  Actions,
  SelectItemLabel,
  SelectItem,
  CheckFormWrapper,
  LinkElement
} from './styles';

const propTypes = {
  modalClose: PropTypes.func.isRequired,
  org: PropTypes.object.isRequired,
  getOrganizationMembers: PropTypes.func.isRequired
};

const CheckCreate = ({
  modalClose,
  getOrganizationMembers,
  org,
  appMembers,
  appSubscribers,
  appState,
  userOrgs,
  getAllChecksRequest,
  editCheckToList,
  getCheckStatus,
  setFilter,
  getCurrentOrg
}) => {
  const [isCreating, setIsCreating] = useState(false);
  const [isLoadingDns, setIsLoadingDns] = useState(false);
  const [applicationMembers, setApplicationMembers] = useState([]);
  const activeOrg = getOrgId();

  const organization =
    userOrgs && userOrgs.find(userOrg => userOrg.id === Number(activeOrg));

  // @TODO move `plans` state to store and cache
  const [plans, setPlans] = useState(null);
  const getPlans = async () => {
    const publicPlans = await request(`/public-plans/?name=${pricingPlan}`);
    setPlans(publicPlans);
  };

  const pricingPlan = organization && organization.subscription.plan_name;
  const activeFeatures = getActiveFeatures(plans, pricingPlan);

  useEffect(() => {
    getPlans();
  }, []);

  const { basic, alert, ssl } = CHECK_FORM_CONFIG.collection;
  const {
    // orgMembers,
    edgeLocations
  } = org;
  /**
   * Comments from miran for showing users who have disabled alerts.
   * User can configure the check alerts for users individually
   * and he can choose to enable or disable the alerts globally
   */
  // const appMembers = orgMembers
  //   ? orgMembers.results
  //   : // .filter(member => !member.disable_alert_emails)
  //     [];

  const edgeLocationsLists = edgeLocations ? [...edgeLocations.results] : [];

  const getApplicationMembers = async () => {
    if (org.applications) {
      const members = await request(
        `/application-members/?application=${org.applications.results[0].id}`
      );
      setApplicationMembers(members.results);
    }
  };

  useEffect(() => {
    getOrganizationMembers();
    getApplicationMembers();
  }, []);

  const autoloadDnsRecord = async (domain_name, dns_type, callback) => {
    try {
      setIsLoadingDns(true);
      const response = await request(
        `/checks/dns/match-string?domain_name=${domain_name}&dns_type=${dns_type}`,
        {
          method: 'GET'
        }
      );
      setIsLoadingDns(false);
      if (response.data && response.data.answers) {
        if (response.data.answers.length === 0) {
          toastr.warning(`No configured value for DNS record`);
        }
        const answers = response.data.answers.join(',');
        callback(answers);
      } else {
        toastr.error(`Could not autoload DNS record`);
      }
    } catch (error) {
      toastr.error(`Could not autoload DNS record`);
    }
  };

  const submitCheckForm = async (values, form) => {
    const formData = getCheckFormData(values, org, activeFeatures);
    try {
      setIsCreating(true);
      const payload = await request(
        `/checks/${
          appState.checkDrawer.check ? appState.checkDrawer.check.id : ''
        }`,
        {
          method: appState.checkDrawer.check ? 'PATCH' : 'POST',
          payload: formData
        }
      );
      // add/edit side effect actions
      if (appState.checkDrawer.check) {
        editCheckToList({ response: payload, meta: { meta: {} } });
      } else {
        getAllChecksRequest();
      }
      setIsCreating(false);
      if (!payload.status) {
        toastr.success(
          `Check ${
            appState.checkDrawer.check ? 'saved' : 'created'
          } successfully.`
        );
        modalClose();
        getCheckStatus(payload.id);
        setFilter({ status: 'all' });
        getCurrentOrg();

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent(
            appState.checkDrawer.check ? 'Edit Check' : 'Add Check',
            window._na_module
          );
        }
      } else if (payload.response.non_field_errors) {
        toastr.error(`${payload.response.non_field_errors[0]}`);
      } else {
        toastr.error(`Something went wrong`);
      }
    } catch (error) {
      Form.handleAPIError(error, form);
    }
  };

  const defaultApplicationMembers =
    appMembers && appSubscribers
      ? [...appMembers.results, ...appSubscribers.results]
      : [];
  const initialValues = getCheckFormInitialState(
    appState.checkDrawer.check,
    userOptions(defaultApplicationMembers)
  );

  return (
    <CheckFormWrapper>
      <Form
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          type: Yup.string().required('Required'),
          url: Yup.string()
            .matches(
              /^(http:\/\/|https:\/\/|udp:\/\/|tcp:\/\/|dns-resolve:\/\/|icmp-ping:\/\/|ws:\/\/|wss:\/\/)?[a-z0-9\u00C0-\u00FF\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF\u00a1-\uffff]+([-._a-z0-9\u00A1-\uFFFF\u00C0-\u00FF\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/|https:\/\/|udp:\/\/|tcp:\/\/|dns-resolve:\/\/|icmp-ping:\/\/|ws:\/\/|wss:\/\/)?(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:[0-9]{1,5})?|^([0-9]{1-3}\.[0-9]{1-3}\.[0-9]{1-3}\.[0-9]{1-3})(:[0-9]{1,5})?)(\/.*)?$/,
              { message: 'Enter a valid IP or URL' }
            )
            .required('Enter a Url'),

          name: Yup.string()
            .trim()
            .required('Enter a check name'),
          port: Yup.mixed()
            .when('type', {
              is: CheckType.UDP,
              then: Yup.number()
                .required('Enter a port')
                .typeError('you must specify a number')
            })
            .when('type', {
              is: CheckType.TCP,
              then: Yup.number()
                .required('Enter a port')
                .typeError('you must specify a number')
            }),
          command_string: Yup.string()
            .trim()
            .when('type', {
              is: CheckType.UDP,
              then: Yup.string().required('Enter a command string')
            }),
          dns_type: Yup.string()
            .trim()
            .when('type', {
              is: CheckType.DNS,
              then: Yup.string().required('Select Record Type')
            }),
          dns_value: Yup.string()
            .trim()
            .when('type', {
              is: CheckType.DNS,
              then: Yup.string().required('Select Record Value')
            }),
        })}
        onSubmit={submitCheckForm}
      >
        {({ values }) => (
          <>
            <div className="check-menu">
              <h1>{appState.checkDrawer.check ? 'Edit' : 'Add'} Check</h1>
              <Scrollspy
                ids={CHECK_FORM_CONFIG.ids}
                collections={CHECK_FORM_CONFIG.collection}
                activeItemClassName="active"
                itemClassName="menu-item"
                checkType={values.type || CheckType.HTTPS}
              />
            </div>
            <div className="check-content">
              <FormElement>
                <div className="form-content">
                  <section className="form-section">
                    <NestedSection
                      modalClose={modalClose}
                      section={basic[ALERT_SECTIONS.CHECK_INFO]}
                    >
                      {/*  */}
                      <Form.Field.CustomCheckType
                        isEdit={appState.checkDrawer.check}
                        checkOptions={checkTypeOptions}
                        isLoadingDns={isLoadingDns}
                        autoloadDnsRecord={autoloadDnsRecord}
                      />
                      <Form.Field.Input
                        name="name"
                        placeholder="Check name"
                        label="Friendly check name"
                        requiredField
                      />
                      <Form.Field.RadioGroup
                        inline
                        name="monitering_interval"
                        label="Check interval"
                        options={CheckIntervalOptions}
                        tip="Specify your check frequency"
                      />
                      <NestedSection
                        modalClose={modalClose}
                        upgradePlan={isSectionAvailable(
                          plans,
                          'request_timeout',
                          pricingPlan
                        )}
                      >
                        <Form.Field.Slider
                          name="request_timeout"
                          label="Request timeout"
                          disabled={
                            activeFeatures.indexOf('request_timeout') === -1
                          }
                          min={1}
                          max={30}
                          metric="s"
                          tip="Set the wait time for a response from the server. If the request times out, this Check will be marked as 'down'"
                        />
                      </NestedSection>
                    </NestedSection>
                    <NestedSection
                      spaced
                      modalClose={modalClose}
                      section={basic[ALERT_SECTIONS.ALERT_SETTING]}
                    >
                      {/* <Form.Field.Select
                      isMulti
                      withClearValue={false}
                      name="email_alert_members"
                      label="Who to alert?"
                      placeholder="Select a users to alert"
                      options={userOptions(defaultApplicationMembers)}
                      renderOption={renderUser(defaultApplicationMembers)}
                      renderValue={renderUser(defaultApplicationMembers)}
                    /> */}
                      <LinkElement
                        onClick={() => window.open('/settings/users')}
                      >
                        + New user
                      </LinkElement>
                      <Form.Field.CustomSelectField
                        isMulti
                        withClearValue={false}
                        name="email_alert_members"
                        label="Whom to alert?"
                        placeholder="Select a users to alert"
                        formName={[
                          'email_alert_members',
                          'email_alert_subscribers'
                        ]}
                        options={userOptions(defaultApplicationMembers)}
                        renderOption={renderUser(defaultApplicationMembers)}
                        renderValue={renderUser(defaultApplicationMembers)}
                      />
                      <Form.Field.Textarea
                        name="notes"
                        label="Add an alert note"
                        placeholder="Troubleshooting Instructions / URL"
                      />
                    </NestedSection>
                    <NestedSection
                      hideTitle={
                        values.type === CheckType.SOCKET ||
                        values.type === CheckType.ICMP
                      }
                      modalClose={modalClose}
                      spaced
                      upgradePlan={isSectionAvailable(
                        plans,
                        'basic_authentication',
                        pricingPlan
                      )}
                      section={basic[ALERT_SECTIONS.AUTH_PARAMS]}
                    >
                      <Form.Field.KeyValue
                        name="basic_authentication"
                        disabled={
                          activeFeatures.indexOf('basic_authentication') === -1
                        }
                        disableTypeCondition={checkType =>
                          checkType === CheckType.SOCKET ||
                          checkType === CheckType.ICMP
                        }
                        // label="Authentication Parameters"
                        // tip="Add credentials to get through authentication and perform the check."
                        keyLabel="Username"
                        valueLabel="Password"
                      />
                    </NestedSection>
                    <NestedSection
                      hideTitle={values.type !== CheckType.HTTPS}
                      modalClose={modalClose}
                      spaced
                      upgradePlan={isSectionAvailable(
                        plans,
                        'custom_header',
                        pricingPlan
                      )}
                      section={basic[ALERT_SECTIONS.CUSTOM_HEADER]}
                    >
                      <Form.Field.KeyValue
                        name="custom_header"
                        disabled={
                          activeFeatures.indexOf('custom_header') === -1
                        }
                        disableTypeCondition={checkType =>
                          checkType !== CheckType.HTTPS
                        }
                        // label="Custom HTTP Header"
                        // tip="Add custom header parameters and key"
                        keyPlaceholder="Header"
                        valuePlaceholder="Key"
                        isMulti
                      />
                    </NestedSection>
                    <NestedSection
                      spaced
                      modalClose={modalClose}
                      section={basic[ALERT_SECTIONS.MONITORING_SETTING]}
                    >
                      <Form.Field.Select
                        withClearValue={false}
                        name="preferred_edge_locations_csv"
                        label="Monitoring location"
                        placeholder="Any location"
                        options={locationOptions(edgeLocationsLists)}
                        renderOption={renderLocation(edgeLocationsLists)}
                        renderValue={renderLocation(edgeLocationsLists)}
                      />

                      <Form.Field.Slider
                        name="target_response_time"
                        label="Apdex response threshold"
                        disabled={
                          activeFeatures.indexOf('target_response_time') === -1
                        }
                        disableTypeCondition={checkType =>
                          checkType !== CheckType.HTTPS &&
                          checkType !== CheckType.DNS
                        }
                        min={50}
                        max={5000}
                        metric="ms"
                        tip="Set a response time threshold to calculate the Apdex score"
                      />
                    </NestedSection>
                  </section>

                  <section className="form-section">
                    <NestedSection
                      modalClose={modalClose}
                      section={alert[ALERT_SECTIONS.ALERT_FINE_TUNE]}
                      upgradePlan={isSectionAvailable(
                        plans,
                        'alert_sensitivity',
                        pricingPlan
                      )}
                    >
                      <Form.Field.RadioGroupWithMenu
                        name="email_alerts_min_outage_duration"
                        disabled={
                          activeFeatures.indexOf('alert_sensitivity') === -1
                        }
                        // label="Alert Fine tuning"
                        // eslint-disable-next-line max-len
                        // tip="Check for a String, Error code. Set fault configuration and performance threshold"
                        options={
                          AlertDebounceOptions[values.monitering_interval]
                        }
                      />

                      <Form.Field.KeyValue
                        name="string_check"
                        disabled={activeFeatures.indexOf('string_check') === -1}
                        label="Consider up only when response string"
                        keyOptions={ResponseStringCheckKey}
                        valuePlaceholder="Enter the string that should be present in the response"
                        disableTypeCondition={checkType =>
                          checkType !== CheckType.HTTPS
                        }
                      />

                      <Form.Field.KeyValue
                        name="status_check"
                        disabled={activeFeatures.indexOf('status_check') === -1}
                        label="Configure Check status based on status codes"
                        keyOptions={ResponseCodeCheckKey}
                        valuePlaceholder="Enter comma-separated status codes"
                        disableTypeCondition={checkType =>
                          checkType !== CheckType.HTTPS
                        }
                      />
                    </NestedSection>
                    <NestedSection
                      hideTitle
                      modalClose={modalClose}
                      section={alert[ALERT_SECTIONS.PERF_ALERT]}
                      spaced
                      upgradePlan={isSectionAvailable(
                        plans,
                        'performance_alerting',
                        pricingPlan
                      )}
                    >
                      <Form.Field.RadioGroup
                        inline
                        name="performance_alert_active"
                        disabled={
                          activeFeatures.indexOf('performance_alerting') === -1
                        }
                        label="Performance alerting"
                        tip="Configure the request timeout threshold and define the alerting rules "
                        options={PerfAlertOptions}
                      />
                      <Form.Field.Slider
                        name="performance_alert_threshold"
                        disabled={
                          activeFeatures.indexOf('performance_alerting') === -1
                        }
                        disableConditionKeyVal={{
                          key: 'performance_alert_active',
                          value: PerfAlert.OFF
                        }}
                        label="Response-time threshold"
                        min={100}
                        max={30000}
                        metric="ms"
                        tip="Set the maximum time to wait for a response from the server"
                      />
                      <Form.Field.RadioGroupWithMenu
                        name="performance_alert_duration"
                        disabled={
                          activeFeatures.indexOf('performance_alerting') === -1
                        }
                        disableConditionKeyVal={{
                          key: 'performance_alert_active',
                          value: PerfAlert.OFF
                        }}
                        label="Fine-tune performance alerting"
                        options={
                          PerfAlertDebounceOptions[values.monitering_interval]
                        }
                      />
                    </NestedSection>
                  </section>

                  <section className="form-section">
                    <NestedSection
                      modalClose={modalClose}
                      hideTitle
                      section={ssl[ALERT_SECTIONS.SSL]}
                      upgradePlan={isSectionAvailable(
                        plans,
                        'ignore_ssl_verification',
                        pricingPlan
                      )}
                    >
                      <Form.Field.RadioGroup
                        inline
                        name="ignore_ssl_verification"
                        disabled={
                          activeFeatures.indexOf('ignore_ssl_verification') ===
                          -1
                        }
                        label="SSL monitoring"
                        tip="Consider the website to be down if the SSL check fails"
                        options={SslAlertOptions}
                        disableTypeCondition={checkType =>
                          checkType !== CheckType.HTTPS &&
                          checkType !== CheckType.SOCKET
                        }
                      />
                      <div
                        className={`custom-field ${
                          activeFeatures.indexOf('ignore_ssl_verification') ===
                          -1
                            ? 'disabled'
                            : ''
                        }`}
                      >
                        <FieldLabel htmlFor="ssl_expiry_alert">
                          SSL expiry alert
                        </FieldLabel>
                        <FieldTip>
                          Select when to be notified in case of SSL expiry
                        </FieldTip>

                        <div className="checkbox-group">
                          <Form.Field.Checkbox
                            inline
                            name={`ssl_expiry_alert_${SslExpiry.SEVEN}`}
                            inputLabel={SslExpiryCopy[SslExpiry.SEVEN]}
                            disabled={
                              activeFeatures.indexOf('ssl_expiry_alert') === -1
                            }
                          />
                          <Form.Field.Checkbox
                            inline
                            name={`ssl_expiry_alert_${SslExpiry.FIFTEEN}`}
                            inputLabel={SslExpiryCopy[SslExpiry.FIFTEEN]}
                            disabled={
                              activeFeatures.indexOf('ssl_expiry_alert') === -1
                            }
                          />
                          <Form.Field.Checkbox
                            inline
                            name={`ssl_expiry_alert_${SslExpiry.EXPIRY}`}
                            inputLabel={SslExpiryCopy[SslExpiry.EXPIRY]}
                            disabled={
                              activeFeatures.indexOf('ssl_expiry_alert') === -1
                            }
                          />
                        </div>
                      </div>
                    </NestedSection>
                  </section>
                </div>
                <Actions>
                  <Button
                    inline
                    type="secondary"
                    variant="empty"
                    onClick={modalClose}
                  >
                    Cancel
                  </Button>
                  <Button inline type="primary" loading={isCreating}>
                    {appState.checkDrawer.check ? 'Save' : 'Add Check'}
                  </Button>
                </Actions>
              </FormElement>
            </div>
          </>
        )}
      </Form>
    </CheckFormWrapper>
  );
};

const locationOptions = locations => [
  { value: 'any', label: 'Any Location' },
  ...locations.map(location => ({ value: location.key, label: location.name }))
];

const renderLocation = locations => ({ value: userKey, removeOptionValue }) => {
  const user = [{ key: 'any', name: 'Any Location' }, ...locations].find(
    ({ key }) => key === userKey
  );

  return (
    <SelectItem
      key={user.value}
      withBottomMargin={!!removeOptionValue}
      onClick={() => removeOptionValue && removeOptionValue()}
    >
      <SelectItemLabel>{user.name}</SelectItemLabel>
      {removeOptionValue && <Close width="14" height="14" />}
    </SelectItem>
  );
};

const userOptions = members =>
  members
    .filter(m => m.user_mini || m.subscriber_mini)
    .map(member => ({
      value: member.id,
      name:
        (member.user_mini && member.user_mini.email) ||
        member.subscriber_mini.email,
      label:
        (member.user_mini && member.user_mini.email) ||
        member.subscriber_mini.email,
      formFieldName: member.user_mini
        ? 'email_alert_members'
        : 'email_alert_subscribers'
    }));
const renderUser = members => ({ value: userId, removeOptionValue }) => {
  const user = members.find(({ id }) => id === userId);
  if (!user) {
    return null;
  }
  return (
    <SelectItem
      key={user.id}
      onClick={() => removeOptionValue && removeOptionValue()}
    >
      <SelectItemLabel>
        {(user.user_mini && user.user_mini.email) || user.subscriber_mini.email}
      </SelectItemLabel>
      {removeOptionValue && <Close width="14" height="14" />}
    </SelectItem>
  );
};
CheckCreate.propTypes = propTypes;

const mapStateToProps = state => ({
  appState: state.app,
  userOrgs: state.user.org,
  org: state.org
});

const mapDispatchProps = dispatch => ({
  getCurrentUser: () => dispatch(getCurrentUser()),
  addCheckToList: payload => dispatch(addCheckSuccess(payload)),
  getAllChecksRequest: () => dispatch(getChecks()),
  editCheckToList: payload => dispatch(editCheckSuccess(payload)),
  getOrganizationMembers: () => dispatch(getOrgMembers()),
  getCurrentOrg: () => dispatch(getCurrentUserOrg()),
  getCheckStatus: id => dispatch(getCheckStatuses(id)),
  setFilter: filter => dispatch(setFilterState(filter))
});

export default connect(mapStateToProps, mapDispatchProps)(CheckCreate);
