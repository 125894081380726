// Fservice apis expects to be in the value format instead of name key
export const nameOfFieldsToChange = {
  ticket_type: 'type',
  group: 'group_id',
  department: 'department_id',
  agent: 'responder_id'
};

// These fields will output stringId as value
export const stringDropdownFieldTypes = [
  'category',
  'dropdown',
  'ticket_type',
  'field',
  'multi_select_dropdown'
];

// These fields will be treated as dropdown fields
export const dropdownFieldTypes = [
  'dropdown',
  'requester',
  'group',
  'urgency',
  'ticket_type',
  'field',
  'impact',
  'agent',
  'department',
  'category'
];

// https://api.freshservice.com/v2/#create_ticket
// These will be populated from the backend
export const nameOfFieldsNotToShow = [
  'description',
  'subject',
  'priority',
  'requester',
  'email',
  'phone',
  'requester_id',
  'status',
  'source'
];
