import React from "react";
import PropTypes from "prop-types";
import { request } from "../utils/Api";
import { URLQueryParams } from "../utils/utility";
import { toastr } from "react-redux-toastr";

class SlackIntegrationSuccess extends React.Component {
  componentWillMount() {
    const queryParams = URLQueryParams();
    this.completeSlackAuthorization(queryParams.code, queryParams.state);
  }
  async completeSlackAuthorization(code, alerterId) {
    const data = {
      code: code
    };
    const response = await request(
      `/integrations/slack-alerters/${alerterId}/authorize/`,
      { method: "POST", payload: data }
    );
    if (response.message) {
      toastr.success("Slack Authorization Success");
      // const orgId = localStorage.getItem("slack-oauth-orgid");
      this.context.router.history.push(`/settings/integrations`);
    } else {
      toastr.error("Slack Authorization Failed");
      // const orgId = localStorage.getItem("slack-oauth-orgid");
      this.context.router.history.push(`/settings/integrations`);
    }
  }
  render() {
    return <div />;
  }
}
SlackIntegrationSuccess.contextTypes = {
  router: PropTypes.object.isRequired
};
export default SlackIntegrationSuccess;
