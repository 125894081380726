import React from 'react';
import { connect } from 'react-redux';
// import lock from "../images/lock.svg";
import RowData, { StyledCheckbox } from '../containers/RowData';
import { getOrgId } from '../utils/utility';
import {
  getCheckStatuses,
  addCheckSuccess,
  setFilterState,
  getCurrentUserOrg
} from '../actions';
import Tooltip from '../components/styled/Tooltip';
import info from '../images/info.svg';
import { orderBy } from 'lodash';
import styled from 'styled-components';
// import { Link } from "react-router-dom";

const mapDispatchToProps = dispatch => ({
  getCheckStatuses: id => dispatch(getCheckStatuses(id)),
  addCheckSuccess: () => dispatch(addCheckSuccess),
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg),
  setFilterState: filter => dispatch(setFilterState(filter))
});

const mapStateToProps = state => ({
  check: state.applications.application,
  org: state.user.org,
  checks: state.checks.checks,
  filterParams: state.checks.filterParams
});

class ApplicationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByStatusOrder: 'asc',
      ordering: 'name',
      checkAll: false,
      selectedIds: []
    };
  }

  getCheckStatuses = checkId => {
    this.props.getCheckStatuses(checkId);
  };

  sortByCheckName = () => {
    const ordering = this.state.ordering === 'name' ? '-name' : 'name';
    this.setState({
      sortByStatusOrder: '',
      ordering
    });
    this.props.setFilterState({ ordering });
  };

  sortByStatus() {
    this.setState({ ordering: '' });
    var orderedChecks = orderBy(
      this.props.checks,
      function(e) {
        return e.statuses.state;
      },
      [this.state.sortByStatusOrder]
    );
    this.setState({ checks: orderedChecks }, function() {
      if (this.state.sortByStatusOrder === 'desc')
        this.setState({ sortByStatusOrder: 'asc' });
      else this.setState({ sortByStatusOrder: 'desc' });
    });
  }

  captureInterest = type => {
    const payload = {
      state: true,
      type
    };
    this.props.toggleInterestModal(payload);
  };

  checkAll = e => {
    const { checked } = e.target;
    const selectedIds = this.props.selectedChecks;
    const checks = Array.from(
      document.querySelectorAll("input[name='checks']")
    );

    checks.map(check => {
      check.checked = checked;
      const index = selectedIds.indexOf(Number(check.id));
      if (checked && index === -1) {
        selectedIds.push(Number(check.id));
      } else if (!checked && index > -1) {
        selectedIds.splice(index, 1);
      }
      return Number(check.id);
    });
    this.props.updateChecks(selectedIds, checked);
  };

  render() {
    const {
      org,
      checks,
      selectedChecks,
      filterParams,
      deviceSize
    } = this.props; // applications, getCheckStatuses
    const { ordering, checkAll } = this.state; //sortStatus
    const currentOrg = org && org.find(org => org.id === Number(getOrgId()));
    let isAdmin = false;
    org &&
      org.forEach(el => {
        if (el.id === Number(getOrgId()) && el.logged_in_user_role === 'AD') {
          isAdmin = true;
        }
      });
    this.props.checkForCheckall();
    return (
      <div className={deviceSize + ' table checks-table'}>
        <div className="row-heading Row">
          {deviceSize && deviceSize === 'device-large' ? (
            <span className="checkbox">
              <StyledCheckbox>
                <input
                  id="checkall"
                  onClick={e => this.checkAll(e)}
                  type="checkbox"
                  name="allchecks"
                  checked={this.props.checkAllState}
                />
                <div className="checkbox-custom rectangular">&nbsp;</div>
              </StyledCheckbox>
            </span>
          ) : null}
          <span
            onClick={this.sortByCheckName}
            style={{ width: '18%' }}
            data-sort={ordering}
          >
            Checks
          </span>
          <span style={{ width: '15%' }}>Status</span>
          <span>
            Availability
            {deviceSize && deviceSize === 'device-large' ? (
              <Tooltip
                position="top"
                content="Availability% of last 24hrs = (total uptime/(total uptime + total downtime)*100)"
              >
                <img
                  className="info-icon thead-info"
                  src={info}
                  alt="info icon"
                />
              </Tooltip>
            ) : null}
          </span>
          <span style={{ width: '15%' }}>
            Downtime
            {deviceSize && deviceSize === 'device-large' ? (
              <Tooltip position="top" content="Total downtime in last 24hrs">
                <img
                  className="info-icon thead-info"
                  src={info}
                  alt="info icon"
                />
              </Tooltip>
            ) : null}
          </span>
          {deviceSize && deviceSize === 'device-large' ? (
            <span style={{ width: '12%' }}>Apdex</span>
          ) : null}
          {deviceSize && deviceSize === 'device-large' ? (
            <span>Avg. response time</span>
          ) : null}
          {deviceSize && deviceSize === 'device-large' ? (
            <span>Response Time Graph (Last 24hrs)</span>
          ) : null}
        </div>
        {checks && org ? (
          <React.Fragment>
            {checks.map(check => (
              <RowData
                isAdmin={isAdmin}
                CheckId={check.id}
                check={check}
                getCheckStatuses={this.getCheckStatuses}
                appName={check.application_mini.name}
                planname={currentOrg && currentOrg.subscription.plan_name}
                updateChecks={this.props.updateChecks}
                checkAll={checkAll}
                checked={
                  selectedChecks.length
                    ? selectedChecks.indexOf(Number(check.id)) > -1
                    : false
                }
                setFilterState={this.props.setFilterState}
                filterParams={filterParams}
                getCurrentUserOrg={this.props.getCurrentUserOrg}
                currentOrg={currentOrg}
                key={check.id}
                deviceSize={deviceSize}
              />
            ))}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationList);

// const TableBody = styled.div`
//   &.basic {
//     width: 100%;
//   }
//   .__react_component_tooltip {
//     font-weight: normal;
//     padding: 10px;
//     width: 200px;
//   }
// `;

// const Divider = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   background: transparent !important;

//   > div:first-child {
//     width: 60%;
//     margin: 0;
//     border-bottom: 1px solid #e8e8e8;
//   }

//   > div:last-child {
//     width: 40%;
//     margin: 0;
//     border-bottom: 1px solid #e8e8e8;
//   }
// `;

export const Garden = styled.div`
  border-radius: 2px;
  background-color: #fdaf5c;
  padding: 8px !important;
  color: #ffffff !important;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 10%;
  right: 18%;
  height: 25px;
  margin-top: 50px;
  span,
  img {
    display: inline-block !important;
    padding: 0 2px;
    vertical-align: middle;
  }

  &.lock-only {
    width: 12px !important;
    height: 10px;
  }

  a {
    color: #ffffff;
    &:hover {
      color: #fff;
    }
  }
`;
