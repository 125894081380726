import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import SettingsNav from './SettingsSideNav';
import UserManagement from './UserManagement';
import Integrations from './Integrations';
import AccountSettings from './AccountSettings';
import Pricing from './Pricing';
import '../styles/Settings.css';

const Settings = props => {
  const { url } = props.match;
  return (
    <SettingsWrapper>
      <SettingsLeftNav>
        <SettingsNav url={url} />
      </SettingsLeftNav>
      <Settingscontent>
        <Switch>
          <Route
            exact
            path={`${url}/`}
          ><UserManagement url={url} /></Route>
          <Route
            exact
            path={`${url}/users`}
          ><UserManagement url={url} /></Route>
          <Route
            exact
            path={`${url}/integrations`}>
              <Integrations />
            </Route>
          <Route
            exact
            path={`${url}/account`}
          ><AccountSettings /></Route>
          <Route
            exact
            path={`${url}/pricing`}
          ><Pricing url={url} /></Route>
          <Redirect from={`${url}/estimate`} to={`${url}/pricing`} />
        </Switch>
      </Settingscontent>
    </SettingsWrapper>
  );
};

export default Settings;

const SettingsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const SettingsLeftNav = styled.div`
  width: 212px;
`;

const Settingscontent = styled.div`
  width: calc(100% - 212px);
  margin-left: 2px;
  .content {
    padding: 16px;
    max-width: 965px;
    margin: 0px auto;
  }

  .commonHeader {
    position: fixed;
    z-index: 2;
    width: calc(100% - 277px);
    top: 60px;
  }
`;
