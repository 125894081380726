// import styled from "styled-components";
import React from 'react';
import { Toggle } from 'freemium-ui';

class BadgeOptions extends React.Component {
  getCurrentStatus = badgeformData => {
    if (
      (badgeformData.metric_types.length > 0 &&
        badgeformData.metric_types.length === 2) ||
      badgeformData.metric_types[0] === 'CS'
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      badgeform,
      isTypeError,
      handleConfiguration,
      handleBadgeTypeChanges
    } = this.props;
    let theme = badgeform.data.badge_meta
      ? badgeform.data.badge_meta.theme === 'light'
      : true;
    let shape = badgeform.data.badge_meta
      ? badgeform.data.badge_meta.shape === 'round'
      : true;
    let currentStatus = this.getCurrentStatus(badgeform.data);
    let no_of_days_since = badgeform.data.no_of_days_since
      ? badgeform.data.no_of_days_since
      : 0;
    return (
      <React.Fragment>
        <div className="badge-controls">
          <div className="badge-checks-header">
            Design your badge
            {isTypeError && (
              <p className="badge-checks-error">
                {' '}
                Select atleast one (Current Status or Availability %){' '}
              </p>
            )}
          </div>
          <div className="badge-content">
            <div className="badge-option">
              <div className="badgeField">Apply round corners</div>
              <Toggle
                hasIcon={false}
                on={shape}
                handleChange={() => {
                  shape = !shape;
                  handleBadgeTypeChanges({ name: 'shape', value: shape });
                }}
              />
            </div>
            <div className="badge-option">
              <div className="badgeField">Apply dark theme</div>
              <Toggle
                hasIcon={false}
                on={theme}
                handleChange={() => {
                  theme = !theme;
                  handleBadgeTypeChanges({ name: 'theme', value: theme });
                }}
              />
            </div>
            <div className="badge-option">
              <div className="badgeField">Show current status</div>
              <Toggle
                hasIcon={false}
                on={currentStatus}
                handleChange={() => {
                  currentStatus = !currentStatus;
                  handleConfiguration({
                    name: 'current_status',
                    value: currentStatus
                  });
                }}
              />
            </div>
            <div className="badge-option">
              <div className="badgeField">Show availability %</div>
              <Toggle
                hasIcon={false}
                on={no_of_days_since}
                handleChange={() => {
                  handleConfiguration({
                    name: 'no_of_days_since',
                    value: no_of_days_since === 0 ? 30 : 0
                  });
                }}
              />
            </div>
            <div className="availability-options">
              <input
                type="radio"
                id="30_days"
                name="availability"
                disabled={!no_of_days_since}
                checked={no_of_days_since === 30}
                onChange={() => {
                  handleConfiguration({ name: 'no_of_days_since', value: 30 });
                }}
              />
              <label htmlFor="30_days" className="last-label">
                30 days
              </label>
              <input
                type="radio"
                id="100_days"
                name="availability"
                disabled={!no_of_days_since}
                checked={no_of_days_since === 100}
                onChange={() => {
                  handleConfiguration({ name: 'no_of_days_since', value: 100 });
                }}
              />
              <label htmlFor="100_days">100 days</label>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BadgeOptions;
