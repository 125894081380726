import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
// import { StyledStatusBar } from "./ApplicationStatus";
import PreviewCheckItem from './PreviewCheckItem';
import up from '../images/overview_up.svg';
import StatusPageBlank from '../images/statuspage-empty.svg';

const StatusPagePreview = ({ statuspageform, selectedChecks }) => {
  const downCount = 0;
  const currentDateTime = format(new Date(), 'MMMM Do YYYY,  hh:mmA');
  const activeChecks =
    selectedChecks && selectedChecks.filter(check => check.active);
  return (
    <StyledPreview className="content">
      <header>
        {statuspageform.logo_url ? (
          <img src={statuspageform.logo_url} alt="preview-logo" />
        ) : (
          <h1>{statuspageform.name ? statuspageform.name : 'Company Name'}</h1>
        )}
        <div className="timestamp">Last updated {currentDateTime}</div>
      </header>
      <main>
        {activeChecks.length ? (
          <StyledStatusBar>
            <div
              className={`status-header ${downCount && 'status-header__error'}`}
            >
              {downCount ? (
                <div className="status-header__container">
                  <span className="status-value">{downCount}</span>
                  <span className="down-label"> DOWN</span>
                </div>
              ) : (
                <div className="status-header__container">
                  <img src={up} alt="all up" />
                  <span>ALL SYSTEMS OPERATIONAL</span>
                </div>
              )}
            </div>
          </StyledStatusBar>
        ) : (
          <h2 className="blank-preview">
            <img src={StatusPageBlank} alt="Statuspage empty" />
            <div>Add a check and showcase your services reliability</div>
          </h2>
        )}
        <div className="statuspage-checkslist">
          {activeChecks &&
            activeChecks.length !== 0 &&
            activeChecks.map(check => (
              <PreviewCheckItem key={check.id} check={check} />
            ))}
        </div>
      </main>
    </StyledPreview>
  );
};

export default StatusPagePreview;

const StyledStatusBar = styled.div`
  width: 90%;
  height: 85px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  border-radius: 5px;
  background: #fff;
  display: flex;
`;

const StyledPreview = styled.div`
  main {
    background: #f4f4f4;
    padding: 20px;
  }
  .blank-preview {
    margin-top: 60px;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    h5 {
      font-weight: 500;
      color: #8a8a8a;
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    vertical-align: middle;
    height: 72px;
    background-color: #ffffff;
    border-bottom: solid 1px #dcdcdc;
    img {
      top: 1px;
      max-height: 70px;
      max-width: 100%;
      position: relative;
      box-sizing: border-box;
      padding: 5px 20px;
    }
    h1 {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      color: #ffffff;
      background-color: #d2d0d0;
      padding: 5px;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 2px;
    }
    .timestamp {
      font-weight: 400;
      color: #333;
    }
  }
`;
