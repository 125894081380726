const isNilOrEmptyString = value =>
  value === undefined || value === null || value === "";

// @TODO conditional validation
// sample scheme
// const validationSchema = Yup.object().shape({
//   isCompany: Yup.boolean(),
//   companyName: Yup.object().when('isCompany', {
//     is: true,
//     then: Yup.string().required('Field is required'),
//     otherwise: Yup.string()
//   }),
//   companyAddress: Yup.object().when('isCompany', {
//     is: (companyValue) => true,//just an e.g. you can return a function
//     then: Yup.string().required('Field is required'),
//     otherwise: Yup.string()
//   }),
// });

export const is = {
  match: (testFn, message = "") => (value, fieldValues) =>
    !testFn(value, fieldValues) && message,

  required: () => value =>
    isNilOrEmptyString(value) && "This field is required",

  requiredWithoutSpace: () => value =>
    isNilOrEmptyString(value.trim()) && "This field is required",

  minLength: min => value =>
    !!value && value.length < min && `Must be at least ${min} characters`,

  maxLength: max => value =>
    !!value && value.length > max && `Must be at most ${max} characters`,

  notEmptyArray: () => value =>
    Array.isArray(value) &&
    value.length === 0 &&
    "Please add at least one item",

  email: () => value =>
    !!value && !/.+@.+\..+/.test(value) && "Must be a valid email",

  url: () => value =>
    !!value &&
    // eslint-disable-next-line no-useless-escape
    !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
      value
    ) &&
    "Must be a valid URL"
};

export const generateErrors = (fieldValues, fieldValidators) => {
  const errors = {};

  Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
    [validators].flat().forEach(validator => {
      const errorMessage = validator(fieldValues[fieldName], fieldValues);
      if (errorMessage && !errors[fieldName]) {
        errors[fieldName] = errorMessage;
      }
    });
  });
  return errors;
};
