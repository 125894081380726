import React from "react";
import Highcharts from "highcharts";
import moment from "moment";
import { getStateFromStats } from "../utils/utility";

class SingleBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: this.props.graphResult,
      selectedTime: this.props.selectedTime
    };
  }

  getXAxisData = () => {
    if (this.state.graphData) {
      const { selectedTime } = this.props;
      const data = this.state.graphData.response_times.reverse();
      const resultArr = [];
      const dataformat =
        selectedTime === 1
          ? "hh:mm a"
          : selectedTime === 24
          ? "hh a"
          : "MMM DD";
      for (let i = 0; i < data.length; i++) {
        resultArr.push(
          moment(new Date(data[i].start).toUTCString()).format(dataformat)
        );
      }
      return resultArr;
    }
  };

  getYAxisData = () => {
    if (this.state.graphData) {
      const data = this.state.graphData.response_times;
      const resultArr = [];
      for (let i = 0; i < data.length; i++) {
        let currentStatus = getStateFromStats(data[i]);
        if (
          currentStatus === "RE" ||
          currentStatus === "NR" ||
          currentStatus === "SME" ||
          currentStatus === "SCE"
        ) {
          resultArr.push({
            name: moment(new Date(data[i].start).toUTCString()).format(
              "MMM DD, YY hh:mm"
            ),
            data: [1],
            color: "#ff3d3d",
            borderWidth: 0.5,
            states: {
              hover: {
                color: "#fe2121"
              }
            }
          });
        } else if (currentStatus === "AV") {
          resultArr.push({
            name: moment(new Date(data[i].start).toUTCString()).format(
              "MMM DD, YY hh:mm"
            ),
            data: [1],
            color: "#bcf2b9",
            borderWidth: 0.5,
            states: {
              hover: {
                color: "#bcf2b9"
              }
            }
          });
        } else {
          resultArr.push({
            name: moment(new Date(data[i].start).toUTCString()).format(
              "MMM DD, YY hh:mm"
            ),
            data: [1],
            color: "#6f7c87",
            borderWidth: 0.5,
            states: {
              hover: {
                color: "#6f7c87"
              }
            }
          });
        }
      }
      return resultArr;
    }
  };

  paintGraph() {
    const id = this.props.checkId
      ? this.props.checkId + "-container1"
      : "container1";
    const yAxisEnabled = this.props.isReportsPage ? true : false;
    const xAxisData = this.getXAxisData();
    const yAxisData = this.getYAxisData().reverse();
    const chart = Highcharts.chart(id, {
      chart: {
        type: "bar",
        spacing: this.props.isReportsPage ? [10, 10, 10, 50] : [5, 5, 5, 0]
      },
      title: {
        text: ""
      },
      xAxis: {
        labels: false,
        visible: false
      },
      yAxis: {
        title: {
          text: ""
        },
        reversed: true,
        categories: xAxisData,
        labels: {
          align: "right",
          rotation: -45,
          x: -10
        },
        visible: yAxisEnabled,
        uniqueNames: true
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        series: {
          stacking: "normal",
          pointWidth: this.props.isReportsPage ? 30 : 12
        }
      },
      series: yAxisData
    });
    if (chart) {
      chart.reflow();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.graphResult !== prevProps.graphResult) {
      this.setState({ graphData: this.props.graphResult }, function() {
        this.paintGraph();
      });
    }
  }

  componentDidMount() {
    this.paintGraph();
  }

  render() {
    const checkId = this.props.checkId ? this.props.checkId : "";
    const style = this.props.isReportsPage ? { maxHeight: "85px" } : {};
    return (
      <div
        id={checkId ? checkId + "-container1" : "container1"}
        style={style}
      />
    );
  }
}

export default SingleBarChart;
