import styled from 'styled-components';

export const FieldBox = styled.div`
  height: 32px;
  display: grid;
  place-content: center;
  padding: 0 8px;
  background: #f8f8f8;
  border-left: 1px solid #cfd7df;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  color: #475867;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
  label {
    display: unset;
    padding-top: unset;
    padding-bottom: 12px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #383838 !important;
    line-height: 14px !important;
    letter-spacing: -0.0923077px !important;
  }
`;

export const AuthErrorCard = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #262626;
  width: fit-content;
  max-width: 986px;
  margin: 8px auto;
  padding: 4px 8px;
  background: #fef1e1;
  border: 1px solid #fddbb5;
  box-sizing: border-box;
  border-radius: 4px;
  & > div.divider {
    margin: 0 8px 0 4px;
    width: 1px;
    height: 24px;
    background: #fddbb5;
  }
`;

export const FormSection = styled.div`
  padding: 16px 32px;
  flex: 1;
  overflow: auto;
`;

export const FormField = styled.div`
  :not(:last-child) {
    margin-bottom: 32px;
  }
  div.field-hint {
    :last-child {
      margin-top: 8px;
    }
    font-size: 12px;
    line-height: 205.5%;
    color: #0851dd;
    text-align: end;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const FormActions = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid rgb(232, 232, 232);
  align-items: center;
`;

export const BackButton = styled.div`
  margin: 24px 24px 0 24px;
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  & > span {
    font-size: 12px;
    color: #343434;
    opacity: 0.5;
  }
  & > :not(:last-child) {
    margin-right: 12px;
  }
`;
