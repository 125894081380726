import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { ConnectedRouter } from 'react-router-redux';
import { ReactQueryDevtools } from 'react-query/devtools';
import client from 'utils/client';
import { QueryClientProvider } from 'react-query';
import { ThemeProvider as FreemiumThemeProvider } from 'freemium-ui';
import { createBrowserHistory } from 'history';

import App from './App';
import appConfig from './config';
import theme from './styles/theme/fping';
import configureStore from './store';
import './index.css';

const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

const freshIdSessionState = window.localStorage.getItem('freshid-session-state');
const freshIdSessionToken = window.localStorage.getItem('freshid-session-token');
const omnibarDomainUrl = window.localStorage.getItem('freshid-organization');

const render = () =>
  ReactDOM.render(
    <Provider store={store}>
      <Fragment>
        <FreemiumThemeProvider globalStyle={false} theme={theme}>
          <QueryClientProvider client={client}>
            <ConnectedRouter history={history}>
              <App store={store} />
            </ConnectedRouter>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </FreemiumThemeProvider>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
        />
        <freshworks-omnibar
          data-omnibar-product={appConfig.APP}
          data-omnibar-environment={appConfig.ENV}
          data-omnibar-user-role={appConfig.OB_ROLE}
          data-omnibar-user-locale="en-us"
          data-omnibar-organisation-domain={omnibarDomainUrl}
          data-omnibar-client-id={appConfig.clientId}
          data-omnibar-session-state={freshIdSessionState}
          data-omnibar-session-token={freshIdSessionToken}
        />
      </Fragment>
    </Provider>,
    MOUNT_NODE
  );

render();

if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}

render();
