import React, { useEffect, Children } from "react";
import FocusLock from "react-focus-lock";
import { createPortal } from "react-dom";
import { TransitionGroup } from "react-transition-group";

import DrawerPrimitive from "./primitives";
import { Blanket } from "./style";
import { Fade } from "./transitions";

const OnlyChild = ({ children }) => Children.toArray(children)[0] || null;

function toggleRootAriaHide(value) {
  const node = document.querySelector("#root");
  if (!node) {
    return false;
  }
  if (value) {
    let attr = node.getAttribute("aria-hidden");
    let alreadyHidden = attr !== null && attr !== "false";
    if (alreadyHidden) {
      return;
    }
    node.setAttribute("aria-hidden", "true");
  } else {
    node.setAttribute("aria-hidden", "false");
  }
}

const DrawerBase = props => {
  const body = document.querySelector("body");
  const { isOpen, children, onClose, icon, width } = props;

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    toggleRootAriaHide(isOpen);
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }
  }, [isOpen]);

  const handleClose = event => {
    if (onClose) {
      onClose(event);
    }
  };

  const handleBlanketClick = event => {
    handleClose(event, "blanket");
  };

  const handleBackButtonClick = event => {
    handleClose(event, "backButton");
  };

  const handleKeyDown = event => {
    const { isOpen, onKeyDown } = props;

    if (event.key === "Escape" && isOpen) {
      handleClose(event, "escKey");
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  if (!body) {
    return null;
  }

  return createPortal(
    <TransitionGroup component={OnlyChild}>
      <>
        <FocusLock>
          {/* $FlowFixMe the `in` prop is internal */}
          <Fade in={isOpen}>
            <Blanket isTinted onBlanketClicked={handleBlanketClick} />
          </Fade>
          <DrawerPrimitive
            icon={icon}
            in={isOpen}
            onClose={handleBackButtonClick}
            width={width}
          >
            {children}
          </DrawerPrimitive>
        </FocusLock>
      </>
    </TransitionGroup>,
    body
  );
};

export default DrawerBase;

// export class DrawerBase extends Component {
//   constructor(props) {
//     super(props);
//     this.body = document.querySelector("body");
//   }
//   componentDidMount() {
//     const { isOpen } = this.props;

//     if (isOpen) {
//       window.addEventListener("keydown", this.handleKeyDown);
//     }
//   }

//   componentDidUpdate(prevProps) {
//     const { isOpen } = this.props;
//     if (isOpen !== prevProps.isOpen) {
//       if (isOpen) {
//         window.addEventListener("keydown", this.handleKeyDown);
//       } else {
//         window.removeEventListener("keydown", this.handleKeyDown);
//       }
//     }
//   }
//   componentWillUnmount() {
//     window.removeEventListener("keydown", this.handleKeyDown);
//   }

//   handleClose = event => {
//     const { onClose } = this.props;

//     if (onClose) {
//       onClose(event);
//     }
//   };

//   handleBlanketClick = event => {
//     this.handleClose(event, "blanket");
//   };

//   handleBackButtonClick = event => {
//     this.handleClose(event, "backButton");
//   };

//   handleKeyDown = event => {
//     const { isOpen, onKeyDown } = this.props;

//     if (event.key === "Escape" && isOpen) {
//       this.handleClose(event, "escKey");
//     }
//     if (onKeyDown) {
//       onKeyDown(event);
//     }
//   };

//   render() {
//     if (!this.body) {
//       return null;
//     }
//     const { isOpen, children, icon, width } = this.props;
//     return createPortal(
//       <TransitionGroup component={OnlyChild}>
//         <Fragment>
//           <FocusLock>
//             {/* $FlowFixMe the `in` prop is internal */}
//             <Fade in={isOpen}>
//               <Blanket isTinted onBlanketClicked={this.handleBlanketClick} />
//             </Fade>
//             <DrawerPrimitive
//               icon={icon}
//               in={isOpen}
//               onClose={this.handleBackButtonClick}
//               width={width}
//             >
//               {children}
//             </DrawerPrimitive>
//           </FocusLock>
//         </Fragment>
//       </TransitionGroup>,
//       this.body
//     );
//   }
// }

// export default DrawerBase;
