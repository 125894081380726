import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #cfd7df;
  & > div.close-placeholder {
    cursor: pointer;
    display: grid;
    place-content: center;
  }
  & > div.heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #183247;
  }
`;

export const Container = styled.div`
  width: 415px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: auto;
`;

export const Item = styled.div`
  display: flex;
  padding: 12px;
  & > div.icon {
    cursor: pointer;
    margin-right: 8px;
  }
  & > div.data {
    flex: 1;
    & > div.group-name {
      cursor: pointer;
      margin-top: 3px;
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #183247;
    }
    & > div.group-options {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
`;

export const Tag = styled.div`
  cursor: pointer;
  background: #fafafb;
  margin: 0 8px 8px 0;
  border: 0.5px solid #bec9d5;
  border-radius: 2px;
  padding: 2px 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #12344d;
`;
