import { call, put, all, fork } from "redux-saga/effects";
import loginUser from "./login";
import {
  getCheck,
  getChecks,
  getCheckStatuses,
  addCheck,
  addChecksToList
} from "./check";
import {
  getCurrentUser,
  getCurrentUserOrganisation,
  updateUserUIState,
  getSyncSubscription
} from "./user";
import {
  getEdgeLocations,
  getOrgApplications,
  getOrganizationMembers,
  getApplicationMembers,
  getApplicationSubscribers
} from "./org";
import {
  watcher,
  manager,
  routeChangeWatcher,
  getApplications,
  getChecksAndFilteredOrg,
  getAllChecksStatuses,
  setFilterState
} from "./checks";
import getStatusPagesSaga, {
  updateStatusPageCheckOrder,
  getStatusPageChecksSaga,
  deleteStatusPageCheck,
  addStatusPageChecks,
  addChecksToStatusPage,
  deleteStatusPage,
  setStatusPageAndChecks,
  updateStatusPage,
  createStatusPage,
  createStatusPageCname,
  verifyStatusPageCname
} from "./statuspages";

import getBadgeSaga, {
  getBadgeChecksSaga,
  deleteBadge,
  setBadgeAndChecks,
  updateBadge,
  createBadge
  // requestBadge
} from "./badge";

export function* requestAndPut(
  requestParameters,
  successAction,
  failureAction,
  meta
) {
  const response = yield call(...requestParameters);
  if (response && Object.keys(response).length && response.status !== 404) {
    yield put(successAction({ response, meta }));
  } else {
    yield put(failureAction({ response, id: meta }));
  }
}

export default function* root() {
  yield all([
    fork(updateStatusPageCheckOrder),
    fork(loginUser),
    fork(getCheck),
    fork(getOrganizationMembers),
    fork(getEdgeLocations),
    fork(getChecks),
    fork(getStatusPagesSaga),
    fork(getBadgeSaga),
    fork(getStatusPageChecksSaga),
    fork(setStatusPageAndChecks),
    fork(updateStatusPage),
    fork(addStatusPageChecks),
    fork(getApplicationMembers),
    fork(getApplicationSubscribers),
    fork(deleteStatusPageCheck),
    fork(createStatusPage),
    fork(createStatusPageCname),
    fork(verifyStatusPageCname),
    fork(addChecksToStatusPage),
    fork(deleteStatusPage),
    fork(getCurrentUser),
    fork(updateUserUIState),
    fork(getCurrentUserOrganisation),
    fork(getAllChecksStatuses),
    fork(getCheckStatuses),
    fork(getApplications),
    fork(getOrgApplications),
    fork(getChecksAndFilteredOrg),
    fork(watcher),
    fork(routeChangeWatcher),
    fork(manager),
    fork(addCheck),
    fork(addChecksToList),
    fork(setFilterState),
    fork(getSyncSubscription),
    fork(getBadgeChecksSaga),
    fork(deleteBadge),
    fork(setBadgeAndChecks),
    fork(updateBadge),
    fork(createBadge)
  ]);
}
