/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable class-methods-use-this */
import React from 'react';
import { ScrollSpyContainer } from './style';

const SPY_INTERVAL = 300;

class Scrollspy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount() {
    this.timer = window.setInterval(() => this.spy(), SPY_INTERVAL);
  }

  componentWillUnmount() {
    window.clearInterval(this.timer);
  }

  spy() {
    const { ids, collections } = this.props;
    const items = ids
      .map(id => {
        const element = document.getElementById(id);
        if (element) {
          let currentLabel = null;
          Object.keys(collections).forEach(c => {
            if (collections[c].sections.indexOf(id) > -1) {
              currentLabel = collections[c][id];
            }
          });
          return {
            id,
            label: currentLabel.label,
            inView: this.isInView(element),
            element
          };
        }
        return false;
      })
      .filter(item => item);

    const firstTrueItem = items.find(item => !!item && item.inView);

    if (!firstTrueItem) {
      return false;
    }
    const update = items.map(item => ({
      ...item,
      inView: item === firstTrueItem
    }));

    this.setState({ items: update });
    return true;
  }

  isInView(element) {
    if (!element) {
      return false;
    }
    const { offset = 2 } = this.props;
    const rect = element.getBoundingClientRect();

    return rect.top >= 0 - offset && rect.bottom <= window.innerHeight + 50;
  }

  scrollTo(element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  render() {
    const {
      activeItemClassName,
      itemClassName,
      collections,
      checkType
    } = this.props;

    const isSectionActive = collection => {
      const activeItem = this.state.items.find(item => item.inView);

      return activeItem && collection.sections.indexOf(activeItem.id) > -1
        ? 'active'
        : '';
    };

    return (
      <ScrollSpyContainer>
        {Object.keys(collections).map(key => (
          <div key={key}>
            <div
              className={`section-title ${isSectionActive(collections[key])}`}
              onClick={() => {
                this.scrollTo(document.getElementById(collections[key].id));
              }}
            >
              {collections[key].label}
            </div>
            {collections[key].sections.map(section => {
              const computedItem = this.state.items.find(i => i.id === section);
              const activeItem = collections[key][section];
              const item = { ...computedItem, ...activeItem };
              return (
                item && (
                  <div
                    key={`link-${item.id}`}
                    className={`${itemClassName} ${
                      item.inView ? activeItemClassName : null
                    } ${item.hidden && 'hide'} ${item.hideFor &&
                      item.hideFor.indexOf(checkType) > -1 &&
                      'hide'} `}
                    onClick={() => {
                      this.scrollTo(item.element);
                    }}
                  >
                    {item.label}
                  </div>
                )
              );
            })}
          </div>
        ))}
        {/* {this.state.items.map((item, k) => (
          <div
            className={`${itemClassName} ${
              item.inView ? activeItemClassName : null
            } `}
            key={k}
            onClick={() => {
              this.scrollTo(item.element);
            }}
          >
            {item.label}
          </div>
        ))} */}
      </ScrollSpyContainer>
    );
  }
}

export default Scrollspy;
