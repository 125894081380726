import {
  ORG_MEMBERS_REQUEST,
  ORG_MEMBERS_SUCCESS,
  ORG_MEMBERS_FAILURE,
  EDGE_LOCATION_SUCCESS,
  ORG_APPLICATION_REQUEST,
  ORG_APPLICATION_SUCCESS,
  ORG_APPLICATION_FAILURE,
  APP_SUBSCRIBERS_REQUEST,
  APP_SUBSCRIBERS_SUCCESS,
  APP_SUBSCRIBERS_FAILURE,
  APP_MEMBERS_REQUEST,
  APP_MEMBERS_SUCCESS,
  APP_MEMBERS_FAILURE
} from "../actions";

const initialRoute = {
  orgMembers: null,
  edgeLocations: null,
  applications: null
};

export default function routeReducer(state = initialRoute, action) {
  switch (action.type) {
    // applications

    case ORG_APPLICATION_REQUEST:
      return {
        ...state
      };
    case ORG_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: action.payload.response
      };
    case ORG_APPLICATION_FAILURE:
      return {
        ...state,
        applications: null
      };
    case APP_MEMBERS_REQUEST:
      return {
        ...state
      };
    case APP_MEMBERS_SUCCESS:
      return {
        ...state,
        applicationMemebers: action.payload.response
      };
    case APP_MEMBERS_FAILURE:
      return {
        ...state,
        applicationMemebers: null
      };
    case APP_SUBSCRIBERS_REQUEST:
      return {
        ...state
      };
    case APP_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        applicationSubscribers: action.payload.response
      };
    case APP_SUBSCRIBERS_FAILURE:
      return {
        ...state,
        applicationSubscribers: null
      };
    case ORG_MEMBERS_REQUEST:
      return {
        ...state
      };
    case EDGE_LOCATION_SUCCESS:
      return {
        ...state,
        edgeLocations: action.payload.response
      };
    case ORG_MEMBERS_SUCCESS:
      return {
        ...state,
        orgMembers: action.payload.response
      };
    case ORG_MEMBERS_FAILURE:
      return {
        ...state,
        orgMembers: null
      };
    default:
      return state;
  }
}
