import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getAllChecks,
  getBadges,
  setBadgeErrors,
  resetBadgeErrors,
  createBadge,
  setActiveBadgeChecks,
  setBadgeForm,
  updateBadge
} from "../actions";

import { initialBadgeState } from "../reducers/badge";
import { getOrgId } from "../utils/utility";
import { StyledLoading } from "../components/styled/Loading";
import BadgeViewer from "./BadgeViewer";
import BadgeCreateModal from "./BadgeCreateModal";
import "../styles/status.css";
import "../styles/badge.css";

const mapDispatchToProps = dispatch => ({
  getBadges: () => dispatch(getBadges()),
  getAllChecks: () => dispatch(getAllChecks()),
  resetBadgeErrors: () => dispatch(resetBadgeErrors()),
  setBadgeErrors: target_url => dispatch(setBadgeErrors(target_url)),
  setBadgeForm: badge => dispatch(setBadgeForm(badge)),
  updateBadge: badge => dispatch(updateBadge(badge)),
  createBadge: badge => dispatch(createBadge(badge)),
  setActiveBadgeChecks: badge => dispatch(setActiveBadgeChecks(badge))
});

const mapStateToProps = state => ({
  badges: state.badges,
  checks: state.checks,
  org: state.user.org
});

class Badges extends Component {
  constructor() {
    super();
    this.state = {
      isCreateModalOpen: false,
      isCancelModalOpen: false
    };
  }

  componentDidMount() {
    this.props.getAllChecks();
    this.props.getBadges();
    if (this.props.create) {
      this.props.setBadgeForm({
        ...initialBadgeState.badgeform,
        id: -1,
        organization: getOrgId()
      });
    }
  }

  componentWillUnmount() {
    this.props.setBadgeForm({
      ...initialBadgeState.badgeform,
      id: null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.badges.formState === "loading" &&
      nextProps.badges.formState === "success" &&
      nextProps.badges.badgeform.id
    ) {
      this.setState({ isCreateModalOpen: true });
    }
    if (nextProps.create) {
      if (
        !this.props.badges.badgeform.name &&
        !this.props.badges.badgeform.name.length &&
        nextProps.org
      ) {
        this.props.setBadgeForm({
          name: nextProps.org[0].name + " badge"
        });
      }
      return;
    }
    const {
      checks: { isLoading, checks },
      badges: { appState, badges, badgeform }
    } = nextProps;
    const url = window.location.href;
    const badgeId = url.substring(url.lastIndexOf("/") + 1);

    if (
      (badgeform.id && badgeform.id !== badgeId) ||
      (!isLoading &&
        checks.length &&
        appState === "success" &&
        badges.results.length &&
        !badgeform.id)
    ) {
      this.setFormEdit(badges, badgeId);
    }
  }

  setFormEdit = (badges, badgeId) => {
    const selectedItem = badges.results.find(badges => badges.id === badgeId);
    if (selectedItem) {
      this.props.setActiveBadgeChecks(selectedItem);
    }
  };

  gotoList = () => {
    this.props.history.push(`/badge`);
  };

  flipCancelModal = () => {
    this.setState(state => ({
      isCancelModalOpen: !state.isCancelModalOpen
    }));
  };

  render() {
    const {
      badges: { appState, formState, badgeform, badgeformErrors },
      checks: { checks },
      setBadgeForm,
      updateBadge,
      resetBadgeErrors,
      setBadgeErrors,
      createBadge
    } = this.props;

    const { isCreateModalOpen, isCancelModalOpen } = this.state;
    return (
      <div className="status-container">
        {appState !== "success" && badgeform && badgeform.id ? (
          <StyledLoading />
        ) : (
          <React.Fragment>
            {badgeform.id && (
              <BadgeViewer
                badgeform={badgeform}
                badgeformErrors={badgeformErrors}
                updateBadge={updateBadge}
                resetBadgeErrors={resetBadgeErrors}
                setBadgeErrors={setBadgeErrors}
                createBadge={createBadge}
                checks={checks}
                gotoList={this.gotoList}
                formState={formState}
                setBadgeForm={setBadgeForm}
                flipCancelModal={this.flipCancelModal}
              />
            )}
            {(isCreateModalOpen || isCancelModalOpen) && (
              <BadgeCreateModal
                badgeform={badgeform}
                gotoList={this.gotoList}
                isCreateModalOpen={isCreateModalOpen}
                isCancelModalOpen={isCancelModalOpen}
                flipCancelModal={this.flipCancelModal}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Badges));
