import styled from 'styled-components';

import { color } from 'shared/utils/styles';

export const RadioOption = styled.label`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
  border: solid 1px ${props => (props.isActive ? color.primary : '#e6e6e6')} ;
  background-color: #fff;
  color: ${color.textNormal}
  opacity: ${props => (props.isActive ? 1 : '0.7')}
  font-size: 11px;
  min-width: 45px;
  min-height: 46px;
  margin-top: 10px;
  padding: 0 6px;
  cursor: pointer;
  .section-option {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .radio-select {
    width: 13px;
    height: 13px;
    border: solid 1px ${color.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${props => (props.isActive ? color.primary : '#ffffff')};
    margin-left: 10px;
    margin-right: 10px;
    svg {
      color: #fff;
      max-width: 100%;
    }
  }
  user-select: none;
  input[type="radio"] {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
  }
  &:hover {
    border: solid 1px ${color.primary};
  }
  
`;
export const Options = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledRadioGroup = styled.div`
  height: 100%;
  width: 100%;
`;
