import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../components/Layout";
import StatusList from "../containers/StatusList";
import StatusView from "../containers/StatusView";
import { getOrgId } from "../utils/utility";
import { NoAccess } from "./settings";

const mapStateToProps = state => ({
  org: state.user.org
});

const Status = props => {
  const {
    match: { url },
    org
  } = props;
  const currentOrg = org && org.find(obj => obj.id === Number(getOrgId()));
  return (
    <Layout page="Status">
      {currentOrg && currentOrg.logged_in_user_role === "ST" ? (
        <NoAccess>
          Sorry, looks like you do not have access to view this page.
        </NoAccess>
      ) : (
        <Switch>
          <Route
            exact
            path={`${url}/new`}
            component={() => <StatusView url={url} create />}
          />
          <Route
            exact
            path={`${url}/:id`}
            component={() => <StatusView url={url} />}
          />
          <Route
            exact
            path={`${url}/`}
            component={() => <StatusList url={url} />}
          />
        </Switch>
      )}
    </Layout>
  );
};

export default connect(mapStateToProps, {})(Status);
