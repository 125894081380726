import { createAction } from "redux-actions";
import { LOGIN_USER_REQUEST } from "../constants/actions";

export const loginUser = values => ({
  type: LOGIN_USER_REQUEST,
  payload: {
    values
  }
});

export const APP_MEMBERS_REQUEST = "APP_MEMBERS_REQUEST";
export const APP_MEMBERS_SUCCESS = "APP_MEMBERS_SUCCESS";
export const APP_MEMBERS_FAILURE = "APP_MEMBERS_FAILURE";
export const getAppMembers = createAction(APP_MEMBERS_REQUEST);
export const getAppMembersSuccess = createAction(APP_MEMBERS_SUCCESS);
export const getAppMembersFailure = createAction(APP_MEMBERS_FAILURE);

export const APP_SUBSCRIBERS_REQUEST = "APP_SUBSCRIBERS_REQUEST";
export const APP_SUBSCRIBERS_SUCCESS = "APP_SUBSCRIBERS_SUCCESS";
export const APP_SUBSCRIBERS_FAILURE = "APP_SUBSCRIBERS_FAILURE";
export const getAppSubscribers = createAction(APP_SUBSCRIBERS_REQUEST);
export const getAppSubscribersSuccess = createAction(APP_SUBSCRIBERS_SUCCESS);
export const getAppSubscribersFailure = createAction(APP_SUBSCRIBERS_FAILURE);

export const ORG_MEMBERS_REQUEST = "ORG_MEMBERS_REQUEST";
export const getOrgMembers = createAction(ORG_MEMBERS_REQUEST);
export const ORG_MEMBERS_SUCCESS = "ORG_MEMBERS_SUCCESS";
export const getOrgMembersSuccess = createAction(ORG_MEMBERS_SUCCESS);
export const ORG_MEMBERS_FAILURE = "ORG_MEMBERS_FAILURE";
export const getOrgMembersFailure = createAction(ORG_MEMBERS_FAILURE);

export const EDGE_LOCATION_REQUEST = "EDGE_LOCATION_REQUEST";
export const getEdgeLocations = createAction(EDGE_LOCATION_REQUEST);
export const EDGE_LOCATION_SUCCESS = "EDGE_LOCATION_SUCCESS";
export const getEdgeLocationSuccess = createAction(EDGE_LOCATION_SUCCESS);
export const EDGE_LOCATION_FAILURE = "EDGE_LOCATION_FAILURE";
export const getEdgeLocationFailure = createAction(EDGE_LOCATION_FAILURE);

export const ONLINE = "ONLINE";
export const OFFLINE = "OFFLINE";
export const goOnline = createAction(ONLINE);
export const goOffline = createAction(OFFLINE);

export const START_POLLING = "START_POLLING";
export const startPolling = createAction(START_POLLING);

export const RECORD_STATS = "RECORD_STATS";
export const recordStats = createAction(RECORD_STATS);

export const UPDATE_REFRESH_COUNTER = "UPDATE_REFRESH_COUNTER";
export const refreshCounter = createAction(UPDATE_REFRESH_COUNTER);

export const GET_APPLICATIONS_REQUEST = "GET_APPLICATIONS_REQUEST";
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_FAILURE = "GET_APPLICATIONS_FAILURE";
export const getAccountApplications = createAction(GET_APPLICATIONS_REQUEST);

export const GET_APPLICATION_REQUEST = "GET_APPLICATION_REQUEST";
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS";
export const GET_APPLICATION_FAILURE = "GET_APPLICATION_FAILURE";

export const getCheck = createAction(GET_APPLICATION_REQUEST);
export const getCheckSuccess = createAction(GET_APPLICATION_SUCCESS);
export const getCheckFailure = createAction(GET_APPLICATION_FAILURE);

export const GET_CHECK_STATUSES_REQUEST = "GET_CHECK_STATUSES_REQUEST";
export const GET_CHECK_STATUSES_SUCCESS = "GET_CHECK_STATUSES_SUCCESS";
export const GET_CHECK_STATUSES_FAILURE = "GET_CHECK_STATUSES_FAILURE";

export const getCheckStatuses = createAction(GET_CHECK_STATUSES_REQUEST);
export const getCheckStatusesSuccess = createAction(GET_CHECK_STATUSES_SUCCESS);
export const getCheckStatusesFailure = createAction(GET_CHECK_STATUSES_FAILURE);

export const GET_ALL_CHECKS_REQUEST = "GET_ALL_CHECKS_REQUEST";
export const GET_CHECKS_REQUEST = "GET_CHECKS_REQUEST";
export const GET_CHECKS_SUCCESS = "GET_CHECKS_SUCCESS";
export const GET_CHECKS_FAILURE = "GET_CHECKS_FAILURE";

export const getAllChecks = createAction(GET_ALL_CHECKS_REQUEST);
export const getChecks = createAction(GET_CHECKS_REQUEST);
export const getChecksSuccess = createAction(GET_CHECKS_SUCCESS);
export const getChecksFailure = createAction(GET_CHECKS_FAILURE);

export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE";
export const getCurrentUser = createAction(GET_CURRENT_USER_REQUEST);
export const getCurrentUserSuccess = createAction(GET_CURRENT_USER_SUCCESS);
export const getCurrentUserFailure = createAction(GET_CURRENT_USER_FAILURE);

export const GET_ALL_CHECK_STATUSES = "GET_ALL_CHECK_STATUSES";
export const getAllStatuses = createAction(GET_ALL_CHECK_STATUSES);

export const GET_CURRENT_USER_ORGANIZATIONS_REQUEST =
  "GET_CURRENT_USER_ORGANIZATIONS_REQUEST";
export const GET_CURRENT_USER_ORGANIZATIONS_SUCCESS =
  "GET_CURRENT_USER_ORGANIZATIONS_SUCCESS";
export const GET_CURRENT_USER_ORGANIZATIONS_FAILURE =
  "GET_CURRENT_USER_ORGANIZATIONS_FAILURE";
export const getCurrentUserOrg = createAction(
  GET_CURRENT_USER_ORGANIZATIONS_REQUEST
);
export const getCurrentUserOrgSuccess = createAction(
  GET_CURRENT_USER_ORGANIZATIONS_SUCCESS
);
export const getCurrentUserOrgFailure = createAction(
  GET_CURRENT_USER_ORGANIZATIONS_FAILURE
);

export const GET_STATUSPAGES_REQUEST = "GET_STATUSPAGES_REQUEST";
export const GET_STATUSPAGES_SUCCESS = "GET_STATUSPAGES_SUCCESS";
export const GET_STATUSPAGES_FAILURE = "GET_STATUSPAGES_FAILURE";

export const getStatusPages = createAction(GET_STATUSPAGES_REQUEST);
export const getStatusPagesSuccess = createAction(GET_STATUSPAGES_SUCCESS);
export const getStatusPagesFailure = createAction(GET_STATUSPAGES_FAILURE);

export const GET_STATUS_CHECKS_REQUEST = "GET_STATUS_CHECKS_REQUEST";
export const GET_STATUS_CHECKS_SUCCESS = "GET_STATUS_CHECKS_SUCCESS";
export const GET_STATUS_CHECKS_FAILURE = "GET_STATUS_CHECKS_FAILURE";

export const getStatusPageChecks = createAction(GET_STATUS_CHECKS_REQUEST);
export const getStatusPageChecksSuccess = createAction(
  GET_STATUS_CHECKS_SUCCESS
);
export const getStatusPageChecksFailure = createAction(
  GET_STATUS_CHECKS_FAILURE
);

export const SET_STATUSPAGE_FORM = "SET_STATUSPAGE_FORM";
export const setStatusPageForm = createAction(SET_STATUSPAGE_FORM);

export const SET_ACTIVE_STATUSPAGE = "SET_ACTIVE_STATUSPAGE"; // sets and gets active statuspage
export const SET_ACTIVE_STATUSPAGE_SUCCESS = "SET_ACTIVE_STATUSPAGE_SUCCESS";
export const SET_ACTIVE_STATUSPAGE_FAILURE = "SET_ACTIVE_STATUSPAGE_FAILURE";
export const setActiveStatusChecks = createAction(SET_ACTIVE_STATUSPAGE);
export const setActiveStatusChecksSuccess = createAction(
  SET_ACTIVE_STATUSPAGE_SUCCESS
);
export const setActiveStatusChecksFailure = createAction(
  SET_ACTIVE_STATUSPAGE_FAILURE
);

export const UPDATE_STATUSPAGE = "UPDATE_STATUSPAGE"; // sets and gets active statuspage
export const UPDATE_STATUSPAGE_SUCCESS = "UPDATE_STATUSPAGE_SUCCESS";
export const UPDATE_STATUSPAGE_FAILURE = "UPDATE_STATUSPAGE_FAILURE";
export const updateStatusPage = createAction(UPDATE_STATUSPAGE);
export const updateStatusPageSuccess = createAction(UPDATE_STATUSPAGE_SUCCESS);
export const updateStatusPageFailure = createAction(UPDATE_STATUSPAGE_FAILURE);

export const CREATE_STATUSPAGE = "CREATE_STATUSPAGE"; // sets and gets active statuspage
export const CREATE_STATUSPAGE_SUCCESS = "CREATE_STATUSPAGE_SUCCESS";
export const CREATE_STATUSPAGE_FAILURE = "CREATE_STATUSPAGE_FAILURE";
export const createStatusPage = createAction(CREATE_STATUSPAGE);
export const createStatusPageSuccess = createAction(CREATE_STATUSPAGE_SUCCESS);
export const createStatusPageFailure = createAction(CREATE_STATUSPAGE_FAILURE);

export const CREATE_STATUSPAGE_CNAME = "CREATE_STATUSPAGE_CNAME"; // generate statuspage CNAME
export const CREATE_STATUSPAGE_CNAME_SUCCESS = "CREATE_STATUSPAGE_CNAME_SUCCESS";
export const CREATE_STATUSPAGE_CNAME_FAILURE = "CREATE_STATUSPAGE_CNAME_FAILURE";
export const createStatusPageCname = createAction(CREATE_STATUSPAGE_CNAME);
export const createStatusPageCnameSuccess = createAction(CREATE_STATUSPAGE_CNAME_SUCCESS);
export const createStatusPageCnameFailure = createAction(CREATE_STATUSPAGE_CNAME_FAILURE);

export const VERIFY_STATUSPAGE_CNAME = "VERIFY_STATUSPAGE_CNAME"; // verify statuspage CNAME
export const VERIFY_STATUSPAGE_CNAME_SUCCESS = "VERIFY_STATUSPAGE_CNAME_SUCCESS";
export const VERIFY_STATUSPAGE_CNAME_FAILURE = "VERIFY_STATUSPAGE_CNAME_FAILURE";
export const verifyStatusPageCname = createAction(VERIFY_STATUSPAGE_CNAME);
export const verifyStatusPageCnameSuccess = createAction(VERIFY_STATUSPAGE_CNAME_SUCCESS);
export const verifyStatusPageCnameFailure = createAction(VERIFY_STATUSPAGE_CNAME_FAILURE);

export const STATUSPAGE_FORM_FIELDS = ["logo_url", "custom_domain", "name"];
export const BADGE_FORM_FIELDS = ["name"];

export const RESET_STATUSPAGE_ERRORS = "RESET_STATUSPAGE_ERRORS";
export const resetStatusPageErrors = createAction(RESET_STATUSPAGE_ERRORS);

export const SET_STATUSPAGE_ERRORS = "SET_STATUSPAGE_ERRORS";
export const setStatusPageErrors = createAction(SET_STATUSPAGE_ERRORS);

export const ADD_TOAST = "@ADD_TOAST";

export const addToast = meta => ({
  type: ADD_TOAST,
  meta
});

export const DELETE_STATUSPAGE = "DELETE_STATUSPAGE"; // sets and gets active statuspage
export const DELETE_STATUSPAGE_SUCCESS = "DELETE_STATUSPAGE_SUCCESS";
export const DELETE_STATUSPAGE_FAILURE = "DELETE_STATUSPAGE_FAILURE";
export const deleteStatusPage = createAction(DELETE_STATUSPAGE);
export const deleteStatusPageSuccess = createAction(DELETE_STATUSPAGE_SUCCESS);
export const deleteStatusPageFailure = createAction(DELETE_STATUSPAGE_FAILURE);

export const CREATE_CHECKS_STATUSPAGE = "CREATE_CHECKS_STATUSPAGE"; // sets and gets active statuspage
export const CREATE_CHECKS_STATUSPAGE_SUCCESS =
  "CREATE_CHECKS_STATUSPAGE_SUCCESS";
export const CREATE_CHECKS_STATUSPAGE_FAILURE =
  "CREATE_CHECKS_STATUSPAGE_FAILURE";
export const createStatusPageChecks = createAction(CREATE_CHECKS_STATUSPAGE);
export const createStatusPageChecksSuccess = createAction(
  CREATE_CHECKS_STATUSPAGE_SUCCESS
);
export const createStatusPageChecksFailure = createAction(
  CREATE_CHECKS_STATUSPAGE_FAILURE
);

export const REMOVE_CHECK_FROM_STATUSPAGE = "REMOVE_CHECK_FROM_STATUSPAGE"; // sets and gets active statuspage
export const REMOVE_CHECK_FROM_STATUSPAGE_SUCCESS =
  "REMOVE_CHECK_FROM_STATUSPAGE_SUCCESS";
export const REMOVE_CHECK_FROM_STATUSPAGE_FAILURE =
  "REMOVE_CHECK_FROM_STATUSPAGE_FAILURE";
export const deleteCheckFromStatus = createAction(REMOVE_CHECK_FROM_STATUSPAGE);
export const deleteCheckFromStatusSuccess = createAction(
  REMOVE_CHECK_FROM_STATUSPAGE_SUCCESS
);
export const deleteCheckFromStatusFailure = createAction(
  REMOVE_CHECK_FROM_STATUSPAGE_FAILURE
);

export const UPDATE_CHECKS_ORDER = "UPDATE_CHECKS_ORDER";
export const updateChecksOrder = createAction(UPDATE_CHECKS_ORDER);

export const UPDATE_STATUS_PAGE_ORDER = "UPDATE_STATUS_PAGE_ORDER";
export const updateStatusPageOrder = createAction(UPDATE_STATUS_PAGE_ORDER);
export const UPDATE_STATUS_PAGE_ORDER_SUCCESS =
  "UPDATE_STATUS_PAGE_ORDER_SUCCESS";
export const UPDATE_STATUS_PAGE_ORDER_FAILURE =
  "UPDATE_STATUS_PAGE_ORDER_FAILURE";
export const updateStatusPageOrderSuccess = createAction(
  UPDATE_STATUS_PAGE_ORDER_SUCCESS
);
export const updateStatusPageOrderFailure = createAction(
  UPDATE_STATUS_PAGE_ORDER_FAILURE
);

// Add check
export const ADD_CHECK = "ADD_CHECK"; // sets and gets checks details
export const ADD_CHECK_SUCCESS = "ADD_CHECK_SUCCESS";
export const EDIT_CHECK_SUCCESS = "EDIT_CHECK_SUCCESS";
export const ADD_CHECK_FAILURE = "ADD_CHECK_FAILURE";
export const addCheck = createAction(ADD_CHECK);
export const addCheckSuccess = createAction(ADD_CHECK_SUCCESS);
export const editCheckSuccess = createAction(EDIT_CHECK_SUCCESS);
export const addCheckFailure = createAction(ADD_CHECK_FAILURE);

// org application
export const ORG_APPLICATION_REQUEST = "ORG_APPLICATION_REQUEST"; // sets and gets checks details
export const ORG_APPLICATION_SUCCESS = "ORG_APPLICATION_SUCCESS";
export const ORG_APPLICATION_FAILURE = "ORG_APPLICATION_FAILURE";
export const getOrgApplications = createAction(ORG_APPLICATION_REQUEST);
export const getOrgApplicationsSuccess = createAction(ORG_APPLICATION_SUCCESS);
export const getOrgApplicationsFailure = createAction(ORG_APPLICATION_FAILURE);

// set filters like type, search, page
export const SET_FILTER_STATE = "SET_FILTER_STATE";
export const setFilterState = createAction("SET_FILTER_STATE");

// get sync subscription updates
export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "GET_SUBSCRIPTION_FAILURE";
export const getSubscription = createAction(GET_SUBSCRIPTION_REQUEST);
export const getSubscriptionSuccess = createAction(GET_SUBSCRIPTION_SUCCESS);
export const getSubscriptionFailure = createAction(GET_SUBSCRIPTION_FAILURE);

export const SET_BADGE_TICKET = "SET_BADGE_TICKET";
export const createBadgeLocal = createAction("SET_BADGE_TICKET");

//
export const TOGGLE_INTEREST_MODAL = "TOGGLE_INTEREST_MODAL";
export const toggleInterestModal = createAction("TOGGLE_INTEREST_MODAL");

export const TOGGLE_CHECK_DRAWER = "TOGGLE_CHECK_DRAWER";
export const toggleCheckDrawer = createAction("TOGGLE_CHECK_DRAWER");

export const TOGGLE_BADGE_HEADER = "TOGGLE_BADGE_HEADER";
export const toggleBadgeHeader = createAction(TOGGLE_BADGE_HEADER);
export const SET_BANNER = "SET_BANNER";
export const setBanner = createAction(SET_BANNER);

export const UPDATE_USER_UI_STATE = "UPDATE_USER_UI_STATE";
export const updateUserUIState = createAction("UPDATE_USER_UI_STATE");

export const GET_BADGE_REQUEST = "GET_BADGE_REQUEST";
export const GET_BADGE_SUCCESS = "GET_BADGE_SUCCESS";
export const GET_BADGE_FAILURE = "GET_BADGE_FAILURE";
export const SET_BADGE_FORM = "SET_BADGE_FORM";
export const SET_BADGE_ERRORS = "SET_BADGE_ERRORS";
export const RESET_BADGE_ERRORS = "RESET_BADGE_ERRORS";
export const UPDATE_BADGE = "UPDATE_BADGE";
export const UPDATE_BADGE_SUCCESS = "UPDATE_BADGE_SUCCESS";
export const UPDATE_BADGE_FAILURE = "UPDATE_BADGE_FAILURE";
export const CREATE_BADGE = "CREATE_BADGE";
export const CREATE_BADGE_SUCCESS = "CREATE_BADGE_SUCCESS";
export const CREATE_BADGE_FAILURE = "CREATE_BADGE_FAILURE";
export const DELETE_BADGE = "DELETE_BADGE";
export const DELETE_BADGE_SUCCESS = "DELETE_BADGE_SUCCESS";
export const DELETE_BADGE_FAILURE = "DELETE_BADGE_FAILURE";
export const SET_ACTIVE_BADGE = "SET_ACTIVE_BADGE";
export const SET_ACTIVE_BADGE_SUCCESS = "SET_ACTIVE_BADGE_SUCCESS";
export const SET_ACTIVE_BADGE_FAILURE = "SET_ACTIVE_BADGE_FAILURE";
export const GET_BADGE_CHECKS_REQUEST = "GET_BADGE_CHECKS_REQUEST";
export const GET_BADGE_CHECKS_SUCCESS = "GET_BADGE_CHECKS_SUCCESS";
export const GET_BADGE_CHECKS_FAILURE = "GET_BADGE_CHECKS_FAILURE";
export const SET_BADGE_URL = "SET_BADGE_URL";

export const getBadges = createAction(GET_BADGE_REQUEST);
export const getBadgeSuccess = createAction(GET_BADGE_SUCCESS);
export const getBadgeFailure = createAction(GET_BADGE_FAILURE);
export const setBadgeForm = createAction(SET_BADGE_FORM);
export const resetBadgeErrors = createAction(RESET_BADGE_ERRORS);
export const setBadgeErrors = createAction(SET_BADGE_ERRORS);
export const deleteBadge = createAction(DELETE_BADGE);
export const deleteBadgeSuccess = createAction(DELETE_BADGE_SUCCESS);
export const deleteBadgeFailure = createAction(DELETE_BADGE_FAILURE);
export const updateBadge = createAction(UPDATE_BADGE);
export const updateBadgeSuccess = createAction(UPDATE_BADGE_SUCCESS);
export const updateBadgeFailure = createAction(UPDATE_BADGE_FAILURE);
export const createBadge = createAction(CREATE_BADGE);
export const createBadgeSuccess = createAction(CREATE_BADGE_SUCCESS);
export const createBadgeFailure = createAction(CREATE_BADGE_FAILURE);
export const setActiveBadgeChecks = createAction(SET_ACTIVE_BADGE);
export const setActiveBadgeChecksSuccess = createAction(
  SET_ACTIVE_BADGE_SUCCESS
);
export const setActiveBadgeChecksFailure = createAction(
  SET_ACTIVE_BADGE_FAILURE
);
export const getBadgeChecks = createAction(GET_BADGE_CHECKS_REQUEST);
export const getBadgeChecksSuccess = createAction(GET_BADGE_CHECKS_SUCCESS);
export const getBadgeChecksFailure = createAction(GET_BADGE_CHECKS_FAILURE);
export const setBadgeUrl = createAction(SET_BADGE_URL);
