/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
import styled from "styled-components";

// const backgroundColor = {
//   light: "rgba(9, 30, 66, 0.54)",
//   dark: ""
// };
export const opacity = p => (p.isTinted ? 1 : 0);
export const pointerEvents = p => (p.canClickThrough ? "none" : "initial");

export const Blanket = styled.div`
  background: rgba(13, 20, 36, 0.63);
  bottom: 0;
  left: 0;
  opacity: ${opacity};
  pointer-events: ${pointerEvents};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 220ms;
  z-index: 500;
`;

export const StyledSlider = styled.div`
  background-color: rgb(255, 255, 255);
  display: flex;
  height: 100vh;
  right: 0px;
  position: fixed;
  top: 0px;
  width: ${props => (props.width ? props.width : "600px")};
  z-index: 501;
`;

export const IconWrapper = styled.button`
  align-items: center;
  background: #111;
  position: absolute;
  left: -26px;
  border: 0;
  color: #fff;
  cursor: ${props => (props.onClick ? "pointer" : null)};
  display: flex;
  justify-content: center;
  line-height: 1;
  padding: 5px;
  
  &:hover: {
    background-color: ${props =>
      props.onClick ? "rgba(9, 30, 66, 0.08)" : null}
  }
  
  &:active: {
    background-color: ${props => (props.onClick ? "#DEEBFF" : null)}
    outline: 0
  }

  svg {
    fill: #fff;
  }
`;
