import React from "react";
import PropTypes from "prop-types";
import { API_ROOT } from "../config";

class FreshidSSOSuccess extends React.Component {
  componentWillMount() {
    const location = new URL(window.location.href);
    const code = location.searchParams.get("code");
    const sessionState = location.searchParams.get("session_state");
    const sessionToken = location.searchParams.get("session_token");
    window.localStorage.setItem("freshid-session-state", sessionState);
    window.localStorage.setItem("freshid-session-token", sessionToken);
    this.completeFreshidSSO(code);
  }

  completeFreshidSSO(code) {
    const self = this;
    const url = `${API_ROOT}/freshid-sso-v2-login/`;

    const header = new Headers({
      "content-type": "application/json"
    });

    const data = {
      auth_code: code,
      account_domain_name: window.location.host.split(".")[0]
    };

    fetch(url, {
      headers: header,
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .catch(error => console.log(error))
      .then(response => {
        if (response.non_field_errors) {
          console.log(response.non_field_errors);
          return;
        }
        if (response.login_token) {
          localStorage.setItem("auth-token", response.login_token);
          localStorage.setItem("active-org", response.organization);
          response.custom_ui_state &&
            localStorage.setItem(
              "upgrade",
              JSON.parse(response.custom_ui_state).plan_name
            );
          self.context.router.history.push({
            pathname: "/",
            state: { email: response.user_email }
          });
          return;
        }
        if (response.redirect_url) {
          window.location = response.redirect_url;
        }
      });
  }

  render() {
    return <div />;
  }
}

FreshidSSOSuccess.contextTypes = {
  router: PropTypes.object.isRequired
};
export default FreshidSSOSuccess;
