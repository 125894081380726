/* eslint-disable arrow-parens */
import { toastr } from 'react-redux-toastr';
/**
 *
 * @param { meta: toastr: { type: "warning", message: `warning message` } }
 */
const toastScheduler = ({ dispatch }) => next => action => {
  if (action.error) {
    if (action.payload) {
      switch (action.payload.status) {
        case 401:
          // dispatch(setSessionError(action.payload.status))
          break;
        case 405:
          toastr.warning('Operation is not permitted', {
            timeOut: 4000,
            showCloseButton: true,
            icon: null,
            progressBar: false
          });
          break;
        case 422:
          toastr.warning(
            action.payload.message
              ? action.payload.message
              : 'Cannot perform action',
            {
              timeOut: 4000,
              showCloseButton: true,
              icon: null,
              progressBar: false
            }
          );
          break;
        case 503:
          toastr.warning('Oops... Something went wrong.', {
            timeOut: 4000,
            showCloseButton: true,
            icon: null,
            progressBar: false
          });
          break;
        default:
          break;
      }
    }
    // else {
    //   dispatch(setSessionError(500))
    // }
    return next(action);
  }
  if (!(action.payload && action.payload.meta && action.payload.meta.toastr)) {
    return next(action);
  }
  const toastrOptions = {
    timeOut: 4000,
    showCloseButton: true
    // icon:
    //   action.payload.meta.toastr.type === "success" ? (
    //     <Icons glyph="success" size="24px" viewBox="0 0 24 24" />
    //   ) : null,
    // progressBar: false
  };
  const toastrMeta = action.payload.meta.toastr;
  if (toastrMeta.type) {
    toastr[toastrMeta.type](toastrMeta.message, toastrOptions);
  }
  return next(action);
};

export default toastScheduler;
