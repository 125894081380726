/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-parens */
import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import { SkeletonWrapper } from './Report';
import { getReadableDuration } from '../utils/utility';
import info from '../images/info.svg';
import Tooltip from '../components/styled/Tooltip';
function getLongestDowntimeDuration(data) {
  const time = data.maximum_downtime_duration;
  if (time === null) return 0;
  const temp = moment.duration(time, 'seconds');
  const result = getReadableDuration(temp);
  return result;
}

function getDownTimeDuration(data) {
  const time = data.duration_seconds_total_downtime;
  const temp = moment.duration(time, 'seconds');
  const result = getReadableDuration(temp);
  return result;
}

/**
 * @TODO Replace Antd Datepicker with airbnb/react-dates
 * If this alone is removed we will drop the whole antd library
 */

const PerformanceMetrics = props => {
  const {
    // activeTimeValue,
    performanceData,
    incidentsCount,
    checkIsLoading,
    timeValid,
    deviceSize,
    planname
  } = props;

  const disabledDate = current => {
    // Can not select days before today and today
    const monthEnabler =
      planname === 'garden' ? 24 : planname === 'blossom' ? 12 : 6;
    return (
      (current && current > moment().endOf('day')) ||
      current <
        moment()
          .subtract(monthEnabler, 'months')
          .startOf('day')
    );
  };
  const downtimeDuration = getDownTimeDuration(performanceData);
  const longdownTime = getLongestDowntimeDuration(performanceData);
  const dateFormat = 'DD-MMM-YYYY';

  return (
    <Metrics className={`${deviceSize} availability-metrics-sub-container`}>
      <div className="timeRangeContainer">
        <h4>Performance metrics</h4>
        {deviceSize && deviceSize === 'device-large' ? (
          <div className="menu">
            {!timeValid ? (
              <ErrorTag className="ErrorTag">
                You cannot select more than 3 months.
              </ErrorTag>
            ) : null}
            <DatePicker.RangePicker
              disabledDate={disabledDate}
              defaultValue={[
                moment(props.startDate, dateFormat),
                moment(props.endDate, dateFormat)
              ]}
              onChange={(date, dateString) =>
                props.handleRangeChange(dateString)
              }
              format={dateFormat}
              allowClear={false}
              className="graphpicker"
              separator="-"
              ranges={{
                'Last 3 Months': [
                  moment()
                    .subtract(2, 'months')
                    .startOf('month'),
                  moment().endOf('day')
                ],
                'Last Month': [
                  moment()
                    .subtract(1, 'months')
                    .startOf('month'),
                  moment()
                    .subtract(1, 'months')
                    .endOf('month')
                ],
                'This Month': [moment().startOf('month'), moment()],
                'This Week': [moment().subtract(6, 'days'), moment()],
                Today: [moment(), moment()]
              }}
            />
            <Tooltip
              position="left"
              content="You can select upto 3 months only"
            >
              <img
                className="info-icon thead-info"
                src={info}
                alt="info icon"
              />
            </Tooltip>
          </div>
        ) : null}
      </div>
      <div className="availability-metrics">
        <div className="info-box">
          <div className="info-metric">
            <p className="info-title"> Availability </p>
            {checkIsLoading ? (
              <SkeletonWrapper>
                <Skeleton height={32} width={80} />
              </SkeletonWrapper>
            ) : (
              <p className="info-value">
                {performanceData.availability_percentage
                  ? `${parseFloat(
                      Number(performanceData.availability_percentage)
                    )} %`
                  : '0 %'}
              </p>
            )}
          </div>
        </div>

        <div className="info-box" style={{ flexGrow: '1' }}>
          <div className="info-metric">
            <div className="downtime-wrapper">
              <span className="info-title"> Downtime </span>{' '}
              {!checkIsLoading && (
                <span className="downtime-title">
                  <span className="downtime-value">
                    {parseFloat(
                      (
                        100 -
                        Number(
                          performanceData.availability_percentage
                            ? performanceData.availability_percentage
                            : 100
                        )
                      ).toFixed(2)
                    )}
                    %
                  </span>
                </span>
              )}
            </div>
            {checkIsLoading ? (
              <SkeletonWrapper>
                <Skeleton height={32} width={180} />
              </SkeletonWrapper>
            ) : (
              <p className="info-value" title={downtimeDuration}>
                {downtimeDuration || '0 Minutes'}
              </p>
            )}
          </div>
        </div>

        <div className="info-box" style={{ flexGrow: '1' }}>
          <div className="info-metric">
            <p className="info-title"> Longest downtime duration </p>
            {checkIsLoading ? (
              <SkeletonWrapper>
                <Skeleton height={32} width={180} />
              </SkeletonWrapper>
            ) : (
              <p className="info-value" title={longdownTime}>
                {longdownTime || '0 mins'}
              </p>
            )}
          </div>
        </div>
        <div className="info-box">
          <div className="info-metric" style={{ borderRight: 0 }}>
            <p className="info-title"> Incidents </p>
            {checkIsLoading ? (
              <SkeletonWrapper>
                <Skeleton height={32} width={80} />
              </SkeletonWrapper>
            ) : (
              <p className="info-value"> {incidentsCount} </p>
            )}
          </div>
        </div>
      </div>
    </Metrics>
  );
};

export default PerformanceMetrics;

const Metrics = styled.div`
  .availability-metrics {
    display: flex;
    justify-content: space-between;
  }

  .graphpicker {
    width: 240px;
    display: block;
    .ant-input {
      border: 1px solid #ebeff3;
    }
  }
  .timeRangeContainer {
    margin-bottom: 6px;
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
  }
  .thead-info {
    width: 16px;
    margin: 4px 0px 4px 4px;
  }

  &.device-small {
    height: auto;

    .availability-metrics {
      display: block;
    }

    .availability-metrics .info-metric {
      border-right: none;
    }

    .info-box {
      width: 50%;
      padding: 20px 0;
      display: inline-block;
      position: relative;
    }
  }
`;

const ErrorTag = styled.div`
  color: #ff3c3d;
  font-size: 12px;
  padding: 0 5px;
`;
