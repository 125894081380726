import styled, { css } from "styled-components";

import { color, font } from "shared/utils/styles";

export const StyledInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;

  .label {
    padding: 0 10px 0 5px;
    cursor: pointer;
  }
`;

export const InputElement = styled.input`
  height: 100%;
  width: 100%;
  padding: 0 7px;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid ${color.borderLightest};
  color: ${color.textDarkest};
  transition: all 0.1s;
  ${font.regular}
  ${font.size(14)}
  ${props => props.hasIcon && "padding-left: 32px;"}
  
  ${props =>
    props.invalid &&
    css`
      &,
      &:focus {
        border-bottom: 1px solid ${color.danger};
        box-shadow: none;
      }
    `}
`;
