import { sortBy } from "lodash";
import {
  // GET_APPLICATION_REQUEST,
  GET_CHECK_STATUSES_REQUEST,
  GET_CHECK_STATUSES_SUCCESS,
  GET_CHECKS_REQUEST,
  GET_CHECKS_SUCCESS,
  GET_CHECKS_FAILURE,
  GET_CHECK_STATUSES_FAILURE,
  UPDATE_CHECKS_ORDER,
  ADD_CHECK,
  ADD_CHECK_SUCCESS,
  EDIT_CHECK_SUCCESS,
  ADD_CHECK_FAILURE,
  SET_FILTER_STATE
} from "actions";

const intialState = {
  isLoading: false,
  allStatusLoaded: false,
  checksEntity: {},
  formState: "idle",
  drawerState: "close",
  checks: [],
  totalChecks: 0,
  filterParams: {
    page: 1,
    status: "all",
    search: "",
    page_size: 10,
    ordering: "name"
  }
};

function sortChecksByFailure(checks) {
  return sortBy(checks, [
    function(o) {
      return o.statuses.state === "AV";
    }
  ]);
}

// function dedupeAndMergeChecks(updated, old) {
//   var o = {};
//   old.forEach(function(v) {
//     o[v.id] = v;
//   });
//   updated.forEach(function(v) {
//     var oldState = o[v.id] ? o[v.id] : {};
//     o[v.id] = { ...oldState, ...v, order: 0 };
//   });
//   var r = [];
//   for (var p in o) {
//     if (o.hasOwnProperty(p)) r.push(o[p]);
//   }
//   return r.filter(c => c.application_mini.logged_in_user_role);
// }

export default function checksReducer(state = intialState, action) {
  switch (action.type) {
    case GET_CHECKS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_CHECKS_SUCCESS:
      if (action.payload.response || action.payload.response.results) {
        return {
          ...state,
          isLoading: false,
          totalChecks: action.payload.response.totalResults
            ? action.payload.response.totalResults
            : action.payload.response.results.length,
          checks: action.payload.response.results // dedupeAndMergeChecks(action.payload.response.results, state.checks)
        };
      }
      return state;
    case GET_CHECKS_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case GET_CHECK_STATUSES_SUCCESS:
      const checks = state.checks.map(check =>
        check.id === action.payload.response.check_id
          ? {
              ...check,
              statuses: {
                appState: "success",
                ...action.payload.response
              }
            }
          : check
      );
      const isStatusesComplete = checks.find(
        el =>
          el.statuses.appState === "idle" || el.statuses.appState === "loading"
      );
      return {
        ...state,
        allStatusLoaded: typeof isStatusesComplete === "undefined",
        checks: isStatusesComplete ? checks : sortChecksByFailure(checks)
      };
    case GET_CHECK_STATUSES_REQUEST:
      return {
        ...state,
        checks: state.checks.map(check => {
          if (check.id === action.payload) {
            const oldStatuses = check.statuses ? check.statuses : {};
            return {
              ...check,
              statuses: {
                ...oldStatuses,
                appState: check.statuses ? "reloading" : "loading"
              }
            };
          }
          return check;
        })
      };
    case GET_CHECK_STATUSES_FAILURE:
      const allChecks = state.checks.map(check => {
        if (check.id === action.payload.id) {
          const oldStatuses = check.statuses ? check.statuses : {};
          return {
            ...check,
            statuses: { ...oldStatuses, appState: "failure" }
          };
        }
        return check;
      });
      const AllStatusesComplete = allChecks.find(
        el =>
          el.statuses.appState === "idle" || el.statuses.appState === "loading"
      );
      return {
        ...state,
        allStatusLoaded: typeof AllStatusesComplete === "undefined",
        checks: allChecks
      };

    case UPDATE_CHECKS_ORDER:
      const { start, end } = action.payload;
      const result = Array.from(state.checks);
      const [removed] = result.splice(start, 1);
      result.splice(end, 0, removed);
      result.map((check, index) => {
        check.order = index + 1;
        return check;
      });
      return {
        ...state,
        checks: result
      };

    case ADD_CHECK:
      return {
        ...state,
        formState: "loading"
      };
    case ADD_CHECK_SUCCESS:
      if (action.payload.response.status === 400) {
        return {
          ...state,
          formState: "error"
        };
      }

      return {
        ...state,
        checks: [action.payload.response, ...state.checks],
        totalChecks: state.totalChecks + 1, // handle soft state update
        formState: "success"
      };
    case ADD_CHECK_FAILURE:
      return {
        ...state,
        formState: "failed"
      };
    case EDIT_CHECK_SUCCESS:
      if (action.payload.response.status === 400) {
        return {
          ...state,
          formState: "error"
        };
      }

      return {
        ...state,
        checks: state.checks.map(check =>
          check.id === action.payload.response.id
            ? action.payload.response
            : check
        ),
        formState: "success"
      };
    case SET_FILTER_STATE:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          ...action.payload
        }
      };
    default:
      return state;
  }
}

/*
function normalizeChecks(action) {
  let checks = {};
  action.payload.response.forEach(check => {
    checks = { ...checks, [check.id]: { ...check } };
  });
  return checks;
}
*/
