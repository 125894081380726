import { useQuery } from 'react-query';
import { request } from 'utils/Api';

export const useStateChangeanalysiss = '';

export const useStateChangeAnalysis = checkId =>
  useQuery(['stateChangeanalysis', checkId], async () => {
    if (!checkId) return {};
    const response = await request(
      `/check-state-changes-analysis/?based_on=${checkId}`
    );
    return response || {};
  });
