/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { withRouter } from "react-router-dom";
import Tooltip from "components/styled/Tooltip";
import { FormHeading, FormSubHeading, UpgradeLabel } from "./styles";

const featurePricingKey = {
  ssl: "sslexpiryalert",
  "performance-alerting": "sslexpiryalert",
  "alert-fine-tune": "advancedsettings",
  "custom-header": "basicauth",
  "authentication-parameters": "basicauth",
  default: "advancedsettings"
};

const NestedSectionTitle = ({
  children,
  hideTitle = false,
  modalClose,
  history,
  spaced = false,
  upgradePlan = false,
  section = {}
}) => {
  const { id, subtitle, label } = section;
  // console.log("upgradePlan", upgradePlan, modalClose);
  const updateAction = () => {
    modalClose();
    history.push(
      `/settings/pricing?interest=${
        featurePricingKey[section.id ? section.id : "default"]
      }`
    );
  };
  return (
    <section
      className={`form-section-wrap ${spaced ? "spaced" : ""} ${
        hideTitle ? "hideTitle" : ""
      }`}
      id={`${id}`}
    >
      {upgradePlan && upgradePlan.name && !upgradePlan.available && (
        <UpgradeLabel>
          <Tooltip
            position="left"
            content={
              <span>
                Upgrade to {upgradePlan.name} to use this feature
                {/* <br /> Click to upgrade */}
              </span>
            }
          >
            <div onClick={updateAction} className="label">
              {" "}
              UPGRADE TO {upgradePlan.name}{" "}
            </div>
          </Tooltip>
        </UpgradeLabel>
      )}
      <div className="section-content">
        {!hideTitle && (
          <div
            className={`section-nested ${
              upgradePlan && !upgradePlan.available ? "upgrade" : ""
            }`}
          >
            <FormHeading>{label}</FormHeading>
            {subtitle && <FormSubHeading>{subtitle}</FormSubHeading>}
          </div>
        )}
        {children}
      </div>
    </section>
  );
};

export default withRouter(NestedSectionTitle);
