/* eslint-disable react/sort-comp */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { get } from 'lodash';
import filter from 'lodash.filter';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { saveAs } from 'file-saver';
import { TablePagination } from '@trendmicro/react-paginations';
import ReactDOM from 'react-dom';
import { Drawer, Button } from 'freemium-ui';
import OutageAnalysis from 'components/OutageAnalysis';

import { request } from '../utils/Api';
import { StyledFooter, StyledHeader } from '../components/CaptureInterst';
import { getCurrentUser } from '../actions';
import { getOrgId } from '../utils/utility';
import download from '../images/download.svg';
import lock from '../images/filled_lock.svg';
import downimg from '../images/down_2.svg';
import Flex from '../components/styled/Flex';

// import { isThisSecond } from "date-fns";

class PastIncidents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      outageAnalysisData: {},
      paginate: {
        page: 1,
        pageLength: 10,
        totalRecords: 30
      }
    };
    this.onDownload = this.onDownload.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    // this.redirectoSettings = this.redirectoSettings.bind(this);
    // this.getIncidentCount = this.getIncidentCount.bind(this);
    this.addCommentToIncident = this.addCommentToIncident.bind(this);
    this.getUserReadableDate = this.getUserReadableDate.bind(this);
    this.getUserReadableTime = this.getUserReadableTime.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.getReadableDuration = this.getReadableDuration.bind(this);
    this.calculateDowntimeDuration = this.calculateDowntimeDuration.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.removeKeyword = this.removeKeyword.bind(this);
  }

  onDownload() {
    if (this.props.pricingPlan.toLowerCase() === 'garden')
      this.setState({ modalShow: true });
  }

  onHide() {
    this.setState({ modalShow: false });
  }

  async onDownloadClick() {
    // saveAs(
    //   `${API_ROOT}/incident-log-csv-download/?check_id=${
    //     this.props.checkID
    //   }&start_time=${this.props.startDate}&end_time=${this.props.endDate}`,
    //   "IncidentLog.csv"
    // );
    request(`/incident-log-csv-download/?check_id=${this.props.checkID}`, {
      method: 'GET'
    }).then(response => {
      // eslint-disable-next-line no-undef
      const blob = new Blob([response], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      saveAs(url, 'IncidentLog.csv');
    });

    this.setState({ modalShow: false });

    if (window._na && window._na_module) {
      window._na.sendFeatureEvent('Download Incidents', window._na_module);
    }
  }

  handleChange(evt, check) {
    const self = this;
    this.setState({ [evt.target.name]: evt.target.value }, function() {
      self.addCommentToIncident(this.state.comment, check);
    });
  }

  addCommentToIncident(comment, check) {
    const self = this;
    const data = {
      comment: this.state.comment
    };
    request(`/check-state-changes/${check.based_on}/`, {
      method: 'PATCH',
      payload: data
    })
      .catch(error => console.error('Error-->:', error))
      .then(() => {
        self.showMessage('', 'Saved');
        setTimeout(() => {
          self.setState({ msg: '' });
        }, 2000);

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent('Add Note', window._na_module);
        }
      });
  }

  getUserReadableDate(dateTime) {
    const currentOrg = filter(this.props.org, { id: Number(getOrgId()) });
    const timezone = currentOrg && currentOrg[0].timezone;
    const userReadableDate = momentTimezone
      .tz(dateTime, timezone)
      .format('MMMM D, YYYY');
    // const userReadableDate = moment(new Date(dateTime).toUTCString()).format(
    //   "MMMM D, YYYY"
    // );
    return userReadableDate;
  }

  getUserReadableTime(dateTime) {
    const currentOrg = filter(this.props.org, { id: Number(getOrgId()) });
    const timezone = currentOrg && currentOrg[0].timezone;
    const userReadableTime = momentTimezone
      .tz(dateTime, timezone)
      .format('hh:mm A z');
    // let userReadableTime = moment(new Date(dateTime).toUTCString()).format(
    //   "hh:mm A"
    // );
    return userReadableTime;
  }

  showMessage(e, msg, check) {
    if (check) {
      this.setState({ saving: check, msg });
    } else {
      this.setState({ msg });
    }
  }

  getReadableDuration(temp) {
    let result = '';
    result += temp.years()
      ? temp.years() + (temp.years() > 1 ? ' yrs ' : ' yr ')
      : '';
    result += temp.months()
      ? temp.months() + (temp.months() > 1 ? ' mos ' : ' mo ')
      : '';
    result += temp.days()
      ? temp.days() + (temp.days() > 1 ? ' days ' : ' day ')
      : '';
    result += temp.hours()
      ? temp.hours() + (temp.hours() > 1 ? ' hrs ' : ' hr ')
      : '';
    result += temp.minutes()
      ? temp.minutes() + (temp.minutes() > 1 ? ' mins' : ' min')
      : '';
    if (!result.length) {
      result += temp.seconds() ? `${temp.seconds()} secs` : '';
    }
    return result;
  }

  calculateDowntimeDuration(next, current) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(next)) {
      return 'Ongoing';
    }
    const duration = next.diff(current, 'seconds');
    const tempDuration = moment.duration(duration, 'seconds');
    return this.getReadableDuration(tempDuration);
  }

  // redirectoSettings() {
  //   window.location = `${window.location.origin}/settings/account`;
  // }

  // getIncidentCount() {
  //   let count = 0;
  //   // this.props.result
  //   //   ? this.props.result.map(item =>
  //   //       item.new_state === "RE" || item.new_state === "NR" ? count+=1: count+=0
  //   //     )
  //   //   : [];
  //   const itemArray = this.props.result;
  //   if (itemArray) {
  //     for (let i = 0; i < itemArray.length; i += 1) {
  //       if (
  //         itemArray[i].new_state === 'RE' ||
  //         itemArray[i].new_state === 'NR' ||
  //         itemArray[i].new_state === 'SME' ||
  //         itemArray[i].new_state === 'SCE'
  //       )
  //         count += 1;
  //     }
  //   }
  //   this.setState({ count });
  // }

  onPageChange({ page, pageLength }) {
    this.setState({
      paginate: {
        ...this.state.paginate,
        page,
        pageLength
      }
      // pastIncidentsData: null
    });
    this.props.getPastIncidentsData(this.props.checkID, page, pageLength);
  }

  removeKeyword() {
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this);
    // eslint-disable-next-line no-undef
    if (node instanceof HTMLElement) {
      const child = node.querySelector(
        '.paginations---pagination-records---YOUAz'
      );
      child.innerHTML = child.innerHTML
        .replace('Records: ', '')
        .replace('/', ' of ');
    }
  }

  componentDidMount() {
    this.removeKeyword();
    // this.getIncidentCount();
    this.props.getCurrentUser();
  }
  componentDidUpdate() {
    this.removeKeyword();
  }
  render() {
    const {
      result: response,
      checkData,
      edgeLocations,
      deviceSize,
      selectedCheck
    } = this.props;
    const noIncidents =
      response &&
      (response.length === 0 ||
        (response.length === 1 && response[0].new_state === 'AV'));
    return (
      <div
        className="sub-container"
        id="downtime-history"
        style={{ position: 'relative' }}
      >
        <Drawer
          isOpen={Boolean(this.state.outageAnalysisData.recorded_time)}
          onDismiss={() => this.setState({ outageAnalysisData: {} })}
          isFullHeight
          clickOutToClose={false}
          size="large"
          hideDismissIcon={false}
          placement="right"
        >
          <OutageAnalysis
            checkData={checkData}
            selectedCheck={selectedCheck}
            org={this.props.org}
            edgeLocations={edgeLocations}
            outageAnalysisData={this.state.outageAnalysisData}
          />
        </Drawer>
        <Flex
          justify="space-between"
          style={{
            borderBottom: '1px solid #ebeff3',
            alignItems: 'center'
          }}
        >
          <h4 style={{ marginBottom: '5px' }}>Incidents history</h4>
          <Download>
            {this.props.pricingPlan.toLowerCase() !== 'garden' ? (
              <Link to="/settings/pricing?interest=downloadincidentlog">
                <Button type="secondary">
                  <img src={lock} alt="lock" height="20px" width="12px" />
                  Download incidents
                </Button>
              </Link>
            ) : (
              <Button
                type="secondary"
                disabled={noIncidents}
                onClick={this.onDownload}
              >
                <img src={download} alt="download" />
                Download incidents
              </Button>
            )}
          </Download>
        </Flex>
        <ModalTransition>
          {this.state.modalShow && (
            <Modal
              heading={undefined}
              autoFocus={false}
              shouldCloseOnOverlayClick
              width="500px"
              components={{
                Header: () => <Header title="Download Confirmation" />,
                Footer: () => (
                  <Footer
                    close={this.onHide}
                    onSuccess={this.onDownloadClick}
                  />
                )
              }}
            >
              <StyledDownloadModal>
                You will download all {this.props.totalRecords} incidents on
                this check. Are you sure to download the csv file?
              </StyledDownloadModal>
            </Modal>
          )}
        </ModalTransition>
        <div className="divTable">
          <div className="divTableHeading">
            <div className="divTableRow">
              <div
                className="divTableHead"
                style={{
                  width: deviceSize === 'device-small' ? '33.33%' : '20%'
                }}
              >
                Date &amp; Time
              </div>
              <div
                className="divTableHead"
                style={{
                  width: deviceSize === 'device-small' ? '33.33%' : '20%'
                }}
              >
                Downtime
              </div>
              <div
                className="divTableHead"
                style={{
                  width: deviceSize === 'device-small' ? '33.33%' : '25%'
                }}
              >
                Response summary
              </div>
              {deviceSize && deviceSize === 'device-large' ? (
                <div className="divTableHead" style={{ width: '25%' }}>
                  Notes
                </div>
              ) : null}
              {deviceSize && deviceSize === 'device-large' ? (
                <div
                  className="divTableHead"
                  style={{ width: '10%', textAlign: 'right' }}
                ></div>
              ) : null}
            </div>
          </div>
          <div className="divTableBody">
            {response ? (
              response.map(
                (result, index) =>
                  result.new_state !== 'AV' && (
                    <div
                      className={`${result.new_state} divTableRow`}
                      key={`${result.basedon}-${index}`}
                    >
                      <div className="divTableCell">
                        <span className="dayFormat">
                          {result.check_start_time
                            ? this.getUserReadableDate(result.check_start_time)
                            : ''}
                        </span>
                        <span className="timeFormat">
                          {result.check_start_time
                            ? this.getUserReadableTime(result.check_start_time)
                            : ''}
                        </span>
                      </div>
                      <div className="divTableCell">
                        <div className="downtime-duration">
                          <img
                            src={downimg}
                            alt="down icon"
                            className="down-status-img"
                          />
                          <span>
                            {result.check_start_time
                              ? this.calculateDowntimeDuration(
                                  moment(
                                    result ? result.next_state_change_time : ''
                                  ),
                                  moment(result.check_start_time)
                                )
                              : ''}
                          </span>
                        </div>
                      </div>
                      <div className="divTableCell">
                        <span className="response-code">
                          {result.new_error_summary
                            ? result.new_error_summary
                            : ''}
                        </span>
                      </div>
                      {deviceSize && deviceSize === 'device-large' ? (
                        <div className="divTableCell">
                          <textarea
                            name="comment"
                            className="incidents-notes"
                            placeholder="Add notes"
                            onBlur={e => this.handleChange(e, result)}
                            onChange={e =>
                              this.showMessage(e, 'Saving…', result.based_on)
                            }
                            defaultValue={result.comment ? result.comment : ''}
                          />
                          <span className="incidents-saving-msg">
                            {result.based_on === this.state.saving &&
                              this.state.msg}
                          </span>
                        </div>
                      ) : null}
                      {deviceSize && deviceSize === 'device-large' ? (
                        <div className="divTableCell">
                          {!noIncidents && (
                            <Button
                              inline
                              type="link"
                              onClick={() =>
                                this.setState({ outageAnalysisData: result })
                              }
                            >
                              View Analysis
                            </Button>
                          )}
                        </div>
                      ) : null}
                    </div>
                  )
              )
            ) : (
              <Statusloader>
                <div className="indicator">
                  <svg width="16px" height="12px">
                    <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                    <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                  </svg>
                </div>
              </Statusloader>
            )}
            {noIncidents && (
              <div className="no-incidents">No incidents reported.</div>
            )}
          </div>
        </div>
        <Pagination>
          <div className={`pagination-container ${noIncidents ? 'hide' : ''}`}>
            <TablePagination
              type="minor"
              page={this.props.page}
              pageLength={
                this.props.totalRecords > 10
                  ? this.props.page.length
                  : this.props.totalRecords
              }
              totalRecords={this.props.totalRecords}
              onPageChange={this.onPageChange}
              prevPageRenderer={() => <i className="fa fa-angle-left" />}
              nextPageRenderer={() => <i className="fa fa-angle-right" />}
            />
          </div>
        </Pagination>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  org: state.user.org,
  edgeLocations: get(state, ['org', 'edgeLocations', 'results'], [])
});
export default withRouter(
  connect(mapStateToProps, { getCurrentUser })(PastIncidents)
);

const Header = props => (
  <StyledHeader>
    <h2> {props.title} </h2>
  </StyledHeader>
);

const Footer = props => (
  <StyledFooter>
    <Fragment>
      <button onClick={() => props.close()} className="secondary-btn">
        Cancel
      </button>
      <button onClick={() => props.onSuccess()} className="primary-btn">
        Download
      </button>
    </Fragment>
  </StyledFooter>
);

const StyledDownloadModal = styled.div`
  color: #6f7c87;
  padding: 10px 0 15px 0;
`;

const Download = styled.div`
  width: auto;
  button {
    user-select: none;
    :disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  img {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
  }
  a {
    display: inline-block;
    position: relative;
    z-index: 1;
    color: #222;
    border-radius: 5px;
    top: -1px;
  }
`;

const Pagination = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  .pagination-container.hide {
    visibility: hidden;
  }
  .pagination-container {
    width: 190px;
  }
  ul {
    position: relative;
    li {
      position: absolute;
      &:first-child {
        right: 125px;
      }
    }
  }

  .paginations---pagination-records---YOUAz {
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    width: 125px;
    text-align: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 18px !important;
  }

  .paginations---table-pagination-block---Cx6IH > div {
    margin: 8px 0;
  }
`;

const Statusloader = styled.div`
  min-height: 760px;
`;
