/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { get, filter } from 'lodash';
import momentTimezone from 'moment-timezone';
import { getOrgId } from 'utils/utility';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { ChevronDownBold } from 'components/Icons';
import { getCheckType } from 'utils/check';
import { CheckTypeCopy } from 'constants/check';

import {
  OutageCardContainer,
  Header,
  LinkURL,
  Content,
  ContentFooter,
  Toggle,
  FoldableRowContainer,
  ContentHeader
} from './styles';

const getHostname = url => {
  try {
    const { hostname } = new URL(url);
    return hostname;
  } catch (e) {
    return url;
  }
};

const getUserReadableDate = (dateTime, org) => {
  const currentOrg = filter(org, { id: Number(getOrgId()) });
  const timezone = currentOrg && currentOrg[0].timezone;
  const userReadableDate = momentTimezone
    .tz(dateTime, timezone)
    .format('MMMM D, YYYY');
  return userReadableDate;
};

const getUserReadableTime = (dateTime, org) => {
  const currentOrg = filter(org, { id: Number(getOrgId()) });
  const timezone = currentOrg && currentOrg[0].timezone;
  const userReadableTime = momentTimezone
    .tz(dateTime, timezone)
    .format('hh:mm:ss.SSS A z');
  return userReadableTime;
};

const OutageCard = props => {
  const { probeData, org, stateChangeAnalysis, name } = props;
  const [isCardOpen, toggleCard] = React.useState(true);
  const serverProbes = get(stateChangeAnalysis, ['server-probes'], []);
  const checkUrl = get(stateChangeAnalysis, ['check_data', 'url'], '');
  const responseTime = get(probeData, ['response_time'], undefined);
  const requestTimeout = get(
    stateChangeAnalysis,
    ['check_data', 'request_timeout'],
    undefined
  );
  const errorSummary = get(probeData, ['error_summary'], undefined);
  const edgeSourceName = get(probeData, ['source'], '');
  const defaultEdgeLocation = (serverProbes || []).find(
    ([serverName]) => serverName === 'US East (North Virginia)'
  );
  const edgeLocation = (serverProbes || []).find(
    ([serverName]) => serverName === edgeSourceName
  );
  const edgeLocationName = `${get(
    edgeLocation,
    [0],
    get(defaultEdgeLocation, [0], 'US East (North Virginia)')
  )} - (${get(
    edgeLocation,
    [1],
    get(defaultEdgeLocation, [1], '35.173.69.86')
  )})`;
  const checkType = getCheckType(checkUrl);
  // console.log('checkType', checkType);
  return (
    <OutageCardContainer>
      <ReactTooltip isMultiline place="bottom" type="dark" effect="solid" />
      <Header>
        <Toggle
          rotate={isCardOpen ? 0 : -90}
          onClick={() => toggleCard(!isCardOpen)}
        >
          <ChevronDownBold />
        </Toggle>
        <div className="title">
          <div className="heading">{name}</div>
          <div className="timestamp">
            {`${
              probeData.check_start_time
                ? getUserReadableDate(probeData.check_start_time, org)
                : ''
            }
          ${
            probeData.check_start_time
              ? getUserReadableTime(probeData.check_start_time, org)
              : ''
          }`}
          </div>
        </div>
        <div className="desc">
          <div className="status-box">
            <span className="status-txt">Status : &nbsp;</span>
            <span title={errorSummary || 'Error'} className="error">
              {errorSummary || 'Error'}
            </span>
          </div>
          <div className="location">
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.17108 7.48981C3.23408 7.56381 4.62908 9.38581 5.98108 11.1538C7.09408 9.70081 8.74708 7.54281 8.81808 7.45681C10.0271 6.00781 9.92808 3.82381 8.59008 2.48681C7.15208 1.04781 4.81108 1.04781 3.37108 2.48681C2.01908 3.83981 1.93108 6.03681 3.17108 7.48981ZM5.98108 13.0068C5.76308 13.0068 5.55708 12.9048 5.42508 12.7308C4.15708 11.0738 2.23708 8.56481 2.10108 8.39281C0.395077 6.38781 0.518077 3.36081 2.38108 1.49681C4.36508 -0.488193 7.59608 -0.488193 9.58008 1.49681C11.4561 3.37181 11.5901 6.31881 9.89308 8.35381C9.74808 8.53481 6.53608 12.7318 6.53608 12.7318C6.40408 12.9048 6.19808 13.0068 5.98108 13.0068Z"
                fill="#264966"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.98046 4.3462C5.64947 4.3462 5.38047 4.6162 5.38047 4.9472C5.38047 5.2782 5.64947 5.5462 5.98046 5.5462C6.31146 5.5462 6.58046 5.2782 6.58046 4.9472C6.58046 4.6162 6.31146 4.3462 5.98046 4.3462ZM5.98047 6.9472C4.87747 6.9472 3.98047 6.0492 3.98047 4.9472C3.98047 3.8432 4.87747 2.9462 5.98047 2.9462C7.08347 2.9462 7.98047 3.8432 7.98047 4.9472C7.98047 6.0492 7.08347 6.9472 5.98047 6.9472Z"
                fill="#264966"
              />
            </svg>
            &nbsp;
            <span data-tip={`Check Location : ${edgeLocationName}`}>
              {edgeLocationName}
            </span>
          </div>
        </div>
      </Header>
      {isCardOpen && (
        <Content>
          <ContentHeader>
            <div className="link-time">
              <LinkURL>{checkUrl}</LinkURL>
              <div className="time">{`${requestTimeout || 30}s`}</div>
            </div>
            <div className="chip-container">
              <div className="chip">
                {CheckTypeCopy[checkType] || CheckTypeCopy.https}
              </div>
              <div className="chip">GET</div>
            </div>
          </ContentHeader>
          {/* <FoldableRow label="Lookup IP" value="1 ms" /> */}
          {/* <FoldableRow label="Connection to 193.34.76.44" value="111 ms" /> */}
          {responseTime && (
            <FoldableRow
              label="Send Request"
              value={`${responseTime || 0} ms`}
              subValues={[]}
              // subValues={[
              //   {
              //     label: 'Error',
              //     value: 'Request timeout - 30 sec'
              //   }
              // ]}
            />
          )}
          <ContentFooter>
            <div className="footer-title">Request header</div>
            <div className="row-data">
              <div>Host :</div>
              <LinkURL>{getHostname(checkUrl)}</LinkURL>
            </div>
            <div className="row-data">
              <div>User-Agent :</div>
              <div className="bold">
                {get(stateChangeAnalysis, ['user-agent'], 0)}
              </div>
            </div>
            <div className="row-data">
              <div>Accept-Encoding :</div>
              <div className="bold">gzip,deflate,identity</div>
            </div>
          </ContentFooter>
        </Content>
      )}
    </OutageCardContainer>
  );
};

const FoldableRow = ({ label = '', value = '', subValues = [] }) => {
  const [isCardOpen, toggleCard] = React.useState(false);
  const enableToggle = subValues.length > 0;
  return (
    <FoldableRowContainer>
      <div className="foldable-header">
        <Toggle
          className="foldable-toggle"
          rotate={isCardOpen ? 0 : -90}
          onClick={() => enableToggle && toggleCard(!isCardOpen)}
        >
          {enableToggle && <ChevronDownBold />}
        </Toggle>
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </div>
      {isCardOpen && subValues.length > 0 && (
        <div className="foldable-body">
          {subValues.map(({ label: l, value: v }) => (
            <div className="foldable-row">
              <div className="foldable-label">{l}</div>
              <div className="foldable-value">{v}</div>
            </div>
          ))}
        </div>
      )}
    </FoldableRowContainer>
  );
};

OutageCard.propTypes = {
  stateChangeAnalysis: PropTypes.any,
  probeData: PropTypes.any,
  org: PropTypes.any,
  name: PropTypes.string
};
OutageCard.defaultProps = {
  stateChangeAnalysis: {},
  probeData: {},
  org: {},
  name: ''
};

export default OutageCard;
