import React from 'react';
import styled from 'styled-components';
import { FILTER_TABS } from '../constants/constant';

const FilterTabs = ({ onChange, selectedItem, planname }) => {
  return (
    <FlexRow>
      {FILTER_TABS.map(
        filter =>
          (filter.plan === 'ALL' || filter.plan == planname) && (
            <Pill
              active={filter.id === selectedItem}
              onClick={() => onChange('status', filter.id)}
              key={filter.id}
            >
              <span>{filter.label}</span>
            </Pill>
          )
      )}
    </FlexRow>
  );
};

export default FilterTabs;

const Pill = styled.div`
  cursor: pointer;
  padding: 6px 13px;
  margin-left: 6px;

  min-width: 75px;
  text-align: center;
  font-weight: 600;
  user-select: none;

  span {
    display: inline-block;
    padding: 8px;
    color: ${props => (props.active ? 'var(--text-link)' : '#6f7c87')};
  }

  &:after {
    background-color: ${props => (props.active ? 'var(--text-link)' : '#fff')};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    content: '';
    display: block;
    height: 3px;
  }

  &:hover {
    &:after {
      background-color: ${props =>
        props.active ? 'var(--text-link)' : 'var(--border-color)'};
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 11px 7px;
`;
