import React from 'react';
import Layout from '../components/Layout';
import ExploreMain from '../containers/ExploreMain';

const Explore = () => (
  <Layout page="EXPLORE">
    <ExploreMain />
  </Layout>
);

export default Explore;
