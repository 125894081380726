import styled, { css } from 'styled-components';

import { color, font } from 'shared/utils/styles';

export const StyledInput = styled.div`
  position: relative;
  display: inline-block;
  height: 32px;
  width: 100%;
`;

export const InputElement = styled.input`
  height: 100%;
  width: 100%;
  padding: 0 7px;
  min-height: 37px;
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid ${color.borderLightest};
  color: ${color.textDarkest};
  // background: ${color.backgroundLightest};
  transition: all 0.1s;
  ${font.regular}
  ${font.size(14)}
  ${props => props.hasIcon && 'padding-left: 32px;'}
  &:hover {
    border-bottom-color: var(--text-link);
  }
  &:focus {
    border-bottom-color: var(--text-link);
  }
  ${props =>
    props.invalid &&
    css`
      &,
      &:focus {
        border-bottom: 1px solid ${color.danger};
        box-shadow: none;
      }
    `}
`;
