import styled from 'styled-components';

export const StyledSlider = styled.div`
  position: relative;
  display: flex;
  height: 45px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  [data-reach-slider-handle] {
    border: 2px solid var(--text-link);
  }

  [data-reach-slider-track-highlight] {
    background: var(--text-link);
  }
  .rc-input-number-handler-wrap {
    border: 0;
  }
  .rc-input-number {
    border: 0;
    border-radius: 0;
  }
  .rc-input-number-input-wrap {
    border-bottom: solid 1px #d6d6d6;
  }

  .metric {
    position: absolute;
    right: 22px;
    height: 25px;
    display: flex;
    align-items: center;
  }

  .input-wrap {
    display: flex;
    position: relative;
  }

  .rc-input-number-handler {
    display: flex;
    border: 0;
    justify-content: center;
    align-items: center;
  }
`;

export default {};
