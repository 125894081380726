import { useQuery, useMutation } from 'react-query';
import { request } from 'utils/Api';
import queryClient from 'utils/client';
import { toastr } from 'react-redux-toastr';
import {
  nameOfFieldsNotToShow,
  nameOfFieldsToChange
} from './IntegrationForm/constants';
import { getChoices, isMandatory } from './IntegrationForm/utils';

export const useFdIntegration = () =>
  useQuery('fdIntegration', async () => {
    const response = await request('/integrations/freshdesk-alerters/');
    return response && response.results && response.results.length > 0
      ? response.results
      : [];
  });

export const useFdFields = ({
  freshdesk_api_key,
  freshdesk_domain,
  is_valid,
  id,
  data_json
}) =>
  useQuery(['fdFields', `${id}`], async () => {
    if (!is_valid || !freshdesk_api_key || !freshdesk_domain) return [];
    const response = await request('/integrations/freshdesk-form-fields/', {
      method: 'POST',
      payload: {
        data_json,
        api_key: freshdesk_api_key,
        domain: freshdesk_domain
      }
    });
    return ((response && response.data_json) || [])
      .filter(field => !nameOfFieldsNotToShow.includes(field.name))
      .map(field => ({
        ...field,
        // The create api fields are different from field list apis
        name: nameOfFieldsToChange[field.name] || field.name,
        options: getChoices(field.choices),
        isMandatory: Boolean(isMandatory(field)),
        type: field.type && field.type.slice(field.type.indexOf('_') + 1)
      }))
      .sort((a, b) => b.isMandatory - a.isMandatory);
  });

export const useAddFdIntegration = ({ updateIntegrationCount } = {}) =>
  useMutation(
    async ({ payload }) => {
      const response = await request('/integrations/freshdesk-alerters/', {
        method: 'POST',
        payload
      });
      return response;
    },
    {
      onSuccess: (response, { actions, onCreate, fetchFields }) => {
        if (updateIntegrationCount) updateIntegrationCount(false);
        if (actions) actions.setSubmitting(false);
        if (response.freshdesk_api_key && response.freshdesk_domain) {
          queryClient.invalidateQueries('fdIntegration');
          toastr.success('Freshdesk authenticated successfully');
          if (onCreate) onCreate(response);
          if (fetchFields)
            queryClient.invalidateQueries(['fdFields', `${response.id}`]);

          if (window._na && window._na_module) {
            window._na.sendFeatureEvent('Freshdesk Create Integration', window._na_module);
          }
        } else {
          toastr.error('Freshdesk authentication failed');
        }
      },
      onError: () => {
        if (updateIntegrationCount) updateIntegrationCount(false);
        toastr.error('Freshdesk authentication failed');
      }
    }
  );

export const useSaveFdIntegration = () =>
  useMutation(
    async ({ id, payload }) => {
      const response = await request(
        `/integrations/freshdesk-alerters/${id}/`,
        {
          method: 'PATCH',
          payload
        }
      );
      if ((response && response.status > 399) || response === undefined)
        throw new Error(JSON.stringify((response && response.response) || {}));
      return response;
    },
    {
      onSuccess: (response, { id, fetchFields, statusChange, actions }) => {
        queryClient.setQueryData('fdIntegration', integrations =>
          integrations.map(integration =>
            integration.id === response.id ? response : integration
          )
        );
        let text = 'Freshdesk integration modified';
        if (fetchFields) {
          queryClient.invalidateQueries(['fdFields', `${id}`]);
        }
        if (statusChange) {
          text = `Freshdesk integration ${response.freshdesk_domain} ${
            response.state === 'AC' ? 'enabled' : 'disabled'
          }`;
        }
        if (actions) actions.setSubmitting(false);
        toastr.success(text);

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent('Freshdesk Edit Integration', window._na_module);
        }
      },
      onError: (error, { actions, mutate = false }, snapshotValue) => {
        const errorMessage = JSON.parse((error && error.message) || '{}');
        if (actions) actions.setSubmitting(false);
        if (mutate) queryClient.setQueryData('fdIntegration', snapshotValue);
        toastr.error(
          (errorMessage &&
            errorMessage.non_field_errors &&
            errorMessage.non_field_errors[0]) ||
            'Save freshdesk integration failed'
        );
      },
      onMutate: ({ payload, id, mutate = false }) => {
        if (!mutate) return {};
        const previousValue = queryClient.getQueryData('fdIntegration');
        queryClient.setQueryData('fdIntegration', integrations =>
          integrations.map(integration =>
            integration.id === id ? { ...integration, ...payload } : integration
          )
        );
        return previousValue;
      }
    }
  );

export const useDeleteFdIntegration = ({ updateIntegrationCount } = {}) =>
  useMutation(
    async id => {
      await request(`/integrations/freshdesk-alerters/${id}/`, {
        method: 'DELETE'
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fdIntegration');
        toastr.success('Freshdesk integration deleted');
        if (updateIntegrationCount) updateIntegrationCount(false);

        if (window._na && window._na_module) {
          window._na.sendFeatureEvent('Freshdesk Delete Integration', window._na_module);
        }
      }
    }
  );
