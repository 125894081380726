export default {
  fontFamily: `-apple-system, ".SFNSText-Regular", "San Francisco", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Lucida Grande", Arial, sans-serif`,

  headings: {
    h1: {
      fontSize: '44px',
      lineHeight: `${44 * 1.5}px`,
      margin: '0px'
    },
    h2: {
      fontSize: '32px',
      lineHeight: `${32 * 1.5}px`,
      margin: '0px'
    },
    h3: {
      fontSize: '24px',
      lineHeight: `${24 * 1.5}px`,
      margin: '0px'
    },
    h4: {
      fontSize: '18px',
      lineHeight: `${18 * 1.3}px`,
      margin: '0px'
    },
    h5: {
      fontSize: '16px',
      lineHeight: `${16 * 1.3}px`,
      margin: '0px'
    }
  },

  text: {
    regular: {
      fontWeight: 400
    },
    semiBold: {
      fontWeight: 500
    },
    bold: {
      fontWeight: 600
    }
  },

  body: {
    regular: {
      fontSize: '14px',
      lineHeight: `${14 * 1.3}px`,
      margin: '0px'
    },
    regularBold: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: `${14 * 1.5}px`,
      margin: '0px'
    },
    secondary: {
      fontSize: '12px'
    },
    secondaryBold: {
      fontSize: '12px',
      lineHeight: `${12 * 1.5}px`,
      fontWeight: 600
    },
    dropdown: {
      fontSize: '13px',
      lineHeight: '30px'
    },
    label: {
      fontSize: '12px',
      lineHeight: '19px',
      fontWeight: 500
    },
    requiredLabel: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '14px'
    },
    errorText: {
      fontSize: '12px'
    },
    anchor: {
      light: {
        fontSize: '14px',
        lineHeight: '17px'
      },
      bold: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '15px'
      }
    },
    tagText: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: '500',
      letterSpacing: '0.2px'
    }
  },

  input: {
    text: {
      fontSize: '14px',
      lineHeight: '32px',
      fontWeight: '500'
    },
    textarea: {
      fontSize: '14px',
      lineHeight: '1.4',
      fontWeight: '500'
    },
    disabled: {
      fontWeight: 'bold'
    },
    label: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600
    },
    error: {
      fontSize: '12px',
      lineHeight: '17px'
    }
  },
  table: {
    // fontWeight: 600,
    header: {
      fontWeight: 800,
      fontSize: '12px',
      padding: '16px',
      lineHeight: '16px'
    },
    body: {
      fontWeight: 400,
      fontSize: '14px',
      padding: '12px',
      lineHeight: '20px'
    }
  },
  tabs: {
    fontSize: '14px',
    padding: '8px'
  },
  menu: {
    padding: '8px',
    menuItem: {
      fontWeight: 500,
      fontSize: '14px',
      padding: '8px'
    }
  }
};
