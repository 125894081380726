import { call, put, take } from "redux-saga/effects";
import { push } from "react-router-redux";
import { request } from "../utils/Api";
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE
} from "../constants/actions";

export default function* loginUser() {
  const {
    payload: { values }
  } = yield take(LOGIN_USER_REQUEST);
  const response = yield call(request, "/user-login/", {
    method: "POST",
    payload: values
  });

  if (response && Object.keys(response).length && response.token) {
    yield put({
      type: LOGIN_USER_SUCCESS,
      payload: { token: response.token }
    });
    localStorage.setItem("auth-token", response.token);
    yield put(push("/"));
  } else {
    yield put({
      type: LOGIN_USER_FAILURE,
      payload: response
    });
  }
}
