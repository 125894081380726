import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import {
  getAccountApplications,
  getChecks,
  goOnline,
  getCurrentUserOrg,
  setFilterState
} from '../actions';
import ApplicationStatus from '../components/ApplicationStatus';
import ApplicationList from '../components/ApplicationList';
import emptyLogo from '../images/emptyCheck.png';
import '../styles/Checks.css';
import styled from 'styled-components';
import filter from 'lodash.filter';
import { getOrgId } from '../utils/utility';
import { toastr } from 'react-redux-toastr';
import Pagination from '../components/Pagination';
import { request } from '../utils/Api';

const quotes = [
  '“Devs are from Venus, Ops are from Mars” - Steven Haines',
  '“Develop systems, not software” - Ben Butler Cole',
  '“You build it, you run it” - Werner Vogels',
  '“The most powerful tool we have as developers is automation” - Scott Hanselman',
  '“If you automate a mess, you get an automated mess” - Rod Michael',
  '“DevOps is not a goal, but a never-ending process of continual improvement” - Jez Humble'
];
const RandomQuote = quotes[Math.floor(Math.random() * quotes.length)];

const bulkActionTxtCopy = {
  delete: {
    url: '/bulk-delete-checks/',
    toaster: 'check(s) deleted successfully'
  },
  pause: {
    url: '/pause-monitoring-bulk/',
    toaster: 'check(s) paused successfully'
  },
  restart: {
    url: '/pause-monitoring-bulk/',
    toaster: 'check(s) restarted successfully'
  }
};

class Checks extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      isOpen: false,
      selectedChecks: [],
      checkAll: false,
      deviceSize: null
    };

    window.addEventListener('resize', this.handleResize.bind(this));
  }

  handleResize = () => {
    let deviceSize = 'device-large';

    if (typeof window !== 'undefined' && window.innerWidth < 1000) {
      deviceSize = 'device-small';
    }

    this.setState({ deviceSize: deviceSize });
  };

  // handleClick = () => {
  //   this.setState({ show: true });
  //   document.getElementById('add-check-modal').style.display = 'block';

  //   if (window.fcWidget) {
  //     window.fcWidget.hide();
  //   }
  //   document.body.classList.add('remove-overflow');
  //   if (document.getElementById('add-check-modal')) {
  //     var modal = document.getElementById('add-check-modal');
  //     document.getElementById('app-hidden').setAttribute('method', 'POST');
  //     modal.getElementsByClassName('modal-header')[0].innerHTML =
  //       '<h4>Add Check</h4>';
  //     document.getElementById('add-new-check-form').reset();
  //   }
  // };

  open = () => this.setState({ isOpen: true });

  close = () => this.setState({ isOpen: false });

  onpaginate = page => {
    this.checkSelectAll(false);
    this.setState({ page });
    this.props.setFilterState({ page });
  };

  checkFirst = () => {
    if (localStorage.getItem('upgrade') !== null) {
      this.setState({
        isOpen: localStorage.getItem('upgrade') !== 'SPROUT',
        planname: localStorage.getItem('upgrade')
      });
      localStorage.removeItem('upgrade');
    }
  };

  checkSelectAll = state => this.setState({ checkAll: state });

  updateChecks = (checks, state) => {
    let activeChecks = this.state.selectedChecks.length
      ? [...this.state.selectedChecks]
      : [];
    if (typeof checks === 'number') {
      const index = activeChecks.indexOf(checks);
      if (state && index === -1) {
        activeChecks.push(checks);
      } else if (!state && index > -1) {
        activeChecks.splice(index, 1);
      }
    } else if (!checks.length && !state) {
      activeChecks = checks;
    } else {
      checks.map(check => {
        const index = activeChecks.indexOf(check);
        if (state && index === -1) {
          activeChecks.push(check);
        }
        // else if (!state && index > -1) {
        //   activeChecks.splice(index, 1);
        // }
        return check;
      });
    }
    this.setState({
      selectedChecks: activeChecks,
      selectStatus: activeChecks.length ? 'updated' : 'empty'
    });
    this.checkForCheckall();
  };

  bulkActions = async type => {
    const { selectedChecks } = this.state;
    const payload =
      type === 'delete'
        ? {
            organization: Number(getOrgId()),
            checks: selectedChecks
          }
        : {
            application_checks: selectedChecks,
            new_state: type === 'pause' ? 'PS' : 'AC'
          };
    const response = await request(bulkActionTxtCopy[type].url, {
      method: 'POST',
      payload
    });
    
    if (document.getElementById('checkall') !== null)
      document.getElementById('checkall').checked = false;

    if (!response.errors) {
      toastr.success(
        `${
          payload.checks
            ? payload.checks.length
            : payload.application_checks.length
        } ${bulkActionTxtCopy[type].toaster}`
      );
      this.setState({ selectedChecks: [] });
      this.props.getCurrentUserOrg();
      this.props.setFilterState(
        type === 'delete'
          ? { page: null }
          : { status: this.props.filterParams.status }
      );

      if (window._na && window._na_module) {
        window._na.sendFeatureEvent(
          type === "delete" ? "Bulk Delete" : type === "pause" ? "Bulk Pause" : "Bulk Restart",
          window._na_module
        );
      }
    }
  };

  componentDidMount() {
    // this.props.getAccountApplications();
    this.props.getChecks();
    this.setState({
      currentOrg: Number(getOrgId())
    });
    this.checkFirst();
  }

  checkForCheckall = () => {
    const domLength = document.querySelectorAll("input[name='checks']:checked")
      .length;
    const checksLength = this.props.checks && this.props.checks.length;
    this.setState({
      checkAll:
        checksLength && domLength && checksLength === domLength ? true : false
    });
  };

  componentWillMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    document.body.classList.remove('remove-overflow');
  }

  render() {
    const {
      applications,
      // checksStatusesLoaded,
      checks,
      loading,
      org,
      filterParams,
      totalChecks
    } = this.props;
    const { selectedChecks, deviceSize } = this.state; //isOpen, planname,
    let isAdmin = false;
    org &&
      org.forEach(el => {
        if (
          el.id === this.state.currentOrg &&
          el.logged_in_user_role === 'AD'
        ) {
          isAdmin = true;
        }
      });

    const currentOrg = filter(org, { id: Number(getOrgId()) })[0];

    return (
      <Fragment>
        <ApplicationStatus
          goOnline={this.props.goOnline}
          org={currentOrg}
          selectedChecks={selectedChecks}
          bulkActions={this.bulkActions}
          selectStatus={this.state.selectStatus}
          deviceSize={deviceSize}
        />
        {loading && checks.length === 0 ? (
          <div className="page-loader">
            <div className="Check-Card-Loader">
              <div className="indicator">
                <svg width="16px" height="12px">
                  <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                  <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                </svg>
              </div>
            </div>
            <h2 className="loading-text">{RandomQuote}</h2>
          </div>
        ) : !loading && checks.length === 0 ? (
          <StyledEmptyContainer>
            <img
              src={emptyLogo}
              className="emptylogo"
              alt="Dashboard no checks"
            />
            <div className="empty-check_text">
              {filterParams.search.length || filterParams.status.length
                ? 'No Checks found'
                : "Seems like you haven't added any check to your account"}
            </div>
            {/* {isAdmin &&
              !filterParams.search.length &&
              !filterParams.status.length && (
                <button
                  id="add-check"
                  className="add-new-check-btn"
                  onClick={this.handleClick.bind(this)}
                >
                  Add Check
                </button>
              )} */}
          </StyledEmptyContainer>
        ) : (
          <Fragment>
            <ApplicationList
              applications={applications}
              checks={checks}
              updateChecks={this.updateChecks}
              selectedChecks={selectedChecks}
              checkSelectAll={this.checkSelectAll}
              checkAllState={this.state.checkAll}
              checkForCheckall={this.checkForCheckall}
              deviceSize={deviceSize}
            />
            <PageContainer>
              <Pagination
                org={currentOrg}
                params={filterParams}
                onpaginate={this.onpaginate}
                totalChecks={totalChecks}
              />
            </PageContainer>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  applications: state.applications.applications,
  isLoading: state.applications.isLoading,
  hasErrored: state.applications.hasErrored,
  checks: state.checks.checks,
  checksStatusesLoaded: state.checks.allStatusLoaded,
  loading: state.checks.isLoading,
  filterParams: state.checks.filterParams,
  org: state.user.org,
  totalChecks: state.checks.totalChecks
});

const mapDispatchProps = dispatch => ({
  getAccountApplications: () => dispatch(getAccountApplications()),
  getChecks: filter => dispatch(getChecks(filter)),
  goOnline: () => dispatch(goOnline()),
  getCurrentUserOrg: () => dispatch(getCurrentUserOrg()),
  setFilterState: filter => dispatch(setFilterState(filter))
});

export default connect(mapStateToProps, mapDispatchProps)(Checks);

const StyledEmptyContainer = styled.div`
  display: block;
  margin: 20px auto;
  text-align: center;
  .emptylogo {
    width: 25%;
  }
  .empty-check_text {
    margin-top: 15px;
    color: #6f7c87;
  }

  .empty-check_tip {
    font-size: 12px;
    color: #6f7c87;
  }
`;

const PageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  width: 100%;
  margin-bottom: 75px;
  display: flex;
  flex-direction: row-reverse;
`;
