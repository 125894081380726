import React from 'react';
import {
  Toggle,
  Popover,
  Modal,
  Drawer,
  Menu,
  Button,
  Confirmation
} from 'freemium-ui';
import webhook from 'images/webhook.svg';
import Loader from 'components/Loader';
import optionDot from 'images/option-dot.svg';
import { useChecks } from 'shared/hooks';

import CreateIntegration from './CreateIntegration';
import {
  Card,
  CardHeader,
  CardList,
  Center,
  TestCell,
  ModalBody,
  ModalFooter,
  EmptyData,
  IconButton,
  ModalContent
} from './styles';
import {
  useAddWebhookIntegration,
  useDeleteWebhookIntegration,
  useWebhookIntegration,
  useSaveWebhookIntegration,
  useTestWebhookIntegration
} from './hooks';

const options = [
  {
    id: 'edit',
    name: 'Edit'
  },
  {
    id: 'clone',
    name: 'Clone'
  },
  {
    id: 'delete',
    name: 'Delete'
  }
];

const WebhookIntegration = props => {
  const {
    updateIntegrationCount = () => {},
    isIntegrationAllowed = true
  } = props;
  const [showDeletePopup, toggleDeletePopup] = React.useState(false);
  const [openDrawer, toggleDrawer] = React.useState(false);
  const [openModal, toggleModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [webhookIntegration, setWebhookIntegration] = React.useState({});

  const { data: checks = [], isLoading: isLoadingChecks } = useChecks();
  const {
    data: webhookIntegrations = [],
    isError: isWebhookError,
    isLoading: isLoadingWebhookIntegrations
  } = useWebhookIntegration();
  const {
    mutate: addIntegration,
    isLoading: isAdding
  } = useAddWebhookIntegration({
    updateIntegrationCount
  });
  const {
    mutate: saveIntegration,
    isLoading: isSaving,
    variables: savePayload
  } = useSaveWebhookIntegration();
  const {
    mutate: testIntegration,
    data: testResponse,
    variables: testPayload,
    isSuccess: isTestSuccess,
    isLoading: isTesting
  } = useTestWebhookIntegration({
    callback: () => {
      toggleModal(true);
    }
  });
  const {
    mutate: deleteIntegration,
    isLoading: isDeleting,
    variables: deleteId
  } = useDeleteWebhookIntegration({ updateIntegrationCount });

  const onCreate = () => {
    toggleDrawer(false);
    setWebhookIntegration({});
  };

  const handleMenuOption = (option, selectedIntegration) => {
    if (anchorEl) setAnchorEl(null);
    const { id, ...payload } = selectedIntegration;
    switch (option.id) {
      case 'edit':
        toggleDrawer(true);
        setWebhookIntegration(selectedIntegration);
        break;
      case 'save':
        saveIntegration({
          id,
          payload: {
            state: selectedIntegration.state === 'AC' ? 'DA' : 'AC'
          },
          mutate: true,
          statusChange: true
        });
        break;
      case 'clone':
        toggleDrawer(true);
        setWebhookIntegration({ ...payload, clone: true });
        break;
      case 'delete':
        toggleDeletePopup(true);
        setWebhookIntegration(selectedIntegration);
        break;
      default:
    }
  };

  React.useEffect(() => {
    if (webhookIntegrations.length > 0 && webhookIntegration.id) {
      const newWebhookIntegration = webhookIntegrations.find(
        i => i.id === webhookIntegration.id
      );
      setWebhookIntegration(newWebhookIntegration);
    }
  }, [webhookIntegrations]);

  const testData =
    testResponse && testResponse.test_data
      ? JSON.parse(testResponse.test_data)
      : {};

  return (
    <Card>
      <Modal
        isOpen={openModal && isTestSuccess}
        onDismiss={() => toggleModal(false)}
      >
        <ModalBody>
          <div className="modal-title">Webhook Test Data</div>
          <div className="modal-description">
            Webhook test data has been sent.
          </div>
          <pre>{JSON.stringify(testData, null, 4)}</pre>
        </ModalBody>
        <ModalFooter>
          <div />
          <div>
            <Button type="secondary" inline onClick={() => toggleModal(false)}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Confirmation
        isOpen={showDeletePopup && webhookIntegration.id}
        onDismiss={() => {
          toggleDeletePopup(false);
        }}
        title="Delete Confirmation"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        onConfirm={() => {
          deleteIntegration(webhookIntegration.id);
          setWebhookIntegration({});
          toggleDrawer(false);
          toggleDeletePopup(false);
        }}
      >
        Are you sure you want to delete{' '}
        <strong>{webhookIntegration.name}</strong> webhook integration?
      </Confirmation>
      <CardHeader>
        <div>
          <img src={webhook} alt="webhook Logo" />
          <span>Webhook</span>
        </div>
        <div>
          <Button
            size="normal"
            onClick={() => {
              toggleDrawer(true);
              setWebhookIntegration({});
            }}
            disabled={
              !isIntegrationAllowed ||
              isWebhookError ||
              isLoadingWebhookIntegrations
            }
            className={
              !isIntegrationAllowed ||
              isWebhookError ||
              isLoadingWebhookIntegrations
                ? 'disabled'
                : ''
            }
            inline
            type="link"
          >
            &#43; Create Integration
          </Button>
        </div>
      </CardHeader>
      {!isLoadingWebhookIntegrations && webhookIntegrations.length > 0 ? (
        <CardList>
          <colgroup>
            <col span="1" style={{ width: 'auto' }} />
            <col span="1" style={{ width: 'auto' }} />
            <col span="1" style={{ width: '10%' }} />
            <col span="1" style={{ width: '10%' }} />
            <col span="1" style={{ width: '10%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Name</th>
              <th>URL</th>
              <th className="center">Test</th>
              <th>Status</th>
              <th className="center">Action</th>
            </tr>
          </thead>
          <tbody>
            {webhookIntegrations.map(integration => (
              <tr key={integration.id}>
                <td>{integration.name}</td>
                <td>
                  <div className="name">
                    <span title={integration.url}>{integration.url}</span>
                  </div>
                </td>
                <td className="center">
                  {isTesting && testPayload.id === integration.id ? (
                    <Loader width="16px" height="16px" />
                  ) : (
                    <TestCell
                      onClick={() =>
                        testIntegration({
                          id: integration.id,
                          meta: integration.meta
                        })
                      }
                    >
                      Test
                    </TestCell>
                  )}
                </td>
                <td>
                  <Toggle
                    on={integration.state === 'AC'}
                    hasIcon={false}
                    handleChange={() =>
                      handleMenuOption({ id: 'save' }, integration)
                    }
                    disabled={
                      (isSaving && savePayload.id === integration.id) ||
                      (isDeleting && integration.id === deleteId)
                    }
                  />
                </td>
                <td className="center">
                  <IconButton
                    role="button"
                    tabIndex={0}
                    onClick={e => setAnchorEl(e.currentTarget)}
                    onKeyDown={({ keyCode, currentTarget }) => {
                      if (keyCode === 13) setAnchorEl(currentTarget);
                    }}
                    aria-describedby={`${integration.id}`}
                  >
                    <img src={optionDot} alt="." />
                  </IconButton>
                  <Popover
                    id={`${integration.id}`}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <Menu
                      options={
                        !isIntegrationAllowed || isWebhookError
                          ? options.filter(option => option.id !== 'clone')
                          : options
                      }
                      onSelect={v => handleMenuOption(v, integration)}
                    />
                  </Popover>
                </td>
              </tr>
            ))}
          </tbody>
        </CardList>
      ) : (
        (isLoadingWebhookIntegrations || isLoadingChecks) && (
          <EmptyData>
            <Center>
              <Loader />
            </Center>
          </EmptyData>
        )
      )}
      <Drawer
        isOpen={openDrawer}
        onDismiss={() => toggleDrawer(false)}
        isFullHeight
        size="medium"
        clickOutToClose={false}
        placement="right"
      >
        <ModalContent>
          <CreateIntegration
            onClose={() => {
              toggleDrawer(false);
              setWebhookIntegration({});
            }}
            addIntegration={addIntegration}
            saveIntegration={saveIntegration}
            deleteIntegration={() => {
              handleMenuOption({ id: 'delete' }, webhookIntegration);
            }}
            checks={checks}
            onCreate={onCreate}
            isAdding={isAdding}
            isSaving={isSaving}
            isDeleting={isDeleting}
            webhookIntegration={webhookIntegration}
          />
        </ModalContent>
      </Drawer>
    </Card>
  );
};

export default WebhookIntegration;
