import React from "react";
import { Transition } from "react-transition-group";
import { StyledSlider } from "./style";

export const transitionDuration = "0.22s";
export const transitionDurationMs = 220;
export const transitionTimingFunction = "cubic-bezier(0.2, 0, 0, 1)";

// Transitions
// ------------------------------
function TransitionHandler(props) {
  const {
    in: inProp,
    defaultStyles,
    transitionStyles,
    transitionProps,
    ...restProps
  } = props;
  const timeout = { enter: 0, exit: transitionDurationMs };

  return (
    <Transition in={inProp} timeout={timeout} {...transitionProps}>
      {state => {
        const style = {
          ...defaultStyles,
          ...transitionStyles[state]
        };

        return (
          <StyledSlider
            style={style}
            {...restProps}
            transition={transitionProps}
          />
        );
      }}
    </Transition>
  );
}

TransitionHandler.defaultProps = {
  component: "div",
  transitionProps: {
    appear: true,
    mountOnEnter: true,
    unmountOnExit: true
  }
};

export const Fade = ({ onExited, ...props }) => (
  <TransitionHandler
    defaultStyles={{
      transition: `opacity ${transitionDurationMs}ms ${transitionTimingFunction}`,
      opacity: 0,
      position: "fixed",
      zIndex: 500
    }}
    transitionStyles={{
      entering: { opacity: 0 },
      entered: { opacity: 1 }
    }}
    {...props}
  />
);

export const Slide = ({ onExited, ...props }) => (
  <TransitionHandler
    defaultStyles={{
      transition: `transform ${transitionDurationMs}ms ${transitionTimingFunction}`,
      transform: "translate3d(100%,0,0)"
    }}
    transitionStyles={{
      entered: { transform: "translate3d(0,0,0)" },
      exited: { transform: "translate3d(100%,0,0)" }
    }}
    {...props}
  />
);
