import styled, { keyframes } from 'styled-components';
import React from 'react';

const rotate360 = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;
const Spinner = styled.div`
  color: #333;
  font-family: sans-serif;
  margin-top: 200px;
  &:before {
    display: inline-block;
    content: '';
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: solid 2px transparent;
    border-bottom-color: var(--bg-dark);
    border-right-color: var(--bg-dark);
    animation: ${rotate360} 1s linear infinite;
    margin-right: 6px;
    vertical-align: bottom;
  }
`;

const Loading = () => <Spinner />;

export default Loading;

const StyledLoadingWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const StyledLoading = props => (
  <StyledLoadingWrapper>
    <Loading {...props} />
  </StyledLoadingWrapper>
);
