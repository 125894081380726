import React from 'react';
import styled, { keyframes } from 'styled-components';

const FormField = ({ viewBox, width, height }) => (
  <Container>
    <svg
      viewBox={viewBox || '0 0 16 16'}
      width={width || '32px'}
      height={height || '32px'}
    >
      <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
      <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
    </svg>
  </Container>
);

export default FormField;

const dash = keyframes`
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
`;

const Container = styled.div`
  svg polyline {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  svg polyline#back {
    stroke: rgba(59, 211, 171, 0.3);
  }

  svg polyline#front {
    stroke: #3bd3ab;
    stroke-dasharray: 12, 36;
    stroke-dashoffset: 48;
    animation: ${dash} 1s linear infinite;
  }
`;
