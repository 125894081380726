import styled from 'styled-components';

export const Form = styled.form`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  input[type='radio'],
  input[type='checkbox'] {
    width: 1px;
    height: 1px;
  }
  .checkbox {
    width: unset;
    padding: unset;
  }
  textarea {
    outline: none;
  }
  label {
    /* font-weight: unset; */
    /* font-size: unset; */
    /* color: unset; */
    display: unset;
    padding: unset;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #383838 !important;
    line-height: 14px !important;
    letter-spacing: -0.0923077px !important;
  }
`;

export const FieldList = styled.div``;

export const StickyAddNew = styled.div`
  padding-top: 32px;
`;

export const FieldListHeader = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #767676;
  height: 50px;
  align-items: center;
  border-bottom: 0.5px solid #e8e8e8;
  margin-bottom: 8px;
  & > :first-child {
    flex: 1;
  }
  & > :last-child {
    width: 300px;
  }
`;

export const IconHolder = styled.div`
  min-width: 22px;
  cursor: pointer;
  padding-right: 8px;
  margin-top: -2px;
`;

export const FieldListItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #3e3e3e;
  min-height: 34px;
  :not(:last-child) {
    margin-bottom: 16px;
  }
  & > :first-child {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #3e3e3e;
    & > div.required {
      margin-left: 4px;
      color: red;
    }
  }
  & > :last-child {
    width: 300px;
  }
`;

export const Error = styled.div`
  display: block;
  padding-left: 2px;
  padding-top: 4px;
  font-size: 12px;
  color: #d72d30;
`;

export const OptionalField = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > :not(:last-child) {
    margin-right: 8px;
  }
  & > div.text {
    font-weight: 600;
    font-size: 16px;
    line-height: 205.5%;
    color: #2c5cc5;
  }
`;

export const EmptyField = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  padding: 140px 0;
  & > div.heading {
    font-weight: 600;
    font-size: 15px;
    line-height: 50px;
    color: #262626;
  }
  & > div.description {
    margin-bottom: 16px;
    font-size: 12px;
    color: #262626;
  }
`;

export const FlexBox = styled.div`
  & > :not(:last-child) {
    padding-bottom: 8px;
  }
  & > :not(:first-child) {
    padding-left: 16px;
    border-left: 1px solid #cfd7df;
  }
`;

export const FormErrorCard = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ color }) => color || '#12344d'};
  width: fit-content;
  max-width: 986px;
  margin: ${({ margin }) => margin || '16px auto'};
  padding: 4px 8px;
  background: ${({ background }) => background || '#ffecf0'};
  border: 1px solid ${({ borderColor }) => borderColor || '#ffd0d6'};
  box-sizing: border-box;
  border-radius: 4px;
  & > :not(:last-child) {
    margin-right: 8px;
  }
  & > div.message {
    font-weight: 700;
  }
  & > div.divider {
    margin: 0 8px 0 4px;
    width: 1px;
    height: 24px;
    background: #ffd0d6;
  }
`;

export const SubSection = styled.div`
  padding: 24px;
  flex: 1;
  overflow: auto;
  div.integration-heading {
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 33px;
    font-size: 16px;
    color: #262626;
  }
  div.sub-sec-main-heading {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #183247;
    margin-bottom: 24px;
  }
  div.sub-sec-heading {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #475867;
    margin-bottom: 8px;
  }
  div.sub-sec-description {
    & > span {
      margin-left: 4px;
      color: red;
    }
    font-size: 12px;
    color: #545454;
    line-height: 14px;
    margin-bottom: 24px;
  }
  div.item-errorText {
    font-size: 12px;
    color: #d72d30;
    padding: 0 4px;
    margin-top: 4px;
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
`;

export const Actions = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-top: ${({ showBorder }) =>
    showBorder ? '0.5px solid #e8e8e8' : 'unset'};
  & > div.action-left {
  }
  & > div.action-right {
    & > button {
      margin: 0 0 0 8px;
    }
  }
`;
