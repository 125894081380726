import styled from 'styled-components';

export const ErrorText = styled.div`
  display: block;
  padding-left: 2px;
  padding-top: 4px;
  font-size: 12px;
  color: #d72d30;
`;

export const Container = styled.div`
  & .editor {
    border-radius: 5px;
    border: 1px solid ${({ error }) => (error ? '#d72d30' : '#cfd7df')};
    padding: 16px 0px;
  }
`;

export const Label = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #475867;
  margin-bottom: 8px;
`;
