/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Slider } from "@reach/slider";
import InputNumber from "rc-input-number";

import "rc-input-number/assets/index.css";
import "@reach/slider/styles.css";

import { StyledSlider } from "./style";

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // name: PropTypes.string.isRequired,
  // options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  min: PropTypes.number,
  metric: PropTypes.string,
  max: PropTypes.number
};

const defaultProps = {
  className: undefined,
  value: undefined,
  metric: "",
  onChange: () => {},
  min: 0,
  max: 10
};

const Input = ({ className, value, metric, min, max, onChange }) => {
  const upHandler = (
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: "4px solid transparent",
        borderRight: "4px solid transparent",
        borderBottom: "7px solid #d1d1d1"
      }}
    />
  );
  const downHandler = (
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: "4px solid transparent",
        borderRight: "4px solid transparent",
        borderTop: "7px solid #d1d1d1"
      }}
    />
  );
  return (
    <StyledSlider className={className}>
      <Slider
        min={min}
        max={max}
        value={value}
        onChange={v => onChange(v)}
        step={1}
      />
      <div className="input-wrap">
        <InputNumber
          aria-label="Simple number input example"
          min={min}
          max={max}
          style={{ width: 100 }}
          value={value}
          formatter={v => v}
          parser={v => v.replace(/[^0-9]+/g, "")}
          onChange={v => onChange(v)}
          upHandler={upHandler}
          downHandler={downHandler}
        />
        <div className="metric">{metric}</div>
      </div>
    </StyledSlider>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
