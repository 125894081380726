import React from "react";
import Downshift from "downshift";
// import settings from "../images/settings_select.svg";

const STATUS_MENUS = [
  {
    label: "Edit"
  },
  {
    label: "Delete"
  }
];

const StatusActions = ({ onChange }) => (
  <div className="badge-container">
    <Downshift itemToString={i => (i ? i.label : "")}>
      {({ isOpen, toggleMenu }) => (
        <div>
          <div className="btn-group">
            <div
              className="status-actions"
              onClick={toggleMenu}
              role="presentation"
            >
              <div className={`more-container ${isOpen ? 'is-opened' : ''}`}>
                <span className="more-icon" />
              </div>
            </div>
            <div
              className={`status-actions-dropdown droplist-menu ${isOpen &&
                "dropdown-menu--open"}`}
            >
              {STATUS_MENUS.map(item => (
                <button
                  onClick={() => onChange(item)}
                  key={item.label}
                  className="profile-dropdown__item"
                  style={{ cursor: "pointer" }}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </Downshift>
  </div>
);

export default StatusActions;
