/* eslint-disable no-confusing-arrow */
import styled, { keyframes } from 'styled-components';

import { color, font } from 'shared/utils/styles';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  & > div:first-child {
    margin-right: 24px;
  }
  & > div {
    position: relative;
    flex: 1;
  }
`;

export const AutoloadButton = styled.div`
  cursor: pointer;
  z-index: 1;
  right: 0;
  top: 0;
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-link);
  background: none;
  border: none;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  img {
    &.loading {
      animation: ${rotate} 2s linear infinite;
    }
  }
  span {
    margin-left: 4px;
  }
`;

export const StyledField = styled.div`
  margin-top: ${props => (props.noHeader ? 0 : '30px')};
  opacity: ${props => (props.disabled ? 0.6 : 1)}
  pointer-events: ${props => (props.disabled ? 'none' : 'all')}
  ${props =>
    props.inline
      ? `
        display: flex;
        justify-content: space-between;
      `
      : ''}

  &:first-child {
    margin-top: 0;
  }
`;

export const FieldLabel = styled.label`
  display: block;
  padding-bottom: 5px;
  padding-top: 0;
  ${font.size(12)}
  letter-spacing: -0.09px;
  color: #595959;
  font-weight: 600;
  span {
    padding-left: 2px;
    color: #e41d45;
  }
`;

export const FieldTip = styled.div`
  padding-bottom: 6px;
  ${font.size(12)}
  opacity: 0.5;
  letter-spacing: -0.09px;
  color: #5f5f5f;
`;

export const FieldError = styled.div`
  margin-top: 6px;
  line-height: 1;
  color: ${color.danger};
  ${font.medium}
  ${font.size(12.5)}
`;
