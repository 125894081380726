import styled from 'styled-components';

import { color, font } from 'shared/utils/styles';
import { Button, Form } from 'shared/components';

export const FormElement = styled(Form.Element)``;

export const LinkElement = styled.button`
  position: absolute;
  text-align: right;
  top: 32%;
  right: 0;
  z-index: 1;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 10px;
  color: ${color.primary};
  background: none;
  border: none;

  &:hover {
    background: #efefef;
    border-radius: 3px;
    transition: background 0.6s ease;
  }
`;

export const FormHeading = styled.div`
  padding-bottom: 8px;
  padding-top: 5px;
  font-weight: 600;
  letter-spacing: -0.09px;
  color: #595959;
  /* text-transform: uppercase; */
  ${font.size(14)}
`;

export const UpgradeLabel = styled.div`
  width: auto;
  position: absolute;
  top: 0px;
  right: -5px;
  z-index: 11;
  cursor: pointer;
  .label {
    height: 30px;
    border-radius: 2px;
    border: solid 1px var(--border-warning-color);
    background-color: var(--bg-warning);
    font-size: 8px;
    font-weight: bold;
    color: var(--text-warning);
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
`;

export const SelectItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  ${props => props.withBottomMargin && `margin-bottom: 5px;`}
`;

export const SelectItemLabel = styled.div`
  padding: 0 3px 0 6px;
`;

export const Divider = styled.div`
  margin-top: 22px;
  border-top: 1px solid ${color.borderLightest};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  position: sticky;
  right: 26px;
  bottom: 0;
  width: 100%;
  padding-bottom: 13px;
  background: #ebeef0;
  z-index: 999;
`;

export const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 0px 20px;
  height: 33px;
  border: none;
  &[type='submit'] {
    min-width: 85px;
  }

  &:hover {
  }
`;

export const FormSubHeading = styled.p`
  ${font.size(12)}
  opacity: 0.5;
  letter-spacing: -0.09px;
  color: #5f5f5f;
`;

export const CheckFormWrapper = styled.div`
  display: flex;
  .check-menu {
    width: 250px;
    min-width: 250px;
    padding: 20px 18px;
    height: 100vh;
    position: sticky;
    top: 0;
    h1 {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .check-content {
    flex-grow: 1;
    padding: 13px 13px 0 13px;
    background: #ebeef0;
    overflow: auto;
    position: relativ .form-content {
      margin-bottom: 10px;
    }
    .form-section {
      border-radius: 3px;
      padding: 25px 20px 35px;
      /* box-shadow: 0 2px 3px 0 #d1d1d1; */
      background-color: #ffffff;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }
    .form-section-wrap {
      position: relative;

      .section-content {
        .upgrade {
          opacity: 0.6;
        }
      }

      &.spaced {
        margin-top: 30px;
      }
    }
  }
  .checkbox-group {
    margin-top: 10px;
    display: flex;
  }
  .custom-field {
    margin-top: 20px;
    &.disabled {
      opacity: 0.6;
    }
  }
`;
