import {
  UPDATE_REFRESH_COUNTER,
  GET_APPLICATIONS_SUCCESS,
  TOGGLE_INTEREST_MODAL,
  TOGGLE_CHECK_DRAWER,
  // SET_BADGE_TICKET,
  TOGGLE_BADGE_HEADER,
  SET_BANNER
} from "../actions";
import { getOrgId } from "../utils/utility";

function initialBadgeHeaderState() {
  const orgId = Number(getOrgId());
  const badgeHeader = JSON.parse(window.localStorage.getItem("badge-header"));
  let showBadgeHeader = true;
  if (badgeHeader) {
    for (let count = 0; count < badgeHeader.length; count + 1) {
      if (badgeHeader[count].orgId === orgId) {
        showBadgeHeader = false;
        break;
      }
    }
  }
  return showBadgeHeader;
}

const initialAppState = {
  counter: 60,
  // badgeTicket: !!window.localStorage.getItem("badge-ticket-url"),
  checkDrawer: {
    isOpen: false,
    check: null
  },
  badgeHeader: initialBadgeHeaderState(),
  banner: null,
  interest: {
    state: false,
    type: "estate"
  }
};

export default function appReducer(state = initialAppState, action) {
  switch (action.type) {
    case UPDATE_REFRESH_COUNTER:
      return {
        ...state,
        counter: action.payload
      };
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        counter: initialAppState.counter
      };
    case TOGGLE_BADGE_HEADER:
      return {
        ...state,
        badgeHeader: false
      };
    case TOGGLE_INTEREST_MODAL:
      return {
        ...state,
        interest: {
          ...state.interest,
          ...action.payload
        }
      };
    case TOGGLE_CHECK_DRAWER:
      return {
        ...state,
        checkDrawer: action.payload
      }
    case SET_BANNER:
      return {
        ...state,
        banner: action.payload
      };
    default:
      return state;
  }
}
