import styled from 'styled-components';
import arrow from '../../images/dropdown.svg';

export const Plans = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  width: 90%;
`;

export const Container = styled.div`
  section.topbar {
    position: fixed;
    z-index: 1;
    width: calc(100% - 277px);
  }
  .pricing-content {
    position: relative;
    top: 70px;
    min-width: 60rem;
    padding: 0;
  }
`;

export const Plan = styled.div`
  margin-top: 10px;
  width: 30%;
  min-height: 1048px;
  border-radius: 10px;
  border: solid 1px rgba(134, 134, 134, 0.15);
  background: rgba(255, 255, 255);
  padding: 25px 0;
  position: relative;
  text-align: center;
  user-select: none;

  .planname {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .price {
    margin: 19px 0;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    .dollar {
      font-size: 16px;
      font-weight: 500;
    }
    .term {
      font-size: 12px;
      color: #7d7d7d;
      font-weight: 400;
    }
  }

  .checksavailable {
    font-size: 18px;
    margin: 15px 0;
    color: #666666;
    text-align: center;
  }

  .additional {
    color: #666666;
    width: 120px;
    opacity: 0.7;
    font-style: italic;
    margin: 33px auto;
    text-align: center;
  }

  hr {
    opacity: 0.3;
    border: solid 1px var(--border-color);
    margin: 16px 18px;
  }

  .info {
    font-weight: 600;
    color: #666666;
  }

  a.disabled {
    pointer-events: none;
  }
`;

export const PlanLabel = styled.div`
  width: 170px;
  border-radius: 16px;
  border: solid 1px var(--border-info-color);
  background: var(--bg-info);
  color: var(--base-color);
  font-weight: 600;
  padding: 6px 0;
  text-align: center;
  position: absolute;
  top: -16px;
  left: calc(50% - 85px);

  &.promote {
    background-color: var(--text-link);
    color: #fff;
  }
`;

export const PrimaryButton = styled.button`
  padding: 12px 0;
  border-radius: 3px;
  border: solid 1px rgba(237, 124, 2, 0.5);
  font-size: 12px;
  font-weight: bold;
  color: var(--text-link);
  width: 190px;
  cursor: pointer;
  user-select: none;
`;

export const SecondaryButton = styled.button`
  padding: 12px 0;
  border-radius: 3px;
  border: solid 1px var(--text-link);
  background-color: var(--text-link);
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  width: 190px;
  cursor: pointer;
  user-select: none;

  &:disabled {
    color: rgba(94, 94, 94, 0.5);
    border-radius: 3px;
    background-color: rgba(205, 170, 132, 0.2);
    border: 0;
    pointer-events: none;
  }
`;

export const Switcher = styled.div`
  margin: 20px auto;
  width: 90%;
  user-select: none;
  position: relative;
  text-align: center;
  padding: 10px 0;
  hr {
    width: 100%;
    border: 0.5px solid #d8d8d8;
  }

  .widget {
    position: absolute;
    padding: 10px 12px;
    left: calc(50% - 110px);
    top: -4px;
    background: #ebeef0;
  }

  .monthly,
  .annually {
    font-weight: 600;
    color: #666666;
    opacity: 0.5;
    text-align: center;
    display: inline-block;
    padding: 0 8px;
    &.active {
      opacity: 1;
    }
  }

  span > span {
    background: var(--text-link);
  }
`;

export const Features = styled.div`
  text-align: left;
  color: #666;
  padding: 15px 14px;
  min-height: 345px;

  .item {
    display: flex;
    font-size: 12px;
    padding: 6px 0;
    line-height: 1.47;
    color: #666666;
    align-items: center;

    .tick {
      height: 23px;
      width: 23px;
      border-radius: 50%;
      background: var(--color-smoke-50);
      margin-right: 10px;
      img {
        position: relative;
        width: 18px;
        left: 2px;
        top: 2px;
      }
    }
  }
  .subitem {
    font-size: 12px;
    padding: 6px 0;
    line-height: 1.47;
    color: #666666;
    margin-left: 33px;
  }
`;

export const Report = styled.div`
  border-top: dashed 1px #e3e5e7;
  padding: 12px;
  .item {
    padding: 8px 12px;
    border-radius: 4px;
    color: #666;
    font-weight: normal;
    display: flex;
    justify-content: space-between;

    &.highlight {
      background-color: rgba(253, 241, 229, 0.52);
    }
  }
`;

export const Conditions = styled.div`
  opacity: 0.7;
  font-size: 12px;
  letter-spacing: -0.2px;
  text-align: center;
  margin: auto;
  margin-bottom: 25px;
  width: 90%;
`;

export const Billing = styled.div`
  background-color: #f6efe6;
  padding: 8px 12px;
  margin: 13px 0;
  .item {
    justify-content: space-between;
    align-items: center;
    display: flex;
    font-size: 12px;
    padding: 6px 0;

    .count {
      font-size: 15px;
      font-weight: 500;
    }

    .plan-price {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      &.big {
        font-size: 18px;
      }
    }
  }
`;

export const Remainder = styled.div`
  margin: 14px auto;
  text-align: center;
  color: #797979;
  padding: 5px 0;
  .trailinfo {
    font-weight: 600;
    color: #333;
  }

  .billinginfo {
    color: #797979;
    padding: 5px 0;
  }
`;

export const Suspended = styled.div`
  border-radius: 4px;
  background-color: #ece5d7;
  padding: 14px 24px;
  position: relative;
  margin-bottom: 15px;

  .error {
    font-size: 15px;
    font-weight: 500;
    color: #df3f3f;
    margin-bottom: 8px;

    span {
      padding: 0 5px;
    }
  }
  .message {
    font-size: 12px;
  }
`;

export const InterestBanner = styled.div`
  border-radius: 4px;
  background-color: #fff6eb;
  padding: 8px 24px;
  border: 1px solid #f5af6f;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  max-height: 40px;
`;

export const Crumbs = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  color: #7e7e7e;
  justify-content: center;
  padding-top: 10px;
  user-select: none;
  .item {
    text-transform: capitalize;
    padding-right: 5px;
    &.active {
      font-weight: 500;
    }
    &:after {
      content: '';
      background: url(${arrow}) no-repeat center;
      background-size: 14px;
      width: 14px;
      height: 8px;
      transform: rotate(-90deg);
      display: inline-block;
      padding-right: 10px;
    }
    &:last-child:after {
      display: none;
    }
  }

  &.adjust {
    justify-content: flex-start;
    padding: 10px 0;
    margin-bottom: 5px;
    a {
      color: inherit !important;
    }
  }
`;

export const Offer = styled.div`
  border-radius: 19px;
  background-color: #fcf6ef;
  font-size: 12px;
  text-align: center;
  color: #676767;
  padding: 5px 16px;
  min-width: 135px;
  text-transform: initial;
  font-weight: 400;
  position: absolute;
  top: 11px;
  right: 11px;
  b {
    color: var(--text-link);
    font-weight: 500;
  }
`;

export const Estimation = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  width: 90%;
  margin: auto;
  padding-bottom: 10px;
  position: relative;

  .overlay {
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
  }

  header {
    padding: 15px;
    font-size: 17px;
    font-weight: 500;
    border-bottom: dashed 1px #d8d8d8;
    color: #333;
    text-transform: uppercase;
    position: relative;
  }

  .estimate-tbl {
    margin: 20px 0;
  }

  .proceed {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #ec0707;
      padding-top: 12px;
      width: 50%;
    }
  }

  .terms {
    font-size: 12px;
    letter-spacing: -0.2px;
    text-align: center;
    color: #7e7e7e;
    padding: 10px 0;
  }

  .__react_component_tooltip {
    padding: 5px 12px;
    font-weight: normal;
  }

  .note-item {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    padding-top: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 20px auto;
  color: #333;
  font-weight: normal;
  padding-right: 26px;
  p {
    margin: 0;
  }

  .item-name {
    width: 55%;
    b {
      font-weight: 500;
      margin-right: 10px;
    }
    img {
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  .Select-control {
    border-radius: 3px;
  }

  .Select,
  .item-type {
    width: 140px;
  }

  .item-calc {
    min-width: 125px;
    text-align: right;
  }

  &.creditsInfo {
    background-color: #fdfaf7;
    border-radius: 3px;
    font-weight: normal;
    padding: 17px 25px 17px 28px;
    display: block;
    span {
      font-size: 17px;
      font-weight: bold;
      color: #18701e;
    }
  }

  &.total {
    border-radius: 3px;
    border: solid 1px #dadada;
    font-weight: 500;
    color: #7e7e7e;
    padding: 17px 25px 17px 28px;
    margin-left: auto;

    .item-name,
    .item-calc {
      span:first-child {
        font-weight: normal;
      }
    }

    .item-total {
      font-size: 20px;
      min-width: 100px;
    }
    .item-calc {
      min-width: 220px;
      color: #7d7d7d;
      b {
        color: #333;
      }
    }
  }

  .item-total {
    font-weight: bold;
    color: #333;
    font-size: 16px;
    min-width: 80px;
    text-align: right;
  }
`;

export const Counter = styled.div`
  width: 140px;
  height: 34px;
  border-radius: 3px;
  border: solid 1px #dde3e8;
  background-color: #ffffff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    border: 0;
    height: 100%;
    font-size: 21px;
    padding: 0 10px;
    display: block;
    cursor: pointer;
    user-select: none;

    &:disabled {
      background: #f1f1f1;
      /* pointer-events: none; */
      cursor: not-allowed;
    }

    &:hover {
      background: #ebeef0;
    }
  }
  div {
    width: 76px;
    text-align: center;
    border-right: 1px solid #dfe5e9;
    height: 100%;
    line-height: 35px;
    user-select: none;
    border-left: 1px solid #dfe5e9;
  }
`;

export const UpdatePayment = styled.div`
  position: absolute;
  right: 20px;
  top: calc(50% - 12px);
`;

export const Loader = styled.div`
  text-align: center;
  padding-top: 200px;
  position: relative;
  .wrapper {
    display: table;
    position: relative;
    width: 93%;
    padding: 20px 0 70px;
    table-layout: fixed;
    border-spacing: 10px;
    border-radius: 5px;
    margin: 0 auto;
    svg polyline {
      fill: none;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      &#back {
        stroke: rgba(59, 211, 171, 0.3);
      }
      &#front {
        stroke: #3bd3ab;
        stroke-dasharray: 12, 36;
        stroke-dashoffset: 48;
        animation: dash 1s linear infinite;
      }
    }
  }
`;

export const Banner = styled.div`
  position: relative;
  min-height: 220px;
  max-height: 220px;
  width: 37%;
  min-width: 420px;
  max-width: 45%;
  flex-grow: 1;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  padding: 20px 30px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.SPROUT {
    min-height: 70px;
    &.active {
      min-height: 115px;
    }
  }
  .planname {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    align-items: center;
    display: flex;
  }

  .checks {
    font-weight: normal;
    line-height: 1.85;
    color: #7d7d7d;
    text-transform: initial;
    white-space: nowrap;
    b {
      color: #333;
    }
  }
  &.SPROUT {
    margin-bottom: 0px !important;
  }

  .additional {
    display: flex;
    align-items: center;
  }

  .suspensionInfo {
    border-top: solid 1px #d8d8d8;
    background-color: #faf7f2;
    padding: 8px 30px;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    color: var(--secondary-color);
    b {
      color: var(--base-color);
    }
  }
`;

export const PriceTag = styled.div`
  border-radius: 4px;
  background-color: var(--bg-info);
  padding: 10px 15px;
  margin-left: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:first-child {
    color: #7d7d7d;
    display: inline-block;
    margin-right: 10px;
  }

  &.warning {
    background-color: #ffeded !important;
    span:last-child {
      color: #ff3b3b;
    }
  }

  b {
    margin-left: 4px;
    font-size: 18px;
  }
`;

export const Downgrade = styled.div`
  background-color: #ffffff;
  padding: 20px 0;
  color: #505050;
  .heading {
    text-align: center;
    margin-bottom: 15px;
    h3 {
      font-size: 18px;
      font-weight: bold;
      color: #4f4f4f;
    }
    p {
      margin: 0 auto;
      color: #505050;
      width: 80%;
    }
  }
  .important {
    padding-left: 25px;
    margin-bottom: 15px;
    span {
      margin-right: 12px;
      color: #505050;
      font-weight: 600;
      &:first-child {
        border-radius: 3px;
        background-color: #ff6b68;
        color: #fff;
        padding: 4px 8px;
        font-size: 12px;
      }
    }
  }

  ul {
    -moz-column-count: 3;
    -moz-column-gap: 0px;
    -webkit-column-count: 3;
    -webkit-column-gap: 0px;
    column-count: 3;
    column-gap: 0px;
    margin-bottom: 20px;
    li {
      font-size: 12px;
      color: #505050;
      line-height: 2;
    }
  }

  .info {
    background-color: rgba(250, 241, 231, 0.5);
    padding: 8px 15px;
    font-style: italic;
    &.alert {
      font-style: normal;
      text-align: center;
      span {
        color: #963d3d;
      }
      div {
        line-height: 2;
      }
    }
  }

  .actions {
    float: right;
    margin: 15px 0;
  }
`;

export const StyledCardDetails = styled.div`
  position: relative;
  min-height: 220px;
  max-height: 220px;
  width: 30%;
  min-width: 330px;
  max-width: 45%;
  flex-grow: 1;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  padding: 20px 30px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .heading {
    font-size: 16px;
  }
  .field {
    font-size: 14px;
    color: #7d7d7d;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      color: #000;
      margin-top: 4px;
    }
  }
  .field-value {
    font-weight: 600;
  }
`;

export const StyledLoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
