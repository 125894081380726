import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'freemium-ui';
import Select from 'react-select';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import Drawer from 'shared/components/Drawer';
import _ from 'lodash';
import moment from 'moment';
import 'react-select/dist/react-select.css';
import { Loader } from './style';
import config from '../../config';
import { request } from '../../utils/Api';
import { getSubscription } from '../../actions';
import { getTimezoneDate } from '../../utils/utility';
import { Row, Counter, Estimation, Crumbs, Offer } from './style';
import info from '../../images/info.svg';
import ReactTooltip from 'react-tooltip';
import { PLAN_META } from '../../constants/constant';

class Estimate extends React.Component {
  state = {
    additional_checks: 10,
    check_price: {
      monthly: 10,
      annually: 8
    },
    checkscount: 0,
    unitCount: 0,
    period: this.props.plandata.period,
    cardError: '',
    term_options: [
      { value: 'Y', label: 'Annually' },
      { value: 'M', label: 'Monthly' }
    ],
    submit: false,
    updateCardHostedUrl: '',
    dirty: false
  };

  increment = () => {
    const { unitCount, checkscount, credits, addons } = this.state;
    const units = checkscount <= 1000 ? unitCount + 1 : unitCount;
    const stepCounter =
      checkscount >= 250 && checkscount < 500
        ? 25
        : checkscount >= 500 && checkscount < 1000
        ? 50
        : checkscount < 1000 && 10;
    const currentPrice = this.calculateAddonPrice(units, stepCounter, addons);
    const check = checkscount <= 1000 ? checkscount + stepCounter : checkscount;
    const creditsPrice =
      credits && currentPrice < credits
        ? (credits - currentPrice).toFixed(2)
        : 0;
    this.setState({
      checkscount: check,
      unitCount: units,
      currentPrice,
      creditsPrice,
      dirty: true
    });
  };

  decrement = () => {
    const { unitCount, checkscount, credits, addons } = this.state;
    const units = unitCount > 0 ? unitCount - 1 : unitCount;
    const stepCounter =
      checkscount > 250 && checkscount <= 500
        ? 25
        : checkscount > 500
        ? 50
        : 10;
    const currentPrice = this.calculateAddonPrice(units, stepCounter, addons);
    const checks = checkscount > 0 ? checkscount - stepCounter : checkscount;
    const creditsPrice =
      credits && currentPrice < credits
        ? (credits - currentPrice).toFixed(2)
        : 0;
    this.setState({
      checkscount: checks,
      unitCount: units,
      currentPrice,
      creditsPrice,
      dirty: true
    });
  };

  addonsCollection = (units, addons) => {
    const addedAddons = addons.map(obj => {
      const { unit, cb_id, max_count } = obj;
      const count = unit === 10 ? units : unit === 25 ? units - 25 : units - 35;
      return {
        cb_id,
        quantity: count <= max_count ? count : max_count
      };
    });
    this.setState({ addedAddons });
  };

  calculateAddonPrice = (units, stepCounter, addons) => {
    const addon50 = addons.find(obj => obj.unit === 50);
    const addon25 = addons.find(obj => obj.unit === 25);
    const addon10 = addons.find(obj => obj.unit === 10);
    let currentPrice = 0;
    if (stepCounter === 10) {
      currentPrice = addon10.price * units;
      this.addonsCollection(units, [addon10]);
    } else if (stepCounter === 25) {
      currentPrice = addon10.price * 25 + addon25.price * (units - 25);
      this.addonsCollection(units, [addon10, addon25]);
    } else if (stepCounter === 50) {
      currentPrice =
        addon10.price * 25 + addon25.price * 10 + addon50.price * (units - 35);
      this.addonsCollection(units, [addon10, addon25, addon50]);
    }
    return currentPrice;
  };

  handleChange = (e, field) => {
    this.setState({
      [field]: e.value,
      dirty: e.value !== this.state.period
    });
    this.getCurrentPlan(e.value);
  };

  checkout = async consolidated => {
    const { cb_plan_id, pay_now } = this.props.plandata;
    const { addedAddons, unitCount } = this.state;
    const { addons } = this.props.currentOrg.subscription;
    const badge = addons.find(add => add.cb_id.indexOf('badge') > -1);
    if (badge)
      badge.cb_id =
        this.state.period === 'M' ? 'badge_monthly' : 'badge_yearly';
    const addonsList = unitCount
      ? badge
        ? [...addedAddons, badge]
        : addedAddons
      : addons || [];
    this.setState({
      submit: true
    });
    const payload = {
      plan_id: cb_plan_id,
      organization: this.props.orgId,
      addons: addonsList,
      pay_now
    };

    const response = await request(`/update-plan/`, {
      method: 'POST',
      payload
    });
    if (response.status === 400) {
      this.setState({ cardError: response.response[0], submit: false });
      toastr.error(response.response.non_field_errors[0] || 'Update failed');
    } else {
      this.setState({ submit: false, cardError: '' }, () => {
        this.props.setCheckoutProps(payload, consolidated);
        if (response.hosted_page_url) {
          this.setState({ updateCardHostedUrl: response.hosted_page_url });
        } else if (response.checkout_method === 'subscription_updated') {
          this.props.history.push(`/settings/pricing`);
          toastr.success('Subscription has been updated successfully');
          this.props.getSubscription();
        }
      });
    }
  };

  getCurrentPlan = unit => {
    const { plans, plandata, currentOrg } = this.props;
    const { addons, started_at, plan_unit_price } = currentOrg.subscription;

    const currentPlan = _.filter(plans, {
      name: plandata.name,
      period_unit: unit
    })[0];
    plandata.cb_plan_id = currentPlan.cb_plan_id;
    const billingMonths = unit === 'M' ? 1 : 12;
    let filterdaddons = _.filter(currentPlan.addons, { addon_type: 'CH' });
    filterdaddons = _.orderBy(filterdaddons, ['unit']);
    this.calculateCredits(addons, started_at, plan_unit_price, filterdaddons);
    this.setState({
      currentPlan,
      billingMonths,
      addons: filterdaddons,
      totalAddonPrice: 0,
      dirty: plandata.cb_plan_id !== currentOrg.subscription.cb_plan
    });

    this.getBillCycle(unit);
  };

  computeTotalAmount = currentOrg => {
    const {
      billingMonths,
      currentPlan,
      currentPrice,
      totalAddonPrice
      // creditsPrice,
      // remainingAmt
    } = this.state;
    // const { refundable_credits, promotional_credits } = currentOrg.subscription;
    const PLAN = currentPlan && currentPlan.price * billingMonths;
    const ADDON = currentPrice ? currentPrice * billingMonths : totalAddonPrice;
    const TOTAL = PLAN + ADDON;
    // remainingAmt -
    // (refundable_credits + promotional_credits) -
    // (creditsPrice ? creditsPrice : 0);
    return {
      PLAN,
      ADDON,
      TOTAL
    };
  };

  calculateCredits = (addons, started_at, plan_unit_price, filterdaddons) => {
    const {
      current_term_end,
      refundable_credits,
      promotional_credits,
      billing_period_unit
    } = this.props.currentOrg.subscription;
    const start = moment(started_at);
    const end = moment();
    const daysDifference = end.diff(start, 'days') + 1;
    const totalDays = moment(current_term_end).diff(start, 'days') + 1;
    let checkscount = 0,
      unitCount = 0,
      currentPrice = 0;
    // addonPrice = 0;
    if (addons && addons.length) {
      for (let i = 0; i < addons.length; i++) {
        // const referAdd = filterdaddons.find(
        //   addon => addon.cb_id === addons[i].cb_id
        // );
        checkscount =
          checkscount +
          parseInt(PLAN_META.addons[addons[i].cb_id]) * addons[i].quantity;
        unitCount +=
          addons[i].cb_id.indexOf('badge') === -1 ? addons[i].quantity : 0;
        currentPrice +=
          billing_period_unit === 'year'
            ? (addons[i].unit_price * addons[i].quantity) / (100 * 12)
            : (addons[i].unit_price * addons[i].quantity) / 100;
        // addonPrice += referAdd && (referAdd.price) * addons[i].quantity
      }
    }
    // if(this.state.period === 'Y')
    const currentPlanCredit =
      (plan_unit_price - (plan_unit_price * daysDifference) / totalDays) / 100;
    const addOnCredit = (
      currentPrice -
      (currentPrice * daysDifference) / totalDays
    ).toFixed(2);
    const totalCredits = (
      Number(currentPlanCredit) +
      Number(addOnCredit) +
      Number(refundable_credits) +
      Number(promotional_credits)
    ).toFixed(2);
    addons.length && this.addonsCollection(unitCount, addons);

    const stepCounter =
      checkscount > 250 && checkscount <= 500
        ? 25
        : checkscount > 500
        ? 50
        : 10;
    currentPrice =
      filterdaddons.length &&
      this.calculateAddonPrice(unitCount, stepCounter, filterdaddons);

    this.setState({
      checkscount,
      unitCount,
      currentPrice,
      credits: totalCredits,
      remainingAmt: totalCredits
    });
  };

  getBillCycle = unit => {
    const { timezone, subscription } = this.props.currentOrg;
    let lastDate =
      unit === 'M'
        ? getTimezoneDate(moment().add(1, 'months'), timezone)
        : getTimezoneDate(moment().add(1, 'years'), timezone);
    let startDate = getTimezoneDate(moment(), timezone);
    if (subscription.status === 'in_trial') {
      startDate = getTimezoneDate(moment(), timezone);
      lastDate =
        unit === 'M'
          ? getTimezoneDate(moment().add(1, 'months'), timezone)
          : getTimezoneDate(moment().add(1, 'years'), timezone);
    }
    this.setState({
      startDate,
      lastDate
    });
  };

  messageEventListener = e => {
    if (e.origin === config.chargebeeDomain) {
      var messObj = JSON.parse(e.data);
      if (messObj.success) {
        this.setState({ updateCardHostedUrl: '' });
        this.props.history.push(`/settings/pricing`);
        toastr.success('Changes updated successfully');
      }
      if (messObj.cancel) {
        this.setState({
          updateCardHostedUrl: ''
        });
        this.props.history.push(`/settings/pricing`);
        toastr.error('Update cancelled');
      }
    }
  };

  componentDidMount() {
    window.addEventListener('message', this.messageEventListener, false);
    this.getCurrentPlan(this.state.period);
  }

  render() {
    const {
      checkscount,
      period,
      billingMonths,
      currentPlan,
      addons,
      currentPrice,
      totalAddonPrice,
      term_options,
      submit
    } = this.state;
    const { plandata, currentOrg } = this.props;
    const { card_status, status } = currentOrg.subscription;
    const { PLAN, ADDON, TOTAL } = this.computeTotalAmount(currentOrg);
    return (
      <Fragment>
        <Drawer
          onClose={() =>
            this.setState({
              updateCardHostedUrl: ''
            })
          }
          isOpen={this.state.updateCardHostedUrl}
          width="800px"
        >
          <iframe
            src={`${this.state.updateCardHostedUrl}#${window.location.href}`}
            title="chargebee-payment"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              padding: '20px 0'
            }}
          />
        </Drawer>
        <Estimation>
          {submit ? (
            <div className="overlay">
              <Loader style={{ top: '20%' }}>
                <div className="indicator">
                  <svg width="16px" height="12px">
                    <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                    <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" />
                  </svg>
                </div>
              </Loader>
            </div>
          ) : null}
          <header>
            {plandata.name}
            {period === 'M' ? (
              <Offer>
                <b>Save 20% </b> by selecting annual plan
              </Offer>
            ) : (
              <Offer className="done">
                You saved <b> 20% </b>
              </Offer>
            )}
          </header>
          <div className="estimate-tbl">
            <Row>
              <div className="item-name"> Billed </div>
              <div className="item-type">
                <Select
                  name="term"
                  value={period}
                  clearable={false}
                  searchable={false}
                  options={term_options}
                  onChange={e => this.handleChange(e, 'period')}
                />
              </div>
              <div className="item-calc">
                ${currentPlan && currentPlan.price} x {billingMonths} month(s)
              </div>
              <b className="item-total"> $ {PLAN} </b>
            </Row>
            <Row>
              {addons && (
                <div className="item-name">
                  Add-on Checks
                  <img
                    src={info}
                    alt="info about plans"
                    data-tip
                    data-for="addon"
                  />
                  <ReactTooltip
                    id="addon"
                    place="bottom"
                    type="dark"
                    effect="solid"
                  >
                    {addons.map((obj, i) => (
                      <p key={`adddon-${i}`}>
                        {i === 1 ? (
                          <span> After 250 checks, </span>
                        ) : (
                          i === 2 && <span> After 500 checks, </span>
                        )}
                        <b>{obj.unit}</b> checks at
                        <b> ${obj.price}</b> /month
                      </p>
                    ))}
                  </ReactTooltip>
                </div>
              )}
              <div className="item-type">
                <Counter>
                  <button disabled={!checkscount} onClick={this.decrement}>
                    -
                  </button>
                  <div> {checkscount} </div>
                  <button
                    disabled={checkscount === 1000}
                    onClick={this.increment}
                  >
                    +
                  </button>
                </Counter>
              </div>
              <div className="item-calc">
                ${currentPrice ? currentPrice : totalAddonPrice} x{' '}
                {billingMonths} month(s)
              </div>
              <b className="item-total"> $ {ADDON} </b>
            </Row>

            <Row className="total">
              <div className="item-name">
                <span>Bill cycle:</span>
                <span
                  style={{ color: '#333' }}
                >{` ${this.state.startDate} - ${this.state.lastDate}`}</span>
              </div>
              <div className="item-type"> </div>
              <div className="item-calc">
                <span> You will be charged </span>{' '}
              </div>
              <b className="item-total"> $ {TOTAL ? TOTAL : 0} </b>
            </Row>
          </div>
          <div className="proceed">
            <Button
              inline
              type="primary"
              disabled={!this.state.dirty && status !== 'in_trial'}
              onClick={() => this.checkout(TOTAL)}
            >
              {submit
                ? 'Please wait'
                : // : status === "in_trial"
                  // ? "START FREE TRIAL"
                  'Proceed'}
            </Button>
            {this.state.cardError && <span>* {this.state.cardError} </span>}
          </div>
          <Crumbs>
            {card_status === 'no_card' ? (
              <Fragment>
                <div className="item"> Enter card details </div>
                <div className="item">
                  <span
                    role="img"
                    aria-label="upgrade"
                    style={{ fontSize: '14px' }}
                  >
                    🚀
                  </span>
                </div>
              </Fragment>
            ) : null}
          </Crumbs>
          <div className="note-item">
            {' '}
            Coupon code can be applied in checkout page
          </div>
          <ReactTooltip place="bottom" html type="dark" effect="solid" />
        </Estimation>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  org: state.user.org
});

export default withRouter(
  connect(mapStateToProps, { getSubscription })(Estimate)
);
