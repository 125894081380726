/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { StyledInput, InputElement } from "./style";

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  invalid: PropTypes.bool,
  filter: PropTypes.instanceOf(RegExp),
  onBlurSideEffect: PropTypes.any,
  onChange: PropTypes.func
};

const defaultProps = {
  className: undefined,
  value: undefined,
  icon: undefined,
  invalid: false,
  onBlurSideEffect: undefined,
  filter: undefined,
  onChange: () => {}
};

const Input = forwardRef(
  (
    {
      className,
      filter,
      onChange,
      onBlurSideEffect,
      setFieldValue,
      value,
      inputLabel,
      formError,
      formTouched,
      formValues,
      ...inputProps
    },
    ref
  ) => {
    const handleChange = event => {
      onChange(event.target.checked, event);
    };

    return (
      <StyledInput className={className}>
        <InputElement
          type="checkbox"
          {...inputProps}
          checked={formValues[inputProps.name]}
          onChange={handleChange}
          ref={ref}
        />
        <span
          onClick={() =>
            setFieldValue(inputProps.name, !formValues[inputProps.name])
          }
          className="label"
        >
          {inputLabel}
        </span>
      </StyledInput>
    );
  }
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
