import React from 'react';
import { Link } from 'react-router-dom';
// import { getOrgId } from "../utils/utility";
import accountSettings from '../images/account_settings.svg';
import integration from '../images/integraiton.svg';
import pricing from '../images/pricing.svg';
import userManagement from '../images/user_management.svg';

const sidebarList = [
  { url: 'users', title: 'User management', asset: userManagement },
  { url: 'integrations', title: 'Integrations', asset: integration },
  { url: 'account', title: 'Account settings', asset: accountSettings },
  { url: 'pricing', title: 'Pricing and billing', asset: pricing }
];

function CheckCurrentRoute(match) {
  const url = window.location.pathname;
  if (url.indexOf(match) > -1 || (url === `/settings` && match === 'users')) {
    return true;
  }
  return false;
}

const SettingsSideNav = props => {
  return (
    <ul className="settings-sidebar">
      {sidebarList.map(bar => (
        <li key={bar.url}>
          <Link
            to={`${props.url}/${bar.url}`}
            href={`${props.url}/${bar.url}`}
            className={CheckCurrentRoute(bar.url) ? 'active' : 'inactive'}
          >
            <img alt="icons" src={bar.asset} style={{ margin: '0 15px' }} />
            {bar.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SettingsSideNav;
