import React, { Component } from "react";
import { request } from "../utils/Api";
import { getOrgId } from "../utils/utility";
import close from "../images/close.svg";

class MemberChecks extends Component {
  state = {
    applicationsOfMember: [],
    applicationMembers: [],
    checks: []
  };
  componentDidMount() {
    this.getMemberAndAllApplications();
  }

  getMemberAndAllApplications = async () => {
    // var results= [], self = this, res= [];
    // var promises = [];
    // const { orgId } = this.props;
    // const id = this.props.memberId;
    /*const applications = await request(`/applications/?organization=${getOrgId()}`);

    const response = await request(`/application-members/?user=${id}&application=${applications.results[0].id}`);
    this.setState({
      applicationMembers: response.results
    });
    var applicationMembers = response.results;
    for(var i=0; i<applicationMembers.length; i++) {
      var promise = request(`/checks/?application=${applicationMembers[i].application}`);
        promises.push(promise);
    }
    Promise.all(promises).then(function(response) {
      results = (response).map(function(o){
        return o.results;
      });
      for(var i=0; i<results.length; i++){
        for(var j=0; j<results[i].length; j++){
          if(results[i][j].email_alert_users_mode === "AAM")
            res.push(results[i][j]);
          else if(self.state.applicationMembers.length === 0){
            continue;
          }
          else if(results[i][j].email_alert_users_mode === "SAM" && results[i][j].email_alert_members.indexOf(self.state.applicationMembers[0].id) !== -1)
            res.push(results[i][j]);
        }
      }
      self.setState({
        checks: res
      });
    });*/
    const checks = await request(
      `/checks/?alerts_target_email=${
        this.props.member.user_mini
          ? encodeURIComponent(this.props.member.user_mini.email)
          : encodeURIComponent(this.props.member.email)
      }`
    );
    this.setState({ checks: checks.results });
  };
  openConfirmRemoveApp = (e, application) => {
    const { id, name } = this.props;
    this.setState({
      confirmRemoveAppDialog: true,
      applicationDelete: { id, name, application }
    });
  };
  closeConfirmRemoveAppDialog = () => {
    this.setState({
      confirmRemoveAppDialog: false
    });
  };
  render() {
    const { checks } = this.state;
    const filteredChecks =
      checks && checks.length > 0
        ? checks.filter(function(item) {
            return (
              item.application_mini &&
              item.application_mini.organization === Number(getOrgId())
            );
          })
        : [];
    return (
      <div className="user-apps">
        <div className="expand-applications">
          <span className="hidden-app-count">
            {filteredChecks.length} Checks
          </span>
          <div className="tooltip-dropdown">
            {filteredChecks.map((check, index) => {
              return <p key={check.id}>{JSON.parse(check.data).url}</p>;
            })}
          </div>
        </div>
        {this.state.confirmRemoveAppDialog && (
          <div
            className="modal delete-user"
            style={
              this.state.confirmRemoveAppDialog
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="modal-content delete-user-from-org-modal">
              <img
                src={close}
                alt="close icon"
                className="close-image"
                onClick={this.closeConfirmRemoveAppDialog}
              />
              <div className="modal-body">
                <div className="modal-header">
                  <h4>
                    Do you want to remove{" "}
                    <span className="dynamic-values">
                      {this.state.applicationDelete.application &&
                        this.state.applicationDelete.application
                          .application_mini.name}
                    </span>{" "}
                    from the group{" "}
                    <span className="dynamic-values">
                      {this.state.applicationDelete.name} ?
                    </span>
                  </h4>
                </div>
                <div className="modal-footer delete-user-from-org-modal-footer">
                  <button
                    className="cancel-btn secondary-btn"
                    onClick={this.closeConfirmRemoveAppDialog}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-danger"
                    onClick={this.removeAppFromUser}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MemberChecks;
