import React, { Component } from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { toastr } from 'react-redux-toastr';
import Downshift from 'downshift';
import PhoneInput from 'react-phone-number-input/react-responsive-ui';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { request } from '../utils/Api';
import { companySizeOptions } from '../constants/constant';
import { DropdownIcon } from '../components/Icons';

const FeatureListIcon = ({ iconFillColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 22 22"
    >
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="9" cy="9" r="9" fill="#FFF" stroke={`${iconFillColor}1A`} />
        <path
          stroke={iconFillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M7 9.188L8.563 10.75 12 7"
        />
      </g>
    </svg>
  );
};
const InputField = ({ field, name, formAttributes, handleDataChange }) => {
  return (
    <React.Fragment>
      <label className="label-field" htmlFor={field}>
        {name}
      </label>
      <input
        type="text"
        spellCheck={false}
        onChange={handleDataChange}
        className="explore-modal-input"
        name={field}
        autoComplete="off"
        value={formAttributes[field]}
      />
    </React.Fragment>
  );
};
class ExploreModal extends Component {
  constructor(props) {
    super(props);
    const { first_name, last_name, email } = this.props.userDetails;
    const { company_size, phone_number } = this.props.userProfile;
    this.state = {
      formAttributes: {
        first_name,
        last_name,
        email,
        company_size,
        phone_number,
        company_name: ''
      },
      loading: false
    };
  }
  handleDataChange = e => {
    this.setState({
      formAttributes: {
        ...this.state.formAttributes,
        [e.target.name]: e.target.value
      }
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const { getCurrentUser, onClose, product } = this.props;
    const response = await fetch('https://geolocation-db.com/json/');
    const { country_name, state, city } = await response.json();
    const promise1 = request(`/user-profile`, {
      method: 'POST',
      payload: this.state.formAttributes
    });
    const promise2 = request(`/marketing-request-demo`, {
      method: 'POST',
      payload: {
        product: product.name,
        country: country_name,
        state: state,
        city: city
      }
    });
    Promise.all([promise1, promise2]).then(function(values) {
      if (values.find(value => value.status))
        toastr.error('Meeting schedule unsuccessfull');
      else {
        toastr.success(`Request submitted successfully`);
      }
      getCurrentUser();
      onClose();
    });
      
    if (window._na && window._na_module) {
      window._na.sendFeatureEvent(product.name + ' - ' + 'Request Demo', window._na_module);
    }
  };

  isfieldsValid = formAttributes => {
    if (
      !isValidPhoneNumber(formAttributes.phone_number) ||
      Object.keys(formAttributes).find(attr => !formAttributes[attr])
    )
      return false;
    return true;
  };
  render() {
    const { product } = this.props;
    const { formAttributes } = this.state;
    return (
      <ModalTransition>
        <Modal autoFocus={true} width="800px">
          <ModalContent>
            <SideContent>
              <div
                style={{
                  height: '100%',
                  padding: '38px 30px 30px'
                }}
              >
                <div style={{ display: 'flex', paddingBottom: '20px' }}>
                  <img src={product.logo} alt={product.name} />
                </div>
                {product.features.map(feature => {
                  return (
                    <div
                      style={{ display: 'flex', alignItems: 'end' }}
                      key={feature}
                    >
                      <FeatureListIcon iconFillColor={product.colorCode} />{' '}
                      <div className="feature"> {feature}</div>
                    </div>
                  );
                })}
                <div className="feature" style={{ paddingLeft: '30px' }}>
                  {'& More ...'}
                </div>
              </div>
            </SideContent>
            <MainContent>
              <div className="heading">Schedule a Demo</div>
              <div className="text-content">
                In this one-hour session, our product consultant will walk you
                through a workflow that will demonstrate .{' '}
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  className="name-container"
                  style={{
                    marginRight: '10px'
                  }}
                >
                  <InputField
                    field="first_name"
                    name="First Name"
                    formAttributes={formAttributes}
                    handleDataChange={this.handleDataChange}
                  />
                </div>
                <div className="name-container">
                  <InputField
                    field="last_name"
                    name="Last Name"
                    formAttributes={formAttributes}
                    handleDataChange={this.handleDataChange}
                  />
                </div>
              </div>
              <InputField
                field="email"
                name="Email"
                formAttributes={formAttributes}
                handleDataChange={this.handleDataChange}
              />
              <InputField
                field="company_name"
                name="Company Name"
                formAttributes={formAttributes}
                handleDataChange={this.handleDataChange}
              />
              <div>
                <label className="label-field" htmlFor="company_size">
                  Company Size
                </label>
                <Downshift>
                  {({ isOpen, toggleMenu }) => (
                    <div className="explore-modal-input" onClick={toggleMenu}>
                      {formAttributes.company_size}
                      <div style={{ position: 'absolute', right: '10px' }}>
                        <DropdownIcon />
                      </div>
                      {isOpen && (
                        <div className="dropdown__options">
                          {companySizeOptions.map(option => (
                            <div
                              className="dropdown__option"
                              name="company_size"
                              style={
                                formAttributes.company_size === option
                                  ? { color: '#000' }
                                  : {}
                              }
                              onClick={e => {
                                e.stopPropagation();
                                toggleMenu();
                                this.setState({
                                  formAttributes: {
                                    ...this.state.formAttributes,
                                    company_size: option
                                  }
                                });
                              }}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </Downshift>
              </div>
              <div>
                <div className="label-field">Phone number</div>
                <PhoneInput
                  key="phone-number"
                  value={formAttributes.phone_number}
                  className={'explore-modal-input'}
                  country="US"
                  onChange={value => {
                    this.setState({
                      formAttributes: {
                        ...this.state.formAttributes,
                        phone_number: value
                      }
                    });
                  }}
                />
                {formAttributes.phone_number != null &&
                  !isValidPhoneNumber(formAttributes.phone_number) && (
                    <div className="error-text">Phone number invalid</div>
                  )}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                  className="submit-button"
                  style={{ backgroundColor: product.colorCode }}
                  disabled={
                    !this.isfieldsValid(formAttributes) || this.state.loading
                  }
                  onClick={this.handleSubmit}
                >
                  {this.state.loading ? 'Please Wait' : 'SUBMIT'}
                </button>
                <button className="cancel-button" onClick={this.props.onClose}>
                  Cancel
                </button>
              </div>
            </MainContent>
          </ModalContent>
        </Modal>
      </ModalTransition>
    );
  }
}
const ModalContent = styled.div`
  display: flex;
`;
const SideContent = styled.div`
  max-width: 330px;
  border-radius: 3px 0 0 3px;
  background-color: #f3f3f3;
  width: 38%;
  position: relative;
  left: -20px;
  top: -2px;
  .feature {
    color: #6f6f6f;
    line-height: 1.8;
    margin-bottom: 12px;
    width: calc(100% - 24px);
    padding-left: 10px;
  }
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 65%;
  height: 580px;
  padding: 30px 12px;
  position: relative;
  background-color: #fff;
  .name-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .dropdown__option {
    height: 40px;
    color: #6f6f6f;
    padding: 10px;
  }
  .dropdown__option:hover {
    background-color: #f5f5f5;
  }
  .dropdown__options {
    left: 0;
    top: 45px;
    padding: 5px;
    z-index: 1;
    position: absolute;
    border: none;
    border-radius: 4px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 51px 0 rgba(0, 0, 0, 0.09);
  }
  .label-field {
    color: #9c9c9c;
    font-size: 12px;
  }
  .error-text {
    color: red;
    font-size: 12px;
    padding-top: 10px;
  }
  .heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: normal;
    color: #333333;
  }
  .text-content {
    font-size: 14px;
    line-height: 1.71;
    color: #6f6f6f;
    padding-bottom: 13px;
    padding-top: 7px;
  }
  .submit-button {
    color: white;
    border-radius: 3px;
    height: 40px;
    width: 120px;
    font-size: 15px;
    border: none;
    letter-spacing: 0.5px;
    margin-top: 15px;
    margin-right: 20px;
  }
  .cancel-button {
    border-radius: 3px;
    height: 40px;
    width: 120px;
    font-size: 15px;
    border: 1px solid #c7c7c7;
    background-color: #fff;
    letter-spacing: 0.5px;
    margin-top: 15px;
    cursor: pointer;
  }
  .submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .explore-modal-input {
    border: 1px solid #c7c7c7;
    border-radius: 3px;
    height: 40px;
    width: 100%;
    margin: 5px 0 10px;
    padding: 0px 10px;
    color: #686868;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
  }
`;

export default ExploreModal;
