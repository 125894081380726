import React from "react";

const PreviewCheckItem = ({ check }) => (
  <div className="statuspage-checks">
    <div className="statuspage-checks-content">
      <div className="statuspage-checks-name-div">
        {
          <span
            className="content-placeholder"
            style={{ width: "20px", borderRadius: "50%" }}
          >
            {/* <Up2 className="statuspage-checks-status-img" /> */}
            &nbsp;
          </span>
        }
        <div className="statuspage-checks-name">
          <span className="link">{check.name}</span>
        </div>
      </div>
      <div className="statuspage-checks-availability-div">
        <div className="statuspage-checks-availability statuspage-checks-title">
          Availability
        </div>
        <div className="statuspage-checks-availability-data statuspage-checks-data">
          {
            <span className="content-placeholder" style={{ width: "80px" }}>
              &nbsp;
            </span>
          }
        </div>
      </div>
      <div className="statuspage-checks-downtime-div">
        <div className="statuspage-checks-downtime statuspage-checks-title">
          Downtime
        </div>
        <div className="statuspage-checks-downtime-data statuspage-checks-data">
          {
            <span className="content-placeholder" style={{ width: "80px" }}>
              &nbsp;
            </span>
          }
        </div>
      </div>
      {/* <div className="statuspage-checks-avg-res-time-div">
        <div className="statuspage-checks-avg-res-time statuspage-checks-title">
          Avg response time
        </div>
        <div className="statuspage-checks-avg-res-time-data statuspage-checks-data">
          {
            <span className="content-placeholder" style={{ width: "80px" }}>
              &nbsp;
            </span>
          }
        </div>
      </div> */}
    </div>
    <div>
      {
        <span
          className="bar-chart-loading content-placeholder"
          style={{ width: "calc(100% - 20px)" }}
        >
          &nbsp;
        </span>
      }
    </div>
  </div>
);

export default PreviewCheckItem;
