/* eslint-disable import/first */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import Select from 'react-select';
import { Button } from 'freemium-ui';
import { uniq } from 'lodash';
import { getOrgId, validateFormFields } from 'utils/utility';
import { request } from 'utils/Api';
import { Close } from 'components/Icons';
import 'react-responsive-ui/style.css';
import PhoneInput from 'react-phone-number-input/react-responsive-ui';
import CloseTwilioPhone from 'images/close_twilio_phone.svg';

class TwilioIntegrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: '',
      createIntegrations: false,
      selectedApplications: [],
      addPhoneField: 1,
      alert_applications: [],
      edit_alert_recipients: [],
      errormessage: ''
    };
  }

  handleChange(evt, value, name, index) {
    const alertApplications = this.state.alert_applications;
    const editAlertRecipients = this.state.edit_alert_recipients;
    if (name === 'alert_traget_numbers') {
      alertApplications[index] = value || '';
      editAlertRecipients[index] = value || '';
      // if (!value) {
      //   editAlertRecipients[index] = "";
      // }
      this.setState({
        alert_applications: [...alertApplications],
        edit_alert_recipients: [...editAlertRecipients]
      });
      return;
    }
    this.setState({
      [evt.target ? evt.target.name : name]: evt.target
        ? evt.target.value
        : evt.value
    });
  }

  handleError(evt) {
    if (
      evt &&
      evt.target &&
      evt.target.nextElementSibling &&
      evt.target.nextElementSibling.classList
        .toString('')
        .indexOf('validation-msg') > -1
    ) {
      evt.target.classList.remove('invalid-input');
      evt.target.nextElementSibling.style.display = 'none';
    } else if (
      evt.target &&
      evt.target.parentNode.nextElementSibling &&
      evt.target.parentNode.nextElementSibling.classList
        .toString('')
        .indexOf('validation-msg') > -1
    ) {
      evt.target.classList.remove('invalid-input');
      evt.target.parentNode.nextElementSibling.style.display = 'none';
    }
  }

  async getApplications() {
    const response = await request(`/applications/?organization=${getOrgId()}`);
    this.setState({ alert_source_applications: [response.results[0].id] });
  }
  addPhoneInputField(e) {
    this.setState({ addPhoneField: this.state.addPhoneField + 1 });
  }
  createNewTwilioIntegration(e) {
    e.preventDefault();
    const formElements = document.forms['create-twilio-integration'].elements;
    if (!validateFormFields(formElements)) return;
    if (this.props.integrationToEdit) {
      this.editTwilioIntegration();
      return;
    }
    this.setState({ createIntegrations: true });
    const self = this;
    const data = {
      twilio_account_sid: this.state.twilio_account_sid,
      twilio_auth_token: this.state.twilio_auth_token,
      twilio_number: this.state.twilio_number,
      alert_source_check_state: this.state.alert_source_check_state,
      state: 'AC',
      organization: getOrgId(),
      alert_source_applications: this.state.alert_source_applications,
      alert_traget_numbers: this.state.alert_applications.join()
    };

    request(`/integrations/sms-alerters/`, {
      method: 'POST',
      payload: data
    })
      .catch(error => console.error('Error-->:', error))
      .then(response => {
        self.setState({ createIntegrations: false });
        if (response.status === 400) {
          for (const prop in response.response) {
            self.setState({ errormessage: response.response[prop][0] });
            break;
          }
        }
        if (response && response.id) {
          self.props.onCloseModal();
          self.props.setTwilioIntegration(response);
          self.props.updateIntegrationCount();

          if (window._na && window._na_module) {
            window._na.sendFeatureEvent('Twillio Create Integration', window._na_module);
          }
        } else if (response && response.response.non_field_errors) {
          console.log(response.response.non_field_errors[0]);
        } else if (response) {
          if (response.data) console.log(response.data[0]);
          else if (response.response.data) {
            console.log(response.response.data[0]);
          }
        }
      });
  }
  editTwilioIntegration() {
    const self = this;
    const data = {};
    this.setState({ createIntegrations: true });
    if (this.state.twilio_account_sid) {
      data.twilio_account_sid = this.state.twilio_account_sid;
    }
    if (this.state.twilio_auth_token) {
      data.twilio_auth_token = this.state.twilio_auth_token;
    }
    if (this.state.twilio_number) data.twilio_number = this.state.twilio_number;
    if (this.state.alert_source_check_state) {
      data.alert_source_check_state = this.state.alert_source_check_state;
    }
    if (this.state.alert_applications.length > 0) {
      const newlyAddedNos = this.state.alert_applications.filter(
        element => element !== null && element !== undefined && element !== ''
      );
      const editedNos = this.state.edit_alert_recipients.filter(
        element => element !== null && element !== undefined && element !== ''
      );
      data.alert_traget_numbers = uniq([...editedNos, ...newlyAddedNos]).join();
    }

    request(`/integrations/sms-alerters/${this.props.integrationToEdit.id}`, {
      method: 'PATCH',
      payload: data
    })
      .catch(error => console.error('Error-->:', error))
      .then(response => {
        self.setState({ createIntegrations: false });
        if (response.status === 400) {
          for (const prop in response.response) {
            self.setState({ errormessage: response.response[prop][0] });
            break;
          }
        }
        if (response.id) {
          self.props.onCloseModal();
          self.props.changeState(response);

          if (window._na && window._na_module) {
            window._na.sendFeatureEvent('Twillio Edit Integration', window._na_module);
          }
        } else if (response.response.non_field_errors) {
          console.log(response.response.non_field_errors[0]);
        } else if (response) {
          if (response.data) console.log(response.data[0]);
          else if (response.response.data) {
            console.log(response.response.data[0]);
          }
        }
      });
  }

  deletePhoneNo(e, number, index) {
    e.stopPropagation();
    const alertApplications = this.state.alert_applications;
    const editAlertRecipients = this.state.edit_alert_recipients;
    alertApplications[index] = '';
    editAlertRecipients[index] = '';
    this.setState({
      alert_applications: [...alertApplications],
      edit_alert_recipients: [...editAlertRecipients]
    });
    e.target.parentElement.style.display = 'none';
  }

  getRecipientsNos() {
    if (!this.props.integrationToEdit) return;
    const recipientsNos = this.props.integrationToEdit.alert_traget_numbers.split(
      ','
    );
    this.setState({ addPhoneField: recipientsNos.length });
    this.setState({ edit_alert_recipients: recipientsNos });
  }
  componentDidMount() {
    this.getApplications();
    this.getRecipientsNos();
  }
  render() {
    const { createIntegrations } = this.state;
    let integrationToEdit = this.props.integrationToEdit;
    let title = 'Edit';
    let buttonTxt = 'Save';
    if (this.props.new) {
      title = 'Create';
      buttonTxt = 'Create';
      integrationToEdit = '';
    }

    // if (integrationToEdit && !this.state.state) {
    //   var state = integrationToEdit.state;
    // } else {
    //   var state = this.state.state;
    // }
    // const { twilio_number } = this.state;
    return (
      <div className="add-new-user modal integration-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h4>{title} Twilio SMS Alert</h4>
          </div>
          <div className="modal-body">
            <form action="" id="create-twilio-integration">
              <div className="form-fields">
                <label htmlFor="twilio_account_sid">
                  Twilio Account SID
                  <span className="mandatory"> *</span>
                </label>
                <input
                  name="twilio_account_sid"
                  placeholder="account id"
                  onChange={e => this.handleChange(e)}
                  onFocus={this.handleError}
                  defaultValue={
                    integrationToEdit
                      ? integrationToEdit.twilio_account_sid
                      : ''
                  }
                  data-labeltext="Twilio Account SID"
                  required
                />
                <label htmlFor="twilio_auth_token">
                  Twilio Auth Token
                  <span className="mandatory"> *</span>
                </label>
                <input
                  name="twilio_auth_token"
                  placeholder="token"
                  autoComplete="off"
                  onChange={e => this.handleChange(e)}
                  onFocus={this.handleError}
                  defaultValue={
                    integrationToEdit ? integrationToEdit.twilio_auth_token : ''
                  }
                  data-labeltext="Twilio Auth Token"
                  required
                />
                <label htmlFor="twilio_number">
                  Twilio Phone Number
                  <span className="mandatory"> *</span>
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={
                    this.state.twilio_number
                      ? this.state.twilio_number
                      : integrationToEdit
                      ? integrationToEdit.twilio_number
                      : ''
                  }
                  country="US"
                  onChange={twilio_number => this.setState({ twilio_number })}
                  onFocus={this.handleError}
                  data-labeltext="Twilio Phone Number"
                  id="twilio-phone-number"
                  required
                />
                <label htmlFor="alert_source_check_state">
                  States to Check<span className="mandatory"> *</span>
                </label>
                <Select
                  style={{ boxShadow: 'none' }}
                  className="user-type-dropdown"
                  value={
                    this.state.alert_source_check_state
                      ? this.state.alert_source_check_state
                      : integrationToEdit.alert_source_check_state
                      ? integrationToEdit.alert_source_check_state
                      : 'AN'
                  }
                  clearable={false}
                  required
                  searchable={false}
                  onChange={(e, value) =>
                    this.handleChange(e, value, 'alert_source_check_state')
                  }
                  options={[
                    { label: 'Any State', value: 'AN' },
                    { label: 'Any Error', value: 'AE' },
                    { label: 'Reporting Error only', value: 'RE' },
                    { label: 'Not Responding only', value: 'NR' }
                  ]}
                />
                <label htmlFor="alert_traget_numbers">
                  Alert Mobile number
                </label>
                {this.state.addPhoneField > 0 &&
                  Array(...Array(this.state.addPhoneField)).map(
                    (count, index) => (
                      <div
                        key={`alert_traget_numbers_${index}`}
                        style={{ display: 'block' }}
                      >
                        <PhoneInput
                          key={`alert_traget_numbers_${index}`}
                          placeholder="Enter phone number"
                          value={
                            this.state.edit_alert_recipients.length > 0
                              ? this.state.edit_alert_recipients[index]
                              : this.state.alert_traget_numbers
                          }
                          country={
                            this.state.edit_alert_recipients.length === 0
                              ? 'US'
                              : this.state.edit_alert_recipients[index]
                          }
                          onChange={value =>
                            this.handleChange(
                              '',
                              value,
                              'alert_traget_numbers',
                              index
                            )
                          }
                        />
                        <div
                          className="fa-times-thin-para"
                          onClick={e =>
                            this.deletePhoneNo(
                              e,
                              this.state.edit_alert_recipients[index],
                              index
                            )
                          }
                        >
                          <img
                            className="fa fa-times-thin"
                            src={CloseTwilioPhone}
                            alt="close"
                          />
                        </div>
                      </div>
                    )
                  )}
                <div
                  className="add-mobile-number"
                  onClick={e => this.addPhoneInputField(e)}
                >
                  + Add mobile number
                </div>
              </div>

              <span className="integration-error-span">
                {!this.state.errormessage ? '' : this.state.errormessage}
              </span>

              <div className="modal-footer">
                <Button
                  type="secondary"
                  inline
                  onClick={() => this.props.onCloseModal()}
                >
                  Cancel
                </Button>
                <Button
                  inline
                  type="primary"
                  disabled={createIntegrations}
                  onClick={e => this.createNewTwilioIntegration(e)}
                >
                  {createIntegrations ? 'Please wait' : buttonTxt}
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="edit-integration-close-container close-icon">
          <span
            title="close icon"
            className="edit-integration-close-image close-image"
            onClick={this.props.onCloseModal.bind(this)}
          >
            <Close />
          </span>
        </div>
      </div>
    );
  }
}

export default TwilioIntegrationModal;
