/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Close } from "components/Icons";
import Select from "shared/components/Select";
import {
  StyledKeyValue,
  AddOptionButton,
  KeyValueSection,
  Label,
  IconButton,
  InputElement
} from "./style";

const propTypes = {
  className: PropTypes.string,
  keyLabel: PropTypes.string,
  valueLabel: PropTypes.string,
  keyPlaceholder: PropTypes.string,
  valuePlaceholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invalid: PropTypes.bool,
  isMulti: PropTypes.bool,
  keyOptions: PropTypes.any,
  filter: PropTypes.instanceOf(RegExp),
  onChange: PropTypes.func
};

const defaultProps = {
  className: undefined,
  value: undefined,
  icon: undefined,
  isMulti: false,
  invalid: false,
  keyOptions: false,
  filter: undefined,
  onChange: () => {}
};

const KeyValue = forwardRef(
  (
    {
      className,
      filter,
      onChange,
      isMulti,
      keyPlaceholder = "",
      valuePlaceholder = "",
      keyLabel,
      valueLabel,
      name,
      value,
      keyOptions,
      ...inputProps
    },
    ref
  ) => {
    const handleChange = (val, key) => {
      const newValue = { ...value, [key]: val };

      onChange(newValue);
    };

    const handleMultiChange = (event, key, index) => {
      event.preventDefault();
      event.stopPropagation();
      const newValue = value.map((v, i) => {
        if (i === index) {
          return { ...v, [key]: event.target.value };
        }
        return v;
      });

      onChange(newValue);
    };

    const addOption = e => {
      e.preventDefault();
      e.stopPropagation();
      onChange([...value, { key: "", value: "" }]);
    };

    const removeOption = index => {
      onChange(value.filter((v, i) => i !== index));
    };

    const [key, keyValue] = Object.keys(value);
    // console.log("v", value[key]);
    return (
      <div>
        <StyledKeyValue isMulti={isMulti} className={className}>
          {isMulti ? (
            value.map((node, index) => (
              <KeyValueSection key={`${name}-${index}-multi-option`}>
                {index !== 0 && (
                  <IconButton
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeOption(index);
                    }}
                  >
                    <Close width="10" height="10" />
                  </IconButton>
                )}
                <div className="field-wrap">
                  <InputElement
                    {...inputProps}
                    placeholder={keyPlaceholder}
                    value={node.key}
                    onChange={e => handleMultiChange(e, "key", index)}
                    ref={ref}
                  />
                </div>
                <div className="field-wrap">
                  <InputElement
                    {...inputProps}
                    placeholder={valuePlaceholder}
                    value={node.value}
                    onChange={e => handleMultiChange(e, "value", index)}
                    ref={ref}
                  />
                </div>
              </KeyValueSection>
            ))
          ) : (
            <>
              <div className={`field-wrap ${keyOptions && "one-third"}`}>
                {keyOptions ? (
                  <Select
                    withClearValue={false}
                    options={keyOptions}
                    value={value[key]}
                    onChange={val => handleChange(val, key)}
                  />
                ) : (
                  <>
                    <Label>{keyLabel}</Label>
                    <InputElement
                      {...inputProps}
                      placeholder={keyPlaceholder}
                      value={value[key]}
                      onChange={e => handleChange(e.target.value, key)}
                      ref={ref}
                    />
                  </>
                )}
              </div>
              <div className={`field-wrap ${keyOptions && "two-third"}`}>
                <Label>{valueLabel}</Label>
                <InputElement
                  {...inputProps}
                  placeholder={valuePlaceholder}
                  value={value[keyValue]}
                  onChange={e => handleChange(e.target.value, keyValue)}
                  ref={ref}
                />
              </div>
            </>
          )}
        </StyledKeyValue>
        {isMulti && (
          <AddOptionButton onClick={addOption}>
            {" "}
            +&nbsp;Add custom header
          </AddOptionButton>
        )}
      </div>
    );
  }
);

KeyValue.propTypes = propTypes;
KeyValue.defaultProps = defaultProps;

export default KeyValue;
